import { Link } from 'react-router-dom';
// context
import { useTournamentContext } from '../TournamentProvider';
// types
import { TournamentSubViews } from '@components/tournament/types';
// components
import Banner from './BannerBox/Banner';
import Info from './InfoBox/Info';
// icons
import { GoBackIcon, NextRouteIcon } from '../../common/icons/TournamentIcons';

interface IHero {
  setCurrentSubView: (subView: TournamentSubViews) => void;
}

const Hero: React.FC<IHero> = ({ setCurrentSubView }) => {
  const { tournament } = useTournamentContext();

  return tournament ? (
    <div
      className={`
        relative mx-auto custom_container

        lg:px-12

        max-md:bg-lightBlack

        md:px-4
      `}
    >
      <Link
        to="/games/apex"
        className={`
          absolute left-2 top-2 z-10 mt-3 rounded-full bg-black/40 p-3
          duration-300 mobile_goback_icon

          hover:bg-black/80

          md:hidden
        `}
      >
        <GoBackIcon />
      </Link>
      <div
        className={`
          hidden items-center justify-between pb-8

          md:flex
        `}
      >
        <Link
          to="/games/apex"
          className={`
            font-compact group flex items-center gap-2 rounded-xl border py-3
            pe-4 ps-3 text-base font-medium !leading-4 !text-white
            border-lightGray

            hover:opacity-80
          `}
        >
          <span
            className={`
              duration-300

              group-hover:translate-x-[-0.313rem]
            `}
          >
            <GoBackIcon />
          </span>
          <span className="-mb-0.5"> Back</span>
        </Link>
        <div className="flex items-center gap-4">
          <Link
            to="/games/apex"
            className={`
              font-compact -mb-0.5 text-sm font-normal !leading-4 text-ebonyClay
              duration-200

              hover:text-slateGray
            `}
          >
            Tournaments
          </Link>
          <span>
            <NextRouteIcon />
          </span>
          <span
            className={`
              font-compact -mb-0.5 text-sm font-normal !leading-4 text-slateGray
            `}
          >
            {tournament.name}
          </span>
        </div>
      </div>
      <div
        className={`
          flex w-full flex-col gap-10

          custom_xl:flex-row
        `}
      >
        <div
          className={`
            order-2 mt-2 w-full

            custom_xl:w-[45%] custom_xl:order-1

            md:mt-0
          `}
        >
          <Info setCurrentSubView={setCurrentSubView} />
        </div>
        <div
          className={`
            order-1 hidden

            custom_xl:w-full custom_xl:order-2

            md:block
          `}
        >
          <div
            className={`
              bg-lightBlack h-full

              custom_xl:rounded-xl
            `}
          >
            <Banner />
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

export default Hero;
