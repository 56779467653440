import {
  RefObject,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
// firebase
import { firestore } from '@src/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
// context
import { useTeamInfoContext } from '@components/team/TeamInfoProvider';
// types
import { TeamEditModalStep } from '../../../types';
// utils
import { debounce } from '@utils/Debounce';
import {
  isDiscordLinkValid,
  isTwitchLinkValid,
  isTwitterLinkValid,
  isWebsiteLinkValid,
} from '@utils/validations/userSocials';
// libraries
import { toast } from 'react-toastify';
// components
import Input from '@ui/Input';
import Modal from '@ui/Modal';
// assets
import step1PositionImg from '@assets/images/png/team/step1PositionImg.webp';
// icons
import {
  SelectImageIcon,
  UploadArrowIcon,
  UploadLogoIcon,
} from '@icons/ProfileIcons';
import { IoClose } from 'react-icons/io5';
import {
  DiscordIcon,
  TwitterIcon,
  TwitchIcon,
  WebsiteIcon,
} from '@icons/Socials';
import { FaArrowRight } from 'react-icons/fa';

const Step1 = () => {
  const {
    changeModalStep,
    currentEditModalStep,
    setCurrentEditModalStep,
    teamInfo,
    setTeamInfo,
    edit,
  } = useTeamInfoContext();

  const [formFilled, setFormFilled] = useState(false); // Track whether the form is filled

  const [teamNameValid, setTeamNameValid] = useState<boolean>(false);
  const [teamTagValid, setTeamTagValid] = useState<boolean>(false);
  const [teamNameUnique, setTeamNameUnique] = useState<boolean>(true);

  const [twitchLinkValid, setTwitchLinkValid] = useState<boolean>(false);
  const [twitterLinkValid, setTwitterLinkValid] = useState<boolean>(false);
  const [websiteLinkValid, setWebsiteLinkValid] = useState<boolean>(false);
  const [discordLinkValid, setDiscordLinkValid] = useState<boolean>(false);

  const validateFields = useCallback(() => {
    setTeamNameValid(/^[a-zA-Z0-9-_. ']{2,30}$/.test(teamInfo.teamName));
    setTeamTagValid(/^[a-zA-Z0-9]{2,3}$/.test(teamInfo.teamTag));

    setTwitchLinkValid(
      teamInfo.twitchLink === '' || isTwitchLinkValid(teamInfo.twitchLink)
    );
    setTwitterLinkValid(
      teamInfo.twitterLink === '' || isTwitterLinkValid(teamInfo.twitterLink)
    );
    setWebsiteLinkValid(
      teamInfo.websiteLink === '' || isWebsiteLinkValid(teamInfo.websiteLink)
    );
    setDiscordLinkValid(
      teamInfo.discordLink === '' || isDiscordLinkValid(teamInfo.discordLink)
    );
  }, [teamInfo]);

  useEffect(() => {
    validateFields();
  }, [teamInfo, validateFields]);

  useEffect(() => {
    if (teamInfo.logo) {
      if (
        !['image/jpeg', 'image/png', 'image/webp'].includes(teamInfo.logo.type)
      ) {
        removeLogoImageAttachement();
        toast.error(`You can only upload jpeg, png or webp image formats`);
      }
    }
  }, [teamInfo]);

  useEffect(() => {
    if (teamInfo.banner) {
      if (
        !['image/jpeg', 'image/png', 'image/webp'].includes(
          teamInfo.banner.type
        )
      ) {
        removeBannerImageAttachement();
        toast.error(`You can only upload jpeg, png or webp image formats`);
      }
    }
  }, [teamInfo]);

  // emulated clicks so file upload inputs can be opened from external buttons
  const bannerImageRef: RefObject<HTMLInputElement> = useRef(null);
  const logoImageRef: RefObject<HTMLInputElement> = useRef(null);

  const clickBannerUpload = () => {
    bannerImageRef.current!.click();
  };

  const clickLogoUpload = () => {
    logoImageRef.current!.click();
  };

  const handleBannerImageAttach = (e: { target: HTMLInputElement }) => {
    const banner = e.target.files![0];
    const previewUrl = URL.createObjectURL(banner);
    setTeamInfo({ ...teamInfo, banner: banner, bannerPreviewUrl: previewUrl });
  };

  const removeBannerImageAttachement = () => {
    setTeamInfo({ ...teamInfo, banner: null, bannerPreviewUrl: '' });
  };

  const handleLogoImageAttach = (e: { target: HTMLInputElement }) => {
    const logo = e.target.files![0];
    const previewUrl = URL.createObjectURL(logo);
    setTeamInfo({ ...teamInfo, logo: logo, logoPreviewUrl: previewUrl });
  };

  const removeLogoImageAttachement = () => {
    setTeamInfo({ ...teamInfo, logo: null, logoPreviewUrl: '' });
  };

  const checkTeamNameUnique = useCallback(
    async (teamName: string) => {
      const checkTeamExists = async (teamName: string) => {
        const teamsCollectionRef = collection(firestore, 'teams');
        const q = query(teamsCollectionRef, where('teamName', '==', teamName));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
      };

      const originalName = teamInfo.originalTeamName;

      let teamNameExists = false;

      if (edit) {
        if (teamName !== originalName) {
          teamNameExists = await checkTeamExists(teamName);
        } else {
          teamNameExists = false;
        }
      } else {
        teamNameExists = await checkTeamExists(teamName);
      }

      setTeamNameUnique(!teamNameExists);
    },
    [edit, teamInfo.originalTeamName]
  );

  const debouncedCheckTeamNameUnique = useMemo(
    () => debounce(checkTeamNameUnique, 500),
    [checkTeamNameUnique]
  );

  const validateForm = useCallback(async () => {
    const teamFieldsValid = teamNameValid && teamNameUnique && teamTagValid;
    const teamLinksValid =
      twitchLinkValid &&
      twitterLinkValid &&
      websiteLinkValid &&
      discordLinkValid;
    const teamUploadsValid =
      teamInfo.logoPreviewUrl !== '' || teamInfo.logo !== null;
    const isFormFilled = teamFieldsValid && teamUploadsValid && teamLinksValid;
    setFormFilled(isFormFilled);
  }, [
    discordLinkValid,
    teamInfo,
    teamNameUnique,
    teamNameValid,
    teamTagValid,
    twitchLinkValid,
    twitterLinkValid,
    websiteLinkValid,
  ]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleStepSubmit = () => {
    changeModalStep(1);
  };

  return (
    <Modal
      title="Edit Team"
      step={1}
      totalSteps={3}
      stepNames={['General', 'Details', 'Payment']}
      scroll={true}
      buttonText={
        <div className="flex items-center gap-x-2">
          <p>Next</p>
          <FaArrowRight className="-translate-y-[1px]" />
        </div>
      }
      buttonDisabled={!formFilled}
      buttonOnClick={handleStepSubmit}
      open={currentEditModalStep === TeamEditModalStep.profile}
      setOpen={(open: boolean) => {
        setCurrentEditModalStep(open ? TeamEditModalStep.profile : -1);
      }}
    >
      <div className="max-[26.25rem]:px-3 px-6">
        <div className="rounded-2xl bg-lightGray h-[11.063rem] mt-6 relative overflow-hidden">
          {teamInfo.bannerPreviewUrl ? (
            <>
              <img
                src={teamInfo.bannerPreviewUrl}
                alt="banner-image"
                className="w-full h-[11.063rem] object-cover"
              />
              <button
                type="button"
                aria-label="remove banner attachment"
                onClick={() => removeBannerImageAttachement()}
                className="absolute z-50 top-1 right-1 p-1 bg-black/40 backdrop-blur rounded-full hover:opacity-75"
              >
                <p className="text-red text-lg">
                  <IoClose />
                </p>
              </button>
            </>
          ) : (
            <div className="p-4">
              <img
                src={step1PositionImg}
                className="absolute top-0 start-0 max-w-[19.188rem] h-full"
              />
              <div className="flex justify-end">
                <span className="cursor-pointer" onClick={clickBannerUpload}>
                  <span className="rounded-full bg-ebonyClay p-3 w-[2.5rem] h-[2.5rem] cursor-pointer flex items-center justify-center">
                    <SelectImageIcon />
                  </span>
                </span>
              </div>
              <input
                hidden
                id="upload-photo"
                type="file"
                accept=".jpg, .jpeg, .png, .webp"
                ref={bannerImageRef}
                onChange={(e) => handleBannerImageAttach(e)}
              />
            </div>
          )}
        </div>
        <div className="rounded-2xl border-4 border-solid border-lightBlack bg-lightGray w-[7.5rem] h-[7.5rem] mx-auto -mt-[4.375rem] z-50 relative overflow-hidden">
          {teamInfo.logoPreviewUrl ? (
            <>
              <img
                src={teamInfo.logoPreviewUrl}
                className="w-full h-full object-cover"
              />
              <button
                type="button"
                aria-label="remove logo attachment"
                onClick={() => removeLogoImageAttachement()}
                className="absolute z-50 top-1 right-1 p-1 bg-black/40 backdrop-blur rounded-full hover:opacity-75"
              >
                <p className="text-red text-lg">
                  <IoClose />
                </p>
              </button>
            </>
          ) : (
            <div className="h-full relative">
              <p className="absolute top-2 right-2 text-steelGray font-compact font-semibold text-xl">
                *
              </p>
              <button
                type="button"
                aria-label="attach logo file"
                onClick={clickLogoUpload}
                className="w-full h-full cursor-pointer flex justify-center items-center"
              >
                <UploadLogoIcon />
              </button>
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .webp"
                hidden
                required
                name="photo"
                ref={logoImageRef}
                id="upload-logo"
                onChange={handleLogoImageAttach}
              />
            </div>
          )}
        </div>
        <div className="flex gap-1 items-center pt-4 justify-center pb-6">
          <p className="font-compact text-base font-medium tracking-[0.01rem] uppercase text-white text-center !leading-4 -mb-1">
            upload a team logo
          </p>
          <span className="block">
            <UploadArrowIcon />
          </span>
        </div>
      </div>

      <div className="flex flex-col max-[26.25rem]:p-3 px-6 text-start">
        <Input
          type="text"
          label="Team Name"
          required={true}
          valid={teamNameValid && teamNameUnique}
          value={teamInfo.teamName}
          onChange={(newValue) => {
            debouncedCheckTeamNameUnique(newValue);
            setTeamInfo({ ...teamInfo, teamName: newValue });
          }}
          placeholder="Team name here"
          black={true}
        />
        <p
          className={`font-compact font-normal text-sm tracking-[0.009rem] leading-[114.286%] pt-2 text-end
                       ${teamNameValid ? 'text-green' : 'text-red'} opacity-60`}
        >
          {teamInfo.teamName.length}/{30}
        </p>
        {!teamNameUnique ? (
          <p
            className={`font-compact font-semibold text-sm tracking-[0.009rem] leading-[114.286%] pt-2 text-end text-red opacity-60`}
          >
            Team name: "{teamInfo.teamName}" already in use.
          </p>
        ) : (
          ''
        )}

        <Input
          type="text"
          label="Team Tag"
          required={true}
          valid={teamTagValid}
          value={teamInfo.teamTag}
          onChange={(newValue) => {
            setTeamInfo({ ...teamInfo, teamTag: newValue.toUpperCase() });
          }}
          placeholder="Team tag here"
          black={true}
        />
        <p
          className={`font-compact font-normal text-sm tracking-[0.009rem] leading-[114.286%] pt-2 text-end
                        ${teamTagValid ? 'text-green' : 'text-red'} opacity-60`}
        >
          2-3 Characters
        </p>
      </div>

      <div className="font-compact text-base font-normal px-6">
        <div className="text-steelGray flex items-center gap-x-2">
          <p className="text-left">Socials</p>{' '}
          <p className="text-sm opacity-80 mb-[0.5px]">(optional)</p>
        </div>

        <div className="flex flex-col gap-y-3">
          <div className="flex items-center">
            <div className="flex items-center gap-x-2">
              <DiscordIcon className="w-[40px] h-auto aspect-square p-[0.7rem] fill-white" />
            </div>
            <Input
              type="text"
              required={false}
              valid={discordLinkValid}
              value={teamInfo.discordLink}
              onChange={(newValue) => {
                setTeamInfo({ ...teamInfo, discordLink: newValue });
              }}
              placeholder="discord.gg/versus-gg"
              black={true}
            />
          </div>

          <div className="flex items-center">
            <div className="flex items-center gap-x-2">
              <TwitterIcon className="w-[40px] h-auto aspect-square p-[0.7rem] fill-white" />
            </div>
            <Input
              type="text"
              required={false}
              valid={twitterLinkValid}
              value={teamInfo.twitterLink}
              onChange={(newValue) => {
                setTeamInfo({ ...teamInfo, twitterLink: newValue });
              }}
              placeholder="twitter.com/gg_versus"
              black={true}
            />
          </div>

          <div className="flex items-center">
            <div className="flex items-center gap-x-2">
              <TwitchIcon className="w-[40px] h-auto aspect-square p-[0.7rem] fill-white" />
            </div>
            <Input
              type="text"
              required={false}
              valid={twitchLinkValid}
              value={teamInfo.twitchLink}
              onChange={(newValue) => {
                setTeamInfo({ ...teamInfo, twitchLink: newValue });
              }}
              placeholder="twitch.tv/versus_gg_"
              black={true}
            />
          </div>

          <div className="flex items-center">
            <div className="flex items-center gap-x-2">
              <WebsiteIcon className="w-[40px] h-auto aspect-square p-[0.7rem] fill-white" />
            </div>
            <Input
              type="text"
              required={false}
              valid={websiteLinkValid}
              value={teamInfo.websiteLink}
              onChange={(newValue) => {
                setTeamInfo({ ...teamInfo, websiteLink: newValue });
              }}
              placeholder="example.com"
              black={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Step1;
