// Importing image assets
import vStudios from '../../assets/images/png/services/vStudio_logo.webp';
import heroDiamondImage from '../../assets/images/svg/about/about_hero_diamond_img.svg';
import aboutHeroGlowArrow from '../../assets/images/svg/services/about_hero_glow_arrow.svg';
import services_line_box_icon from '../../assets/images/svg/services/services_line_box_icon.svg';
import {
  ServiceLineForMobile,
  ServicesLineForDesktop,
} from '../common/icons/Services';

// React functional component for the ServicesHero
const ServicesHero = () => {
  return (
    <>
      {/* Container for the ServicesHero */}
      <div className="relative pb-[11.25rem] -mb-40 sm:-mb-14 z-[1]">
        {/* Diamond image positioned absolutely */}
        <img
          className="absolute -top-24 sm:-top-16 left-1/2 -translate-x-1/2 max-h-[34.813rem] max-w-[34.813rem] w-full h-full z-10 object-cover"
          src={heroDiamondImage}
          alt="diamond-image"
        />

        {/* Main content section with flex layout */}
        <div className="relative z-[11] flex items-center justify-center flex-col">
          {/* Glow arrow image */}
          <img src={aboutHeroGlowArrow} alt="glow-arrow" />

          {/* Desktop and mobile line components */}
          <span className="absolute services_hero_graph_sm_line w-full top-[26%] sm:top-[20%] xl:top-[23%] block sm:hidden">
            <ServicesLineForDesktop />
          </span>
          <span className="absolute w-full top-[13%] sm:top-[20%] xl:top-[23%] hidden sm:block">
            <ServiceLineForMobile />
          </span>

          {/* Logo image */}
          <div className="max-w-[14rem] md:max-w-[21.875rem] lg:max-w-[25rem] xl:max-w-[28.75rem] pt-[3.313rem] md:pt-20">
            <img src={vStudios} alt="page-logo" />
          </div>

          {/* Title and box icon */}
          <p className="text-white text-center font-medium text-sm uppercase pt-3 sm:pt-6 font-wide tracking-[4.2px]">
            ESPORTS SOLUTIONS
          </p>
          <img src={services_line_box_icon} alt="box-icon" />
        </div>
      </div>
    </>
  );
};

// Exporting the ServicesHero component
export default ServicesHero;
