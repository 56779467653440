export enum LeaderboardSortingOption {
  totalWinningsDesc,
  trophiesWonDesc,
}

export enum TeamsLeaderboardSortingOption {
  totalWinningsDesc,
  trophiesWonDesc,
  apexWinNumDesc,
  apexWinRateDesc,
}
