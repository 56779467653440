// context
import {
  useTournamentsContext,
  TournamentsRegionOption,
  TournamentsSortingOption,
} from '../TournamentsProvider';
// components
import TournamentCard from './TournamentCard';
import SkeletonTournamentCard from './SkeletonTournamentCard';
import SearchSortFilterBar from '@components/global/SearchSortFilterBar';
// icons
import { VscDebugDisconnect } from 'react-icons/vsc';
import { ImSpinner8 } from 'react-icons/im';

export const regionDropdownOptions = [
  { value: TournamentsRegionOption.ALL, label: 'All Regions' },
  { value: TournamentsRegionOption.EMEA, label: 'EMEA' },
  { value: TournamentsRegionOption.NA, label: 'NA' },
  { value: TournamentsRegionOption.APAC, label: 'APAC' },
  { value: TournamentsRegionOption.LATAM, label: 'LATAM' },
];

export const sortingDropdownOptions = [
  { value: TournamentsSortingOption.upNext, label: 'Up Next' },
  { value: TournamentsSortingOption.prizeDesc, label: 'Prize: High-Low' },
  { value: TournamentsSortingOption.nameAsc, label: 'Name: A-Z' },
];

const TournamentList = () => {
  const {
    tournaments,
    tournamentsLoaded,
    initiallyLoaded,
    searchQuery,
    setSearchQuery,
    searchQueued,
    sortingOption,
    setSortingOption,
    regionOption,
    setRegionOption,
  } = useTournamentsContext();

  return (
    <div className="overflow-hidden">
      <SearchSortFilterBar
        variant="tournament"
        listTitle="tournaments"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        // Region dropdown
        regionDropdownOptions={regionDropdownOptions}
        selectedRegionOption={regionOption}
        setSelectedRegionOption={setRegionOption}
        // Sorting dropdown
        selectedSortingOption={sortingOption}
        setSelectedSortingOption={setSortingOption}
        sortingOptions={sortingDropdownOptions}
        initiallyLoaded={initiallyLoaded}
        searchBarPlaceholder="Search for a tournament"
      />

      {tournamentsLoaded ? (
        <>
          {searchQueued ? (
            <div
              className={`
                flex min-h-[350px] flex-col items-center justify-center
              `}
            >
              <ImSpinner8
                className={`
                  aspect-square h-auto w-[60px] text-green animate-spin
                `}
              />
            </div>
          ) : (
            <>
              {tournaments.length > 0 ? (
                <div className="overflow-auto">
                  <div
                    className={`
                      mt-8 flex justify-start flex-wrap-0 w-full gap-8
                      overflow-auto pb-3 text-white scrollbar_none

                      lg:grid lg:grid-cols-3

                      md:overflow-hidden

                      xl:grid-cols-4
                    `}
                  >
                    {tournaments.map((tournament) => (
                      <TournamentCard
                        key={`main-list-${tournament.id}`}
                        tournament={tournament}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div
                  className={`
                    flex min-h-[350px] flex-col items-center justify-center
                    gap-y-2
                  `}
                >
                  <div
                    className={`
                      flex aspect-square h-auto items-center justify-center
                      bg-lightGray w-[50px] rounded-full
                    `}
                  >
                    <VscDebugDisconnect className="text-2xl text-white" />
                  </div>
                  <p
                    className={`
                      font-wide text-2xl font-semibold uppercase text-white
                    `}
                  >
                    No tournaments found
                  </p>
                  <p className="text-steelGray font-compact">
                    Try adjusting your search parameters
                  </p>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="overflow-auto">
          <div
            className={`
              flex w-full overflow-auto pb-3 text-white scrollbar_none

              md:flex-wrap md:overflow-hidden
            `}
          >
            {Array.from({ length: 6 }).map((_i, index) => (
              <SkeletonTournamentCard key={`main-list-${index}`} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TournamentList;
