import { useState } from 'react';
// context
import PlayersProvider from './PlayersProvider';
// components
import PlayerList from './components/PlayerList';
import FindTeam from '@common/FindTeam';

const Players = () => {
  const [playersLoaded, setPlayersLoaded] = useState<boolean>(false);

  return (
    <div className="mx-auto">
      <PlayersProvider announcePlayersLoaded={setPlayersLoaded}>
        <div className="">
          <PlayerList />
          <div className="">
            <FindTeam loaded={playersLoaded} />
          </div>
        </div>
      </PlayersProvider>
    </div>
  );
};

export default Players;
