import { ReactNode } from 'react';

interface IPopoverText {
  className?: string;
  bottom?: boolean;
  right?: boolean;
  visible: boolean;
  children: ReactNode;
}

const PopoverText: React.FC<IPopoverText> = ({
  right,
  children,
  visible,
  className,
  bottom,
}) => {
  return (
    <div
      className={`
        ${className}
        ${visible ? '' : 'opacity-0'}

        pointer-events-none absolute z-[20] w-fit transition-opacity

        ${
          bottom
            ? 'bottom-0 translate-y-[calc(100%+10px)]'
            : 'top-0 -translate-y-[calc(100%+10px)]'
        }
        ${right ? 'right-0' : 'left-0'}

        flex flex-col rounded-lg p-2 bg-lightBlack/70 border backdrop-blur-md
        border-steelGray/50 gap-y-3 font-semibold
      `}
    >
      <p className="whitespace-nowrap text-sm font-thin text-white font-compact">
        {children}
      </p>
    </div>
  );
};

export default PopoverText;
