import { useContext } from 'react';
// context
import { PlayersContext } from './PlayersProvider';

export const usePlayersContext = () => {
  const context = useContext(PlayersContext);
  return context;
};

export const PlayerResultsPerPage = 30;
