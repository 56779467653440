import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BannerContent } from '../common/content/HomePageContent';

const Hero = () => {
  return (
    <Swiper
      className="landing_hero_swiper"
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      navigation={{
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      }}
      effect={'fade'}
      autoplay={{
        delay: 5000,
      }}
      modules={[EffectFade, Navigation, Pagination, Autoplay]}
      loop={true}
    >
      {/* HERO SECTION SLIDER DATA THROUGH THE MAP WITH THE HELP OF THE ARRAY */}
      {BannerContent.map((obj, index) => (
        <SwiperSlide
          key={index}
          className={`
            relative flex h-[40.625rem] w-full items-center bg-cover
            bg-no-repeat

            sm:h-[36.25rem]

            xl:h-[45rem]

            ${obj.image}
          `}
        >
          <img
            className={`
              absolute bottom-0 right-0 hidden

              lg:bottom-[-0.188rem]

              md:bottom-[-1.188rem]

              sm:bottom-[-15.563rem] sm:block

              ${index === 0 ? 'z-0' : 'z-[1]'}
            `}
            src={obj.bannerEffectForBottom}
            alt="cartton-img-1"
          />
          <img
            className={`
              absolute bottom-0 right-0

              sm:hidden

              ${index === 0 ? 'z-0' : 'z-[1]'}
            `}
            src={obj.bannerEffectForBottomMobile}
            alt="cartton-img-1"
          />
          <div
            className={`
              relative mx-auto flex w-full flex-col justify-center text-center
              custom_container
            `}
          >
            <button className="absolute swiper-button-next"></button>
            <button className="absolute swiper-button-prev"></button>
            <img
              className={`
                ${
                  index === 0
                    ? `
                      right-1/2 top-[3.625rem] z-0 w-[19.375rem] translate-x-1/2

                      custom_4xl:top-[-9.375rem]

                      lg:right-[15.625rem] lg:top-[4.5rem] lg:!w-[21.875rem]

                      md:right-40 md:top-[11.8rem] md:w-[17.5rem]

                      min-[1599px]:right-[24.25rem]

                      sm:right-[4.688rem] sm:top-[7.813rem] sm:w-[16.25rem]
                      sm:translate-x-0

                      xl:right-[20.25rem] xl:top-[0.875rem] xl:!w-[29.25rem]
                    `
                    : `
                      !right-1/2 !top-[-1.375rem] !z-[-1] !w-[19.375rem]
                      !translate-x-1/2

                      lg:!top-[1.375rem] lg:!w-[19.5rem]

                      md:!right-[4.375rem] md:!top-[5.875rem] md:!w-[17.625rem]

                      min-[1599px]:!right-[8%]

                      sm:!right-5 sm:!top-[5.438rem] sm:!w-[18.75rem]
                      sm:!translate-x-0

                      xl:!-top-[2.25rem] xl:!w-[25rem]
                    `
                }

                absolute hidden

                sm:block
              `}
              src={obj.avatar}
              alt="cartton-img-1"
            />
            <img
              className={`
                absolute left-1/2 top-[-5rem] w-full -translate-x-1/2

                sm:hidden

                ${index === 0 ? '' : 'opacity-60'}
              `}
              src={obj.bannerCartoonForMobile}
              alt="bannerCartoonForMobile"
            />
            <img
              className={`
                absolute right-[0.188rem] top-[-4.875rem] z-[-1] w-[9.375rem]

                lg:right-[10.438rem] lg:top-[2.938rem] lg:w-[12.5rem]

                md:right-20 md:top-[10.438rem] md:w-[10.625rem]

                min-[1599px]:right-[16.313rem]

                sm:right-0 sm:top-[6.25rem]

                xl:right-[11.313rem] xl:top-[1.063rem] xl:w-[14.875rem]

                ${
                  index === 0
                    ? `
                      hidden

                      sm:block
                    `
                    : 'hidden'
                }
              `}
              src={obj.avatarSecond}
              alt="cartton-img-2"
            />
            <img
              className={`
                ${
                  index === 0
                    ? `
                      left-[0.188rem] z-0 w-[16.25rem]

                      lg:left-[14.313rem] lg:top-[2.938rem] lg:w-[22.75rem]

                      md:left-[11.125rem] md:top-[10.875rem] md:w-[18.75rem]

                      min-[99.938rem]:left-[22%]

                      sm:left-[4.313rem] sm:top-[8rem]

                      xl:-top-0.5 xl:left-[19.188rem] xl:w-auto
                    `
                    : `
                      !left-[0.188rem] z-0 w-[11.25rem]

                      lg:!top-[1.375rem] lg:w-[16.5rem]

                      md:!left-[7.625rem] md:!top-[8rem] md:w-[13.875rem]

                      min-[1599px]:!left-[15%]

                      sm:!left-5 sm:!top-[7.813rem]

                      xl:!left-[10.438rem] xl:!top-[2.375rem] xl:w-[20rem]
                    `
                }

                absolute !hidden

                sm:!block
              `}
              src={obj.avatarThird}
              alt="cartton-img-3"
            />
            <img
              className={`
                ${index === 0 ? 'block' : '!hidden'}

                absolute left-0 z-[-1] hidden w-[11.25rem]

                lg:left-[9.25rem] lg:top-[3.125rem] lg:w-[18.75rem]

                md:left-24 md:top-[11.813rem] md:w-[13.875rem]

                min-[1599px]:left-[14%]

                sm:top-[8rem] sm:block

                xl:left-[12.375rem] xl:top-[5.313rem]
              `}
              src={obj.avatarFour}
              alt="cartton-img-4"
            />
            <div
              className={`
                relative mx-auto w-full pt-6

                lg:pb-14

                sm:w-8/12 sm:pb-48 sm:pt-0
              `}
            >
              <span className="flex justify-center">{obj.bannerLine}</span>
              <h1
                className={`
                  ${
                    index === 0
                      ? 'text-white'
                      : 'text-lightGray mx-auto max-w-[50rem]'
                  }

                  max-w-[46rem] px-12 pt-3 text-[3.15rem] font-bold uppercase
                  leading-[90%] font-wide mx-auto flex items-center
                  justify-center text-center

                  lg:pt-5 lg:text-[5.04rem]

                  md:text-[3.78rem]

                  sm:pt-0 sm:text-[3.6rem]

                  xl:text-[5.58rem]
                `}
              >
                {obj.title}
              </h1>
              <p
                className={`
                  ${index === 0 ? 'text-white' : 'text-lightGray'}

                  py-10 text-base font-normal uppercase tracking-[0.2rem]
                  font-compact

                  lg:py-8

                  max-[26.25rem]:py-4

                  sm:py-6

                  xl:py-10
                `}
              >
                {obj.description}
              </p>
              <div className="z-10 flex justify-center gap-6">
                <span>{obj.bannerLeftLine}</span>
                <Link
                  to={obj.link}
                  className={`
                    relative z-[1] flex items-center justify-center
                    whitespace-nowrap rounded-xl pb-2 pt-3 text-sm uppercase
                    duration-300 a-auto font-compact font-medium

                    md:text-base

                    sm:pb-3 sm:pt-4

                    ${
                      index === 0
                        ? `
                          bg-green px-[2.47rem] text-black

                          hover:bg-gorse
                        `
                        : `
                          bg-lightGray border-lightGray border px-[3.7rem]
                          text-white

                          hover:bg-black
                        `
                    }
                  `}
                >
                  {obj.button}
                </Link>
                <span>{obj.bannerRightLine}</span>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Hero;
