import { useEffect, useState, useCallback } from 'react';
// firebase
import { functions } from '../../../../firebase';
import { httpsCallable } from 'firebase/functions';
// context
import { useTournamentContext } from '../../TournamentProvider';
import { useTournamentAdminContext } from '../TournamentAdminProvider';
// types
import { TournamentStage } from '../../../../firestore/tournaments';
// components
import Group from './Group';
import Unassigned from './Unassigned';
// libaries
import { toast } from 'react-toastify';
// icons
import { TbRefresh } from 'react-icons/tb';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import { VscDebugDisconnect } from 'react-icons/vsc';

const seedTournamentGroups = httpsCallable(functions, 'seedTournamentGroups');

interface IStage {
  stage: TournamentStage;
}

const Stage: React.FC<IStage> = ({ stage }) => {
  const { tournament } = useTournamentContext();
  const { confirmedTeams, setStageLocked, groupGames } =
    useTournamentAdminContext();

  const [mapsChosen, setMapsChosen] = useState<boolean>(false);

  const checkGameMapsPopulated = useCallback(() => {
    if (groupGames) {
      let populated = true;
      Object.values(groupGames).forEach((groupGames) => {
        groupGames.forEach((game) => {
          if (game.map === '') populated = false;
        });
      });
      return populated;
    } else {
      return false;
    }
  }, [groupGames]);

  useEffect(() => {
    setMapsChosen(checkGameMapsPopulated());
  }, [groupGames, checkGameMapsPopulated]);

  const unassignedTeams = confirmedTeams.filter((team) => {
    const notInGroup = !team.groups.some((group) =>
      stage.groups.some((stageGroup) => group.groupId === stageGroup.id)
    );
    const notEliminated = !team.eliminated;
    return notInGroup && notEliminated;
  });

  const [initialGroupFocused, setInitialGroupFocused] =
    useState<boolean>(false);
  const [currentGroup, setCurrentGroup] = useState<string>(''); // either the group's id, or strings: 'unassigned'  | 'declined' | 'ignored'
  const [reSeeding, setReSeeding] = useState<boolean>(false);

  const handleSeedTournaments = async () => {
    if (tournament) {
      setReSeeding(true);
      const seedPromise = seedTournamentGroups({
        tournamentId: tournament.id,
        stageNum: stage.stageNum,
      });
      toast.promise(seedPromise, {
        pending: 'Re-seeding Teams',
        success: 'Teams re-seeded succesfully',
        error: 'Error re-seeding teams',
      });
      await seedPromise;
      setReSeeding(false);
    }
  };

  useEffect(() => {
    if (!initialGroupFocused && stage.groups.length > 0) {
      setCurrentGroup(stage.groups[0].id);
      setInitialGroupFocused(true);
    }
  }, [stage.groups]);

  return (
    <div
      className={`
        ${
          tournament && tournament.activeStage < stage.stageNum
            ? 'pointer-events-none opacity-50'
            : ''
        }
      `}
    >
      <div className="flex w-full items-center gap-x-6 overflow-x-auto">
        <div className="flex w-full items-center gap-x-2">
          <div className="flex items-center gap-x-2">
            {stage.groups.map((group) => (
              <button
                key={`group-button-${group.id}`}
                type="button"
                onClick={() => setCurrentGroup(group.id)}
                className={`
                  border-[1px] p-1 px-2 border-lightGray text-steelGray
                  whitespace-nowrap rounded-full font-medium transition-colors

                  ${
                    currentGroup === group.id
                      ? 'bg-lightGray text-white/85'
                      : 'hover:border-steelGray hover:bg-lightGray/20'
                  }
                `}
              >
                {group.groupName}
              </button>
            ))}
            <div className="h-[18px] w-[2px] rounded bg-lightGray"></div>
            <div className="flex">
              <button
                type="button"
                onClick={() => setCurrentGroup('unassigned')}
                className={`
                  flex items-center gap-x-2 rounded-full p-1 px-4 bg-red/20
                  text-red/60 font-medium transition-colors

                  ${
                    currentGroup === 'unassigned'
                      ? 'bg-red/40 text-white'
                      : 'hover:bg-red/40'
                  }
                `}
              >
                <span>Unassigned ({unassignedTeams.length})</span>
                <VscDebugDisconnect className="text-lg" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={'mt-6 flex'}>
        <div
          className={`
            w-full

            lg:w-1/2
          `}
        >
          <div
            className={`
              mb-8 mt-2 flex h-fit w-full flex-col gap-y-2 text-white

              lg:w-4/5
            `}
          >
            <button
              type="button"
              disabled={
                reSeeding ||
                (tournament !== null &&
                  tournament.lockedStages.includes(stage.stageNum))
              }
              onClick={() => handleSeedTournaments()}
              className={`
                flex w-full items-center justify-center gap-x-2 rounded-xl
                py-[0.8125rem] bg-lightGray font-compact text-base font-medium
                uppercase !leading-6 transition-opacity

                disabled:opacity-50

                hover:opacity-75
              `}
            >
              <span>Seed Teams</span>
              <TbRefresh
                className={`
                  text-xl

                  ${reSeeding ? 'animate-spin' : ''}
                `}
              />
            </button>
            {tournament && tournament.lockedStages.includes(stage.stageNum) ? (
              <button
                type="button"
                onClick={() =>
                  setStageLocked(stage.stageNum, false, unassignedTeams.length)
                }
                className={`
                  flex w-full items-center justify-center gap-x-2 rounded-xl
                  py-[0.8125rem] text-black bg-green font-compact text-base
                  font-medium uppercase !leading-6 transition-colors

                  hover:bg-gorse
                `}
              >
                <span>Unlock Groups</span>
                <FaLockOpen className="mb-2 text-xl" />
              </button>
            ) : (
              <>
                {!mapsChosen ? (
                  <p className="text-red/70 text-sm font-compact font-thin">
                    Maps must be set before groups can be locked
                  </p>
                ) : (
                  ''
                )}
                <button
                  type="button"
                  disabled={!mapsChosen}
                  onClick={() =>
                    setStageLocked(stage.stageNum, true, unassignedTeams.length)
                  }
                  className={`
                    flex w-full items-center justify-center gap-x-2 rounded-xl
                    py-[0.8125rem] text-black bg-green font-compact text-base
                    font-medium uppercase !leading-6 transition-colors

                    disabled:opacity-50 disabled:hover:bg-green

                    hover:bg-gorse
                  `}
                >
                  <span>Lock Groups</span>
                  <FaLock className="mb-2 text-xl" />
                </button>
              </>
            )}
          </div>
          <div>
            {stage.groups.map((group) => (
              <div key={`group-view-${group.id}`}>
                {currentGroup === group.id ? (
                  <Group
                    stageNum={stage.stageNum}
                    group={group}
                    unassignedTeams={unassignedTeams}
                  />
                ) : (
                  ''
                )}
              </div>
            ))}
            {currentGroup === 'unassigned' ? (
              <Unassigned
                stageNum={stage.stageNum}
                groups={stage.groups}
                unassignedTeams={unassignedTeams}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stage;
