import { ReactNode } from 'react';

interface IPillButton {
  active: boolean;
  onClick: () => void;
  children: ReactNode;
}

const PillButton: React.FC<IPillButton> = ({ active, onClick, children }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`
        relative flex items-center gap-x-2 whitespace-nowrap rounded-full
        border-[1px] p-1 px-2 border-steelGray/0 font-medium transition-colors

        ${
          active
            ? 'text-white/85 bg-lightGray'
            : `
              text-steelGray border-steelGray/50

              hover:border-steelGray
            `
        }
      `}
    >
      {children}
    </button>
  );
};

export default PillButton;
