import { Link } from 'react-router-dom';
// icons
import { useAuthContext } from '@provider/AuthContextProvider';
// packages
import { toast } from 'react-toastify';
// assetts
import placeholderImage from '@assets/images/placeholder/placeholder.webp';
// icons
import { ApexLegendsIcon } from '@icons/Games';
import { LocationIcon } from '@icons/Common';

const CardCornerChevron = ({ className }: { className: string }) => (
  <svg
    className={`${className}`}
    viewBox="0 0 301 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M104.375 173.938L221.424 286.485C251.288 315.2 301 294.035 301 252.605V47C301 21.0426 279.957 0 254 0H47.9957C6.62477 0 -14.565 49.5911 14.0315 79.4875L104.375 173.938Z"
      fill="#D4FF27"
      className="fill-inherit"
    />
  </svg>
);

interface ITeamBox {
  handleClick: () => void;
}

const TeamBox: React.FC<ITeamBox> = ({ handleClick }) => {
  const { userObj, userTeam } = useAuthContext();

  const processTeamNameClass = (displayName: string) => {
    if (displayName.length <= 8) {
      return 'text-xl';
    } else if (displayName.length <= 10) {
      return 'text-base';
    } else {
      return 'text-base';
    }
  };

  return (
    <>
      {userTeam ? (
        <Link
          to={'/my-team'}
          onClick={() => {
            handleClick();
            document.body.classList.remove('overflow_hidden');
          }}
          className="relative w-full overflow-hidden flex flex-col group transition-all mt-3"
        >
          <CardCornerChevron className="absolute top-[2px] right-[2px] z-[4] w-[12px] h-auto aspect-square fill-green transition-colors" />
          <div className="absolute top-[2px] right-[2px] bg-lightBlack  z-[3] w-[100px] h-auto aspect-square rotate-45 origin-center translate-x-[70%] -translate-y-[70%]  transition-all"></div>

          <div className="h-auto aspect-[2.67] w-full transition-all relative z-[2] rounded-xl overflow-hidden">
            <div className="relative w-full h-full px-4 bg-lightBlack transition-colors">
              <div className="absolute top-0 left-0 group-hover:-top-[2.5px] group-hover:-left-[2.5px] w-full h-full group-hover:w-[calc(100%+5px)] group-hover:h-[calc(100%+5px)] transition-all">
                <div className="absolute top-0 left-0 z-[1] bg-black/70 group-hover:bg-black/35 w-full h-full transition-colors scale-105"></div>
                <img
                  src={
                    userTeam.bannerUrl !== ''
                      ? userTeam.bannerUrl
                      : placeholderImage
                  }
                  alt={`${userTeam.teamName} banner`}
                  className="absolute z-[0] top-0 left-0 w-full h-full object-cover opacity-50"
                />
              </div>

              <div className="relative flex items-center gap-x-4 h-full z-[2]">
                <div className="relative z-[2] h-fit w-fit transition-transform flex-shrink-0">
                  <img
                    src={userTeam.logoUrl}
                    alt={`${userTeam.teamName} logo`}
                    className="relative z-[1] w-[75px] h-auto aspect-square rounded-2xl bg-lightBlack group-hover:bg-lightGray border-[3px] border-lightBlack object-cover transition-all"
                  />
                </div>

                <div className="flex flex-col gap-y-1">
                  <h3
                    className={`font-wide font-semibold uppercase text-white ${processTeamNameClass(userTeam.teamName)}`}
                  >
                    {userTeam.teamName}
                  </h3>
                  <div className="flex items-center gap-x-3">
                    <p className="text-sm text-steelGray group-hover:text-white/65 font-compact font-medium transition-colors -mb-[3px]">
                      {userTeam.teamTag}
                    </p>
                    <ApexLegendsIcon className="w-[15px] h-auto aspect-square fill-steelGray group-hover:fill-white/65 transition-colors" />
                    <div className="flex items-center gap-x-1">
                      <LocationIcon className="w-[12px] h-auto aspect-square fill-steelGray group-hover:fill-white/65 transition-colors" />
                      <p className="text-steelGray group-hover:text-white/65 font-compact font-medium -mb-1 transition-colors">
                        {userTeam.region.toUpperCase()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <div className="mt-3">
          <div
            className="bg-lightGray p-2 pb-3  rounded-xl flex flex-col items-center gap-y-1
                            font-wide font-semibold text-steelGray "
          >
            {/* <p className='text-sm'>No team</p> */}
            {userObj !== null && !userObj.profileComplete ? (
              <p className="text-sm font-medium text-red/70 font-compact text-center">
                You must complete your profile
              </p>
            ) : (
              ''
            )}
            <Link
              to={'/create-team'}
              onClick={(e) => {
                if (userObj !== null && !userObj.profileComplete) {
                  e.preventDefault();
                  toast.error('You must complete your profile');
                } else {
                  handleClick();
                }
              }}
              className={`flex justify-center items-center font-compact text-base font-semibold
                    p-2 py-3 pb-2 !leading-5 rounded-xl uppercase text-center gap-2 w-full text-black
                    ${userObj !== null && !userObj.profileComplete ? 'opacity-50 cursor-default bg-green' : 'bg-green'}`}
            >
              Create Team
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default TeamBox;
