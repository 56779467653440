//user 1 comman
interface IconProps {
  fill?: string;
  className?: string;
}

import trophyGoldBorder from '@assets/images/tournament/trophyGoldBorder.webp';
import trophySilverBorder from '@assets/images/tournament/trophySilverBorder.webp';
import trophyBronzeBorder from '@assets/images/tournament/trophyBronzeBorder.webp';

import trophyGoldNoCup from '@assets/images/tournament/trophyGoldNoCup.webp';
import trophySilverNoCup from '@assets/images/tournament/trophySilverNoCup.webp';
import trophyBronzeNoCup from '@assets/images/tournament/trophyBronzeNoCup.webp';

export const TrophyGoldImageNoCup: React.FC<IconProps> = ({ className }) => {
  return (
    <img
      src={trophyGoldNoCup}
      alt="gold trophy icon"
      className={`${className}`}
    />
  );
};

export const TrophySilverImageNoCup: React.FC<IconProps> = ({ className }) => {
  return (
    <img
      src={trophySilverNoCup}
      alt="gold trophy icon"
      className={`${className}`}
    />
  );
};

export const TrophyBronzeImageNoCup: React.FC<IconProps> = ({ className }) => {
  return (
    <img
      src={trophyBronzeNoCup}
      alt="gold trophy icon"
      className={`${className}`}
    />
  );
};

export const TrophyGoldImage: React.FC<IconProps> = ({ className }) => {
  return (
    <img
      src={trophyGoldBorder}
      alt="gold trophy icon"
      className={`${className}`}
    />
  );
};

export const TrophySilverImage: React.FC<IconProps> = ({ className }) => {
  return (
    <img
      src={trophySilverBorder}
      alt="gold trophy icon"
      className={`${className}`}
    />
  );
};

export const TrophyBronzeImage: React.FC<IconProps> = ({ className }) => {
  return (
    <img
      src={trophyBronzeBorder}
      alt="gold trophy icon"
      className={`${className}`}
    />
  );
};

export const TrophyGoldGradient: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_5911_22297" fill="white">
        <path d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z" />
        <path d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z" />
        <path d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z" />
        <path d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z" />
        <path d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z" />
      </mask>
      <path
        d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z"
        fill="url(#paint0_linear_5911_22297)"
      />
      <path
        d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z"
        fill="#FFEDBD"
        fillOpacity="0.55"
      />
      <path
        d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z"
        fill="url(#paint1_linear_5911_22297)"
      />
      <path
        d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z"
        fill="#FFEDBD"
        fillOpacity="0.55"
      />
      <path
        d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z"
        fill="url(#paint2_linear_5911_22297)"
      />
      <path
        d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z"
        fill="#FFEDBD"
        fillOpacity="0.55"
      />
      <path
        d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z"
        fill="url(#paint3_linear_5911_22297)"
      />
      <path
        d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z"
        fill="#FFEDBD"
        fillOpacity="0.55"
      />
      <path
        d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z"
        fill="url(#paint4_linear_5911_22297)"
      />
      <path
        d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z"
        fill="#FFEDBD"
        fillOpacity="0.55"
      />
      <path
        d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z"
        stroke="url(#paint5_linear_5911_22297)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22297)"
      />
      <path
        d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z"
        stroke="url(#paint6_linear_5911_22297)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22297)"
      />
      <path
        d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z"
        stroke="url(#paint7_linear_5911_22297)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22297)"
      />
      <path
        d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z"
        stroke="url(#paint8_linear_5911_22297)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22297)"
      />
      <path
        d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z"
        stroke="url(#paint9_linear_5911_22297)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22297)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5911_22297"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA8207" />
          <stop offset="0.0932979" stopColor="#DD9318" />
          <stop offset="0.230387" stopColor="#FFCB3C" />
          <stop offset="0.678823" stopColor="#FFCB3C" />
          <stop offset="0.799224" stopColor="#E39C1E" />
          <stop offset="0.835628" stopColor="#FBC63A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5911_22297"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA8207" />
          <stop offset="0.0932979" stopColor="#DD9318" />
          <stop offset="0.230387" stopColor="#FFCB3C" />
          <stop offset="0.678823" stopColor="#FFCB3C" />
          <stop offset="0.799224" stopColor="#E39C1E" />
          <stop offset="0.835628" stopColor="#FBC63A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5911_22297"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA8207" />
          <stop offset="0.0932979" stopColor="#DD9318" />
          <stop offset="0.230387" stopColor="#FFCB3C" />
          <stop offset="0.678823" stopColor="#FFCB3C" />
          <stop offset="0.799224" stopColor="#E39C1E" />
          <stop offset="0.835628" stopColor="#FBC63A" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5911_22297"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA8207" />
          <stop offset="0.0932979" stopColor="#DD9318" />
          <stop offset="0.230387" stopColor="#FFCB3C" />
          <stop offset="0.678823" stopColor="#FFCB3C" />
          <stop offset="0.799224" stopColor="#E39C1E" />
          <stop offset="0.835628" stopColor="#FBC63A" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5911_22297"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA8207" />
          <stop offset="0.0932979" stopColor="#DD9318" />
          <stop offset="0.230387" stopColor="#FFCB3C" />
          <stop offset="0.678823" stopColor="#FFCB3C" />
          <stop offset="0.799224" stopColor="#E39C1E" />
          <stop offset="0.835628" stopColor="#FBC63A" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5911_22297"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEFBEF" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#F7C746" stopOpacity="0.705938" />
          <stop offset="1" stopColor="#FBBC28" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5911_22297"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEFBEF" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#F7C746" stopOpacity="0.705938" />
          <stop offset="1" stopColor="#FBBC28" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5911_22297"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEFBEF" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#F7C746" stopOpacity="0.705938" />
          <stop offset="1" stopColor="#FBBC28" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_5911_22297"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEFBEF" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#F7C746" stopOpacity="0.705938" />
          <stop offset="1" stopColor="#FBBC28" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_5911_22297"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEFBEF" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#F7C746" stopOpacity="0.705938" />
          <stop offset="1" stopColor="#FBBC28" stopOpacity="0.8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const TrophySilverGradient: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_5911_22300" fill="white">
        <path d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z" />
        <path d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z" />
        <path d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z" />
        <path d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z" />
        <path d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z" />
      </mask>
      <path
        d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z"
        fill="url(#paint0_linear_5911_22300)"
      />
      <path
        d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z"
        fill="#E7E6E6"
        fillOpacity="0.55"
      />
      <path
        d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z"
        fill="url(#paint1_linear_5911_22300)"
      />
      <path
        d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z"
        fill="#E7E6E6"
        fillOpacity="0.55"
      />
      <path
        d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z"
        fill="url(#paint2_linear_5911_22300)"
      />
      <path
        d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z"
        fill="#E7E6E6"
        fillOpacity="0.55"
      />
      <path
        d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z"
        fill="url(#paint3_linear_5911_22300)"
      />
      <path
        d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z"
        fill="#E7E6E6"
        fillOpacity="0.55"
      />
      <path
        d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z"
        fill="url(#paint4_linear_5911_22300)"
      />
      <path
        d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z"
        fill="#E7E6E6"
        fillOpacity="0.55"
      />
      <path
        d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z"
        stroke="url(#paint5_linear_5911_22300)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22300)"
      />
      <path
        d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z"
        stroke="url(#paint6_linear_5911_22300)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22300)"
      />
      <path
        d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z"
        stroke="url(#paint7_linear_5911_22300)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22300)"
      />
      <path
        d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z"
        stroke="url(#paint8_linear_5911_22300)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22300)"
      />
      <path
        d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z"
        stroke="url(#paint9_linear_5911_22300)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22300)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5911_22300"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#717171" />
          <stop offset="0.0932979" stopColor="#7B7B7B" />
          <stop offset="0.230387" stopColor="#CBCBCB" />
          <stop offset="0.678823" stopColor="#D1D1D1" />
          <stop offset="0.799224" stopColor="#818181" />
          <stop offset="0.835628" stopColor="#9B9B9B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5911_22300"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#717171" />
          <stop offset="0.0932979" stopColor="#7B7B7B" />
          <stop offset="0.230387" stopColor="#CBCBCB" />
          <stop offset="0.678823" stopColor="#D1D1D1" />
          <stop offset="0.799224" stopColor="#818181" />
          <stop offset="0.835628" stopColor="#9B9B9B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5911_22300"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#717171" />
          <stop offset="0.0932979" stopColor="#7B7B7B" />
          <stop offset="0.230387" stopColor="#CBCBCB" />
          <stop offset="0.678823" stopColor="#D1D1D1" />
          <stop offset="0.799224" stopColor="#818181" />
          <stop offset="0.835628" stopColor="#9B9B9B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5911_22300"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#717171" />
          <stop offset="0.0932979" stopColor="#7B7B7B" />
          <stop offset="0.230387" stopColor="#CBCBCB" />
          <stop offset="0.678823" stopColor="#D1D1D1" />
          <stop offset="0.799224" stopColor="#818181" />
          <stop offset="0.835628" stopColor="#9B9B9B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5911_22300"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#717171" />
          <stop offset="0.0932979" stopColor="#7B7B7B" />
          <stop offset="0.230387" stopColor="#CBCBCB" />
          <stop offset="0.678823" stopColor="#D1D1D1" />
          <stop offset="0.799224" stopColor="#818181" />
          <stop offset="0.835628" stopColor="#9B9B9B" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5911_22300"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#E6E6E6" stopOpacity="0.705938" />
          <stop offset="0.838542" stopColor="#B8B8B8" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5911_22300"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#E6E6E6" stopOpacity="0.705938" />
          <stop offset="0.838542" stopColor="#B8B8B8" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5911_22300"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#E6E6E6" stopOpacity="0.705938" />
          <stop offset="0.838542" stopColor="#B8B8B8" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_5911_22300"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#E6E6E6" stopOpacity="0.705938" />
          <stop offset="0.838542" stopColor="#B8B8B8" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_5911_22300"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#E6E6E6" stopOpacity="0.705938" />
          <stop offset="0.838542" stopColor="#B8B8B8" stopOpacity="0.8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const TrophyBronzeGradient: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_5911_22303" fill="white">
        <path d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z" />
        <path d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z" />
        <path d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z" />
        <path d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z" />
        <path d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z" />
      </mask>
      <path
        d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z"
        fill="url(#paint0_linear_5911_22303)"
      />
      <path
        d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z"
        fill="#FDE3D1"
        fillOpacity="0.52"
      />
      <path
        d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z"
        fill="url(#paint1_linear_5911_22303)"
      />
      <path
        d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z"
        fill="#FDE3D1"
        fillOpacity="0.52"
      />
      <path
        d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z"
        fill="url(#paint2_linear_5911_22303)"
      />
      <path
        d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z"
        fill="#FDE3D1"
        fillOpacity="0.52"
      />
      <path
        d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z"
        fill="url(#paint3_linear_5911_22303)"
      />
      <path
        d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z"
        fill="#FDE3D1"
        fillOpacity="0.52"
      />
      <path
        d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z"
        fill="url(#paint4_linear_5911_22303)"
      />
      <path
        d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z"
        fill="#FDE3D1"
        fillOpacity="0.52"
      />
      <path
        d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z"
        stroke="url(#paint5_linear_5911_22303)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22303)"
      />
      <path
        d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z"
        stroke="url(#paint6_linear_5911_22303)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22303)"
      />
      <path
        d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z"
        stroke="url(#paint7_linear_5911_22303)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22303)"
      />
      <path
        d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z"
        stroke="url(#paint8_linear_5911_22303)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22303)"
      />
      <path
        d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z"
        stroke="url(#paint9_linear_5911_22303)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5911_22303)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5911_22303"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D66D0B" />
          <stop offset="0.150377" stopColor="#E9770C" />
          <stop offset="0.464178" stopColor="#FAC39C" />
          <stop offset="0.619007" stopColor="#FACAA8" />
          <stop offset="0.746638" stopColor="#F26E0F" />
          <stop offset="0.835628" stopColor="#F58B40" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5911_22303"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D66D0B" />
          <stop offset="0.150377" stopColor="#E9770C" />
          <stop offset="0.464178" stopColor="#FAC39C" />
          <stop offset="0.619007" stopColor="#FACAA8" />
          <stop offset="0.746638" stopColor="#F26E0F" />
          <stop offset="0.835628" stopColor="#F58B40" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5911_22303"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D66D0B" />
          <stop offset="0.150377" stopColor="#E9770C" />
          <stop offset="0.464178" stopColor="#FAC39C" />
          <stop offset="0.619007" stopColor="#FACAA8" />
          <stop offset="0.746638" stopColor="#F26E0F" />
          <stop offset="0.835628" stopColor="#F58B40" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5911_22303"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D66D0B" />
          <stop offset="0.150377" stopColor="#E9770C" />
          <stop offset="0.464178" stopColor="#FAC39C" />
          <stop offset="0.619007" stopColor="#FACAA8" />
          <stop offset="0.746638" stopColor="#F26E0F" />
          <stop offset="0.835628" stopColor="#F58B40" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5911_22303"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D66D0B" />
          <stop offset="0.150377" stopColor="#E9770C" />
          <stop offset="0.464178" stopColor="#FAC39C" />
          <stop offset="0.619007" stopColor="#FACAA8" />
          <stop offset="0.746638" stopColor="#F26E0F" />
          <stop offset="0.835628" stopColor="#F58B40" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5911_22303"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#FCE2CF" stopOpacity="0.705938" />
          <stop offset="0.838542" stopColor="#F69F60" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5911_22303"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#FCE2CF" stopOpacity="0.705938" />
          <stop offset="0.838542" stopColor="#F69F60" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5911_22303"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#FCE2CF" stopOpacity="0.705938" />
          <stop offset="0.838542" stopColor="#F69F60" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_5911_22303"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#FCE2CF" stopOpacity="0.705938" />
          <stop offset="0.838542" stopColor="#F69F60" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_5911_22303"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.59" />
          <stop offset="0.552083" stopColor="#FCE2CF" stopOpacity="0.705938" />
          <stop offset="0.838542" stopColor="#F69F60" stopOpacity="0.76" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const TrophyGreenGradient: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_5912_22306" fill="white">
        <path d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z" />
        <path d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z" />
        <path d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z" />
        <path d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z" />
        <path d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z" />
      </mask>
      <path
        d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z"
        fill="url(#paint0_linear_5912_22306)"
      />
      <path
        d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z"
        fill="#FDE3D1"
        fillOpacity="0.48"
      />
      <path
        d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z"
        fill="url(#paint1_linear_5912_22306)"
      />
      <path
        d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z"
        fill="#FDE3D1"
        fillOpacity="0.48"
      />
      <path
        d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z"
        fill="url(#paint2_linear_5912_22306)"
      />
      <path
        d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z"
        fill="#FDE3D1"
        fillOpacity="0.48"
      />
      <path
        d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z"
        fill="url(#paint3_linear_5912_22306)"
      />
      <path
        d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z"
        fill="#FDE3D1"
        fillOpacity="0.48"
      />
      <path
        d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z"
        fill="url(#paint4_linear_5912_22306)"
      />
      <path
        d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z"
        fill="#FDE3D1"
        fillOpacity="0.48"
      />
      <path
        d="M49.9558 10.0013C46.3633 10.0561 42.7784 10.2825 39.201 10.6185C35.5708 10.9545 31.9821 11.5096 28.4123 12.1963C26.6594 12.5359 25.1817 13.2664 24.1827 14.7492C23.4439 15.8485 23.18 17.0611 23.2743 18.3612C23.3421 19.3327 23.3195 20.3042 23.3798 21.2794C23.5268 23.7264 23.8284 26.1515 24.2845 28.562C25.2119 33.4706 26.829 38.1637 29.1587 42.6121C31.2697 46.6478 33.8858 50.3549 37.0297 53.7113C38.809 55.6104 40.7353 57.3489 42.7859 58.9595C43.0423 59.1604 43.1101 59.3576 43.0574 59.6644C42.4994 63.0756 42.8236 66.3918 44.2259 69.5876C44.4861 70.1792 44.8253 70.727 45.1872 71.2603C45.4134 71.589 45.338 71.7497 45.0025 71.9323C42.2695 73.437 39.5931 75.0294 37.0862 76.8701C36.0873 77.6042 35.0996 78.3602 34.2967 79.3135C33.8217 79.8796 33.5428 80.4822 33.5654 81.2309C33.5955 82.312 33.5805 83.393 33.5692 84.4704C33.5692 84.832 33.6785 84.9671 34.0931 84.9562C35.0167 84.9306 35.944 84.9927 36.8714 84.9927C46.4199 85 55.9684 85 65.5207 85C66.3311 85 66.3349 84.9927 66.3274 84.2111C66.3198 83.236 66.2784 82.2608 66.3274 81.2893C66.3726 80.3982 66.0333 79.6933 65.4226 79.0724C64.0166 77.6407 62.3919 76.4757 60.7068 75.38C58.8672 74.1857 56.9824 73.0608 55.0637 71.9871C54.5435 71.6949 54.5246 71.6328 54.8375 71.1398C56.3114 68.8279 56.9673 66.2969 57.1068 63.6052C57.1747 62.3086 57.0993 61.0231 56.9108 59.7448C56.8542 59.3759 56.9598 59.1239 57.2651 58.8755C61.898 55.1137 65.8599 50.7785 68.9963 45.7603C72.3173 40.4427 74.4924 34.705 75.6459 28.5985C76.2868 25.1982 76.6034 21.7651 76.709 18.3101C76.7919 15.5198 75.2237 12.9012 71.5596 12.1999C68.804 11.6703 66.0371 11.2029 63.2476 10.8742C61.0084 10.6112 58.7617 10.3775 56.5037 10.2679C54.3173 10.1656 52.1422 9.98301 49.9558 10.0013Z"
        stroke="url(#paint5_linear_5912_22306)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5912_22306)"
      />
      <path
        d="M79.2384 73.8022C79.0725 71.4685 78.9255 69.3611 78.7672 67.2538C78.7182 66.6146 78.7257 66.5708 79.4005 66.4722C82.7291 65.9755 85.9672 65.1318 89.1714 64.1421C89.2317 64.1238 89.292 64.1092 89.3486 64.08C89.5484 63.9777 89.8763 63.9521 89.8575 63.6819C89.8386 63.4153 89.522 63.5577 89.3448 63.492C88.229 63.0793 87.1396 62.6118 86.0426 62.1589C82.5594 60.7236 79.1366 59.1677 75.8683 57.3051C74.025 56.2569 72.2419 55.121 70.5569 53.8428C69.7502 53.2292 69.4524 53.2767 68.8078 54.0655C66.5158 56.8741 64.0505 59.5293 61.3024 61.9288C60.7784 62.3853 60.7935 62.378 61.2949 62.8017C65.9768 66.7717 71.2091 69.9528 76.6373 72.849C77.3121 73.2069 77.9793 73.5794 78.6579 73.9264C79.1705 74.1857 79.2459 74.1309 79.2346 73.7986L79.2384 73.8022Z"
        stroke="url(#paint6_linear_5912_22306)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5912_22306)"
      />
      <path
        d="M10.0202 63.8608C13.541 65.0332 17.0807 65.9572 20.726 66.4649C21.1821 66.527 21.2424 66.7278 21.216 67.104C21.0841 68.9082 20.971 70.7124 20.8466 72.5166C20.8202 72.9111 20.775 73.3055 20.7486 73.6963C20.7184 74.1236 20.7938 74.1821 21.1821 73.9812C22.264 73.4224 23.3421 72.86 24.4089 72.2793C27.9939 70.318 31.5034 68.2435 34.7905 65.833C36.1664 64.8214 37.516 63.7805 38.8391 62.6994C39.1068 62.4803 39.0879 62.3379 38.8391 62.126C37.188 60.7382 35.6651 59.2262 34.1798 57.6739C33.1545 56.6002 32.2083 55.4643 31.2772 54.3139C31.1905 54.2043 31.1076 54.0948 31.0209 53.9852C30.4667 53.3022 30.2142 53.2547 29.5093 53.7843C26.8592 55.7784 24.0169 57.484 21.0313 58.9595C17.5896 60.6615 14.0839 62.2246 10.465 63.5541C10.3067 63.6125 10.1408 63.6636 10.0127 63.8608H10.0202Z"
        stroke="url(#paint7_linear_5912_22306)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5912_22306)"
      />
      <path
        d="M79.7435 25.8447C79.687 26.2537 79.8981 26.4363 80.2939 26.4838C80.5728 26.5167 80.8556 26.5496 81.1308 26.6117C81.8432 26.7687 82.273 27.3202 82.0845 27.9995C81.1647 31.3194 80.2336 34.6393 78.3789 37.6268C77.5911 38.8978 76.6901 40.0811 75.6572 41.1731C75.4009 41.4471 75.2086 41.7611 75.0541 42.0935C73.5349 45.4024 71.6501 48.5032 69.4486 51.425C69.3695 51.5309 69.2111 51.6441 69.3167 51.7792C69.4298 51.9217 69.573 51.7792 69.6974 51.7391C72.8752 50.731 75.7138 49.1241 78.2734 47.0715C80.2977 45.4463 82.0392 43.5398 83.5697 41.4617C86.4761 37.5136 88.4966 33.1601 89.8085 28.4999C90.6227 25.6073 88.7605 22.7111 85.7448 22.0537C84.1389 21.703 82.518 21.3999 80.9046 21.0858C80.1695 20.9434 80.128 20.9836 80.1243 21.7177C80.1205 22.9996 79.9509 24.2633 79.7775 25.5306C79.7624 25.6365 79.7548 25.7424 79.7398 25.8483L79.7435 25.8447Z"
        stroke="url(#paint8_linear_5912_22306)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5912_22306)"
      />
      <path
        d="M10.0051 27.28C9.97119 27.8169 10.1107 28.3939 10.2841 28.9637C11.6901 33.5509 13.6918 37.8569 16.6208 41.7319C18.9769 44.8473 21.789 47.4879 25.2269 49.471C26.7951 50.3768 28.4424 51.1291 30.1652 51.7172C30.3046 51.7646 30.4667 51.9217 30.5911 51.7646C30.7042 51.6222 30.5346 51.4834 30.4479 51.3665C29.1323 49.6755 27.9675 47.886 26.9157 46.0306C26.1656 44.7122 25.4531 43.3718 24.8613 41.9766C24.62 41.4105 24.2204 40.9723 23.8058 40.534C21.4384 38.0286 20.0588 35.0118 19.0259 31.8234C18.7055 30.8373 18.4303 29.8366 18.1325 28.8432C18.0382 28.5218 17.9628 28.1967 17.9591 27.8571C17.9515 27.2143 18.2456 26.8198 18.8864 26.6591C19.188 26.5824 19.4971 26.5277 19.8062 26.4729C20.1304 26.4181 20.2623 26.2501 20.2171 25.9323C19.9947 24.4422 19.8514 22.9448 19.7647 21.4401C19.7496 21.1443 19.5838 21.0274 19.2973 21.0822C17.4841 21.4474 15.637 21.6483 13.8539 22.1413C11.5167 22.7878 10.0164 24.8075 10.0127 27.2691L10.0051 27.28Z"
        stroke="url(#paint9_linear_5912_22306)"
        strokeWidth="9"
        mask="url(#path-1-inside-1_5912_22306)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5912_22306"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4FF27" />
          <stop offset="0.835628" stopColor="#E6FF4F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5912_22306"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4FF27" />
          <stop offset="0.835628" stopColor="#E6FF4F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5912_22306"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4FF27" />
          <stop offset="0.835628" stopColor="#E6FF4F" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5912_22306"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4FF27" />
          <stop offset="0.835628" stopColor="#E6FF4F" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5912_22306"
          x1="91.6"
          y1="23.75"
          x2="-9.5872"
          y2="34.0781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4FF27" />
          <stop offset="0.835628" stopColor="#E6FF4F" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5912_22306"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6FF4F" stopOpacity="0.59" />
          <stop offset="0.838542" stopColor="#D4FF27" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5912_22306"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6FF4F" stopOpacity="0.59" />
          <stop offset="0.838542" stopColor="#D4FF27" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5912_22306"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6FF4F" stopOpacity="0.59" />
          <stop offset="0.838542" stopColor="#D4FF27" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_5912_22306"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6FF4F" stopOpacity="0.59" />
          <stop offset="0.838542" stopColor="#D4FF27" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_5912_22306"
          x1="50"
          y1="10"
          x2="50"
          y2="85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6FF4F" stopOpacity="0.59" />
          <stop offset="0.838542" stopColor="#D4FF27" stopOpacity="0.76" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const TrophyGold: React.FC<IconProps> = ({ className }) => (
  <svg
    className={`${className}`}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 1.73205C22.8564 0.660254 25.1436 0.660254 27 1.73205L41.7846 10.2679C43.641 11.3397 44.7846 13.3205 44.7846 15.4641V32.5359C44.7846 34.6795 43.641 36.6603 41.7846 37.7321L27 46.2679C25.1436 47.3397 22.8564 47.3397 21 46.2679L6.21539 37.7321C4.35899 36.6603 3.21539 34.6795 3.21539 32.5359V15.4641C3.21539 13.3205 4.35898 11.3397 6.21539 10.2679L21 1.73205Z"
      fill="#151A1F"
    />
    <path
      d="M21 1.73205C22.8564 0.660254 25.1436 0.660254 27 1.73205L41.7846 10.2679C43.641 11.3397 44.7846 13.3205 44.7846 15.4641V32.5359C44.7846 34.6795 43.641 36.6603 41.7846 37.7321L27 46.2679C25.1436 47.3397 22.8564 47.3397 21 46.2679L6.21539 37.7321C4.35899 36.6603 3.21539 34.6795 3.21539 32.5359V15.4641C3.21539 13.3205 4.35898 11.3397 6.21539 10.2679L21 1.73205Z"
      fill="url(#paint0_linear_4649_73746)"
      fillOpacity="0.4"
    />
    <g filter="url(#filter0_d_4649_73746)">
      <path
        d="M26.6266 5.98167L38.2935 12.7176C39.9178 13.6554 40.9185 15.3886 40.9185 17.2642V30.736C40.9185 32.6116 39.9178 34.3448 38.2935 35.2826L26.6266 42.0185C25.0022 42.9564 23.0009 42.9564 21.3766 42.0185L9.70964 35.2826C8.08529 34.3448 7.08464 32.6116 7.08464 30.736V17.2642C7.08464 15.3886 8.08529 13.6554 9.70964 12.7176L21.3766 5.98167C23.0009 5.04384 25.0022 5.04385 26.6266 5.98167Z"
        stroke="#D9A746"
        strokeWidth="1.5"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      d="M23.991 14.4007C23.1288 14.4138 22.2684 14.4682 21.4098 14.5488C20.5386 14.6295 19.6773 14.7627 18.8205 14.9275C18.3998 15.009 18.0452 15.1843 17.8054 15.5402C17.6281 15.804 17.5648 16.095 17.5874 16.4071C17.6037 16.6402 17.5982 16.8734 17.6127 17.1074C17.648 17.6947 17.7204 18.2767 17.8298 18.8553C18.0524 20.0333 18.4405 21.1597 18.9996 22.2273C19.5063 23.1959 20.1342 24.0856 20.8887 24.8911C21.3157 25.3469 21.778 25.7641 22.2702 26.1507C22.3317 26.1989 22.348 26.2462 22.3353 26.3198C22.2014 27.1385 22.2792 27.9344 22.6158 28.7014C22.6782 28.8434 22.7596 28.9749 22.8465 29.1029C22.9008 29.1817 22.8827 29.2203 22.8022 29.2641C22.1462 29.6253 21.5039 30.0074 20.9023 30.4492C20.6625 30.6254 20.4255 30.8068 20.2328 31.0356C20.1188 31.1715 20.0518 31.3161 20.0573 31.4958C20.0645 31.7553 20.0609 32.0147 20.0582 32.2733C20.0582 32.3601 20.0844 32.3925 20.1839 32.3899C20.4056 32.3837 20.6281 32.3986 20.8507 32.3986C23.1423 32.4004 25.434 32.4004 27.7265 32.4004C27.921 32.4004 27.9219 32.3986 27.9201 32.2111C27.9183 31.977 27.9084 31.743 27.9201 31.5098C27.931 31.296 27.8496 31.1268 27.703 30.9778C27.3655 30.6342 26.9756 30.3546 26.5712 30.0916C26.1297 29.805 25.6773 29.535 25.2168 29.2773C25.092 29.2072 25.0875 29.1923 25.1626 29.0739C25.5163 28.5191 25.6737 27.9116 25.7072 27.2656C25.7235 26.9545 25.7054 26.6459 25.6602 26.3391C25.6466 26.2506 25.6719 26.1901 25.7452 26.1305C26.8571 25.2277 27.8079 24.1872 28.5607 22.9829C29.3577 21.7066 29.8797 20.3296 30.1566 18.864C30.3104 18.048 30.3864 17.224 30.4117 16.3948C30.4316 15.7251 30.0553 15.0967 29.1759 14.9284C28.5145 14.8013 27.8505 14.6891 27.181 14.6102C26.6436 14.5471 26.1044 14.491 25.5624 14.4647C25.0377 14.4401 24.5157 14.3963 23.991 14.4007Z"
      fill="#D9A746"
    />
    <path
      d="M31.0188 29.7129C30.979 29.1528 30.9437 28.6471 30.9057 28.1413C30.8939 27.9879 30.8957 27.9774 31.0577 27.9537C31.8565 27.8345 32.6337 27.632 33.4027 27.3945C33.4172 27.3901 33.4317 27.3866 33.4452 27.3796C33.4932 27.355 33.5719 27.3489 33.5674 27.284C33.5628 27.2201 33.4868 27.2542 33.4443 27.2385C33.1765 27.1394 32.9151 27.0272 32.6518 26.9185C31.8158 26.574 30.9943 26.2006 30.21 25.7536C29.7676 25.502 29.3396 25.2294 28.9352 24.9227C28.7416 24.7754 28.6701 24.7868 28.5154 24.9761C27.9654 25.6502 27.3737 26.2874 26.7141 26.8633C26.5884 26.9729 26.592 26.9711 26.7123 27.0728C27.836 28.0256 29.0917 28.7891 30.3945 29.4842C30.5565 29.5701 30.7166 29.6595 30.8795 29.7427C31.0025 29.805 31.0206 29.7918 31.0179 29.712L31.0188 29.7129Z"
      fill="#D9A746"
    />
    <path
      d="M14.4064 27.327C15.2514 27.6084 16.1009 27.8301 16.9758 27.952C17.0853 27.9669 17.0997 28.0151 17.0934 28.1054C17.0617 28.5384 17.0346 28.9714 17.0048 29.4044C16.9984 29.4991 16.9876 29.5937 16.9812 29.6875C16.974 29.7901 16.9921 29.8041 17.0853 29.7559C17.3449 29.6218 17.6037 29.4868 17.8597 29.3474C18.7201 28.8767 19.5624 28.3788 20.3513 27.8003C20.6815 27.5575 21.0054 27.3077 21.323 27.0483C21.3872 26.9957 21.3827 26.9615 21.323 26.9106C20.9267 26.5776 20.5612 26.2147 20.2047 25.8421C19.9586 25.5844 19.7316 25.3118 19.5081 25.0357C19.4873 25.0094 19.4674 24.9831 19.4466 24.9568C19.3136 24.7929 19.253 24.7815 19.0838 24.9086C18.4478 25.3872 17.7656 25.7966 17.0491 26.1507C16.2231 26.5591 15.3817 26.9343 14.5132 27.2534C14.4752 27.2674 14.4354 27.2797 14.4046 27.327H14.4064Z"
      fill="#D9A746"
    />
    <path
      d="M31.14 18.2031C31.1264 18.3013 31.1771 18.3451 31.2721 18.3565C31.339 18.3644 31.4069 18.3723 31.4729 18.3872C31.6439 18.4249 31.7471 18.5572 31.7018 18.7203C31.4811 19.517 31.2576 20.3138 30.8125 21.0308C30.6234 21.3359 30.4072 21.6199 30.1593 21.8819C30.0978 21.9477 30.0516 22.0231 30.0145 22.1028C29.6499 22.897 29.1976 23.6412 28.6692 24.3424C28.6502 24.3678 28.6122 24.395 28.6376 24.4274C28.6647 24.4616 28.6991 24.4274 28.7289 24.4178C29.4916 24.1758 30.1729 23.7902 30.7872 23.2976C31.273 22.9075 31.691 22.4499 32.0583 21.9512C32.7558 21.0037 33.2408 19.9588 33.5556 18.8404C33.751 18.1461 33.3041 17.451 32.5803 17.2933C32.1949 17.2091 31.8059 17.1364 31.4187 17.061C31.2422 17.0268 31.2323 17.0364 31.2314 17.2126C31.2305 17.5203 31.1898 17.8236 31.1482 18.1277C31.1445 18.1532 31.1427 18.1786 31.1391 18.204L31.14 18.2031Z"
      fill="#D9A746"
    />
    <path
      d="M14.4028 18.5476C14.3946 18.6764 14.4281 18.8149 14.4697 18.9517C14.8072 20.0526 15.2876 21.086 15.9906 22.0161C16.556 22.7637 17.2309 23.3975 18.056 23.8734C18.4324 24.0908 18.8278 24.2714 19.2412 24.4125C19.2747 24.4239 19.3136 24.4616 19.3434 24.4239C19.3706 24.3897 19.3299 24.3564 19.3091 24.3284C18.9933 23.9225 18.7138 23.493 18.4613 23.0477C18.2813 22.7313 18.1103 22.4096 17.9683 22.0748C17.9104 21.9389 17.8145 21.8337 17.715 21.7285C17.1468 21.1272 16.8157 20.4032 16.5678 19.638C16.4909 19.4013 16.4248 19.1612 16.3534 18.9228C16.3307 18.8456 16.3126 18.7676 16.3117 18.6861C16.3099 18.5318 16.3805 18.4372 16.5343 18.3986C16.6067 18.3802 16.6809 18.367 16.755 18.3539C16.8329 18.3407 16.8645 18.3004 16.8537 18.2242C16.8003 17.8665 16.7659 17.5071 16.7451 17.146C16.7415 17.075 16.7017 17.047 16.6329 17.0601C16.1977 17.1478 15.7544 17.196 15.3265 17.3143C14.7656 17.4695 14.4055 17.9542 14.4046 18.545L14.4028 18.5476Z"
      fill="#D9A746"
    />
    <defs>
      <filter
        id="filter0_d_4649_73746"
        x="5.58594"
        y="4.52832"
        width="36.832"
        height="40.4434"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.75" />
        <feGaussianBlur stdDeviation="0.375" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4649_73746"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4649_73746"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4649_73746"
        x1="24"
        y1="0"
        x2="24"
        y2="48"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0" />
        <stop offset="1" stopColor="#D9A746" />
      </linearGradient>
    </defs>
  </svg>
);

export const TrophyGoldBorder: React.FC<IconProps> = ({ className }) => (
  <svg
    className={`${className}`}
    viewBox="0 0 48 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.5346 11.9689L27.75 3.43301C25.4295 2.09327 22.5705 2.09327 20.25 3.43301L5.46539 11.9689C3.14488 13.3087 1.71539 15.7846 1.71539 18.4641V35.5359C1.71539 38.2154 3.14488 40.6913 5.46539 42.0311L20.25 50.567C22.5705 51.9067 25.4295 51.9067 27.75 50.567L42.5346 42.0311C44.8551 40.6913 46.2846 38.2154 46.2846 35.5359V18.4641C46.2846 15.7846 44.8551 13.3087 42.5346 11.9689Z"
      fill="#151A1F"
    />
    <path
      d="M42.5346 11.9689L27.75 3.43301C25.4295 2.09327 22.5705 2.09327 20.25 3.43301L5.46539 11.9689C3.14488 13.3087 1.71539 15.7846 1.71539 18.4641V35.5359C1.71539 38.2154 3.14488 40.6913 5.46539 42.0311L20.25 50.567C22.5705 51.9067 25.4295 51.9067 27.75 50.567L42.5346 42.0311C44.8551 40.6913 46.2846 38.2154 46.2846 35.5359V18.4641C46.2846 15.7846 44.8551 13.3087 42.5346 11.9689Z"
      fill="url(#paint0_linear_4704_73156)"
      fillOpacity="0.4"
    />
    <path
      d="M42.5346 11.9689L27.75 3.43301C25.4295 2.09327 22.5705 2.09327 20.25 3.43301L5.46539 11.9689C3.14488 13.3087 1.71539 15.7846 1.71539 18.4641V35.5359C1.71539 38.2154 3.14488 40.6913 5.46539 42.0311L20.25 50.567C22.5705 51.9067 25.4295 51.9067 27.75 50.567L42.5346 42.0311C44.8551 40.6913 46.2846 38.2154 46.2846 35.5359V18.4641C46.2846 15.7846 44.8551 13.3087 42.5346 11.9689Z"
      stroke="#0D1012"
      strokeWidth="3"
    />
    <g filter="url(#filter0_d_4704_73156)">
      <path
        d="M26.6266 8.98118L38.2935 15.7171C39.9178 16.6549 40.9185 18.3881 40.9185 20.2637V33.7355C40.9185 35.6112 39.9178 37.3443 38.2935 38.2821L26.6266 45.018C25.0022 45.9559 23.0009 45.9559 21.3766 45.018L9.70964 38.2821C8.08529 37.3443 7.08464 35.6112 7.08464 33.7355V20.2637C7.08464 18.3881 8.08529 16.6549 9.70964 15.7171L21.3766 8.98118C23.0009 8.04336 25.0022 8.04336 26.6266 8.98118Z"
        stroke="#D9A746"
        strokeWidth="1.5"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      d="M23.991 17.4007C23.1288 17.4138 22.2684 17.4682 21.4098 17.5488C20.5386 17.6295 19.6773 17.7627 18.8205 17.9275C18.3998 18.009 18.0452 18.1843 17.8054 18.5402C17.6281 18.804 17.5648 19.095 17.5874 19.4071C17.6037 19.6402 17.5982 19.8734 17.6127 20.1074C17.648 20.6947 17.7204 21.2767 17.8298 21.8553C18.0524 23.0333 18.4405 24.1597 18.9996 25.2273C19.5063 26.1959 20.1342 27.0856 20.8887 27.8911C21.3157 28.3469 21.778 28.7641 22.2702 29.1507C22.3317 29.1989 22.348 29.2462 22.3353 29.3198C22.2014 30.1385 22.2792 30.9344 22.6158 31.7014C22.6782 31.8434 22.7596 31.9749 22.8465 32.1029C22.9008 32.1817 22.8827 32.2203 22.8022 32.2641C22.1462 32.6253 21.5039 33.0074 20.9023 33.4492C20.6625 33.6254 20.4255 33.8068 20.2328 34.0356C20.1188 34.1715 20.0518 34.3161 20.0573 34.4958C20.0645 34.7553 20.0609 35.0147 20.0582 35.2733C20.0582 35.3601 20.0844 35.3925 20.1839 35.3899C20.4056 35.3837 20.6281 35.3986 20.8507 35.3986C23.1423 35.4004 25.434 35.4004 27.7265 35.4004C27.921 35.4004 27.9219 35.3986 27.9201 35.2111C27.9183 34.977 27.9084 34.743 27.9201 34.5098C27.931 34.296 27.8496 34.1268 27.703 33.9778C27.3655 33.6342 26.9756 33.3546 26.5712 33.0916C26.1297 32.805 25.6773 32.535 25.2168 32.2773C25.092 32.2072 25.0875 32.1923 25.1626 32.0739C25.5163 31.5191 25.6737 30.9116 25.7072 30.2656C25.7235 29.9545 25.7054 29.6459 25.6602 29.3391C25.6466 29.2506 25.6719 29.1901 25.7452 29.1305C26.8571 28.2277 27.8079 27.1872 28.5607 25.9829C29.3577 24.7066 29.8797 23.3296 30.1566 21.864C30.3104 21.048 30.3864 20.224 30.4117 19.3948C30.4316 18.7251 30.0553 18.0967 29.1759 17.9284C28.5145 17.8013 27.8505 17.6891 27.181 17.6102C26.6436 17.5471 26.1044 17.491 25.5624 17.4647C25.0377 17.4401 24.5157 17.3963 23.991 17.4007Z"
      fill="#D9A746"
    />
    <path
      d="M31.0188 32.7129C30.979 32.1528 30.9437 31.6471 30.9057 31.1413C30.8939 30.9879 30.8957 30.9774 31.0577 30.9537C31.8565 30.8345 32.6337 30.632 33.4027 30.3945C33.4172 30.3901 33.4317 30.3866 33.4452 30.3796C33.4932 30.355 33.5719 30.3489 33.5674 30.284C33.5628 30.2201 33.4868 30.2542 33.4443 30.2385C33.1765 30.1394 32.9151 30.0272 32.6518 29.9185C31.8158 29.574 30.9943 29.2006 30.21 28.7536C29.7676 28.502 29.3396 28.2294 28.9352 27.9227C28.7416 27.7754 28.6701 27.7868 28.5154 27.9761C27.9654 28.6502 27.3737 29.2874 26.7141 29.8633C26.5884 29.9729 26.592 29.9711 26.7123 30.0728C27.836 31.0256 29.0917 31.7891 30.3945 32.4842C30.5565 32.5701 30.7166 32.6595 30.8795 32.7427C31.0025 32.805 31.0206 32.7918 31.0179 32.712L31.0188 32.7129Z"
      fill="#D9A746"
    />
    <path
      d="M14.4064 30.327C15.2514 30.6084 16.1009 30.8301 16.9758 30.952C17.0853 30.9669 17.0997 31.0151 17.0934 31.1054C17.0617 31.5384 17.0346 31.9714 17.0048 32.4044C16.9984 32.4991 16.9876 32.5937 16.9812 32.6875C16.974 32.7901 16.9921 32.8041 17.0853 32.7559C17.3449 32.6218 17.6037 32.4868 17.8597 32.3474C18.7201 31.8767 19.5624 31.3788 20.3513 30.8003C20.6815 30.5575 21.0054 30.3077 21.323 30.0483C21.3872 29.9957 21.3827 29.9615 21.323 29.9106C20.9267 29.5776 20.5612 29.2147 20.2047 28.8421C19.9586 28.5844 19.7316 28.3118 19.5081 28.0357C19.4873 28.0094 19.4674 27.9831 19.4466 27.9568C19.3136 27.7929 19.253 27.7815 19.0838 27.9086C18.4478 28.3872 17.7656 28.7966 17.0491 29.1507C16.2231 29.5591 15.3817 29.9343 14.5132 30.2534C14.4752 30.2674 14.4354 30.2797 14.4046 30.327H14.4064Z"
      fill="#D9A746"
    />
    <path
      d="M31.14 21.2031C31.1264 21.3013 31.1771 21.3451 31.2721 21.3565C31.339 21.3644 31.4069 21.3723 31.4729 21.3872C31.6439 21.4249 31.7471 21.5572 31.7018 21.7203C31.4811 22.517 31.2576 23.3138 30.8125 24.0308C30.6234 24.3359 30.4072 24.6199 30.1593 24.8819C30.0978 24.9477 30.0516 25.0231 30.0145 25.1028C29.6499 25.897 29.1976 26.6412 28.6692 27.3424C28.6502 27.3678 28.6122 27.395 28.6376 27.4274C28.6647 27.4616 28.6991 27.4274 28.7289 27.4178C29.4916 27.1758 30.1729 26.7902 30.7872 26.2976C31.273 25.9075 31.691 25.4499 32.0583 24.9512C32.7558 24.0037 33.2408 22.9588 33.5556 21.8404C33.751 21.1461 33.3041 20.451 32.5803 20.2933C32.1949 20.2091 31.8059 20.1364 31.4187 20.061C31.2422 20.0268 31.2323 20.0364 31.2314 20.2126C31.2305 20.5203 31.1898 20.8236 31.1482 21.1277C31.1445 21.1532 31.1427 21.1786 31.1391 21.204L31.14 21.2031Z"
      fill="#D9A746"
    />
    <path
      d="M14.4028 21.5476C14.3946 21.6764 14.4281 21.8149 14.4697 21.9517C14.8072 23.0526 15.2876 24.086 15.9906 25.0161C16.556 25.7637 17.2309 26.3975 18.056 26.8734C18.4324 27.0908 18.8278 27.2714 19.2412 27.4125C19.2747 27.4239 19.3136 27.4616 19.3434 27.4239C19.3706 27.3897 19.3299 27.3564 19.3091 27.3284C18.9933 26.9225 18.7138 26.493 18.4613 26.0477C18.2813 25.7313 18.1103 25.4096 17.9683 25.0748C17.9104 24.9389 17.8145 24.8337 17.715 24.7285C17.1468 24.1272 16.8157 23.4032 16.5678 22.638C16.4909 22.4013 16.4248 22.1612 16.3534 21.9228C16.3307 21.8456 16.3126 21.7676 16.3117 21.6861C16.3099 21.5318 16.3805 21.4372 16.5343 21.3986C16.6067 21.3802 16.6809 21.367 16.755 21.3539C16.8329 21.3407 16.8645 21.3004 16.8537 21.2242C16.8003 20.8665 16.7659 20.5071 16.7451 20.146C16.7415 20.075 16.7017 20.047 16.6329 20.0601C16.1977 20.1478 15.7544 20.196 15.3265 20.3143C14.7656 20.4695 14.4055 20.9542 14.4046 21.545L14.4028 21.5476Z"
      fill="#D9A746"
    />
    <defs>
      <filter
        id="filter0_d_4704_73156"
        x="5.58594"
        y="7.52734"
        width="36.832"
        height="40.4443"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.75" />
        <feGaussianBlur stdDeviation="0.375" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4704_73156"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4704_73156"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4704_73156"
        x1="24"
        y1="3"
        x2="24"
        y2="51"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0" />
        <stop offset="1" stopColor="#D9A746" />
      </linearGradient>
    </defs>
  </svg>
);

export const TrophySilver: React.FC<IconProps> = ({ className }) => (
  <svg
    className={`${className}`}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 1.73205C22.8564 0.660254 25.1436 0.660254 27 1.73205L41.7846 10.2679C43.641 11.3397 44.7846 13.3205 44.7846 15.4641V32.5359C44.7846 34.6795 43.641 36.6603 41.7846 37.7321L27 46.2679C25.1436 47.3397 22.8564 47.3397 21 46.2679L6.21539 37.7321C4.35899 36.6603 3.21539 34.6795 3.21539 32.5359V15.4641C3.21539 13.3205 4.35898 11.3397 6.21539 10.2679L21 1.73205Z"
      fill="#151A1F"
    />
    <path
      d="M21 1.73205C22.8564 0.660254 25.1436 0.660254 27 1.73205L41.7846 10.2679C43.641 11.3397 44.7846 13.3205 44.7846 15.4641V32.5359C44.7846 34.6795 43.641 36.6603 41.7846 37.7321L27 46.2679C25.1436 47.3397 22.8564 47.3397 21 46.2679L6.21539 37.7321C4.35899 36.6603 3.21539 34.6795 3.21539 32.5359V15.4641C3.21539 13.3205 4.35898 11.3397 6.21539 10.2679L21 1.73205Z"
      fill="url(#paint0_linear_4649_73750)"
      fillOpacity="0.4"
    />
    <g filter="url(#filter0_d_4649_73750)">
      <path
        d="M26.6266 5.98167L38.2935 12.7176C39.9178 13.6554 40.9185 15.3886 40.9185 17.2642V30.736C40.9185 32.6116 39.9178 34.3448 38.2935 35.2826L26.6266 42.0185C25.0022 42.9564 23.0009 42.9563 21.3766 42.0185L9.70964 35.2826C8.08529 34.3448 7.08464 32.6116 7.08464 30.736V17.2642C7.08464 15.3886 8.08529 13.6554 9.70964 12.7176L21.3766 5.98167C23.0009 5.04384 25.0022 5.04384 26.6266 5.98167Z"
        stroke="#6E7681"
        strokeWidth="1.5"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      d="M23.991 14.4007C23.1288 14.4138 22.2684 14.4682 21.4098 14.5488C20.5386 14.6295 19.6773 14.7627 18.8205 14.9275C18.3998 15.009 18.0452 15.1843 17.8054 15.5402C17.6281 15.804 17.5648 16.095 17.5874 16.4071C17.6037 16.6402 17.5982 16.8734 17.6127 17.1074C17.648 17.6947 17.7204 18.2767 17.8298 18.8553C18.0524 20.0333 18.4405 21.1597 18.9996 22.2273C19.5063 23.1959 20.1342 24.0856 20.8887 24.8911C21.3157 25.3469 21.778 25.7641 22.2702 26.1507C22.3317 26.1989 22.348 26.2462 22.3353 26.3198C22.2014 27.1385 22.2792 27.9344 22.6158 28.7014C22.6782 28.8434 22.7596 28.9749 22.8465 29.1029C22.9008 29.1817 22.8827 29.2203 22.8022 29.2641C22.1462 29.6253 21.5039 30.0074 20.9023 30.4492C20.6625 30.6254 20.4255 30.8068 20.2328 31.0356C20.1188 31.1715 20.0518 31.3161 20.0573 31.4958C20.0645 31.7553 20.0609 32.0147 20.0582 32.2733C20.0582 32.3601 20.0844 32.3925 20.1839 32.3899C20.4056 32.3837 20.6281 32.3986 20.8507 32.3986C23.1423 32.4004 25.434 32.4004 27.7265 32.4004C27.921 32.4004 27.9219 32.3986 27.9201 32.2111C27.9183 31.977 27.9084 31.743 27.9201 31.5098C27.931 31.296 27.8496 31.1268 27.703 30.9778C27.3655 30.6342 26.9756 30.3546 26.5712 30.0916C26.1297 29.805 25.6773 29.535 25.2168 29.2773C25.092 29.2072 25.0875 29.1923 25.1626 29.0739C25.5163 28.5191 25.6737 27.9116 25.7072 27.2656C25.7235 26.9545 25.7054 26.6459 25.6602 26.3391C25.6466 26.2506 25.6719 26.1901 25.7452 26.1305C26.8571 25.2277 27.8079 24.1872 28.5607 22.9829C29.3577 21.7066 29.8797 20.3296 30.1566 18.864C30.3104 18.048 30.3864 17.224 30.4117 16.3948C30.4316 15.7251 30.0553 15.0967 29.1759 14.9284C28.5145 14.8013 27.8505 14.6891 27.181 14.6102C26.6436 14.5471 26.1044 14.491 25.5624 14.4647C25.0377 14.4401 24.5157 14.3963 23.991 14.4007Z"
      fill="#6E7681"
    />
    <path
      d="M31.0188 29.7129C30.979 29.1528 30.9437 28.6471 30.9057 28.1413C30.8939 27.9879 30.8957 27.9774 31.0577 27.9537C31.8565 27.8345 32.6337 27.632 33.4027 27.3945C33.4172 27.3901 33.4317 27.3866 33.4452 27.3796C33.4932 27.355 33.5719 27.3489 33.5674 27.284C33.5628 27.2201 33.4868 27.2542 33.4443 27.2385C33.1765 27.1394 32.9151 27.0272 32.6518 26.9185C31.8158 26.574 30.9943 26.2006 30.21 25.7536C29.7676 25.502 29.3396 25.2294 28.9352 24.9227C28.7416 24.7754 28.6701 24.7868 28.5154 24.9761C27.9654 25.6502 27.3737 26.2874 26.7141 26.8633C26.5884 26.9729 26.592 26.9711 26.7123 27.0728C27.836 28.0256 29.0917 28.7891 30.3945 29.4842C30.5565 29.5701 30.7166 29.6595 30.8795 29.7427C31.0025 29.805 31.0206 29.7918 31.0179 29.712L31.0188 29.7129Z"
      fill="#6E7681"
    />
    <path
      d="M14.4064 27.327C15.2514 27.6084 16.1009 27.8301 16.9758 27.952C17.0853 27.9669 17.0997 28.0151 17.0934 28.1054C17.0617 28.5384 17.0346 28.9714 17.0048 29.4044C16.9984 29.4991 16.9876 29.5937 16.9812 29.6875C16.974 29.7901 16.9921 29.8041 17.0853 29.7559C17.3449 29.6218 17.6037 29.4868 17.8597 29.3474C18.7201 28.8767 19.5624 28.3788 20.3513 27.8003C20.6815 27.5575 21.0054 27.3077 21.323 27.0483C21.3872 26.9957 21.3827 26.9615 21.323 26.9106C20.9267 26.5776 20.5612 26.2147 20.2047 25.8421C19.9586 25.5844 19.7316 25.3118 19.5081 25.0357C19.4873 25.0094 19.4674 24.9831 19.4466 24.9568C19.3136 24.7929 19.253 24.7815 19.0838 24.9086C18.4478 25.3872 17.7656 25.7966 17.0491 26.1507C16.2231 26.5591 15.3817 26.9343 14.5132 27.2534C14.4752 27.2674 14.4354 27.2797 14.4046 27.327H14.4064Z"
      fill="#6E7681"
    />
    <path
      d="M31.14 18.2031C31.1264 18.3013 31.1771 18.3451 31.2721 18.3565C31.339 18.3644 31.4069 18.3723 31.4729 18.3872C31.6439 18.4249 31.7471 18.5572 31.7018 18.7203C31.4811 19.517 31.2576 20.3138 30.8125 21.0308C30.6234 21.3359 30.4072 21.6199 30.1593 21.8819C30.0978 21.9477 30.0516 22.0231 30.0145 22.1028C29.6499 22.897 29.1976 23.6412 28.6692 24.3424C28.6502 24.3678 28.6122 24.395 28.6376 24.4274C28.6647 24.4616 28.6991 24.4274 28.7289 24.4178C29.4916 24.1758 30.1729 23.7902 30.7872 23.2976C31.273 22.9075 31.691 22.4499 32.0583 21.9512C32.7558 21.0037 33.2408 19.9588 33.5556 18.8404C33.751 18.1461 33.3041 17.451 32.5803 17.2933C32.1949 17.2091 31.8059 17.1364 31.4187 17.061C31.2422 17.0268 31.2323 17.0364 31.2314 17.2126C31.2305 17.5203 31.1898 17.8236 31.1482 18.1277C31.1445 18.1532 31.1427 18.1786 31.1391 18.204L31.14 18.2031Z"
      fill="#6E7681"
    />
    <path
      d="M14.4028 18.5476C14.3946 18.6764 14.4281 18.8149 14.4697 18.9517C14.8072 20.0526 15.2876 21.086 15.9906 22.0161C16.556 22.7637 17.2309 23.3975 18.056 23.8734C18.4324 24.0908 18.8278 24.2714 19.2412 24.4125C19.2747 24.4239 19.3136 24.4616 19.3434 24.4239C19.3706 24.3897 19.3299 24.3564 19.3091 24.3284C18.9933 23.9225 18.7138 23.493 18.4613 23.0477C18.2813 22.7313 18.1103 22.4096 17.9683 22.0748C17.9104 21.9389 17.8145 21.8337 17.715 21.7285C17.1468 21.1272 16.8157 20.4032 16.5678 19.638C16.4909 19.4013 16.4248 19.1612 16.3534 18.9228C16.3307 18.8456 16.3126 18.7676 16.3117 18.6861C16.3099 18.5318 16.3805 18.4372 16.5343 18.3986C16.6067 18.3802 16.6809 18.367 16.755 18.3539C16.8329 18.3407 16.8645 18.3004 16.8537 18.2242C16.8003 17.8665 16.7659 17.5071 16.7451 17.146C16.7415 17.075 16.7017 17.047 16.6329 17.0601C16.1977 17.1478 15.7544 17.196 15.3265 17.3143C14.7656 17.4695 14.4055 17.9542 14.4046 18.545L14.4028 18.5476Z"
      fill="#6E7681"
    />
    <defs>
      <filter
        id="filter0_d_4649_73750"
        x="5.58594"
        y="4.52832"
        width="36.832"
        height="40.4434"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.75" />
        <feGaussianBlur stdDeviation="0.375" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4649_73750"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4649_73750"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4649_73750"
        x1="24"
        y1="0"
        x2="24"
        y2="48"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0" />
        <stop offset="1" stopColor="#6E7681" />
      </linearGradient>
    </defs>
  </svg>
);

export const TrophySilverBorder: React.FC<IconProps> = ({ className }) => (
  <svg
    className={`${className}`}
    viewBox="0 0 48 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.5346 11.9689L27.75 3.43301C25.4295 2.09327 22.5705 2.09327 20.25 3.43301L5.46539 11.9689C3.14488 13.3087 1.71539 15.7846 1.71539 18.4641V35.5359C1.71539 38.2154 3.14488 40.6913 5.46539 42.0311L20.25 50.567C22.5705 51.9067 25.4295 51.9067 27.75 50.567L42.5346 42.0311C44.8551 40.6913 46.2846 38.2154 46.2846 35.5359V18.4641C46.2846 15.7846 44.8551 13.3087 42.5346 11.9689Z"
      fill="#151A1F"
    />
    <path
      d="M42.5346 11.9689L27.75 3.43301C25.4295 2.09327 22.5705 2.09327 20.25 3.43301L5.46539 11.9689C3.14488 13.3087 1.71539 15.7846 1.71539 18.4641V35.5359C1.71539 38.2154 3.14488 40.6913 5.46539 42.0311L20.25 50.567C22.5705 51.9067 25.4295 51.9067 27.75 50.567L42.5346 42.0311C44.8551 40.6913 46.2846 38.2154 46.2846 35.5359V18.4641C46.2846 15.7846 44.8551 13.3087 42.5346 11.9689Z"
      fill="url(#paint0_linear_4704_73149)"
      fillOpacity="0.4"
    />
    <path
      d="M42.5346 11.9689L27.75 3.43301C25.4295 2.09327 22.5705 2.09327 20.25 3.43301L5.46539 11.9689C3.14488 13.3087 1.71539 15.7846 1.71539 18.4641V35.5359C1.71539 38.2154 3.14488 40.6913 5.46539 42.0311L20.25 50.567C22.5705 51.9067 25.4295 51.9067 27.75 50.567L42.5346 42.0311C44.8551 40.6913 46.2846 38.2154 46.2846 35.5359V18.4641C46.2846 15.7846 44.8551 13.3087 42.5346 11.9689Z"
      stroke="#0D1012"
      strokeWidth="3"
    />
    <g filter="url(#filter0_d_4704_73149)">
      <path
        d="M26.6266 8.98118L38.2935 15.7171C39.9178 16.6549 40.9185 18.3881 40.9185 20.2637V33.7355C40.9185 35.6112 39.9178 37.3443 38.2935 38.2821L26.6266 45.018C25.0022 45.9559 23.0009 45.9559 21.3766 45.018L9.70964 38.2821C8.08529 37.3443 7.08464 35.6112 7.08464 33.7355V20.2637C7.08464 18.3881 8.08529 16.6549 9.70964 15.7171L21.3766 8.98118C23.0009 8.04336 25.0022 8.04336 26.6266 8.98118Z"
        stroke="#6E7681"
        strokeWidth="1.5"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      d="M23.991 17.4007C23.1288 17.4138 22.2684 17.4682 21.4098 17.5488C20.5386 17.6295 19.6773 17.7627 18.8205 17.9275C18.3998 18.009 18.0452 18.1843 17.8054 18.5402C17.6281 18.804 17.5648 19.095 17.5874 19.4071C17.6037 19.6402 17.5982 19.8734 17.6127 20.1074C17.648 20.6947 17.7204 21.2767 17.8298 21.8553C18.0524 23.0333 18.4405 24.1597 18.9996 25.2273C19.5063 26.1959 20.1342 27.0856 20.8887 27.8911C21.3157 28.3469 21.778 28.7641 22.2702 29.1507C22.3317 29.1989 22.348 29.2462 22.3353 29.3198C22.2014 30.1385 22.2792 30.9344 22.6158 31.7014C22.6782 31.8434 22.7596 31.9749 22.8465 32.1029C22.9008 32.1817 22.8827 32.2203 22.8022 32.2641C22.1462 32.6253 21.5039 33.0074 20.9023 33.4492C20.6625 33.6254 20.4255 33.8068 20.2328 34.0356C20.1188 34.1715 20.0518 34.3161 20.0573 34.4958C20.0645 34.7553 20.0609 35.0147 20.0582 35.2733C20.0582 35.3601 20.0844 35.3925 20.1839 35.3899C20.4056 35.3837 20.6281 35.3986 20.8507 35.3986C23.1423 35.4004 25.434 35.4004 27.7265 35.4004C27.921 35.4004 27.9219 35.3986 27.9201 35.2111C27.9183 34.977 27.9084 34.743 27.9201 34.5098C27.931 34.296 27.8496 34.1268 27.703 33.9778C27.3655 33.6342 26.9756 33.3546 26.5712 33.0916C26.1297 32.805 25.6773 32.535 25.2168 32.2773C25.092 32.2072 25.0875 32.1923 25.1626 32.0739C25.5163 31.5191 25.6737 30.9116 25.7072 30.2656C25.7235 29.9545 25.7054 29.6459 25.6602 29.3391C25.6466 29.2506 25.6719 29.1901 25.7452 29.1305C26.8571 28.2277 27.8079 27.1872 28.5607 25.9829C29.3577 24.7066 29.8797 23.3296 30.1566 21.864C30.3104 21.048 30.3864 20.224 30.4117 19.3948C30.4316 18.7251 30.0553 18.0967 29.1759 17.9284C28.5145 17.8013 27.8505 17.6891 27.181 17.6102C26.6436 17.5471 26.1044 17.491 25.5624 17.4647C25.0377 17.4401 24.5157 17.3963 23.991 17.4007Z"
      fill="#6E7681"
    />
    <path
      d="M31.0188 32.7129C30.979 32.1528 30.9437 31.6471 30.9057 31.1413C30.8939 30.9879 30.8957 30.9774 31.0577 30.9537C31.8565 30.8345 32.6337 30.632 33.4027 30.3945C33.4172 30.3901 33.4317 30.3866 33.4452 30.3796C33.4932 30.355 33.5719 30.3489 33.5674 30.284C33.5628 30.2201 33.4868 30.2542 33.4443 30.2385C33.1765 30.1394 32.9151 30.0272 32.6518 29.9185C31.8158 29.574 30.9943 29.2006 30.21 28.7536C29.7676 28.502 29.3396 28.2294 28.9352 27.9227C28.7416 27.7754 28.6701 27.7868 28.5154 27.9761C27.9654 28.6502 27.3737 29.2874 26.7141 29.8633C26.5884 29.9729 26.592 29.9711 26.7123 30.0728C27.836 31.0256 29.0917 31.7891 30.3945 32.4842C30.5565 32.5701 30.7166 32.6595 30.8795 32.7427C31.0025 32.805 31.0206 32.7918 31.0179 32.712L31.0188 32.7129Z"
      fill="#6E7681"
    />
    <path
      d="M14.4064 30.327C15.2514 30.6084 16.1009 30.8301 16.9758 30.952C17.0853 30.9669 17.0997 31.0151 17.0934 31.1054C17.0617 31.5384 17.0346 31.9714 17.0048 32.4044C16.9984 32.4991 16.9876 32.5937 16.9812 32.6875C16.974 32.7901 16.9921 32.8041 17.0853 32.7559C17.3449 32.6218 17.6037 32.4868 17.8597 32.3474C18.7201 31.8767 19.5624 31.3788 20.3513 30.8003C20.6815 30.5575 21.0054 30.3077 21.323 30.0483C21.3872 29.9957 21.3827 29.9615 21.323 29.9106C20.9267 29.5776 20.5612 29.2147 20.2047 28.8421C19.9586 28.5844 19.7316 28.3118 19.5081 28.0357C19.4873 28.0094 19.4674 27.9831 19.4466 27.9568C19.3136 27.7929 19.253 27.7815 19.0838 27.9086C18.4478 28.3872 17.7656 28.7966 17.0491 29.1507C16.2231 29.5591 15.3817 29.9343 14.5132 30.2534C14.4752 30.2674 14.4354 30.2797 14.4046 30.327H14.4064Z"
      fill="#6E7681"
    />
    <path
      d="M31.14 21.2031C31.1264 21.3013 31.1771 21.3451 31.2721 21.3565C31.339 21.3644 31.4069 21.3723 31.4729 21.3872C31.6439 21.4249 31.7471 21.5572 31.7018 21.7203C31.4811 22.517 31.2576 23.3138 30.8125 24.0308C30.6234 24.3359 30.4072 24.6199 30.1593 24.8819C30.0978 24.9477 30.0516 25.0231 30.0145 25.1028C29.6499 25.897 29.1976 26.6412 28.6692 27.3424C28.6502 27.3678 28.6122 27.395 28.6376 27.4274C28.6647 27.4616 28.6991 27.4274 28.7289 27.4178C29.4916 27.1758 30.1729 26.7902 30.7872 26.2976C31.273 25.9075 31.691 25.4499 32.0583 24.9512C32.7558 24.0037 33.2408 22.9588 33.5556 21.8404C33.751 21.1461 33.3041 20.451 32.5803 20.2933C32.1949 20.2091 31.8059 20.1364 31.4187 20.061C31.2422 20.0268 31.2323 20.0364 31.2314 20.2126C31.2305 20.5203 31.1898 20.8236 31.1482 21.1277C31.1445 21.1532 31.1427 21.1786 31.1391 21.204L31.14 21.2031Z"
      fill="#6E7681"
    />
    <path
      d="M14.4028 21.5476C14.3946 21.6764 14.4281 21.8149 14.4697 21.9517C14.8072 23.0526 15.2876 24.086 15.9906 25.0161C16.556 25.7637 17.2309 26.3975 18.056 26.8734C18.4324 27.0908 18.8278 27.2714 19.2412 27.4125C19.2747 27.4239 19.3136 27.4616 19.3434 27.4239C19.3706 27.3897 19.3299 27.3564 19.3091 27.3284C18.9933 26.9225 18.7138 26.493 18.4613 26.0477C18.2813 25.7313 18.1103 25.4096 17.9683 25.0748C17.9104 24.9389 17.8145 24.8337 17.715 24.7285C17.1468 24.1272 16.8157 23.4032 16.5678 22.638C16.4909 22.4013 16.4248 22.1612 16.3534 21.9228C16.3307 21.8456 16.3126 21.7676 16.3117 21.6861C16.3099 21.5318 16.3805 21.4372 16.5343 21.3986C16.6067 21.3802 16.6809 21.367 16.755 21.3539C16.8329 21.3407 16.8645 21.3004 16.8537 21.2242C16.8003 20.8665 16.7659 20.5071 16.7451 20.146C16.7415 20.075 16.7017 20.047 16.6329 20.0601C16.1977 20.1478 15.7544 20.196 15.3265 20.3143C14.7656 20.4695 14.4055 20.9542 14.4046 21.545L14.4028 21.5476Z"
      fill="#6E7681"
    />
    <defs>
      <filter
        id="filter0_d_4704_73149"
        x="5.58594"
        y="7.52734"
        width="36.832"
        height="40.4443"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.75" />
        <feGaussianBlur stdDeviation="0.375" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4704_73149"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4704_73149"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4704_73149"
        x1="24"
        y1="3"
        x2="24"
        y2="51"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0" />
        <stop offset="1" stopColor="#6E7681" />
      </linearGradient>
    </defs>
  </svg>
);

export const TrophyBronze: React.FC<IconProps> = ({ className }) => (
  <svg
    className={`${className}`}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 1.73205C22.8564 0.660254 25.1436 0.660254 27 1.73205L41.7846 10.2679C43.641 11.3397 44.7846 13.3205 44.7846 15.4641V32.5359C44.7846 34.6795 43.641 36.6603 41.7846 37.7321L27 46.2679C25.1436 47.3397 22.8564 47.3397 21 46.2679L6.21539 37.7321C4.35899 36.6603 3.21539 34.6795 3.21539 32.5359V15.4641C3.21539 13.3205 4.35898 11.3397 6.21539 10.2679L21 1.73205Z"
      fill="#151A1F"
    />
    <path
      d="M21 1.73205C22.8564 0.660254 25.1436 0.660254 27 1.73205L41.7846 10.2679C43.641 11.3397 44.7846 13.3205 44.7846 15.4641V32.5359C44.7846 34.6795 43.641 36.6603 41.7846 37.7321L27 46.2679C25.1436 47.3397 22.8564 47.3397 21 46.2679L6.21539 37.7321C4.35899 36.6603 3.21539 34.6795 3.21539 32.5359V15.4641C3.21539 13.3205 4.35898 11.3397 6.21539 10.2679L21 1.73205Z"
      fill="url(#paint0_linear_4649_73754)"
      fillOpacity="0.4"
    />
    <g filter="url(#filter0_d_4649_73754)">
      <path
        d="M26.6266 5.98167L38.2935 12.7176C39.9178 13.6554 40.9185 15.3886 40.9185 17.2642V30.736C40.9185 32.6116 39.9178 34.3448 38.2935 35.2826L26.6266 42.0185C25.0022 42.9564 23.0009 42.9563 21.3766 42.0185L9.70964 35.2826C8.08529 34.3448 7.08464 32.6116 7.08464 30.736V17.2642C7.08464 15.3886 8.08529 13.6554 9.70964 12.7176L21.3766 5.98167C23.0009 5.04384 25.0022 5.04384 26.6266 5.98167Z"
        stroke="#C24D28"
        strokeWidth="1.5"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      d="M23.991 14.4007C23.1288 14.4138 22.2684 14.4682 21.4098 14.5488C20.5386 14.6295 19.6773 14.7627 18.8205 14.9275C18.3998 15.009 18.0452 15.1843 17.8054 15.5402C17.6281 15.804 17.5648 16.095 17.5874 16.4071C17.6037 16.6402 17.5982 16.8734 17.6127 17.1074C17.648 17.6947 17.7204 18.2767 17.8298 18.8553C18.0524 20.0333 18.4405 21.1597 18.9996 22.2273C19.5063 23.1959 20.1342 24.0856 20.8887 24.8911C21.3157 25.3469 21.778 25.7641 22.2702 26.1507C22.3317 26.1989 22.348 26.2462 22.3353 26.3198C22.2014 27.1385 22.2792 27.9344 22.6158 28.7014C22.6782 28.8434 22.7596 28.9749 22.8465 29.1029C22.9008 29.1817 22.8827 29.2203 22.8022 29.2641C22.1462 29.6253 21.5039 30.0074 20.9023 30.4492C20.6625 30.6254 20.4255 30.8068 20.2328 31.0356C20.1188 31.1715 20.0518 31.3161 20.0573 31.4958C20.0645 31.7553 20.0609 32.0147 20.0582 32.2733C20.0582 32.3601 20.0844 32.3925 20.1839 32.3899C20.4056 32.3837 20.6281 32.3986 20.8507 32.3986C23.1423 32.4004 25.434 32.4004 27.7265 32.4004C27.921 32.4004 27.9219 32.3986 27.9201 32.2111C27.9183 31.977 27.9084 31.743 27.9201 31.5098C27.931 31.296 27.8496 31.1268 27.703 30.9778C27.3655 30.6342 26.9756 30.3546 26.5712 30.0916C26.1297 29.805 25.6773 29.535 25.2168 29.2773C25.092 29.2072 25.0875 29.1923 25.1626 29.0739C25.5163 28.5191 25.6737 27.9116 25.7072 27.2656C25.7235 26.9545 25.7054 26.6459 25.6602 26.3391C25.6466 26.2506 25.6719 26.1901 25.7452 26.1305C26.8571 25.2277 27.8079 24.1872 28.5607 22.9829C29.3577 21.7066 29.8797 20.3296 30.1566 18.864C30.3104 18.048 30.3864 17.224 30.4117 16.3948C30.4316 15.7251 30.0553 15.0967 29.1759 14.9284C28.5145 14.8013 27.8505 14.6891 27.181 14.6102C26.6436 14.5471 26.1044 14.491 25.5624 14.4647C25.0377 14.4401 24.5157 14.3963 23.991 14.4007Z"
      fill="#C24D28"
    />
    <path
      d="M31.0188 29.7129C30.979 29.1528 30.9437 28.6471 30.9057 28.1413C30.8939 27.9879 30.8957 27.9774 31.0577 27.9537C31.8565 27.8345 32.6337 27.632 33.4027 27.3945C33.4172 27.3901 33.4317 27.3866 33.4452 27.3796C33.4932 27.355 33.5719 27.3489 33.5674 27.284C33.5628 27.2201 33.4868 27.2542 33.4443 27.2385C33.1765 27.1394 32.9151 27.0272 32.6518 26.9185C31.8158 26.574 30.9943 26.2006 30.21 25.7536C29.7676 25.502 29.3396 25.2294 28.9352 24.9227C28.7416 24.7754 28.6701 24.7868 28.5154 24.9761C27.9654 25.6502 27.3737 26.2874 26.7141 26.8633C26.5884 26.9729 26.592 26.9711 26.7123 27.0728C27.836 28.0256 29.0917 28.7891 30.3945 29.4842C30.5565 29.5701 30.7166 29.6595 30.8795 29.7427C31.0025 29.805 31.0206 29.7918 31.0179 29.712L31.0188 29.7129Z"
      fill="#C24D28"
    />
    <path
      d="M14.4064 27.327C15.2514 27.6084 16.1009 27.8301 16.9758 27.952C17.0853 27.9669 17.0997 28.0151 17.0934 28.1054C17.0617 28.5384 17.0346 28.9714 17.0048 29.4044C16.9984 29.4991 16.9876 29.5937 16.9812 29.6875C16.974 29.7901 16.9921 29.8041 17.0853 29.7559C17.3449 29.6218 17.6037 29.4868 17.8597 29.3474C18.7201 28.8767 19.5624 28.3788 20.3513 27.8003C20.6815 27.5575 21.0054 27.3077 21.323 27.0483C21.3872 26.9957 21.3827 26.9615 21.323 26.9106C20.9267 26.5776 20.5612 26.2147 20.2047 25.8421C19.9586 25.5844 19.7316 25.3118 19.5081 25.0357C19.4873 25.0094 19.4674 24.9831 19.4466 24.9568C19.3136 24.7929 19.253 24.7815 19.0838 24.9086C18.4478 25.3872 17.7656 25.7966 17.0491 26.1507C16.2231 26.5591 15.3817 26.9343 14.5132 27.2534C14.4752 27.2674 14.4354 27.2797 14.4046 27.327H14.4064Z"
      fill="#C24D28"
    />
    <path
      d="M31.14 18.2031C31.1264 18.3013 31.1771 18.3451 31.2721 18.3565C31.339 18.3644 31.4069 18.3723 31.4729 18.3872C31.6439 18.4249 31.7471 18.5572 31.7018 18.7203C31.4811 19.517 31.2576 20.3138 30.8125 21.0308C30.6234 21.3359 30.4072 21.6199 30.1593 21.8819C30.0978 21.9477 30.0516 22.0231 30.0145 22.1028C29.6499 22.897 29.1976 23.6412 28.6692 24.3424C28.6502 24.3678 28.6122 24.395 28.6376 24.4274C28.6647 24.4616 28.6991 24.4274 28.7289 24.4178C29.4916 24.1758 30.1729 23.7902 30.7872 23.2976C31.273 22.9075 31.691 22.4499 32.0583 21.9512C32.7558 21.0037 33.2408 19.9588 33.5556 18.8404C33.751 18.1461 33.3041 17.451 32.5803 17.2933C32.1949 17.2091 31.8059 17.1364 31.4187 17.061C31.2422 17.0268 31.2323 17.0364 31.2314 17.2126C31.2305 17.5203 31.1898 17.8236 31.1482 18.1277C31.1445 18.1532 31.1427 18.1786 31.1391 18.204L31.14 18.2031Z"
      fill="#C24D28"
    />
    <path
      d="M14.4028 18.5476C14.3946 18.6764 14.4281 18.8149 14.4697 18.9517C14.8072 20.0526 15.2876 21.086 15.9906 22.0161C16.556 22.7637 17.2309 23.3975 18.056 23.8734C18.4324 24.0908 18.8278 24.2714 19.2412 24.4125C19.2747 24.4239 19.3136 24.4616 19.3434 24.4239C19.3706 24.3897 19.3299 24.3564 19.3091 24.3284C18.9933 23.9225 18.7138 23.493 18.4613 23.0477C18.2813 22.7313 18.1103 22.4096 17.9683 22.0748C17.9104 21.9389 17.8145 21.8337 17.715 21.7285C17.1468 21.1272 16.8157 20.4032 16.5678 19.638C16.4909 19.4013 16.4248 19.1612 16.3534 18.9228C16.3307 18.8456 16.3126 18.7676 16.3117 18.6861C16.3099 18.5318 16.3805 18.4372 16.5343 18.3986C16.6067 18.3802 16.6809 18.367 16.755 18.3539C16.8329 18.3407 16.8645 18.3004 16.8537 18.2242C16.8003 17.8665 16.7659 17.5071 16.7451 17.146C16.7415 17.075 16.7017 17.047 16.6329 17.0601C16.1977 17.1478 15.7544 17.196 15.3265 17.3143C14.7656 17.4695 14.4055 17.9542 14.4046 18.545L14.4028 18.5476Z"
      fill="#C24D28"
    />
    <defs>
      <filter
        id="filter0_d_4649_73754"
        x="5.58594"
        y="4.52832"
        width="36.832"
        height="40.4434"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.75" />
        <feGaussianBlur stdDeviation="0.375" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4649_73754"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4649_73754"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4649_73754"
        x1="24"
        y1="0"
        x2="24"
        y2="48"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0" />
        <stop offset="1" stopColor="#C24D28" />
      </linearGradient>
    </defs>
  </svg>
);

export const TrophyBronzeBorder: React.FC<IconProps> = ({ className }) => (
  <svg
    className={`${className}`}
    viewBox="0 0 48 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.5346 11.9689L27.75 3.43301C25.4295 2.09327 22.5705 2.09327 20.25 3.43301L5.46539 11.9689C3.14488 13.3087 1.71539 15.7846 1.71539 18.4641V35.5359C1.71539 38.2154 3.14488 40.6913 5.46539 42.0311L20.25 50.567C22.5705 51.9067 25.4295 51.9067 27.75 50.567L42.5346 42.0311C44.8551 40.6913 46.2846 38.2154 46.2846 35.5359V18.4641C46.2846 15.7846 44.8551 13.3087 42.5346 11.9689Z"
      fill="#151A1F"
    />
    <path
      d="M42.5346 11.9689L27.75 3.43301C25.4295 2.09327 22.5705 2.09327 20.25 3.43301L5.46539 11.9689C3.14488 13.3087 1.71539 15.7846 1.71539 18.4641V35.5359C1.71539 38.2154 3.14488 40.6913 5.46539 42.0311L20.25 50.567C22.5705 51.9067 25.4295 51.9067 27.75 50.567L42.5346 42.0311C44.8551 40.6913 46.2846 38.2154 46.2846 35.5359V18.4641C46.2846 15.7846 44.8551 13.3087 42.5346 11.9689Z"
      fill="url(#paint0_linear_4704_73164)"
      fillOpacity="0.4"
    />
    <path
      d="M42.5346 11.9689L27.75 3.43301C25.4295 2.09327 22.5705 2.09327 20.25 3.43301L5.46539 11.9689C3.14488 13.3087 1.71539 15.7846 1.71539 18.4641V35.5359C1.71539 38.2154 3.14488 40.6913 5.46539 42.0311L20.25 50.567C22.5705 51.9067 25.4295 51.9067 27.75 50.567L42.5346 42.0311C44.8551 40.6913 46.2846 38.2154 46.2846 35.5359V18.4641C46.2846 15.7846 44.8551 13.3087 42.5346 11.9689Z"
      stroke="#0D1012"
      strokeWidth="3"
    />
    <g filter="url(#filter0_d_4704_73164)">
      <path
        d="M26.6266 8.98118L38.2935 15.7171C39.9178 16.6549 40.9185 18.3881 40.9185 20.2637V33.7355C40.9185 35.6112 39.9178 37.3443 38.2935 38.2821L26.6266 45.018C25.0022 45.9559 23.0009 45.9559 21.3766 45.018L9.70964 38.2821C8.08529 37.3443 7.08464 35.6112 7.08464 33.7355V20.2637C7.08464 18.3881 8.08529 16.6549 9.70964 15.7171L21.3766 8.98118C23.0009 8.04336 25.0022 8.04336 26.6266 8.98118Z"
        stroke="#C24D28"
        strokeWidth="1.5"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      d="M23.991 17.4007C23.1288 17.4138 22.2684 17.4682 21.4098 17.5488C20.5386 17.6295 19.6773 17.7627 18.8205 17.9275C18.3998 18.009 18.0452 18.1843 17.8054 18.5402C17.6281 18.804 17.5648 19.095 17.5874 19.4071C17.6037 19.6402 17.5982 19.8734 17.6127 20.1074C17.648 20.6947 17.7204 21.2767 17.8298 21.8553C18.0524 23.0333 18.4405 24.1597 18.9996 25.2273C19.5063 26.1959 20.1342 27.0856 20.8887 27.8911C21.3157 28.3469 21.778 28.7641 22.2702 29.1507C22.3317 29.1989 22.348 29.2462 22.3353 29.3198C22.2014 30.1385 22.2792 30.9344 22.6158 31.7014C22.6782 31.8434 22.7596 31.9749 22.8465 32.1029C22.9008 32.1817 22.8827 32.2203 22.8022 32.2641C22.1462 32.6253 21.5039 33.0074 20.9023 33.4492C20.6625 33.6254 20.4255 33.8068 20.2328 34.0356C20.1188 34.1715 20.0518 34.3161 20.0573 34.4958C20.0645 34.7553 20.0609 35.0147 20.0582 35.2733C20.0582 35.3601 20.0844 35.3925 20.1839 35.3899C20.4056 35.3837 20.6281 35.3986 20.8507 35.3986C23.1423 35.4004 25.434 35.4004 27.7265 35.4004C27.921 35.4004 27.9219 35.3986 27.9201 35.2111C27.9183 34.977 27.9084 34.743 27.9201 34.5098C27.931 34.296 27.8496 34.1268 27.703 33.9778C27.3655 33.6342 26.9756 33.3546 26.5712 33.0916C26.1297 32.805 25.6773 32.535 25.2168 32.2773C25.092 32.2072 25.0875 32.1923 25.1626 32.0739C25.5163 31.5191 25.6737 30.9116 25.7072 30.2656C25.7235 29.9545 25.7054 29.6459 25.6602 29.3391C25.6466 29.2506 25.6719 29.1901 25.7452 29.1305C26.8571 28.2277 27.8079 27.1872 28.5607 25.9829C29.3577 24.7066 29.8797 23.3296 30.1566 21.864C30.3104 21.048 30.3864 20.224 30.4117 19.3948C30.4316 18.7251 30.0553 18.0967 29.1759 17.9284C28.5145 17.8013 27.8505 17.6891 27.181 17.6102C26.6436 17.5471 26.1044 17.491 25.5624 17.4647C25.0377 17.4401 24.5157 17.3963 23.991 17.4007Z"
      fill="#C24D28"
    />
    <path
      d="M31.0188 32.7129C30.979 32.1528 30.9437 31.6471 30.9057 31.1413C30.8939 30.9879 30.8957 30.9774 31.0577 30.9537C31.8565 30.8345 32.6337 30.632 33.4027 30.3945C33.4172 30.3901 33.4317 30.3866 33.4452 30.3796C33.4932 30.355 33.5719 30.3489 33.5674 30.284C33.5628 30.2201 33.4868 30.2542 33.4443 30.2385C33.1765 30.1394 32.9151 30.0272 32.6518 29.9185C31.8158 29.574 30.9943 29.2006 30.21 28.7536C29.7676 28.502 29.3396 28.2294 28.9352 27.9227C28.7416 27.7754 28.6701 27.7868 28.5154 27.9761C27.9654 28.6502 27.3737 29.2874 26.7141 29.8633C26.5884 29.9729 26.592 29.9711 26.7123 30.0728C27.836 31.0256 29.0917 31.7891 30.3945 32.4842C30.5565 32.5701 30.7166 32.6595 30.8795 32.7427C31.0025 32.805 31.0206 32.7918 31.0179 32.712L31.0188 32.7129Z"
      fill="#C24D28"
    />
    <path
      d="M14.4064 30.327C15.2514 30.6084 16.1009 30.8301 16.9758 30.952C17.0853 30.9669 17.0997 31.0151 17.0934 31.1054C17.0617 31.5384 17.0346 31.9714 17.0048 32.4044C16.9984 32.4991 16.9876 32.5937 16.9812 32.6875C16.974 32.7901 16.9921 32.8041 17.0853 32.7559C17.3449 32.6218 17.6037 32.4868 17.8597 32.3474C18.7201 31.8767 19.5624 31.3788 20.3513 30.8003C20.6815 30.5575 21.0054 30.3077 21.323 30.0483C21.3872 29.9957 21.3827 29.9615 21.323 29.9106C20.9267 29.5776 20.5612 29.2147 20.2047 28.8421C19.9586 28.5844 19.7316 28.3118 19.5081 28.0357C19.4873 28.0094 19.4674 27.9831 19.4466 27.9568C19.3136 27.7929 19.253 27.7815 19.0838 27.9086C18.4478 28.3872 17.7656 28.7966 17.0491 29.1507C16.2231 29.5591 15.3817 29.9343 14.5132 30.2534C14.4752 30.2674 14.4354 30.2797 14.4046 30.327H14.4064Z"
      fill="#C24D28"
    />
    <path
      d="M31.14 21.2031C31.1264 21.3013 31.1771 21.3451 31.2721 21.3565C31.339 21.3644 31.4069 21.3723 31.4729 21.3872C31.6439 21.4249 31.7471 21.5572 31.7018 21.7203C31.4811 22.517 31.2576 23.3138 30.8125 24.0308C30.6234 24.3359 30.4072 24.6199 30.1593 24.8819C30.0978 24.9477 30.0516 25.0231 30.0145 25.1028C29.6499 25.897 29.1976 26.6412 28.6692 27.3424C28.6502 27.3678 28.6122 27.395 28.6376 27.4274C28.6647 27.4616 28.6991 27.4274 28.7289 27.4178C29.4916 27.1758 30.1729 26.7902 30.7872 26.2976C31.273 25.9075 31.691 25.4499 32.0583 24.9512C32.7558 24.0037 33.2408 22.9588 33.5556 21.8404C33.751 21.1461 33.3041 20.451 32.5803 20.2933C32.1949 20.2091 31.8059 20.1364 31.4187 20.061C31.2422 20.0268 31.2323 20.0364 31.2314 20.2126C31.2305 20.5203 31.1898 20.8236 31.1482 21.1277C31.1445 21.1532 31.1427 21.1786 31.1391 21.204L31.14 21.2031Z"
      fill="#C24D28"
    />
    <path
      d="M14.4028 21.5476C14.3946 21.6764 14.4281 21.8149 14.4697 21.9517C14.8072 23.0526 15.2876 24.086 15.9906 25.0161C16.556 25.7637 17.2309 26.3975 18.056 26.8734C18.4324 27.0908 18.8278 27.2714 19.2412 27.4125C19.2747 27.4239 19.3136 27.4616 19.3434 27.4239C19.3706 27.3897 19.3299 27.3564 19.3091 27.3284C18.9933 26.9225 18.7138 26.493 18.4613 26.0477C18.2813 25.7313 18.1103 25.4096 17.9683 25.0748C17.9104 24.9389 17.8145 24.8337 17.715 24.7285C17.1468 24.1272 16.8157 23.4032 16.5678 22.638C16.4909 22.4013 16.4248 22.1612 16.3534 21.9228C16.3307 21.8456 16.3126 21.7676 16.3117 21.6861C16.3099 21.5318 16.3805 21.4372 16.5343 21.3986C16.6067 21.3802 16.6809 21.367 16.755 21.3539C16.8329 21.3407 16.8645 21.3004 16.8537 21.2242C16.8003 20.8665 16.7659 20.5071 16.7451 20.146C16.7415 20.075 16.7017 20.047 16.6329 20.0601C16.1977 20.1478 15.7544 20.196 15.3265 20.3143C14.7656 20.4695 14.4055 20.9542 14.4046 21.545L14.4028 21.5476Z"
      fill="#C24D28"
    />
    <defs>
      <filter
        id="filter0_d_4704_73164"
        x="5.58594"
        y="7.52734"
        width="36.832"
        height="40.4443"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.75" />
        <feGaussianBlur stdDeviation="0.375" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4704_73164"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4704_73164"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4704_73164"
        x1="24"
        y1="3"
        x2="24"
        y2="51"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0" />
        <stop offset="1" stopColor="#C24D28" />
      </linearGradient>
    </defs>
  </svg>
);

export const SwordsIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={`${className}`}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 3.2002L13.7373 8.93745L8.93725 13.7375L3.2 8.0002V3.2002H8Z"
      fill="#4D545E"
      className="fill-inherit"
    />
    <path
      d="M13.9314 22.8688L16.3314 25.2688L14.0686 27.5316L10.4 23.8629L7.99634 26.2666C7.99877 26.3108 8 26.3554 8 26.4002C8 27.7257 6.92548 28.8002 5.6 28.8002C4.27451 28.8002 3.2 27.7257 3.2 26.4002C3.2 25.0747 4.27451 24.0002 5.6 24.0002C5.64483 24.0002 5.68937 24.0014 5.7336 24.0039L8.13726 21.6002L4.46863 17.9316L6.73137 15.6688L9.13137 18.0688L24 3.2002H28.8V8.0002L13.9314 22.8688Z"
      fill="#4D545E"
      className="fill-inherit"
    />
    <path
      d="M26.4 28.8002C25.0745 28.8002 24 27.7257 24 26.4002C24 26.3554 24.0012 26.3108 24.0037 26.2666L19.3373 21.6002L25.2686 15.6688L27.5314 17.9316L23.8627 21.6002L26.2664 24.0039C26.3106 24.0014 26.3552 24.0002 26.4 24.0002C27.7255 24.0002 28.8 25.0747 28.8 26.4002C28.8 27.7257 27.7255 28.8002 26.4 28.8002Z"
      fill="#4D545E"
      className="fill-inherit"
    />
  </svg>
);

interface IPrizeHex extends IconProps {
  fillHex: string;
}

export const PrizeHex: React.FC<IPrizeHex> = ({ className, fillHex }) => {
  return (
    <svg
      className={`${className} relative flex items-center justify-center`}
      viewBox="0 0 80 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="gradientHex" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" stopColor="red" stopOpacity={1} />
          <stop offset="100%" stopColor="red" stopOpacity={0} />
        </linearGradient>
      </defs>
      <path
        d="M45 3.57735C41.906 1.79102 38.094 1.79102 35 3.57735L8.35898 18.9585C5.26497 20.7449 3.35898 24.0461 3.35898 27.6188V58.3812C3.35898 61.9539 5.26497 65.2551 8.35898 67.0415L35 82.4226C38.094 84.209 41.906 84.209 45 82.4226L71.641 67.0415C74.735 65.2551 76.641 61.9539 76.641 58.3812V27.6188C76.641 24.0461 74.735 20.7449 71.641 18.9585L45 3.57735Z"
        fill="#151A1F"
      />
      <path
        d="M45 3.57735C41.906 1.79102 38.094 1.79102 35 3.57735L8.35898 18.9585C5.26497 20.7449 3.35898 24.0461 3.35898 27.6188V58.3812C3.35898 61.9539 5.26497 65.2551 8.35898 67.0415L35 82.4226C38.094 84.209 41.906 84.209 45 82.4226L71.641 67.0415C74.735 65.2551 76.641 61.9539 76.641 58.3812V27.6188C76.641 24.0461 74.735 20.7449 71.641 18.9585L45 3.57735Z"
        style={{ fill: 'url(#gradientHex)' }}
      />
      <path
        d="M45 3.57735C41.906 1.79102 38.094 1.79102 35 3.57735L8.35898 18.9585C5.26497 20.7449 3.35898 24.0461 3.35898 27.6188V58.3812C3.35898 61.9539 5.26497 65.2551 8.35898 67.0415L35 82.4226C38.094 84.209 41.906 84.209 45 82.4226L71.641 67.0415C74.735 65.2551 76.641 61.9539 76.641 58.3812V27.6188C76.641 24.0461 74.735 20.7449 71.641 18.9585L45 3.57735Z"
        stroke="#0D1012"
        strokeWidth="4"
      />
      <g filter="url(#gradientHex)">
        <path
          d="M36.5 12.1754C38.6658 10.925 41.3342 10.925 43.5 12.1754L64.9449 24.5566C67.1107 25.8071 68.4449 28.1179 68.4449 30.6188V55.3812C68.4449 57.8821 67.1107 60.1929 64.9449 61.4434L43.5 73.8246C41.3342 75.075 38.6658 75.075 36.5 73.8246L15.0551 61.4434C12.8893 60.1929 11.5551 57.8821 11.5551 55.3812V30.6188C11.5551 28.1179 12.8893 25.8071 15.0551 24.5566L36.5 12.1754Z"
          stroke={fillHex}
          strokeWidth="2"
          shapeRendering="crispEdges"
        />
      </g>
    </svg>
  );
};

export const WhiteLocationIcon: React.FC<IconProps> = ({ fill, className }) => {
  return (
    <svg
      viewBox="0 0 17 16"
      fill="none"
      className={`w-[17px] h-auto aspect-square ${className}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 10C9.60457 10 10.5 9.10457 10.5 8C10.5 6.89543 9.60457 6 8.5 6C7.39543 6 6.5 6.89543 6.5 8C6.5 9.10457 7.39543 10 8.5 10Z"
        fill={fill ? fill : 'white'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.58296 7C3.00448 4.48749 4.98749 2.50448 7.5 2.08296V0H9.5V2.08296C12.0125 2.50448 13.9955 4.48749 14.417 7H16.5V9H14.417C13.9955 11.5125 12.0125 13.4955 9.5 13.917V16H7.5V13.917C4.98749 13.4955 3.00448 11.5125 2.58296 9H0.5V7H2.58296ZM4.5 8C4.5 5.79086 6.29086 4 8.5 4C10.7091 4 12.5 5.79086 12.5 8C12.5 10.2091 10.7091 12 8.5 12C6.29086 12 4.5 10.2091 4.5 8Z"
        fill={fill ? fill : 'white'}
      />
    </svg>
  );
};

export const TrophyIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9858 3.2006C14.8363 3.21813 13.6891 3.29059 12.5443 3.39812C11.3827 3.50564 10.2343 3.68328 9.09192 3.903C8.531 4.01169 8.05813 4.24544 7.73847 4.71993C7.50204 5.07172 7.4176 5.45973 7.44775 5.87579C7.46947 6.18667 7.46223 6.49755 7.48153 6.8096C7.52858 7.59264 7.62508 8.36867 7.77104 9.14002C8.06778 10.7108 8.58528 12.2126 9.33077 13.6361C10.0063 14.9275 10.8434 16.1138 11.8495 17.1878C12.4189 17.7955 13.0353 18.3518 13.6915 18.8672C13.7735 18.9315 13.7952 18.9946 13.7783 19.0928C13.5998 20.1844 13.7036 21.2456 14.1523 22.2682C14.2355 22.4575 14.3441 22.6329 14.4599 22.8035C14.5323 22.9087 14.5081 22.9601 14.4008 23.0185C13.5262 23.5 12.6698 24.0096 11.8676 24.5986C11.5479 24.8335 11.2319 25.0755 10.9749 25.3805C10.8229 25.5617 10.7337 25.7545 10.7409 25.9941C10.7506 26.34 10.7457 26.686 10.7421 27.0307C10.7421 27.1464 10.7771 27.1897 10.9098 27.1862C11.2053 27.178 11.5021 27.1979 11.7988 27.1979C14.8543 27.2002 17.9099 27.2002 20.9666 27.2002C21.226 27.2002 21.2272 27.1979 21.2247 26.9478C21.2223 26.6357 21.2091 26.3237 21.2247 26.0128C21.2392 25.7276 21.1307 25.5021 20.9352 25.3034C20.4853 24.8452 19.9654 24.4724 19.4262 24.1218C18.8375 23.7396 18.2344 23.3797 17.6204 23.0361C17.4539 22.9426 17.4479 22.9227 17.548 22.7649C18.0196 22.0251 18.2295 21.2152 18.2742 20.3539C18.2959 19.939 18.2718 19.5276 18.2114 19.1185C18.1933 19.0005 18.2271 18.9198 18.3248 18.8404C19.8074 17.6366 21.0752 16.2493 22.0788 14.6435C23.1415 12.9419 23.8376 11.1058 24.2067 9.15171C24.4118 8.06363 24.5131 6.96504 24.5469 5.85943C24.5734 4.96653 24.0716 4.12856 22.8991 3.90417C22.0173 3.73471 21.1319 3.58511 20.2392 3.47993C19.5227 3.39578 18.8037 3.32098 18.0812 3.28592C17.3815 3.25319 16.6855 3.19476 15.9858 3.2006Z"
        fill={'#D9A746'}
        className="fill-inherit"
      />
      <path
        d="M25.3563 23.6169C25.3032 22.8701 25.2562 22.1958 25.2055 21.5214C25.1898 21.3169 25.1922 21.3029 25.4081 21.2713C26.4733 21.1124 27.5095 20.8424 28.5348 20.5257C28.5541 20.5198 28.5734 20.5151 28.5915 20.5058C28.6555 20.4731 28.7604 20.4649 28.7544 20.3784C28.7483 20.2931 28.647 20.3387 28.5903 20.3176C28.2333 20.1856 27.8846 20.036 27.5336 19.891C26.419 19.4317 25.3237 18.9339 24.2779 18.3378C23.688 18.0024 23.1174 17.6389 22.5782 17.2299C22.3201 17.0335 22.2248 17.0487 22.0185 17.3012C21.2851 18.1999 20.4961 19.0496 19.6168 19.8174C19.4491 19.9635 19.4539 19.9612 19.6144 20.0967C21.1126 21.3671 22.7869 22.3851 24.5239 23.3119C24.7399 23.4264 24.9534 23.5456 25.1705 23.6566C25.3346 23.7396 25.3587 23.7221 25.3551 23.6157L25.3563 23.6169Z"
        fill={'#D9A746'}
        className="fill-inherit"
      />
      <path
        d="M3.20645 20.4357C4.33312 20.8108 5.46583 21.1065 6.6323 21.269C6.77826 21.2888 6.79757 21.3531 6.78912 21.4735C6.7469 22.0508 6.71071 22.6282 6.67091 23.2055C6.66246 23.3317 6.64799 23.458 6.63954 23.583C6.62989 23.7198 6.65402 23.7385 6.77826 23.6742C7.12447 23.4954 7.46947 23.3154 7.81085 23.1296C8.95802 22.502 10.0811 21.8381 11.133 21.0668C11.5732 20.743 12.0051 20.41 12.4285 20.064C12.5142 19.9939 12.5081 19.9483 12.4285 19.8805C11.9002 19.4364 11.4128 18.9526 10.9375 18.4559C10.6094 18.1123 10.3067 17.7488 10.0087 17.3806C9.98095 17.3456 9.95441 17.3105 9.92667 17.2755C9.74935 17.0569 9.66853 17.0417 9.44295 17.2112C8.59493 17.8493 7.68539 18.3951 6.73001 18.8672C5.62867 19.4119 4.50683 19.9121 3.34879 20.3375C3.29813 20.3562 3.24505 20.3726 3.20404 20.4357H3.20645Z"
        fill={'#D9A746'}
        className="fill-inherit"
      />
      <path
        d="M25.5179 8.27049C25.4998 8.40139 25.5674 8.45983 25.694 8.47502C25.7833 8.48554 25.8738 8.49606 25.9618 8.51592C26.1898 8.56618 26.3273 8.74266 26.267 8.96004C25.9727 10.0224 25.6747 11.0848 25.0812 12.0408C24.8291 12.4475 24.5408 12.8262 24.2103 13.1756C24.1283 13.2633 24.0668 13.3638 24.0173 13.4701C23.5312 14.529 22.928 15.5212 22.2235 16.4562C22.1982 16.4901 22.1476 16.5263 22.1813 16.5696C22.2175 16.6151 22.2634 16.5696 22.3032 16.5567C23.3201 16.2341 24.2284 15.7199 25.0475 15.0631C25.6952 14.543 26.2525 13.9329 26.7423 13.2679C27.6723 12.0045 28.3189 10.6114 28.7387 9.12015C28.9993 8.19453 28.4034 7.26774 27.4383 7.05737C26.9244 6.94517 26.4057 6.84817 25.8895 6.74766C25.6542 6.70208 25.641 6.71493 25.6398 6.94985C25.6385 7.36006 25.5843 7.76444 25.5288 8.16999C25.5239 8.20388 25.5215 8.23777 25.5167 8.27166L25.5179 8.27049Z"
        fill={'#D9A746'}
        className="fill-inherit"
      />
      <path
        d="M3.20163 8.7298C3.19077 8.9016 3.2354 9.08626 3.29089 9.26858C3.74084 10.7365 4.38137 12.1144 5.31866 13.3544C6.07259 14.3513 6.97248 15.1963 8.07261 15.8309C8.57442 16.1208 9.10157 16.3615 9.65284 16.5497C9.69748 16.5649 9.74935 16.6151 9.78915 16.5649C9.82534 16.5193 9.77106 16.4749 9.74332 16.4375C9.32232 15.8964 8.94958 15.3237 8.61302 14.73C8.37297 14.3081 8.14499 13.8792 7.9556 13.4327C7.8784 13.2516 7.75053 13.1113 7.61784 12.9711C6.86029 12.1693 6.41879 11.204 6.08827 10.1837C5.98573 9.86813 5.89768 9.5479 5.80238 9.23001C5.77222 9.12716 5.7481 9.02315 5.74689 8.91446C5.74448 8.70876 5.83857 8.58254 6.04364 8.53112C6.14014 8.50658 6.23905 8.48905 6.33797 8.47151C6.44171 8.45398 6.48393 8.40022 6.46946 8.29854C6.39828 7.82171 6.35245 7.34253 6.3247 6.86102C6.31988 6.76636 6.2668 6.72896 6.17512 6.74649C5.5949 6.86336 5.00382 6.92764 4.43324 7.08542C3.68535 7.29228 3.20525 7.93858 3.20404 8.72629L3.20163 8.7298Z"
        fill={'#D9A746'}
        className="fill-inherit"
      />
    </svg>
  );
};

export const PrizePoolTrophyIcon: React.FC<IconProps> = ({
  className,
  fill,
}) => {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 32 32"
      fill={fill ?? ''}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9858 3.2006C14.8363 3.21813 13.6891 3.29059 12.5443 3.39812C11.3827 3.50564 10.2343 3.68328 9.09192 3.903C8.531 4.01169 8.05813 4.24544 7.73847 4.71993C7.50204 5.07172 7.4176 5.45973 7.44775 5.87579C7.46947 6.18667 7.46223 6.49755 7.48153 6.8096C7.52858 7.59264 7.62508 8.36867 7.77104 9.14002C8.06778 10.7108 8.58528 12.2126 9.33077 13.6361C10.0063 14.9275 10.8434 16.1138 11.8495 17.1878C12.4189 17.7955 13.0353 18.3518 13.6915 18.8672C13.7735 18.9315 13.7952 18.9946 13.7783 19.0928C13.5998 20.1844 13.7036 21.2456 14.1523 22.2682C14.2355 22.4575 14.3441 22.6329 14.4599 22.8035C14.5323 22.9087 14.5081 22.9601 14.4008 23.0185C13.5262 23.5 12.6698 24.0096 11.8676 24.5986C11.5479 24.8335 11.2319 25.0755 10.9749 25.3805C10.8229 25.5617 10.7337 25.7545 10.7409 25.9941C10.7506 26.34 10.7457 26.686 10.7421 27.0307C10.7421 27.1464 10.7771 27.1897 10.9098 27.1862C11.2053 27.178 11.5021 27.1979 11.7988 27.1979C14.8543 27.2002 17.9099 27.2002 20.9666 27.2002C21.226 27.2002 21.2272 27.1979 21.2247 26.9478C21.2223 26.6357 21.2091 26.3237 21.2247 26.0128C21.2392 25.7276 21.1307 25.5021 20.9352 25.3034C20.4853 24.8452 19.9654 24.4724 19.4262 24.1218C18.8375 23.7396 18.2344 23.3797 17.6204 23.0361C17.4539 22.9426 17.4479 22.9227 17.548 22.7649C18.0196 22.0251 18.2295 21.2152 18.2742 20.3539C18.2959 19.939 18.2718 19.5276 18.2114 19.1185C18.1933 19.0005 18.2271 18.9198 18.3248 18.8404C19.8074 17.6366 21.0752 16.2493 22.0788 14.6435C23.1415 12.9419 23.8376 11.1058 24.2067 9.15171C24.4118 8.06363 24.5131 6.96504 24.5469 5.85943C24.5734 4.96653 24.0716 4.12856 22.8991 3.90417C22.0173 3.73471 21.1319 3.58511 20.2392 3.47993C19.5227 3.39578 18.8037 3.32098 18.0812 3.28592C17.3815 3.25319 16.6855 3.19476 15.9858 3.2006Z"
        className="fill-inherit"
      />
      <path
        d="M25.3563 23.6169C25.3032 22.8701 25.2562 22.1958 25.2055 21.5214C25.1898 21.3169 25.1922 21.3029 25.4081 21.2713C26.4733 21.1124 27.5095 20.8424 28.5348 20.5257C28.5541 20.5198 28.5734 20.5151 28.5915 20.5058C28.6555 20.4731 28.7604 20.4649 28.7544 20.3784C28.7483 20.2931 28.647 20.3387 28.5903 20.3176C28.2333 20.1856 27.8846 20.036 27.5336 19.891C26.419 19.4317 25.3237 18.9339 24.2779 18.3378C23.688 18.0024 23.1174 17.6389 22.5782 17.2299C22.3201 17.0335 22.2248 17.0487 22.0185 17.3012C21.2851 18.1999 20.4961 19.0496 19.6168 19.8174C19.4491 19.9635 19.4539 19.9612 19.6144 20.0967C21.1126 21.3671 22.7869 22.3851 24.5239 23.3119C24.7399 23.4264 24.9534 23.5456 25.1705 23.6566C25.3346 23.7396 25.3587 23.7221 25.3551 23.6157L25.3563 23.6169Z"
        className="fill-inherit"
      />
      <path
        d="M3.20645 20.4357C4.33312 20.8108 5.46583 21.1065 6.6323 21.269C6.77826 21.2888 6.79757 21.3531 6.78912 21.4735C6.7469 22.0508 6.71071 22.6282 6.67091 23.2055C6.66246 23.3317 6.64799 23.458 6.63954 23.583C6.62989 23.7198 6.65402 23.7385 6.77826 23.6742C7.12447 23.4954 7.46947 23.3154 7.81085 23.1296C8.95802 22.502 10.0811 21.8381 11.133 21.0668C11.5732 20.743 12.0051 20.41 12.4285 20.064C12.5142 19.9939 12.5081 19.9483 12.4285 19.8805C11.9002 19.4364 11.4128 18.9526 10.9375 18.4559C10.6094 18.1123 10.3067 17.7488 10.0087 17.3806C9.98095 17.3456 9.95441 17.3105 9.92667 17.2755C9.74935 17.0569 9.66853 17.0417 9.44295 17.2112C8.59493 17.8493 7.68539 18.3951 6.73001 18.8672C5.62867 19.4119 4.50683 19.9121 3.34879 20.3375C3.29813 20.3562 3.24505 20.3726 3.20404 20.4357H3.20645Z"
        className="fill-inherit"
      />
      <path
        d="M25.5179 8.27049C25.4998 8.40139 25.5674 8.45983 25.694 8.47502C25.7833 8.48554 25.8738 8.49606 25.9618 8.51592C26.1898 8.56618 26.3273 8.74266 26.267 8.96004C25.9727 10.0224 25.6747 11.0848 25.0812 12.0408C24.8291 12.4475 24.5408 12.8262 24.2103 13.1756C24.1283 13.2633 24.0668 13.3638 24.0173 13.4701C23.5312 14.529 22.928 15.5212 22.2235 16.4562C22.1982 16.4901 22.1476 16.5263 22.1813 16.5696C22.2175 16.6151 22.2634 16.5696 22.3032 16.5567C23.3201 16.2341 24.2284 15.7199 25.0475 15.0631C25.6952 14.543 26.2525 13.9329 26.7423 13.2679C27.6723 12.0045 28.3189 10.6114 28.7387 9.12015C28.9993 8.19453 28.4034 7.26774 27.4383 7.05737C26.9244 6.94517 26.4057 6.84817 25.8895 6.74766C25.6542 6.70208 25.641 6.71493 25.6398 6.94985C25.6385 7.36006 25.5843 7.76444 25.5288 8.16999C25.5239 8.20388 25.5215 8.23777 25.5167 8.27166L25.5179 8.27049Z"
        className="fill-inherit"
      />
      <path
        d="M3.20163 8.7298C3.19077 8.9016 3.2354 9.08626 3.29089 9.26858C3.74084 10.7365 4.38137 12.1144 5.31866 13.3544C6.07259 14.3513 6.97248 15.1963 8.07261 15.8309C8.57442 16.1208 9.10157 16.3615 9.65284 16.5497C9.69748 16.5649 9.74935 16.6151 9.78915 16.5649C9.82534 16.5193 9.77106 16.4749 9.74332 16.4375C9.32232 15.8964 8.94958 15.3237 8.61302 14.73C8.37297 14.3081 8.14499 13.8792 7.9556 13.4327C7.8784 13.2516 7.75053 13.1113 7.61784 12.9711C6.86029 12.1693 6.41879 11.204 6.08827 10.1837C5.98573 9.86813 5.89768 9.5479 5.80238 9.23001C5.77222 9.12716 5.7481 9.02315 5.74689 8.91446C5.74448 8.70876 5.83857 8.58254 6.04364 8.53112C6.14014 8.50658 6.23905 8.48905 6.33797 8.47151C6.44171 8.45398 6.48393 8.40022 6.46946 8.29854C6.39828 7.82171 6.35245 7.34253 6.3247 6.86102C6.31988 6.76636 6.2668 6.72896 6.17512 6.74649C5.5949 6.86336 5.00382 6.92764 4.43324 7.08542C3.68535 7.29228 3.20525 7.93858 3.20404 8.72629L3.20163 8.7298Z"
        className="fill-inherit"
      />
    </svg>
  );
};

export const RightMarkIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3335 4.7998L6.00014 12.1331L2.66681 8.7998"
        stroke="#4D545E"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const NextRouteIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.3999 3.3335L11.0666 8.00016L6.3999 12.6668"
        stroke="#1F272E"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TeamFeesIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.33333"
        y="0.833333"
        width="18.3333"
        height="18.3333"
        rx="9.16667"
        stroke="#D4FF27"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeDasharray="5.83 5.83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 14.375L5.5 7.5H6.75L8.625 9.375L9.875 5.625H11.125L12.375 9.375L14.25 7.5H15.5L14.25 14.375H6.75ZM10.1875 10.625L9.25 11.875L10.1875 13.125H10.8125L11.75 11.875L10.8125 10.625H10.1875Z"
        fill="#D4FF27"
      />
    </svg>
  );
};
export const GreenTrophyIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99116 2.00025C9.27267 2.01121 8.55568 2.0565 7.8402 2.1237C7.11417 2.1909 6.39643 2.30193 5.68246 2.43925C5.33188 2.50719 5.03634 2.65327 4.83655 2.94984C4.68878 3.1697 4.636 3.41221 4.65485 3.67225C4.66842 3.86655 4.6639 4.06085 4.67596 4.25588C4.70537 4.74528 4.76568 5.23029 4.8569 5.71239C5.04237 6.69411 5.36581 7.63274 5.83174 8.52242C6.25394 9.32957 6.77716 10.071 7.40594 10.7423C7.76179 11.1221 8.14705 11.4698 8.55719 11.7919C8.60846 11.8321 8.62203 11.8715 8.61147 11.9329C8.49989 12.6151 8.56473 13.2784 8.84519 13.9175C8.89721 14.0358 8.96506 14.1454 9.03744 14.2521C9.08268 14.3178 9.0676 14.3499 9.0005 14.3865C8.4539 14.6874 7.91861 15.0059 7.41725 15.374C7.21746 15.5208 7.01993 15.672 6.85934 15.8627C6.76435 15.9759 6.70855 16.0964 6.71308 16.2462C6.71911 16.4624 6.71609 16.6786 6.71383 16.8941C6.71383 16.9664 6.7357 16.9934 6.81863 16.9912C7.00334 16.9861 7.18881 16.9985 7.37427 16.9985C9.28397 17 11.1937 17 13.1041 17C13.2662 17 13.267 16.9985 13.2655 16.8422C13.264 16.6472 13.2557 16.4522 13.2655 16.2579C13.2745 16.0796 13.2067 15.9387 13.0845 15.8145C12.8033 15.5281 12.4784 15.2951 12.1414 15.076C11.7734 14.8371 11.3965 14.6122 11.0127 14.3974C10.9087 14.339 10.9049 14.3266 10.9675 14.228C11.2623 13.7656 11.3935 13.2594 11.4214 12.721C11.4349 12.4617 11.4199 12.2046 11.3822 11.949C11.3708 11.8752 11.392 11.8248 11.453 11.7751C12.3796 11.0227 13.172 10.1557 13.7993 9.15207C14.4635 8.08854 14.8985 6.941 15.1292 5.7197C15.2574 5.03965 15.3207 4.35303 15.3418 3.66202C15.3584 3.10396 15.0447 2.58023 14.3119 2.43998C13.7608 2.33407 13.2074 2.24057 12.6495 2.17483C12.2017 2.12224 11.7523 2.07549 11.3007 2.05358C10.8635 2.03312 10.4284 1.9966 9.99116 2.00025Z"
        fill="#D4FF27"
      />
      <path
        d="M15.8477 14.7604C15.8145 14.2937 15.7851 13.8722 15.7534 13.4508C15.7436 13.3229 15.7451 13.3142 15.8801 13.2944C16.5458 13.1951 17.1934 13.0264 17.8343 12.8284C17.8463 12.8248 17.8584 12.8218 17.8697 12.816C17.9097 12.7955 17.9753 12.7904 17.9715 12.7364C17.9677 12.6831 17.9044 12.7115 17.869 12.6984C17.6458 12.6159 17.4279 12.5224 17.2085 12.4318C16.5119 12.1447 15.8273 11.8335 15.1737 11.461C14.805 11.2514 14.4484 11.0242 14.1114 10.7686C13.95 10.6458 13.8905 10.6553 13.7616 10.8131C13.3032 11.3748 12.8101 11.9059 12.2605 12.3858C12.1557 12.4771 12.1587 12.4756 12.259 12.5603C13.1954 13.3543 14.2418 13.9906 15.3275 14.5698C15.4624 14.6414 15.5959 14.7159 15.7316 14.7853C15.8341 14.8371 15.8492 14.8262 15.8469 14.7597L15.8477 14.7604Z"
        fill="#D4FF27"
      />
      <path
        d="M2.00404 12.7722C2.70821 13.0066 3.41615 13.1914 4.1452 13.293C4.23642 13.3054 4.24849 13.3456 4.24321 13.4208C4.21682 13.7816 4.1942 14.1425 4.16932 14.5033C4.16405 14.5822 4.155 14.6611 4.14972 14.7393C4.14369 14.8247 4.15877 14.8364 4.23642 14.7962C4.4528 14.6845 4.66842 14.572 4.88179 14.4559C5.59877 14.0636 6.30068 13.6487 6.9581 13.1666C7.23329 12.9643 7.50319 12.7561 7.76782 12.5399C7.82135 12.4961 7.81758 12.4676 7.76782 12.4252C7.4376 12.1476 7.13302 11.8452 6.83597 11.5348C6.6309 11.32 6.44166 11.0929 6.25544 10.8628C6.2381 10.8409 6.22152 10.819 6.20418 10.797C6.09335 10.6604 6.04284 10.6509 5.90185 10.7569C5.37184 11.1557 4.80338 11.4968 4.20626 11.7919C3.51793 12.1323 2.81677 12.4449 2.093 12.7108C2.06134 12.7225 2.02816 12.7327 2.00253 12.7722H2.00404Z"
        fill="#D4FF27"
      />
      <path
        d="M15.9487 5.16894C15.9374 5.25075 15.9796 5.28727 16.0588 5.29677C16.1146 5.30334 16.1711 5.30991 16.2262 5.32233C16.3686 5.35374 16.4546 5.46404 16.4169 5.5999C16.2329 6.26388 16.0467 6.92786 15.6758 7.52536C15.5182 7.77956 15.338 8.01622 15.1314 8.23463C15.0802 8.28941 15.0417 8.35223 15.0108 8.4187C14.707 9.08049 14.33 9.70064 13.8897 10.285C13.8739 10.3062 13.8422 10.3288 13.8633 10.3558C13.886 10.3843 13.9146 10.3558 13.9395 10.3478C14.575 10.1462 15.1428 9.82481 15.6547 9.4143C16.0595 9.08925 16.4078 8.70796 16.7139 8.29233C17.2952 7.50272 17.6993 6.63202 17.9617 5.69997C18.1245 5.12146 17.7521 4.54221 17.149 4.41073C16.8278 4.34061 16.5036 4.27998 16.1809 4.21716C16.0339 4.18868 16.0256 4.19671 16.0249 4.34353C16.0241 4.59992 15.9902 4.85265 15.9555 5.10612C15.9525 5.1273 15.951 5.14848 15.948 5.16967L15.9487 5.16894Z"
        fill="#D4FF27"
      />
      <path
        d="M2.00102 5.456C1.99424 5.56338 2.02213 5.67879 2.05681 5.79274C2.33803 6.71018 2.73837 7.57138 3.32417 8.34639C3.79537 8.96946 4.3578 9.49757 5.04539 9.8942C5.35902 10.0754 5.68849 10.2258 6.03303 10.3434C6.06093 10.3529 6.09335 10.3843 6.11823 10.3529C6.14085 10.3244 6.10692 10.2967 6.08958 10.2733C5.82646 9.93511 5.59349 9.57719 5.38315 9.20612C5.23312 8.94243 5.09062 8.67436 4.97226 8.39533C4.92401 8.28211 4.84409 8.19445 4.76116 8.1068C4.28769 7.60571 4.01175 7.00236 3.80517 6.36468C3.74109 6.16746 3.68606 5.96732 3.62649 5.76864C3.60765 5.70436 3.59257 5.63935 3.59181 5.57141C3.59031 5.44286 3.64911 5.36397 3.77728 5.33183C3.83759 5.31649 3.89942 5.30553 3.96124 5.29457C4.02608 5.28362 4.05246 5.25002 4.04342 5.18647C3.99893 4.88845 3.97029 4.58896 3.95294 4.28802C3.94993 4.22885 3.91676 4.20548 3.85946 4.21643C3.49682 4.28948 3.12739 4.32965 2.77078 4.42826C2.30335 4.55755 2.00329 4.96149 2.00253 5.45381L2.00102 5.456Z"
        fill="#D4FF27"
      />
    </svg>
  );
};
export const TournamentGoToIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33362 8.00016H11.8696M8.00002 3.3335L12.6667 8.00016L8.00002 12.6668"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const DiscordSocialIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.991 3.32844C12.0732 2.89872 11.089 2.58212 10.0599 2.40079C10.0412 2.39729 10.0225 2.40603 10.0128 2.42352C9.88623 2.65325 9.74602 2.95294 9.64784 3.1885C8.54102 3.01942 7.43989 3.01942 6.35577 3.1885C6.25757 2.94771 6.11227 2.65325 5.98513 2.42352C5.97547 2.40661 5.95675 2.39787 5.93801 2.40079C4.90953 2.58154 3.92532 2.89814 3.00694 3.32844C2.99899 3.33194 2.99218 3.33778 2.98765 3.34535C1.12082 6.19129 0.609411 8.96729 0.860289 11.7089C0.861424 11.7223 0.868803 11.7351 0.87902 11.7433C2.11071 12.6662 3.30381 13.2266 4.47475 13.598C4.49349 13.6038 4.51334 13.5968 4.52527 13.5811C4.80226 13.1951 5.04917 12.7881 5.26087 12.3601C5.27336 12.3351 5.26143 12.3053 5.2359 12.2954C4.84426 12.1438 4.47134 11.959 4.11262 11.7491C4.08424 11.7322 4.08197 11.6908 4.10808 11.6709C4.18356 11.6132 4.25907 11.5532 4.33115 11.4925C4.3442 11.4815 4.36237 11.4791 4.3777 11.4861C6.73435 12.584 9.28571 12.584 11.6146 11.4861C11.6299 11.4785 11.6481 11.4809 11.6617 11.492C11.7338 11.5526 11.8093 11.6132 11.8853 11.6709C11.9114 11.6908 11.9097 11.7322 11.8813 11.7491C11.5226 11.9631 11.1497 12.1438 10.7575 12.2948C10.732 12.3048 10.7206 12.3351 10.7331 12.3601C10.9493 12.7875 11.1962 13.1945 11.4681 13.5805C11.4795 13.5968 11.4999 13.6038 11.5186 13.598C12.6953 13.2266 13.8884 12.6662 15.12 11.7433C15.1308 11.7351 15.1376 11.7229 15.1388 11.7094C15.439 8.53988 14.6359 5.78665 13.0097 3.34593C13.0057 3.33778 12.9989 3.33194 12.991 3.32844ZM5.61279 10.0395C4.90328 10.0395 4.31866 9.37485 4.31866 8.55855C4.31866 7.74225 4.89194 7.07757 5.61279 7.07757C6.3393 7.07757 6.91827 7.74808 6.90691 8.55855C6.90691 9.37485 6.33363 10.0395 5.61279 10.0395ZM10.3976 10.0395C9.68814 10.0395 9.10352 9.37485 9.10352 8.55855C9.10352 7.74225 9.67678 7.07757 10.3976 7.07757C11.1242 7.07757 11.7031 7.74808 11.6918 8.55855C11.6918 9.37485 11.1242 10.0395 10.3976 10.0395Z"
        fill="white"
      />
    </svg>
  );
};
export const ChatmessageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        className="duration-300"
        d="M4.125 0L1 2.85714L1 13.1429H4.75V16L7.875 13.1429L10.375 13.1429L16 8V0L4.125 0ZM14.75 7.42857L12.25 9.71429H9.75L7.5625 11.7143L7.5625 9.71429H4.75L4.75 1.14286L14.75 1.14286L14.75 7.42857Z"
        fill="#4D545E"
      />
      <path
        className="duration-300"
        d="M12.8751 3.14284H11.6251V6.57141L12.8751 6.57141V3.14284Z"
        fill="#4D545E"
      />
      <path
        className="duration-300"
        d="M9.43759 3.14284H8.18759V6.57141H9.43759V3.14284Z"
        fill="#4D545E"
      />
    </svg>
  );
};

interface ICelenderIcon {
  fill?: string;
}

export const CelenderIcon: React.FC<ICelenderIcon> = ({ fill }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0H2V2H0V5H14V2H12V0H9V2H5V0Z"
        fill={fill ? fill : '#4D545E'}
      />
      <path d="M14 7H0V15H14V7Z" fill={fill ? fill : '#4D545E'} />
    </svg>
  );
};

interface IWatchIcon {
  fill?: string;
}

export const WatchIcon: React.FC<IWatchIcon> = ({ fill }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7 3V8.41421L10.2929 11.7071L11.7071 10.2929L9 7.58579V3H7Z"
        fill={fill ? fill : '#4D545E'}
      />
    </svg>
  );
};
export const GoBackIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6668 8.00016H4.13081M8.0004 3.3335L3.33374 8.00016L8.0004 12.6668"
        stroke="#4D545E"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const TournamentTrophyIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9858 3.2006C14.8363 3.21813 13.6891 3.29059 12.5443 3.39812C11.3827 3.50564 10.2343 3.68328 9.09192 3.903C8.531 4.01169 8.05813 4.24544 7.73847 4.71993C7.50204 5.07172 7.4176 5.45973 7.44775 5.87579C7.46947 6.18667 7.46223 6.49755 7.48153 6.8096C7.52858 7.59264 7.62508 8.36867 7.77104 9.14002C8.06778 10.7108 8.58528 12.2126 9.33077 13.6361C10.0063 14.9275 10.8434 16.1138 11.8495 17.1878C12.4189 17.7955 13.0353 18.3518 13.6915 18.8672C13.7735 18.9315 13.7952 18.9946 13.7783 19.0928C13.5998 20.1844 13.7036 21.2456 14.1523 22.2682C14.2355 22.4575 14.3441 22.6329 14.4599 22.8035C14.5323 22.9087 14.5081 22.9601 14.4008 23.0185C13.5262 23.5 12.6698 24.0096 11.8676 24.5986C11.5479 24.8335 11.2319 25.0755 10.9749 25.3805C10.8229 25.5617 10.7337 25.7545 10.7409 25.9941C10.7506 26.34 10.7457 26.686 10.7421 27.0307C10.7421 27.1464 10.7771 27.1897 10.9098 27.1862C11.2053 27.178 11.5021 27.1979 11.7988 27.1979C14.8543 27.2002 17.9099 27.2002 20.9666 27.2002C21.226 27.2002 21.2272 27.1979 21.2247 26.9478C21.2223 26.6357 21.2091 26.3237 21.2247 26.0128C21.2392 25.7276 21.1307 25.5021 20.9352 25.3034C20.4853 24.8452 19.9654 24.4724 19.4262 24.1218C18.8375 23.7396 18.2344 23.3797 17.6204 23.0361C17.4539 22.9426 17.4479 22.9227 17.548 22.7649C18.0196 22.0251 18.2295 21.2152 18.2742 20.3539C18.2959 19.939 18.2718 19.5276 18.2114 19.1185C18.1933 19.0005 18.2271 18.9198 18.3248 18.8404C19.8074 17.6366 21.0752 16.2493 22.0788 14.6435C23.1415 12.9419 23.8376 11.1058 24.2067 9.15171C24.4118 8.06363 24.5131 6.96504 24.5469 5.85943C24.5734 4.96653 24.0716 4.12856 22.8991 3.90417C22.0173 3.73471 21.1319 3.58511 20.2392 3.47993C19.5227 3.39578 18.8037 3.32098 18.0812 3.28592C17.3815 3.25319 16.6855 3.19476 15.9858 3.2006Z"
        fill="#4D545E"
      />
      <path
        d="M25.3563 23.6169C25.3032 22.8701 25.2562 22.1958 25.2055 21.5214C25.1898 21.3169 25.1922 21.3029 25.4081 21.2713C26.4733 21.1124 27.5095 20.8424 28.5348 20.5257C28.5541 20.5198 28.5734 20.5151 28.5915 20.5058C28.6555 20.4731 28.7604 20.4649 28.7544 20.3784C28.7483 20.2931 28.647 20.3387 28.5903 20.3176C28.2333 20.1856 27.8846 20.036 27.5336 19.891C26.419 19.4317 25.3237 18.9339 24.2779 18.3378C23.688 18.0024 23.1174 17.6389 22.5782 17.2299C22.3201 17.0335 22.2248 17.0487 22.0185 17.3012C21.2851 18.1999 20.4961 19.0496 19.6168 19.8174C19.4491 19.9635 19.4539 19.9612 19.6144 20.0967C21.1126 21.3671 22.7869 22.3851 24.5239 23.3119C24.7399 23.4264 24.9534 23.5456 25.1705 23.6566C25.3346 23.7396 25.3587 23.7221 25.3551 23.6157L25.3563 23.6169Z"
        fill="#4D545E"
      />
      <path
        d="M3.20645 20.4357C4.33312 20.8108 5.46583 21.1065 6.6323 21.269C6.77826 21.2888 6.79757 21.3531 6.78912 21.4735C6.7469 22.0508 6.71071 22.6282 6.67091 23.2055C6.66246 23.3317 6.64799 23.458 6.63954 23.583C6.62989 23.7198 6.65402 23.7385 6.77826 23.6742C7.12447 23.4954 7.46947 23.3154 7.81085 23.1296C8.95802 22.502 10.0811 21.8381 11.133 21.0668C11.5732 20.743 12.0051 20.41 12.4285 20.064C12.5142 19.9939 12.5081 19.9483 12.4285 19.8805C11.9002 19.4364 11.4128 18.9526 10.9375 18.4559C10.6094 18.1123 10.3067 17.7488 10.0087 17.3806C9.98095 17.3456 9.95441 17.3105 9.92667 17.2755C9.74935 17.0569 9.66853 17.0417 9.44295 17.2112C8.59493 17.8493 7.68539 18.3951 6.73001 18.8672C5.62867 19.4119 4.50683 19.9121 3.34879 20.3375C3.29813 20.3562 3.24505 20.3726 3.20404 20.4357H3.20645Z"
        fill="#4D545E"
      />
      <path
        d="M25.5179 8.27049C25.4998 8.40139 25.5674 8.45983 25.694 8.47502C25.7833 8.48554 25.8738 8.49606 25.9618 8.51592C26.1898 8.56618 26.3273 8.74266 26.267 8.96004C25.9727 10.0224 25.6747 11.0848 25.0812 12.0408C24.8291 12.4475 24.5408 12.8262 24.2103 13.1756C24.1283 13.2633 24.0668 13.3638 24.0173 13.4701C23.5312 14.529 22.928 15.5212 22.2235 16.4562C22.1982 16.4901 22.1476 16.5263 22.1813 16.5696C22.2175 16.6151 22.2634 16.5696 22.3032 16.5567C23.3201 16.2341 24.2284 15.7199 25.0475 15.0631C25.6952 14.543 26.2525 13.9329 26.7423 13.2679C27.6723 12.0045 28.3189 10.6114 28.7387 9.12015C28.9993 8.19453 28.4034 7.26774 27.4383 7.05737C26.9244 6.94517 26.4057 6.84817 25.8895 6.74766C25.6542 6.70208 25.641 6.71493 25.6398 6.94985C25.6385 7.36006 25.5843 7.76444 25.5288 8.16999C25.5239 8.20388 25.5215 8.23777 25.5167 8.27166L25.5179 8.27049Z"
        fill="#4D545E"
      />
      <path
        d="M3.20163 8.7298C3.19077 8.9016 3.2354 9.08626 3.29089 9.26858C3.74084 10.7365 4.38137 12.1144 5.31866 13.3544C6.07259 14.3513 6.97248 15.1963 8.07261 15.8309C8.57442 16.1208 9.10157 16.3615 9.65284 16.5497C9.69748 16.5649 9.74935 16.6151 9.78915 16.5649C9.82534 16.5193 9.77106 16.4749 9.74332 16.4375C9.32232 15.8964 8.94958 15.3237 8.61302 14.73C8.37297 14.3081 8.14499 13.8792 7.9556 13.4327C7.8784 13.2516 7.75053 13.1113 7.61784 12.9711C6.86029 12.1693 6.41879 11.204 6.08827 10.1837C5.98573 9.86813 5.89768 9.5479 5.80238 9.23001C5.77222 9.12716 5.7481 9.02315 5.74689 8.91446C5.74448 8.70876 5.83857 8.58254 6.04364 8.53112C6.14014 8.50658 6.23905 8.48905 6.33797 8.47151C6.44171 8.45398 6.48393 8.40022 6.46946 8.29854C6.39828 7.82171 6.35245 7.34253 6.3247 6.86102C6.31988 6.76636 6.2668 6.72896 6.17512 6.74649C5.5949 6.86336 5.00382 6.92764 4.43324 7.08542C3.68535 7.29228 3.20525 7.93858 3.20404 8.72629L3.20163 8.7298Z"
        fill="#4D545E"
      />
    </svg>
  );
};
export const GamePlatformIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_879_33739)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.7405 11.0992C31.2769 12.5521 31.6095 14.0708 31.7643 15.6082C32.4515 22.4292 31.6165 25.4942 29.5839 25.5964C27.7021 25.6888 25.1352 23.8752 22.2316 20.6107C21.882 20.2185 21.3776 19.9934 20.8473 19.9934H11.1527C10.6224 19.9934 10.117 20.2185 9.76838 20.6107C6.86483 23.8752 4.29787 25.6888 2.4161 25.5964C0.383506 25.4942 -0.450506 22.4292 0.23668 15.6082C0.392495 14.0698 0.725101 12.5521 1.26047 11.0992C1.87973 9.42027 2.66181 8.0539 3.61568 7.01881C5.83704 4.60752 9.53666 4.08948 12.3373 5.82152C12.663 6.02303 12.9596 6.24912 13.2263 6.50077C13.5729 6.82811 14.0283 7.01881 14.5088 7.01881H17.4942C17.9737 7.01881 18.4301 6.82712 18.7767 6.50077C19.0434 6.24912 19.34 6.02303 19.6657 5.82152C22.4653 4.0885 26.164 4.60752 28.3853 7.01881C29.3392 8.0539 30.1213 9.42027 30.7405 11.0992ZM25.0862 9.78102C25.0862 8.92484 24.3811 8.23084 23.5111 8.23084C22.6411 8.23084 21.936 8.92484 21.936 9.78102C21.936 10.6372 22.6411 11.3312 23.5111 11.3312C24.3811 11.3312 25.0862 10.6372 25.0862 9.78102ZM18.7857 12.8804C18.7857 13.7366 19.4909 14.4306 20.3608 14.4306C21.2308 14.4306 21.936 13.7366 21.936 12.8804C21.936 12.0242 21.2308 11.3302 20.3608 11.3302C19.4909 11.3302 18.7857 12.0242 18.7857 12.8804ZM21.936 15.9808C21.936 16.837 22.6411 17.531 23.5111 17.531C24.3811 17.531 25.0862 16.837 25.0862 15.9808C25.0862 15.1246 24.3811 14.4306 23.5111 14.4306C22.6411 14.4306 21.936 15.1246 21.936 15.9808ZM25.0862 12.8804C25.0862 13.7366 25.7914 14.4306 26.6614 14.4306C27.5313 14.4306 28.2365 13.7366 28.2365 12.8804C28.2365 12.0242 27.5313 11.3302 26.6614 11.3302C25.7914 11.3302 25.0862 12.0242 25.0862 12.8804ZM8.8 9.5998C8.35817 9.5998 8 9.95798 8 10.3998V12.7998H5.6C5.15817 12.7998 4.8 13.158 4.8 13.5998C4.8 14.0416 5.15817 14.3998 5.6 14.3998H8V16.7998C8 17.2416 8.35817 17.5998 8.8 17.5998C9.24183 17.5998 9.6 17.2416 9.6 16.7998V14.3998H12C12.4418 14.3998 12.8 14.0416 12.8 13.5998C12.8 13.158 12.4418 12.7998 12 12.7998H9.6V10.3998C9.6 9.95798 9.24183 9.5998 8.8 9.5998Z"
          fill="#4D545E"
        />
      </g>
      <defs>
        <clipPath id="clip0_879_33739">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GameModeIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3.2002L13.7373 8.93745L8.93725 13.7375L3.2 8.0002V3.2002H8Z"
        fill="#4D545E"
      />
      <path
        d="M13.9314 22.8688L16.3314 25.2688L14.0686 27.5316L10.4 23.8629L7.99634 26.2666C7.99877 26.3108 8 26.3554 8 26.4002C8 27.7257 6.92548 28.8002 5.6 28.8002C4.27451 28.8002 3.2 27.7257 3.2 26.4002C3.2 25.0747 4.27451 24.0002 5.6 24.0002C5.64483 24.0002 5.68937 24.0014 5.7336 24.0039L8.13726 21.6002L4.46863 17.9316L6.73137 15.6688L9.13137 18.0688L24 3.2002H28.8V8.0002L13.9314 22.8688Z"
        fill="#4D545E"
      />
      <path
        d="M26.4 28.8002C25.0745 28.8002 24 27.7257 24 26.4002C24 26.3554 24.0012 26.3108 24.0037 26.2666L19.3373 21.6002L25.2686 15.6688L27.5314 17.9316L23.8627 21.6002L26.2664 24.0039C26.3106 24.0014 26.3552 24.0002 26.4 24.0002C27.7255 24.0002 28.8 25.0747 28.8 26.4002C28.8 27.7257 27.7255 28.8002 26.4 28.8002Z"
        fill="#4D545E"
      />
    </svg>
  );
};

export const TeamSizeIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8.79981C16 11.0089 14.2091 12.7998 12 12.7998C9.79086 12.7998 8 11.0089 8 8.79981C8 6.59067 9.79086 4.7998 12 4.7998C14.2091 4.7998 16 6.59067 16 8.79981Z"
        fill="#4D545E"
      />
      <path
        d="M8 15.9998C5.34903 15.9998 3.2 18.1488 3.2 20.7998V27.1998H16V15.9998H8Z"
        fill="#4D545E"
      />
      <path
        d="M24 15.9998H19.2V27.1998H28.8V20.7998C28.8 18.1488 26.651 15.9998 24 15.9998Z"
        fill="#4D545E"
      />
      <path
        d="M22.4 12.7998C24.1673 12.7998 25.6 11.3671 25.6 9.5998C25.6 7.83249 24.1673 6.3998 22.4 6.3998C20.6327 6.3998 19.2 7.83249 19.2 9.5998C19.2 11.3671 20.6327 12.7998 22.4 12.7998Z"
        fill="#4D545E"
      />
    </svg>
  );
};
export const DateIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 0H2V2H0V5H14V2H12V0H9V2H5V0Z" fill="#4D545E" />
      <path d="M14 7H0V15H14V7Z" fill="#4D545E" />
    </svg>
  );
};
export const ClockIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7 3V8.41421L10.2929 11.7071L11.7071 10.2929L9 7.58579V3H7Z"
        fill="#4D545E"
      />
    </svg>
  );
};
export const LeftArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4 5L7.40002 12L14.4 19"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
