import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// firebase
import { firestore } from '../../../../firebase';
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
} from 'firebase/firestore';
// types
import {
  tournamentConverter,
  TournamentTeam,
  TournamentTeamStatus,
  Tournament,
} from '../../../../firestore/tournaments';
import { Notification } from '../../../../firestore/notifications';
// context
import { useNotificationContext } from '@provider/NotificationProvider';
// icons
import {
  MouseClickIcon,
  LeaveIcon,
  MegaphoneIcon,
} from '../../../common/icons/Common';
import { ImSpinner8 } from 'react-icons/im';
import { useAuthContext } from '../../../../provider/AuthContextProvider';
import { FaCheck } from 'react-icons/fa';

interface ITournamentCheckInNotif {
  notification: Notification;
  closeMenu: () => void;
}

export const TournamentWaitingCheckInNotif: React.FC<
  ITournamentCheckInNotif
> = ({ notification, closeMenu }) => {
  const navigate = useNavigate();

  const body = notification.body as {
    tournamentName: string;
    tournamentId: string;
  };
  const { tournamentName, tournamentId } = body;

  const { userTeam } = useAuthContext();
  const { currentTime, declineTournamentPresence } = useNotificationContext();

  const [tournament, setTournament] = useState<Tournament | null>(null);
  const [tooLittlePlayers, setTooLittlePlayers] = useState<boolean>(true);
  const [tooManyPlayers, setTooManyPlayers] = useState<boolean>(true);

  const [tournamentSlotsLeft, setTournamentSlotsLeft] = useState<number>(-1);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const notifDuration =
    notification.timeout!.getTime() - notification.timeReceived.getTime();
  const timeLeft = notification.timeout!.getTime() - currentTime; // ms
  const minutesLeft = Math.round(timeLeft / 60_000);
  const percentageTimeLeft = Math.round((timeLeft / notifDuration) * 100);

  const getTournament = async () => {
    try {
      const tournamentRef = doc(
        firestore,
        'tournaments',
        tournamentId
      ).withConverter(tournamentConverter);
      const tournamentSnapshot = await getDoc(tournamentRef);
      const localTournament = tournamentSnapshot.data()!;
      setTournament(localTournament);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (tournament && userTeam) {
      // setTooLittlePlayers(userTeam.players.length < tournament.teamSize);
      // setTooManyPlayers(userTeam.players.length > tournament.maxTeamSize);
      setTooLittlePlayers(false);
      setTooManyPlayers(false);
    }
  }, [tournament, userTeam]);

  useEffect(() => {
    getTournament();
  }, []);

  const handleConfirm = async () => {
    setSubmitting(true);
    navigate(`/tournaments/${tournamentId}/checkIn`);
    closeMenu();
    setSubmitting(false);
  };

  const handleDecline = async () => {
    setSubmitting(true);
    await declineTournamentPresence(notification);
    setSubmitting(false);
  };

  const getTournamentSlotsLeft = async () => {
    const tournamentRef = doc(
      firestore,
      'tournaments',
      tournamentId
    ).withConverter(tournamentConverter);
    const tournament = (await getDoc(tournamentRef)).data()!;
    const tournamentTeamsCollection = collection(
      firestore,
      'tournaments',
      tournamentId,
      'teams'
    );
    const tournamentTeamsQuery = query(tournamentTeamsCollection);
    return onSnapshot(tournamentTeamsQuery, (snapshots) => {
      const tournamentTeams = snapshots.docs.map(
        (snapshot) => snapshot.data() as TournamentTeam
      );
      setTournamentSlotsLeft(
        tournament.teamCapacity -
          tournamentTeams.filter(
            (team) => team.status === TournamentTeamStatus.confirmed
          ).length
      );
    });
  };

  useEffect(() => {
    getTournamentSlotsLeft();
  }, []);

  if (tournamentSlotsLeft === 0) {
    const notificationRef = doc(firestore, 'notifications', notification.id);
    updateDoc(notificationRef, { dismissed: true });
  }

  // Check in Players check to tournament (Leave team card)

  return tournamentSlotsLeft > 0 ? (
    <div
      className="relative bg-lightGray rounded-xl p-4 pr-10"
      key={notification.id}
    >
      <div className="mb-1 flex items-start gap-x-3">
        <div
          className={`
            flex h-[30px] w-[30px] min-w-[30px] flex-shrink-0 rounded-full
            bg-steelGray/30 items-center justify-center
          `}
        >
          <MegaphoneIcon
            className={'aspect-square h-auto w-[16px] fill-white text-xl'}
          />
        </div>
        <div>
          <h3 className="text-base leading-6 text-white font-compact">
            <Link
              to={`/tournaments/${tournamentId}`}
              className={'text-green uppercase not-italic'}
            >
              {tournamentName}
            </Link>
            {' has '}
            <em className="text-green not-italic">
              {tournamentSlotsLeft}
              {tournamentSlotsLeft !== 1 ? ' spots' : ' spot'}
            </em>
            {' available!'}
            <span className="ml-2 text-steelGray whitespace-nowrap text-sm">
              {minutesLeft} mins left
            </span>
          </h3>
        </div>
      </div>
      {tournament && (tooLittlePlayers || tooManyPlayers) ? (
        <p className="text-red/70 text-sm font-compact my-2 text-center">
          {'You no longer meet team size requirements!'}
        </p>
      ) : (
        ''
      )}
      <div className="flex items-center justify-between">
        {/* Accept Button */}
        <button
          disabled={tooLittlePlayers || tooManyPlayers || submitting}
          onClick={handleConfirm}
          className={`
            relative flex w-[48%] bg-green/40 items-center justify-center gap-2
            overflow-hidden rounded-xl border-2 px-3 pb-[0.425rem] pt-[0.60rem]
            text-base font-medium uppercase focus_visible_hidden leading-4
            font-compact transition-opacity border-green

            disabled:pointer-events-none disabled:opacity-50

            hover:opacity-75
          `}
        >
          <div
            className="absolute left-0 top-0 h-full bg-green/80"
            style={{
              width: `${percentageTimeLeft}%`,
              transition: 'width 5s linear',
            }}
          ></div>
          <div className="relative z-[10] flex items-center gap-x-2">
            <p>Claim</p>
            <span className="mb-1">
              {submitting ? (
                <ImSpinner8 className="animate-spin" />
              ) : (
                <MouseClickIcon
                  className={'aspect-square h-auto w-[12px] fill-black'}
                />
              )}
            </span>
          </div>
        </button>
        {/* Decline Button */}
        <button
          disabled={submitting}
          onClick={handleDecline}
          className={`
            w-[48%] bg-red flex h-full items-center justify-center gap-2
            rounded-xl px-3 pb-[0.525rem] pt-[0.7rem] text-base font-medium
            uppercase leading-4 font-compact text-black transition-opacity

            disabled:pointer-events-none disabled:opacity-50

            hover:opacity-75
          `}
        >
          Cancel
          <span className="mb-1">
            {submitting ? (
              <ImSpinner8 className="animate-spin" />
            ) : (
              <LeaveIcon className="aspect-square h-auto w-[14px] fill-black" />
            )}
          </span>
        </button>
      </div>
    </div>
  ) : (
    ''
  );
};

export const TournamentCheckInNotif: React.FC<ITournamentCheckInNotif> = ({
  notification,
  closeMenu,
}) => {
  const navigate = useNavigate();

  const body = notification.body as {
    tournamentName: string;
    tournamentId: string;
  };
  const { tournamentId, tournamentName } = body;
  const { userTeam } = useAuthContext();

  const { currentTime, declineTournamentPresence } = useNotificationContext();
  const [tournament, setTournament] = useState<Tournament | null>(null);
  const [tooLittlePlayers, setTooLittlePlayers] = useState<boolean>(true);
  const [tooManyPlayers, setTooManyPlayers] = useState<boolean>(true);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const notifDuration =
    notification.timeout!.getTime() - notification.timeReceived.getTime();
  const timeLeft = notification.timeout!.getTime() - currentTime; // ms
  const minutesLeft = Math.round(timeLeft / 60_000);
  const percentageTimeLeft = Math.round((timeLeft / notifDuration) * 100);

  const getTournament = async () => {
    try {
      const tournamentRef = doc(
        firestore,
        'tournaments',
        tournamentId
      ).withConverter(tournamentConverter);
      const tournamentSnapshot = await getDoc(tournamentRef);
      const localTournament = tournamentSnapshot.data()!;
      setTournament(localTournament);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (tournament && userTeam) {
      setTooLittlePlayers(userTeam.players.length < tournament.teamSize);
      setTooManyPlayers(userTeam.players.length > tournament.maxTeamSize);
    }
  }, [tournament, userTeam]);

  useEffect(() => {
    getTournament();
  }, []);

  const handleConfirm = async () => {
    setSubmitting(true);
    navigate(`tournaments/${tournamentId}/checkIn`);
    closeMenu();
    setSubmitting(false);
  };

  const handleDecline = async () => {
    setSubmitting(true);
    await declineTournamentPresence(notification);
    setSubmitting(false);
  };

  return (
    <div
      className="relative bg-lightGray rounded-xl p-4 pr-10"
      key={notification.id}
    >
      <div className="mb-1 flex items-start gap-x-3">
        <div
          className={`
            flex h-[30px] w-[30px] flex-shrink-0 justify-center rounded-full
            bg-steelGray/30 items-center
          `}
        >
          <MouseClickIcon
            className={'aspect-square h-auto w-[14px] fill-white text-xl'}
          />
        </div>
        <div>
          <h3 className="text-base leading-6 text-white font-compact">
            <Link
              to={`/tournaments/${tournamentId}/checkIn`}
              className={'text-green uppercase not-italic'}
            >
              {tournamentName}
            </Link>{' '}
            check in is open!
          </h3>
          <p className="font-compact text-sm text-steelGray">
            {minutesLeft} mins left
          </p>
        </div>
      </div>
      {tournament && (tooLittlePlayers || tooManyPlayers) ? (
        <p className="text-red/70 text-sm font-compact my-2 text-center">
          {'You no longer meet team size requirements!'}
        </p>
      ) : (
        ''
      )}
      <div className="flex items-center justify-between">
        {/* Accept Button */}
        <button
          disabled={tooLittlePlayers || tooManyPlayers || submitting}
          onClick={handleConfirm}
          className={`
            relative flex w-[48%] bg-green/40 items-center justify-center gap-2
            overflow-hidden rounded-xl border-2 px-3 pb-[0.425rem] pt-[0.60rem]
            text-base font-medium uppercase focus_visible_hidden leading-4
            font-compact transition-opacity border-green

            disabled:pointer-events-none disabled:opacity-50

            hover:opacity-75
          `}
        >
          <div
            className="absolute left-0 top-0 h-full bg-green/80"
            style={{
              width: `${percentageTimeLeft}%`,
              transition: 'width 5s linear',
            }}
          ></div>
          <div className="relative z-[10] flex items-center gap-x-2">
            <p>Check-In</p>
            <span className="mb-1">
              {submitting ? (
                <ImSpinner8 className="animate-spin" />
              ) : (
                <FaCheck />
              )}
            </span>
          </div>
        </button>
        {/* Decline Button */}
        <button
          disabled={submitting}
          onClick={handleDecline}
          className={`
            w-[48%] bg-red flex h-full items-center justify-center gap-2
            rounded-xl px-3 pb-[0.525rem] pt-[0.7rem] text-base font-medium
            uppercase leading-4 font-compact text-black transition-opacity

            disabled:pointer-events-none disabled:opacity-50

            hover:opacity-75
          `}
        >
          Cancel
          <span className="mb-1">
            {submitting ? (
              <ImSpinner8 className="animate-spin" />
            ) : (
              <LeaveIcon className="aspect-square h-auto w-[14px] fill-black" />
            )}
          </span>
        </button>
      </div>
    </div>
  );
};
