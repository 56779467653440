import { FormEvent, useEffect, useState } from 'react';
// packages
import { toast } from 'react-toastify';
// components
import { registerInterest } from '../../utils/NewsEmail';
// assetts
import stayUpdatedblurr from '../../assets/images/png/homepage/stay-updated-blurr-img.webp';
import stayUpdatedblurrMobile from '../../assets/images/png/homepage/stay-updated-blurr-mobile-img.webp';
import stayUpdatedImg from '../../assets/images/webp/homepage/stay-updated-image.webp';
import positionLineImage from '../../assets/images/png/find-team-position-line.webp';
// icons
import { SubmitArrow, BottomBannerIcon } from '../common/icons/Common';
import { Cross, LineForMobile } from '../common/icons/home/StayUpdated';
import { ImSpinner8 } from 'react-icons/im';

const StayUpdatedBanner = () => {
  const [email, setEmail] = useState<string>('');
  const [activeField, setActiveField] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const submitPromise = registerInterest(email, ['general']);
      toast.promise(submitPromise, {
        pending: 'Registering interest',
        success: 'You are registered for any updates regarding Versus!',
        error: 'Error registering interest',
      });
      await submitPromise;
      setEmail('');
      setSubmitting(false);
    } catch (err) {
      toast.error('Error registering interest');
    }
  };

  const checkForOutsideInputClick: EventListener = (e: Event) => {
    if (e.target) {
      const target = e.target as HTMLElement;
      if (
        !target.classList.contains('emailInput') &&
        !target.closest('emailInput')
      ) {
        setActiveField('');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', checkForOutsideInputClick);

    return () =>
      document.removeEventListener('click', checkForOutsideInputClick);
  }, []);

  return (
    <div className=" pb-10 md:pb-16 lg:pb-24 relative overflow-x-hidden">
      <img
        className="bottom-0 left-0 absolute hidden sm:block"
        src={stayUpdatedblurr}
        alt="stayUpdatedblurr"
      />
      <img
        className="bottom-0 left-0 absolute sm:hidden"
        src={stayUpdatedblurrMobile}
        alt="stayUpdatedblurr"
      />
      <span className="green_gradient absolute bottom-0 left-[-3.125rem] inline-block w-[90rem] h-[18.75rem]"></span>

      <div className="custom_container mx-auto px-4 sm:px-8 lg:px-12">
        <div className="bg-green md:h-[11rem] lg:h-[12rem] overflow-hidden flex flex-col md:flex-row justify-between items-center max-[420px]:pe-3 pe-5 lg:pe-8 rounded-xl relative z-10">
          <img
            className="absolute hidden lg:block top-16 lg:top-[3.5rem] xl:top-5 2xl:top-4 min-[1700px]:top-5 z-0 -end-14 2xl:-end-24 min-[1700px]:-end-48 md:end-[-9rem]"
            src={positionLineImage}
            alt="line"
          />
          <span className="absolute top-12 z-0 end-0 w-full md:hidden">
            <LineForMobile />
          </span>
          <span className="absolute end-2 bottom-6 duration-300 h-0 group-hover:h-4">
            <BottomBannerIcon />
          </span>
          <img
            className=" w-[18.75rem] lg:w-[17.625rem] 2xl:w-[21.875rem] h-[11.875rem] lg:h-[12rem] 2xl:h-[12.5rem] relative z-[1]"
            width={250}
            height={140}
            src={stayUpdatedImg}
            alt="stay-updated-image"
          />
          <div className="lg:w-9/12 md:w-7/12 w-full ">
            <div className="flex flex-col lg:flex-row justify-between max-[420px]:ms-3 ms-5">
              {/* Content on the left side */}
              <div className="lg:w-[30rem] pt-6 lg:pt-0">
                <h2 className="font-wide max-[420px]:text-2xl text-[2.25rem] leading-[100%] font-bold text-black uppercase">
                  Stay updated
                </h2>
                <p className="font-compact font-normal text-sm leading-4 tracking-[0.009rem] pt-3 pe-3 text-GreenWaterloo opacity-60">
                  Receive all the news, updates, and exclusive promotions
                  directly in your inbox.
                </p>
              </div>
              <div className="md:py-6 pb-6 pt-4 flex flex-col sm:flex-row items-center lg:w-[40.125rem] z-10">
                <div id="stayUpdateInput" className="relative w-full">
                  <input
                    className={`font-compact font-normal text-sm h-12 sm:h-10 bg-lightBlack w-full focus:outline-none placeholder:text-darkGray px-4 rounded-xl text-white ${
                      activeField === 'email'
                        ? 'pr-10 placeholder:text-white '
                        : ''
                    }`}
                    onClick={() => setActiveField('email')}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email} // Set the input value
                    required
                    id="email"
                    type="email"
                    placeholder="Your e-mail"
                    autoComplete="off"
                  />
                  {activeField === 'email' && (
                    <span
                      onClick={() => {
                        setEmail('');
                        setActiveField('');
                      }}
                      className="absolute top-1/2 -translate-y-1/2 end-4 cursor-pointer"
                    >
                      <Cross />
                    </span>
                  )}
                </div>
                <button
                  type="button"
                  disabled={submitting}
                  onClick={handleSubmit}
                  className="px-3 pb-[0.688rem] pt-[0.813rem] sm:pt-2.5 sm:pb-[0.600rem] bg-lightGray sm:bg-black flex items-center gap-2 mt-3 sm:mt-0 max-sm:w-full justify-center font-compact font-medium rounded-xl sm:ml-2 lg:ml-3 duration-300 hover:bg-darkWoodsmoke cursor-pointer"
                >
                  <span className="text-white uppercase sm:hidden mb-[-0.188rem]">
                    subscribe
                  </span>{' '}
                  <span className="pl-[0.075rem] mb-[0.1rem]">
                    {submitting ? (
                      <ImSpinner8 className="animate-spin text-green" />
                    ) : (
                      <SubmitArrow />
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StayUpdatedBanner;
