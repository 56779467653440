import { useContext, useEffect, useState, useMemo, useRef } from 'react';
// firebase
import { firestore } from '@src/firebase';
import { collection, query, where, getDocs, Query } from 'firebase/firestore';
// algolia
import algoliasearch from 'algoliasearch';
// context
import { useAuthContext } from '@provider/AuthContextProvider';
import { TeamInfoContext } from '@components/team/TeamInfoProvider';
// types
import { Player } from '@src/firestore/players';
// utils
import { debounce } from '@utils/Debounce';
// assets
import imagePlaceholder from '@assets/images/placeholder/placeholder.webp';
// icons
import { Search, InputCross } from '@icons/Common';
import { TeamMateIcon } from '@icons/ProfileIcons';
import { ImSpinner8 } from 'react-icons/im';

const SearchTeamMate = () => {
  const { userObj } = useAuthContext();
  const { invitePlayer, cancelPlayerInvite, teamInfo } =
    useContext(TeamInfoContext);

  const [players, setPlayers] = useState<Player[]>([]);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const searchQueryRef = useRef(searchQuery);
  const [searching, setSearching] = useState<boolean>(false);
  const [searchBarActive, setSearchBarActive] = useState(false);

  const getPlayers = async () => {
    let q: Query;
    const localPlayers: Player[] = [];
    const localSearchQuery = searchQueryRef.current;
    if (localSearchQuery && userObj) {
      if (import.meta.env.VITE_ENV === 'production') {
        const client = algoliasearch(
          '1EFPJPOFKM',
          '725ce15dcc1a7cf9b3a0beb00b120456'
        );
        const index = client.initIndex('users');
        const { hits } = await index.search(localSearchQuery);
        hits.forEach((hit) => {
          if (userObj.uid !== hit.objectID) {
            const player = hit as unknown as Player;
            localPlayers.push({
              uid: hit.objectID,
              displayName: player.displayName,
              discord: player.discord,
              email: player.email,
              displayImage: player.displayImage,
              username: player.username,
              team: player.team,
            });
          }
        });
      } else if (import.meta.env.VITE_ENV === 'staging') {
        const client = algoliasearch(
          'IONCIJEH1C',
          'f6e30465e863efdc9f34188018765ed8'
        );
        const index = client.initIndex('users');
        const { hits } = await index.search(localSearchQuery);
        hits.forEach((hit) => {
          if (userObj.uid !== hit.objectID) {
            const player = hit as unknown as Player;
            localPlayers.push({
              uid: hit.objectID,
              displayName: player.displayName,
              discord: player.discord,
              email: player.email,
              displayImage: player.displayImage,
              username: player.username,
              team: player.team,
            });
          }
        });
      } else {
        q = query(
          collection(firestore, 'users'),
          where('displayName', '==', localSearchQuery)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          if (userObj.uid !== doc.id) {
            const player = doc.data();
            localPlayers.push({
              uid: doc.id,
              displayName: player.displayName,
              discord: player.discord,
              email: player.email,
              displayImage: player.displayImage,
              username: player.username,
              team: player.team,
            });
          }
        });
      }
    }
    setPlayers(localPlayers);
    setSearching(false);
  };

  const debouncedGetPlayers = useMemo(() => debounce(getPlayers, 500), []);

  useEffect(() => {
    setSearching(true);
    searchQueryRef.current = searchQuery;
    debouncedGetPlayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <div className="px-6 invite_team_scrollbar h-full flex flex-col">
      <div>
        <div className="relative mb-6 mt-5">
          <label
            htmlFor="playerName"
            className="absolute top-1/2 -translate-y-1/2 left-4"
          >
            <Search />
          </label>
          <input
            type="text"
            id="playerName"
            placeholder="Enter full player name"
            className={`!bg-black w-full pb-[0.125rem] ps-10 text-sm focus:outline-none placeholder:text-steelGray placeholder:font-normal placeholder:text-sm h-[2.5rem] placeholder:leading-4 rounded-xl ${
              searchBarActive
                ? 'border-green border-2 pr-10 text-white bg-black'
                : 'border-transparent border-2 bg-black'
            }`}
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            onClick={() => setSearchBarActive(true)}
          />
          {searchBarActive && (
            <span
              onClick={() => {
                setSearchQuery('');
                setSearchBarActive(false);
              }}
              className="absolute top-1/2 -translate-y-1/2 end-4 cursor-pointer validation_pop_up_cross_icon"
            >
              <InputCross />
            </span>
          )}
        </div>
        <div className="relative duration-300 h-[60%] flex-grow">
          <div className="overflow-y-scroll creators_scrollbar relative h-full">
            {!searchQuery ? (
              <div className="pt-6 pb-12 px-8 flex justify-center items-center flex-col">
                <div className="w-[4.5rem] h-[4.5rem] flex justify-center items-center rounded-full bg-lightGray mb-6">
                  <TeamMateIcon />
                </div>
                <h3 className="font-wide font-bold text-2xl !leading-6 text-white uppercase text-center">
                  search for a teammate
                </h3>
                <h4 className="font-compact text-sm font-normal text-steelGray text-center tracking-[0.009rem] leading-4 pt-2">
                  Invite some players to join your team.
                </h4>
              </div>
            ) : (
              <>
                {!searching ? (
                  <>
                    {players.length > 0 ? (
                      <>
                        {players.map((player, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-between px-3 py-3 bg-lightGray rounded-xl mb-2"
                          >
                            <div className="flex items-center min-[26.25rem]:gap-4 gap-2">
                              <img
                                className="max-w-[2.5rem] h-auto aspect-square object-cover rounded-full"
                                src={
                                  player.displayImage
                                    ? player.displayImage
                                    : imagePlaceholder
                                }
                                alt="player"
                              />
                              <div className="flex flex-col gap-y-1">
                                <h2 className="font-wide text-left font-bold text-white leading-4 min-[26.25rem]:pb-1">
                                  {player.displayName}
                                </h2>
                                <h3 className="font-compact text-sm text-steelGray">
                                  @{player.username}
                                </h3>
                              </div>
                            </div>
                            {teamInfo.pendingPlayers.find(
                              (invPlayer) => invPlayer.uid === player.uid
                            ) ? (
                              <button
                                type="button"
                                onClick={() =>
                                  cancelPlayerInvite(
                                    player.uid,
                                    player.displayName
                                  )
                                }
                                className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-white bg-ebonyClay hover:opacity-75 transition-opacity"
                              >
                                Cancel Invite
                              </button>
                            ) : (
                              <>
                                {player.team !== '' &&
                                player.team === teamInfo.id ? (
                                  <p className="text-sm font-compact font-semibold text-steelGray">
                                    Already in your team
                                  </p>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      invitePlayer(
                                        player.uid,
                                        player.displayName
                                      )
                                    }
                                    className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center bg-green hover:bg-gorse transition-colors"
                                  >
                                    Invite
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      <p className="text-steelGray font-semibold">
                        Player name "<strong>{searchQuery}</strong>" not found.
                      </p>
                    )}
                  </>
                ) : (
                  <div className="h-full w-full flex items-center justify-center">
                    <ImSpinner8 className="text-5xl text-steelGray animate-spin" />
                  </div>
                )}
                <div className="bg_bottom_shadow absolute bottom-0 left-0 w-full h-[2.5rem]"></div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SearchTeamMate;
