import { Link, useLocation } from 'react-router-dom';
// context
import { useAuthContext } from '@provider/AuthContextProvider';
// utils
import { HOME_PAGE_ACTIVE } from '@utils/Header';
// components
import {
  // ComingSoonLinks,
  DesktopNavigationLinks,
} from '@components/common/content/HeaderHelper';
import HeaderButton from './HeaderButton';
import GameSelector from './GameSelector/GameSelector';
import AnnouncementBar from './AnnouncementBar/AnnouncementBar';
// --- auth
import SignUpModal from './Auth/SignUpModal';
import LogInModal from './Auth/LogInModal';
import ForgotPasswordModal from './Auth/ForgotPasswordModal';
// assets
import pageLogo from '@assets/images/png/homepage/page_logo.webp';
import { FaShield } from 'react-icons/fa6';

const Header = () => {
  const { userObj } = useAuthContext();
  const location = useLocation();

  return (
    <div
      className={`
        bg-lightGray border-ebonyClay fixed left-0 top-0 z-40 h-fit w-full

        md:border-b
      `}
    >
      {/* GLOBAL ANNOUNCEMENT BAR GOES HERE */}
      <AnnouncementBar />
      <div
        className={`
          relative flex items-center custom_container mx-auto h-[55px]
          justify-between px-3 py-[0.45rem]

          2xl:px-[2.25rem]

          custom_xl:px-6
        `}
      >
        <div className="flex flex-grow items-center">
          <Link
            to="/"
            className={`
              hidden pr-3 ps-3

              custom_xl:pr-6

              md:block md:ps-0
            `}
          >
            <img
              className={`
                w-[9rem]

                custom_xl:w-[9rem]

                md:w-[6rem]
              `}
              src={pageLogo}
              alt="page-logo"
            />
          </Link>

          <div
            className={`
              flex items-center gap-3 pr-3 border-ebonyClay

              custom_xl:px-6 custom_xl:gap-6

              md:border-x md:px-3
            `}
          >
            <GameSelector />
          </div>

          <div
            className={`
              hidden flex-grow items-center justify-between px-3

              custom_xl:px-8

              md:flex
            `}
          >
            <div
              className={`
                flex items-center gap-3

                custom_xl:gap-8
              `}
            >
              {DesktopNavigationLinks.map((value, index) => {
                return (
                  <Link
                    to={value.path}
                    key={index}
                    className={`
                      text-[0.700rem] font-bold uppercase font-wide -mb-[0.3rem]
                      flex items-center gap-2 duration-300

                      custom_xl:text-[0.923rem]

                      ${
                        value.link.toLowerCase() ==
                          location.pathname.split('/')[1].toLocaleLowerCase() ||
                        (location.pathname === HOME_PAGE_ACTIVE &&
                          value.link === 'Home')
                          ? `
                            text-green

                            hover:text-green
                          `
                          : `
                            text-steelGray

                            hover:text-slateGray
                          `
                      }
                    `}
                  >
                    {value.link}
                  </Link>
                );
              })}
              {/* {ComingSoonLinks.map((value, index) => {
                return (
                  <Link
                    key={index}
                    to={value.path}
                    className={`
                      text-[0.700rem] font-bold uppercase font-wide -mb-[0.3rem]
                      flex items-center gap-2 duration-300

                      custom_xl:text-[0.923rem]

                      ${
                        value.link.toLowerCase() ==
                        location.pathname.split('/')[1].toLocaleLowerCase()
                          ? `
                            text-green

                            hover:text-green
                          `
                          : `
                            text-steelGray

                            hover:text-slateGray
                          `
                      }
                    `}
                  >
                    {value.link}
                  </Link>
                );
              })} */}
            </div>
            {userObj && userObj.admin ? (
              <Link
                to={'/admin'}
                className={`
                  bg-ebonyClay flex items-center justify-center gap-x-2
                  rounded-lg p-2 px-3 uppercase text-white font-compact
                  transition-opacity

                  hover:opacity-75
                `}
              >
                <span>Admin Dashboard</span>
                <FaShield className="-mt-1 text-green" />
              </Link>
            ) : (
              ''
            )}
          </div>
        </div>

        <Link
          to="/"
          className={`
            absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 pr-3
            ps-3

            custom_xl:pr-6

            md:hidden md:ps-0
          `}
        >
          <img
            src={pageLogo}
            alt="page-logo"
            className={`
              w-[9rem]

              custom_xl:w-[9rem]

              md:w-[6rem]
            `}
          />
        </Link>

        <HeaderButton />

        <SignUpModal />
        <LogInModal />
        <ForgotPasswordModal />
      </div>
    </div>
  );
};

export default Header;
