import { createContext, ReactNode, useContext, useEffect, useState } from "react";
// firebase
import { firestore } from "@src/firebase";
import { collection, onSnapshot, Unsubscribe } from "firebase/firestore";
// context
import { useTournamentContext } from "./TournamentProvider";
// types
import { Result } from "@src/firestore/results";
import { ApexTeamResult } from '@src/firestore/results';
import { DBTeam } from "@src/firestore/teams";

export enum LeaderboardGameView {
  overall,
  game1,
  game2,
  game3,
  game4,
  game5,
  game6
}

interface LeaderboardTeamGame {
  gameId: string,
  gameName: string,
  gameScore: number,
  map: string,
  gamePosition: number,
  result: ApexTeamResult,
}

interface LeaderboardTeamPlayerStatsByGame {
  gameName: string,
  name: string,
  kills: number,
  damageDealt: number,
  assists: number,
  knockdowns: number,
  revivesGiven: number
}

interface LeaderboardTeamPlayer {
  name: string,
  kills: number,
  damageDealt: number,
  assists: number,
  knockdowns: number,
  revivesGiven: number
  statsByGame: LeaderboardTeamPlayerStatsByGame[]
}

export interface LeaderboardTeam {
  id: string,
  team: DBTeam,
  groupPosition: number,
  score: number,
  kills: number,
  assists: number,
  damage: number,
  placement: number,
  players: LeaderboardTeamPlayer[],
  gameResults: LeaderboardTeamGame[]
}

interface LeaderboardGame {
  gameId: string,
  gamePosition: number,
  result: Result | null,
}

export interface LeaderboardStage {
  stageNum: number,
  groups: LeaderboardGroup[],
}

export interface LeaderboardGroup {
  groupId: string,
  groupNum: number,
  groupName: string,
  final: boolean,
  qualifyingSlots: number,
  dangerQualifyingSlots: number,
  stageNum: number,
  teams: LeaderboardTeam[]
  games: LeaderboardGame[]
}

interface ILeaderboardContext {
  selectedGameView: LeaderboardGameView,
  setSelectedGameView: (view: LeaderboardGameView) => void,
  leaderboardStages: LeaderboardStage[],
  leaderboardGroups: LeaderboardGroup[],
  setLeaderboardGroups: (groups: LeaderboardGroup[]) => void,
}

const defaultLeaderboardContext = {
  selectedGameView: LeaderboardGameView.overall,
  setSelectedGameView: (view: LeaderboardGameView) => view,
  leaderboardStages: [],
  leaderboardGroups: [],
  setLeaderboardGroups: (groups: LeaderboardGroup[]) => groups,
}

const LeaderboardContext = createContext<ILeaderboardContext>(defaultLeaderboardContext)


export const useLeaderboardContext = () => {
  const context = useContext(LeaderboardContext);
  return context;
}

interface ILeaderboardProvider {
  children: ReactNode,
}

const LeaderboardProvider: React.FC<ILeaderboardProvider> = ({ children }) => {
  const { tournament, tournamentTeamObjs } = useTournamentContext();
  const [leaderboardStages, setLeaderboardStages] = useState<LeaderboardStage[]>([]);
  const [leaderboardGroups, setLeaderboardGroups] = useState<LeaderboardGroup[]>([]);

  const [selectedGameView, setSelectedGameView] = useState<LeaderboardGameView>(LeaderboardGameView.overall);

  const getLeaderboardStages = () => {
    let unsubscribe: (() => void) | Unsubscribe = () => false;

    if (tournament) {
      const leaderboardCollection = collection(firestore, 'tournaments', tournament.id, 'leaderboards');
      unsubscribe = onSnapshot(leaderboardCollection, (snapshots) => {
        const localLeaderboardGroups: LeaderboardGroup[] = [];
        const localLeaderboardStages = snapshots.docs.map((doc) => doc.data() as LeaderboardStage);
        localLeaderboardStages.forEach((leaderboardStage) => {
          leaderboardStage.groups.forEach((group) => {
            localLeaderboardGroups.push(group);
            group.teams.forEach((team) => {
              team.team = tournamentTeamObjs.find((teamObj) => teamObj.id === team.id)!
            })
          })
        })
        setLeaderboardGroups(localLeaderboardGroups);
        setLeaderboardStages(localLeaderboardStages);
      })
    }
    return unsubscribe;
  }

  useEffect(() => {
    const unsubscribe = getLeaderboardStages();

    return () => unsubscribe();
  }, [tournament, tournamentTeamObjs])

  const contextValue = {
    selectedGameView: selectedGameView,
    setSelectedGameView: setSelectedGameView,
    leaderboardStages: leaderboardStages,
    leaderboardGroups: leaderboardGroups,
    setLeaderboardGroups: setLeaderboardGroups,

  }

  return (
    <LeaderboardContext.Provider value={contextValue}>
      {children}
    </LeaderboardContext.Provider>
  );
}

export default LeaderboardProvider;
