import { useState, useEffect } from 'react';
// context
import { useTournamentContext } from '@components/tournament/TournamentProvider';
import { useTournamentAdminContext } from '@components/tournament/AdminSettings/TournamentAdminProvider';
// types
import {
  tournamentStageNumToName,
  checkIfStageActive,
} from '@src/firestore/tournaments';
// components
import StageMaps from './StageMaps';

const Maps = () => {
  const { tournament } = useTournamentContext();
  const { stages } = useTournamentAdminContext();

  const [currentStage, setCurrentStage] = useState<number>(0);

  useEffect(() => {
    if (tournament && tournament.activeStage !== -1) {
      setCurrentStage(tournament.activeStage);
    }
  }, [tournament]);

  return (
    <div>
      <div className="flex w-full items-center gap-x-6 overflow-x-auto">
        <h3
          className={`
            font-wide text-xl font-bold uppercase !leading-6 text-white

            md:text-2xl
          `}
        >
          Maps
        </h3>
        <div className="flex w-full items-center gap-x-2">
          <div className="flex items-center gap-x-2">
            {stages.map((stage) => (
              <button
                key={`maps-stage-${stage.stageNum}`}
                type="button"
                onClick={() => setCurrentStage(stage.stageNum)}
                className={`
                  whitespace-nowrap rounded-full border-[1px] p-1 px-2
                  border-lightGray font-medium transition-colors

                  ${
                    currentStage === stage.stageNum
                      ? `
                        bg-lightGray

                        ${
                          tournament &&
                          tournament.activeStage === stage.stageNum
                            ? 'text-green'
                            : 'text-white/85'
                        }
                      `
                      : `
                        hover:bg-lightGray/20

                        ${
                          tournament &&
                          checkIfStageActive(stage.stageNum, tournament)
                            ? `
                              text-green/70

                              hover:text-green
                            `
                            : `
                              text-steelGray

                              hover:border-steelGray
                            `
                        }
                      `
                  }
                `}
              >
                {tournamentStageNumToName(tournament, stage.stageNum)}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-6 flex">
        <div className="w-full">
          {stages.map((stage) => (
            <div key={`maps-stage-view-${stage.stageNum}`}>
              {currentStage === stage.stageNum ? (
                <StageMaps stage={stage} />
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Maps;
