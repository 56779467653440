import { useEffect } from 'react';
// context
import { useTournamentContext } from '../TournamentProvider';
import { useLeaderboardContext } from '../LeaderboardProvider';
// types
import { DBTeam } from '../../../firestore/teams';
// components
import Sidebar from './Sidebar/Sidebar';
import LeaderboardTeamCards from './LeaderboardTeamCards/LeaderboardTeamCards';
// icons
import { VscDebugDisconnect } from 'react-icons/vsc';
import { SwordsIcon } from '@components/common/icons/TournamentIcons';

interface ILeaderboardGroup {
  stageComplete: boolean;
  stageNum: number;
  teams: DBTeam[];
  groupId: string;
}

const LeaderboardGroup: React.FC<ILeaderboardGroup> = ({
  stageNum,
  groupId,
  stageComplete,
}) => {
  const { tournament, tournamentStages } = useTournamentContext();
  const { leaderboardGroups, selectedGameView, setSelectedGameView } =
    useLeaderboardContext();
  const { tournamentTeams } = useTournamentContext();

  useEffect(() => {
    return () => setSelectedGameView(0);
  }, []);

  const leaderboardGroup = leaderboardGroups.find(
    (group) => group.groupId === groupId
  )!;

  const tournamentGroup =
    tournamentStages
      .find((stage) => stage.stageNum === stageNum)
      ?.groups.find((group) => group.id === groupId) ?? null;
  const tournamentGroupGames = tournamentGroup
    ? (tournamentGroup.games ?? [])
    : [];

  const groupStarted =
    tournamentGroupGames.find((game) => game.gamePosition === 1)
      ?.playerCodesDistributed ?? false;

  const totalGroupGames = tournamentGroup ? tournamentGroup.gamesNum : 0;
  const activeGroupGame = tournamentGroup ? tournamentGroup.activeGame : 0;

  const qualifyingSlots = leaderboardGroup.qualifyingSlots;
  const dangerQualifyingSlots = leaderboardGroup.dangerQualifyingSlots;

  const finalScore = stageComplete && leaderboardGroup.final;

  const groupTeams = leaderboardGroup.teams;
  const groupTournamentTeams = tournamentTeams.filter((tournamentTeam) =>
    groupTeams.some((groupTeam) => groupTeam.id === tournamentTeam.id)
  );

  const qualifiedNumber = groupTournamentTeams.filter((groupTeam) =>
    groupTeam.qualifiedStages.includes(leaderboardGroup.stageNum)
  ).length;

  return (
    <div className="mt-3 flex items-start gap-x-10">
      <Sidebar
        groupStarted={groupStarted}
        finalGroup={
          tournament && tournamentGroup
            ? tournamentGroup.stage === tournament.totalStages - 1
            : false
        }
        groupName={tournamentGroup ? tournamentGroup.groupName : ''}
        groupFinished={
          tournamentGroup
            ? tournamentGroup.activeGame > tournamentGroup.gamesNum
            : false
        }
        activeGroupGame={activeGroupGame}
        totalGroupGames={totalGroupGames}
      />
      <div className="flex-grow">
        {tournament && tournament.lockedStages.includes(stageNum) ? (
          <>
            {selectedGameView < activeGroupGame ? (
              <LeaderboardTeamCards
                groupTeams={groupTeams}
                leaderboardGroup={leaderboardGroup}
                stageComplete={stageComplete}
                finalScore={finalScore}
                qualifiedNumber={qualifiedNumber}
                qualifyingSlots={qualifyingSlots}
                dangerQualifyingSlots={dangerQualifyingSlots}
              />
            ) : (
              <div className="mt-10 flex flex-col items-center gap-y-2">
                <div
                  className={`
                    flex aspect-square h-auto items-center justify-center
                    bg-lightGray w-[50px] rounded-full
                  `}
                >
                  <SwordsIcon
                    className={'aspect-square h-auto w-[24px] fill-white'}
                  />
                </div>
                <p
                  className={`
                    font-wide text-2xl font-semibold uppercase text-white
                  `}
                >
                  Game {selectedGameView} - In Progress
                </p>
                <p className="text-steelGray font-compact">
                  Data will populate after game has finished
                </p>
              </div>
            )}
          </>
        ) : (
          <div className="mt-10 flex flex-col items-center gap-y-2">
            <div
              className={`
                flex aspect-square h-auto items-center justify-center
                bg-lightGray w-[50px] rounded-full
              `}
            >
              <VscDebugDisconnect className="text-2xl text-white" />
            </div>
            <p className="font-wide text-2xl font-semibold uppercase text-white">
              No data
            </p>
            <p className="text-steelGray font-compact">
              Groups will populate after teams have been seeded
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeaderboardGroup;
