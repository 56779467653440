import { useState, useEffect, ReactNode } from 'react';
import { Link } from 'react-router-dom';
// context
import { useTournamentContext } from '@components/tournament/TournamentProvider';
// types
import { Prize } from '@src/firestore/tournaments';
import {
  LeaderboardGroup,
  LeaderboardTeam,
  useLeaderboardContext,
} from '@components/tournament/LeaderboardProvider';
// components
import PopoverText from '@ui/PopoverText';
import { Disclosure, DisclosureButton } from '@headlessui/react';
import LeaderboardTeamGames from './Inner/LeaderboardTeamGames';
// libraries
import { toast } from 'react-toastify';
// assets
import skullWhite from '@assets/images/apex/icons/Kills white.webp';
// icons
import { FooterAccordionIcon } from '@icons/FooterIcons';
import {
  DiscordIcon,
  TwitterIcon,
  TwitchIcon,
  WebsiteIcon,
} from '@icons/Socials';
// import { Star } from "@icons/Common";
import {
  TrophyGoldImageNoCup,
  TrophySilverImageNoCup,
  TrophyBronzeImageNoCup,
} from '@icons/TournamentIcons';
import { FaCrown } from 'react-icons/fa';
import SurfsharkIcon from '@components/brands/surfshark/SurfsharkIcon';

interface ILeaderboardTeamCard {
  team: LeaderboardTeam;
  teamIndex: number;
  group: LeaderboardGroup;
  stageComplete: boolean;
  groupStarted: boolean;
  finalScore: boolean;
  matchPointEligible: boolean;
  wonMatch: boolean;
}

const LeaderboardTeamCard: React.FC<ILeaderboardTeamCard> = ({
  team,
  teamIndex,
  group,
  stageComplete,
  groupStarted,
  finalScore,
  matchPointEligible,
  wonMatch,
}) => {
  const { tournament, tournamentTeams } = useTournamentContext();
  const { selectedGameView } = useLeaderboardContext();

  const [prizes, setPrizes] = useState<Prize[]>([]);

  const gameResults =
    selectedGameView === 0
      ? team.gameResults
      : team.gameResults.filter(
          (game) => parseInt(game.gameName.split(' ')[1]) === selectedGameView
        );
  const teamScore =
    selectedGameView === 0 ? team.score : (gameResults[0]?.gameScore ?? 0);
  const teamKills =
    selectedGameView === 0 ? team.kills : (gameResults[0]?.result.kills ?? 0);

  const teamObj = team.team;

  useEffect(() => {
    if (tournament) {
      setPrizes(tournament.prizePool.prizes);
    }
  }, [tournament]);

  const getPrizeTextColorForPosition = (position: number) => {
    const prizeForPosition = prizes.find(
      (prize) => prize.position === position
    );
    if (prizeForPosition) {
      switch (position) {
        case 1:
          return '!text-yellow';
        case 2:
          return '!text-slateGray';
        case 3:
          return '!text-lightRed';
        default:
          return '!text-green';
      }
    }
    return null;
  };

  const getPrizeIconForPosition = (position: number) => {
    const prizeForPosition = prizes.find(
      (prize) => prize.position === position
    );
    let prizeIcon: ReactNode = '';

    if (prizeForPosition) {
      switch (position) {
        case 1:
          prizeIcon = (
            <TrophyGoldImageNoCup
              className={'aspect-square h-auto w-[50px] object-contain'}
            />
          );
          break;
        case 2:
          prizeIcon = (
            <TrophySilverImageNoCup
              className={'aspect-square h-auto w-[50px] object-contain'}
            />
          );
          break;
        case 3:
          prizeIcon = (
            <TrophyBronzeImageNoCup
              className={'aspect-square h-auto w-[50px] object-contain'}
            />
          );
          break;
      }
      return (
        <div className="relative h-fit w-fit">
          <p
            className={`
              ${getPrizeTextColorForPosition(position)}

              font-compact absolute left-1/2 top-1/2 z-10 -translate-x-1/2
              -translate-y-1/2 text-xl leading-none
            `}
          >
            {position}
          </p>
          {prizeIcon}
        </div>
      );
    }
    return null;
  };

  const tournamentTeam = tournamentTeams.find(
    (tournamentTeam) => tournamentTeam.id === team.id
  )!;
  const qualified =
    stageComplete && tournamentTeam.qualifiedStages.includes(group.stageNum);

  const qualifying = teamIndex < group.qualifyingSlots;
  const dangerQualifying =
    group.qualifyingSlots >= teamIndex &&
    teamIndex < group.qualifyingSlots + group.dangerQualifyingSlots;

  const prizeIcon = getPrizeIconForPosition(teamIndex + 1);
  const prizeColor = getPrizeTextColorForPosition(teamIndex + 1);

  return team && teamObj && tournament ? (
    <div className="mx-auto w-full bg-lightBlack rounded-lg">
      <Disclosure>
        {({ open }) => (
          <>
            <div
              className={`
                relative rounded-lg duration-300 group/card

                ${open ? 'max-md:bg-lightGray max-md:m-2' : ''}
              `}
            >
              <DisclosureButton
                disabled={!groupStarted}
                className={`
                  relative flex h-[130px] w-full flex-row items-center
                  justify-between rounded-lg p-0 text-left

                  focus-visible:outline-none

                  md:h-fit
                `}
              >
                <div
                  className={`
                    flex h-full w-1/4 max-w-[80px] items-center justify-center
                    border-r border-lightGray

                    md:hidden
                  `}
                >
                  <div
                    className={`
                      relative flex aspect-square h-full w-full items-center
                      justify-center text-2xl font-semibold uppercase
                      font-compact text-white

                      md:hidden
                    `}
                  >
                    {finalScore && selectedGameView === 0 && prizeIcon ? (
                      <>{prizeIcon}</>
                    ) : (
                      <p
                        className={`
                          ${
                            open || qualifying || dangerQualifying
                              ? ''
                              : 'opacity-50'
                          }

                          z-[10]
                        `}
                      >
                        {teamIndex + 1}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex h-full w-full flex-col justify-evenly px-4">
                  <Link
                    to={`/team/${teamObj.id}`}
                    onClick={(e) => {
                      if (teamObj.dissolved) {
                        e.preventDefault();
                        e.stopPropagation();
                        toast.error('Team has been dissolved');
                      }
                    }}
                    className={`
                      flex h-fit w-fit items-center gap-x-3 transition-opacity

                      hover:opacity-75

                      md:hidden
                    `}
                  >
                    <img
                      className={`
                        aspect-square h-auto w-[45px] rounded-lg object-cover
                      `}
                      src={teamObj.logoUrl}
                      alt="entrants-image"
                    />
                    <div className="mt-[0.313rem] w-full">
                      <p
                        className={`
                          font-compact mb-2 font-medium uppercase !leading-4
                          tracking-[0.0088rem] text-steelGray

                          md:mb-0

                          sm:mt-1
                        `}
                      >
                        {teamObj.teamTag}
                      </p>
                      <h2
                        className={`
                          uppercase font-wide flex items-center gap-4
                          whitespace-nowrap text-lg font-bold !leading-4
                          text-white
                        `}
                      >
                        {teamObj.teamName}
                      </h2>
                    </div>
                  </Link>

                  <div
                    className={`
                      border-t border-lightGray flex w-full justify-between pt-4

                      md:border-none md:pt-0
                    `}
                  >
                    <div
                      className={`
                        hidden

                        md:flex
                      `}
                    >
                      <div
                        className={`
                          relative hidden aspect-square h-full w-auto
                          items-center justify-center border-r text-2xl
                          font-semibold uppercase border-lightGray font-compact

                          md:flex

                          ${
                            finalScore && selectedGameView === 0 && prizeColor
                              ? prizeColor
                              : 'text-white'
                          }
                        `}
                      >
                        {finalScore && selectedGameView === 0 && prizeIcon ? (
                          <>{prizeIcon}</>
                        ) : (
                          <p
                            className={`
                              ${
                                (!stageComplete &&
                                  selectedGameView === 0 &&
                                  (open || qualifying || dangerQualifying)) ||
                                (stageComplete &&
                                  selectedGameView === 0 &&
                                  qualified) ||
                                (tournament.format === 'Match Point' &&
                                  selectedGameView === 0)
                                  ? (stageComplete && qualified) ||
                                    matchPointEligible
                                    ? 'text-green'
                                    : ''
                                  : 'opacity-50'
                              }

                              z-[10]
                            `}
                          >
                            {teamIndex + 1}
                          </p>
                        )}
                      </div>
                      <div
                        className={`
                          relative hidden w-full items-center gap-4 rounded-lg
                          py-4 pe-6 ps-4

                          md:flex
                        `}
                      >
                        <div
                          className={'flex h-full w-full justify-start gap-x-4'}
                        >
                          <Link
                            to={`/team/${teamObj.id}`}
                            onClick={(e) => {
                              if (teamObj.dissolved) {
                                e.preventDefault();
                                e.stopPropagation();
                                toast.error('Team has been dissolved');
                              }
                            }}
                            className={`
                              flex items-center gap-x-4 transition-opacity

                              hover:opacity-75
                            `}
                          >
                            <div
                              className={`
                                h-14 min-w-[3.5rem] overflow-hidden rounded-lg
                                border border-lightGray
                              `}
                            >
                              <img
                                className={`
                                  aspect-square h-full w-auto object-cover
                                `}
                                src={teamObj.logoUrl}
                                alt="entrants-image"
                              />
                            </div>
                            <div className="w-fit">
                              <h2
                                className={`
                                  font-wide flex items-center gap-4
                                  whitespace-nowrap text-base font-medium
                                  uppercase !leading-6 text-white
                                  transition-colors

                                  ${
                                    qualified && selectedGameView === 0
                                      ? '!text-green'
                                      : ''
                                  }
                                  ${
                                    finalScore &&
                                    selectedGameView === 0 &&
                                    prizeColor
                                      ? prizeColor
                                      : 'text-white'
                                  }
                                `}
                              >
                                {teamObj.teamName}
                              </h2>
                              <p className="font-compact text-sm text-steelGray">
                                {teamObj.teamTag}
                              </p>
                            </div>
                          </Link>
                          {/* <div className='h-[20px] w-[1px] bg-lightGray mx-2 mt-[0.35rem]'></div> */}
                          <span
                            className={`
                              mt-[0.35rem] flex h-fit items-center gap-x-2
                            `}
                          >
                            {teamObj.discordLink ? (
                              <Link target="_blank" to={teamObj.discordLink}>
                                <DiscordIcon
                                  className={`
                                    aspect-square h-auto w-[18px]
                                    transition-[300] fill-steelGray

                                    hover:fill-green
                                  `}
                                />
                              </Link>
                            ) : (
                              ''
                            )}
                            {teamObj.twitterLink ? (
                              <Link target="_blank" to={teamObj.twitterLink}>
                                <TwitterIcon
                                  className={`
                                    aspect-square h-auto w-[18px]
                                    transition-[300] fill-steelGray

                                    hover:fill-green
                                  `}
                                />
                              </Link>
                            ) : (
                              ''
                            )}
                            {teamObj.twitchLink ? (
                              <Link target="_blank" to={teamObj.twitchLink}>
                                <TwitchIcon
                                  className={`
                                    aspect-square h-auto w-[18px]
                                    transition-[300] fill-steelGray

                                    hover:fill-green
                                  `}
                                />
                              </Link>
                            ) : (
                              ''
                            )}
                            {teamObj.websiteLink ? (
                              <Link target="_blank" to={teamObj.websiteLink}>
                                <WebsiteIcon
                                  className={`
                                    aspect-square h-auto w-[18px]
                                    transition-[300] fill-steelGray

                                    hover:fill-green
                                  `}
                                />
                              </Link>
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`
                        -mt-1 flex w-fit items-center gap-x-2

                        md:hidden
                      `}
                    >
                      {teamObj.discordLink ? (
                        <Link target="_blank" to={teamObj.discordLink}>
                          <DiscordIcon
                            className={`
                              aspect-square h-auto w-[18px] transition-[300]
                              fill-steelGray

                              hover:fill-green
                            `}
                          />
                        </Link>
                      ) : (
                        ''
                      )}
                      {teamObj.twitterLink ? (
                        <Link target="_blank" to={teamObj.twitterLink}>
                          <TwitterIcon
                            className={`
                              aspect-square h-auto w-[18px] transition-[300]
                              fill-steelGray

                              hover:fill-green
                            `}
                          />
                        </Link>
                      ) : (
                        ''
                      )}
                      {teamObj.twitchLink ? (
                        <Link target="_blank" to={teamObj.twitchLink}>
                          <TwitchIcon
                            className={`
                              aspect-square h-auto w-[18px] transition-[300]
                              fill-steelGray

                              hover:fill-green
                            `}
                          />
                        </Link>
                      ) : (
                        ''
                      )}
                      {teamObj.websiteLink ? (
                        <Link target="_blank" to={teamObj.websiteLink}>
                          <WebsiteIcon
                            className={`
                              aspect-square h-auto w-[18px] transition-[300]
                              fill-steelGray

                              hover:fill-green
                            `}
                          />
                        </Link>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="flex items-center gap-x-3">
                      {groupStarted ? (
                        <>
                          {wonMatch ? (
                            <div
                              className={`
                                group relative hidden h-[35px] items-center
                                gap-x-2 rounded-lg border px-2 text-base
                                uppercase font-compact font-medium text-white
                                border-lightGray

                                custom_xsm:text-xl

                                lg:flex

                                md:font-semibold

                                sm:px-6
                              `}
                            >
                              <PopoverText
                                visible={true}
                                className={`
                                  opacity-0 transition-opacity

                                  group-hover:opacity-100
                                `}
                              >
                                {stageComplete && selectedGameView === 0
                                  ? 'Match Point Winner'
                                  : 'Game Winner'}
                              </PopoverText>
                              <FaCrown
                                className={`
                                  aspect-square h-auto w-[17px] fill-green
                                `}
                              />
                            </div>
                          ) : (
                            ''
                          )}

                          <div
                            className={`
                              flex h-[35px] w-[80px] items-center justify-center
                              gap-x-2 font-compact rounded-lg border text-base
                              font-medium uppercase text-white border-lightGray

                              custom_xsm:text-xl

                              md:font-semibold
                            `}
                          >
                            <img
                              src={skullWhite}
                              alt="skull icon"
                              className="h-auto w-[17px] object-contain"
                            />
                            <p
                              className={`
                                ${
                                  finalScore &&
                                  selectedGameView === 0 &&
                                  prizeColor
                                    ? prizeColor
                                    : 'text-white'
                                }

                                font-wide -mb-1
                              `}
                            >
                              {teamKills ? teamKills : 0}
                            </p>
                          </div>

                          <div
                            className={`
                              mr-4 flex h-[35px] items-center justify-center
                              gap-x-2 font-compact w-[100px] rounded-lg
                              text-base font-medium uppercase text-white
                              bg-lightGray

                              custom_xsm:text-xl

                              md:font-semibold
                            `}
                          >
                            {tournament.brand === 'Surfshark' ? (
                              <>
                                <SurfsharkIcon
                                  className={`
                                    aspect-square h-auto w-[24px] fill-white
                                  `}
                                />
                                <p
                                  className={`
                                    ${finalScore && selectedGameView === 0 && prizeColor ? prizeColor : ''}

                                    font-wide translate-y-[1px]
                                  `}
                                >
                                  {teamScore}
                                </p>
                              </>
                            ) : (
                              <>
                                <p
                                  className={`
                                    ${finalScore && selectedGameView === 0 && prizeColor ? prizeColor : ''}

                                    font-wide translate-y-[1px]
                                  `}
                                >
                                  {teamScore}
                                </p>
                                <p
                                  className={`
                                    translate-y-[1px] text-base font-normal
                                  `}
                                >
                                  pts
                                </p>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <div
                          className={`
                            mr-5 flex items-center gap-x-2 font-compact h-[35px]
                            rounded-lg px-2 uppercase text-white bg-lightGray

                            sm:px-12
                          `}
                        >
                          <p
                            className={`
                              text-base font-normal uppercase text-white
                            `}
                          >
                            Team slot{' '}
                            <em className={'not-italic text-green'}>
                              {team.groupPosition > 9 ? '' : '0'}
                              {team.groupPosition}
                            </em>
                          </p>
                        </div>
                      )}
                      <span
                        className={`
                          border-lightGray entrants_dropdown_arrow
                          transition-opacity

                          group-hover/card:opacity-50

                          md:border-l md:p-9
                        `}
                      >
                        <FooterAccordionIcon
                          accordianArrow={
                            open
                              ? '-rotate-180 duration-300'
                              : 'rotate-0 duration-300'
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </DisclosureButton>{' '}
            </div>
            <LeaderboardTeamGames team={team} group={group} />
          </>
        )}
      </Disclosure>
    </div>
  ) : (
    ''
  );
};

export default LeaderboardTeamCard;
