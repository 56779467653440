import { Fragment, ReactNode, useEffect, useRef, forwardRef } from 'react';
// packages
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
// components
import PopoverText from './PopoverText';
// icons
import { CrossIcon } from '../common/icons/Header';
import {
  CreateBackArrowIcon,
  RightCheckIcon,
} from '../common/icons/ProfileIcons';
import { useAnnouncementContext } from '@src/provider/AnnouncementProvider';

interface IModal {
  id?: string;
  scroll?: boolean;
  xl?: boolean;
  step?: number;
  totalSteps?: number;
  onBackClick?: () => void;
  title: string;
  children: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  buttonNegative?: boolean;
  buttonDisabled?: boolean;
  buttonOnClick?: () => void;
  buttonText?: ReactNode;
  disableClickOff?: boolean;
  onContainerLoad?: (container: HTMLDivElement) => void;
  stepNames?: string[];
  changeStep?: (stepNum: number) => void;
}

const Modal = forwardRef<HTMLDivElement, IModal>(
  (
    {
      id,
      title,
      xl,
      children,
      open,
      setOpen,
      buttonNegative,
      buttonDisabled,
      buttonOnClick,
      buttonText,
      step,
      totalSteps,
      onBackClick,
      scroll,
      onContainerLoad,
      disableClickOff,
      stepNames,
      changeStep,
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    const { announcementVisible } = useAnnouncementContext();

    useEffect(() => {
      if (containerRef.current && onContainerLoad) {
        onContainerLoad(containerRef.current);
      }
    }, [containerRef, onContainerLoad]);

    useEffect(() => {
      if (open) {
        document.body.classList.add('overflow_hidden');
      } else {
        document.body.classList.remove('overflow_hidden');
      }

      return () => {
        document.body.style.overflow = 'visible';
      };
    }, [open]);

    return (
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            if (!disableClickOff) setOpen(false);
          }}
        >
          <div className="fixed inset-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </TransitionChild>

            <div
              className={`
                fixed inset-0

                ${announcementVisible ? 'mt-[85px]' : 'mt-[55px]'}
              `}
            >
              <div
                className={`
                  flex min-h-full w-full items-end justify-center text-center

                  after:absolute after:h-full after:w-full after:bg-black/70

                  sm:items-center sm:p-4
                `}
              >
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <DialogPanel
                    id={id ?? ''}
                    ref={ref ?? null}
                    className={`
                      w-fit min-w-[30rem] max-w-[100vw] transform

                      ${xl ? 'sm:max-w-[75rem]' : 'sm:max-w-[30rem]'}

                      relative z-10 flex !max-h-none flex-shrink-0 items-center
                    `}
                  >
                    <div
                      className={`
                        mx-auto w-full scrollbar_none !bg-lightBlack
                        rounded-none

                        sm:rounded-xl

                        ${scroll ? 'h-[60vh]' : 'max-h-[85vh]'}
                      `}
                    >
                      <div className="flex h-full flex-col">
                        <div
                          className={`
                            flex h-fit items-center justify-between
                            border-b-[0.063rem] px-4 py-3 border-lightGray
                          `}
                        >
                          <div className="flex items-center gap-2">
                            {onBackClick ? (
                              <button
                                className="cursor-pointer"
                                onClick={onBackClick}
                              >
                                <CreateBackArrowIcon />
                              </button>
                            ) : (
                              ''
                            )}
                            <h5
                              className={`
                                mt-1 text-base font-bold uppercase
                                leading-[1rem] text-white font-compact
                              `}
                            >
                              {title}
                            </h5>
                          </div>
                          <button
                            type="button"
                            aria-label={`close ${title} modal`}
                            onClick={() => setOpen(false)}
                            className="cursor-pointer"
                          >
                            <CrossIcon />
                          </button>
                        </div>

                        <div
                          className={`
                            h-full w-full flex-grow p-4 text-steelGray
                            font-compact

                            ${
                              scroll
                                ? 'overflow-y-scroll maskedListVert py-[20px]'
                                : ''
                            }
                          `}
                          ref={containerRef}
                        >
                          {totalSteps !== undefined && step !== undefined ? (
                            <div
                              className={`
                                flex

                                ${totalSteps > 6 ? 'gap-[16px]' : 'gap-[22px]'}

                                relative z-[2] mb-[20px] items-center
                                justify-center pt-6
                              `}
                            >
                              {Array.from({ length: step - 1 }).map(
                                (_item, index) => (
                                  <button
                                    key={`step-change-button-${index}`}
                                    type="button"
                                    aria-label={`Change to step ${index}`}
                                    disabled={!changeStep}
                                    onClick={() => {
                                      if (changeStep) {
                                        changeStep(index);
                                      }
                                    }}
                                    className={`
                                      group relative z-[2] h-fit w-fit
                                    `}
                                  >
                                    {stepNames && stepNames[index] ? (
                                      <PopoverText
                                        visible={true}
                                        bottom={true}
                                        className={`
                                          opacity-0 transition-opacity

                                          group-hover:opacity-100
                                        `}
                                      >
                                        {stepNames[index]}
                                      </PopoverText>
                                    ) : (
                                      ''
                                    )}
                                    <div
                                      key={`${index}-progress-completed-circle`}
                                      className={`
                                        relative bg-green flex h-[20px] w-[20px]
                                        items-center justify-center rounded-full

                                        ${
                                          index !== 0
                                            ? `
                                              after:absolute after:left-0
                                              after:top-1/2 after:block
                                              after:h-[2px]
                                              after:-translate-x-full
                                              after:-translate-y-1/2
                                              after:content-[""]

                                              ${
                                                totalSteps > 6
                                                  ? 'after:w-[16px]'
                                                  : 'after:w-[22px]'
                                              }

                                              after:!bg-green
                                            `
                                            : ''
                                        }
                                      `}
                                    >
                                      <RightCheckIcon
                                        className={`
                                          aspect-square h-auto w-[10px]
                                          stroke-black
                                        `}
                                      />
                                    </div>
                                  </button>
                                )
                              )}
                              <div className="group relative z-[2] h-fit w-fit">
                                {stepNames && stepNames[step - 1] ? (
                                  <PopoverText
                                    visible={true}
                                    bottom={true}
                                    className={`
                                      opacity-0 transition-opacity

                                      group-hover:opacity-100
                                    `}
                                  >
                                    {stepNames[step - 1]}
                                  </PopoverText>
                                ) : (
                                  ''
                                )}
                                <div
                                  className={`
                                    relative h-[20px] w-[20px] rounded-full
                                    outline outline-[0.188rem]
                                    -outline-offset-[0.188rem] outline-green
                                    bg-lightBlack

                                    ${
                                      step !== 1
                                        ? `
                                          after:absolute after:left-0
                                          after:top-1/2 after:block
                                          after:h-[2px] after:-translate-x-full
                                          after:-translate-y-1/2
                                          after:content-[""]

                                          ${
                                            totalSteps > 6
                                              ? 'after:w-[16px]'
                                              : 'after:w-[22px]'
                                          }

                                          after:!bg-green
                                        `
                                        : ''
                                    }
                                  `}
                                ></div>
                              </div>
                              {Array.from({ length: totalSteps - step }).map(
                                (_item, index) => (
                                  <div
                                    key={`step-change-button-${step + index}`}
                                    className={`
                                      group relative z-[2] h-fit w-fit
                                    `}
                                  >
                                    {stepNames && stepNames[step + index] ? (
                                      <PopoverText
                                        visible={true}
                                        bottom={true}
                                        className={`
                                          opacity-0 transition-opacity

                                          group-hover:opacity-100
                                        `}
                                      >
                                        {stepNames[step + index]}
                                      </PopoverText>
                                    ) : (
                                      ''
                                    )}
                                    <div
                                      key={`${index}-progress-circle`}
                                      className={`
                                        relative h-[20px] w-[20px] rounded-full
                                        outline outline-[0.125rem]
                                        -outline-offset-[0.125rem]
                                        outline-ebonyClay

                                        after:absolute after:left-0
                                        after:top-1/2 after:block after:h-[2px]
                                        after:-translate-x-full
                                        after:-translate-y-1/2
                                        after:content-[""]

                                        ${
                                          totalSteps > 6
                                            ? 'after:w-[16px]'
                                            : 'after:w-[22px]'
                                        }

                                        bg-lightBlack

                                        after:!bg-ebonyClay
                                      `}
                                    ></div>
                                  </div>
                                )
                              )}
                            </div>
                          ) : (
                            ''
                          )}
                          {children}
                        </div>

                        {buttonText && buttonOnClick ? (
                          <div
                            className={`
                              h-fit w-full flex-shrink-0 border-t-[0.063rem]
                              px-4 py-3 border-lightGray
                            `}
                          >
                            <button
                              disabled={buttonDisabled === true}
                              onClick={buttonOnClick}
                              className={`
                                flex w-full items-center justify-center
                                font-compact rounded-xl py-[0.938rem]
                                text-center text-base font-semibold uppercase
                                !leading-4

                                ${
                                  buttonNegative
                                    ? `
                                      bg-red/70 gap-2 text-white

                                      disabled:opacity-50
                                      disabled:hover:bg-red/70

                                      hover:bg-red
                                    `
                                    : `
                                      bg-green gap-2 text-black

                                      disabled:opacity-50
                                      disabled:hover:bg-green

                                      hover:bg-gorse
                                    `
                                }
                              `}
                            >
                              {buttonText}
                            </button>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
);

export default Modal;
