// types
import {
  LeaderboardGroup,
  LeaderboardTeam,
  useLeaderboardContext,
} from '../../LeaderboardProvider';
import { useTournamentContext } from '../../TournamentProvider';
// components
import LeaderboardTeamCard from './LeaderboardTeamCard';
// icons
import { FaCaretUp } from 'react-icons/fa';

interface extendedLeaderboardTeam extends LeaderboardTeam {
  matchPointEligible: boolean;
}

interface ILeaderboardTeamCards {
  groupTeams: LeaderboardTeam[];
  leaderboardGroup: LeaderboardGroup;
  stageComplete: boolean;
  finalScore: boolean;
  qualifiedNumber: number;
  qualifyingSlots: number;
  dangerQualifyingSlots: number;
}

const LeaderboardTeamCards: React.FC<ILeaderboardTeamCards> = ({
  groupTeams,
  leaderboardGroup,
  stageComplete,
  finalScore,
  qualifiedNumber,
  qualifyingSlots,
  dangerQualifyingSlots,
}) => {
  const { tournament } = useTournamentContext();
  const { selectedGameView } = useLeaderboardContext();

  const matchPointEligibleTeamsByGame: string[][] = [];

  for (const team of groupTeams) {
    const games = team.gameResults.sort(
      (a, b) => a.gamePosition - b.gamePosition
    );
    let cummulativeGameScore = 0;
    for (const index in games) {
      const game = games[index];
      const gameScore = game.gameScore;
      cummulativeGameScore += gameScore;
      if (
        tournament &&
        cummulativeGameScore >= tournament?.matchPointFormatThreshold
      ) {
        if (matchPointEligibleTeamsByGame[index]) {
          matchPointEligibleTeamsByGame[index].push(team.id);
        } else {
          matchPointEligibleTeamsByGame[index] = [team.id];
        }
      }
    }
  }

  const totalMatchPointEligibleTeams =
    matchPointEligibleTeamsByGame[matchPointEligibleTeamsByGame.length - 1]
      ?.length ?? 0;

  const resultsAvailable =
    groupTeams[0] && groupTeams[0].gameResults.length > 0;

  const sortedGroupTeams: extendedLeaderboardTeam[] = (
    !resultsAvailable
      ? [...groupTeams].sort((a, b) => a.groupPosition - b.groupPosition)
      : selectedGameView === 0
        ? [...groupTeams]
        : [...groupTeams].sort((a, b) => {
            return (
              (b.gameResults.find(
                (game) => game.gamePosition === selectedGameView
              )?.gameScore ?? 0) -
              (a.gameResults.find(
                (game) => game.gamePosition === selectedGameView
              )?.gameScore ?? 0)
            );
          })
  ).map((team) => ({
    ...team,
    matchPointEligible:
      selectedGameView === 0
        ? matchPointEligibleTeamsByGame.some((game) => game.includes(team.id))
        : (matchPointEligibleTeamsByGame[selectedGameView - 2]?.includes(
            team.id
          ) ?? false),
  }));

  const wonMatch = (team: extendedLeaderboardTeam, matchNum: number) => {
    const match = team.gameResults.sort(
      (a, b) => a.gamePosition - b.gamePosition
    )[matchNum - 1];

    return match !== undefined && match.result.teamPlacement === 1;
  };

  const wonLastMatch = (team: extendedLeaderboardTeam) => {
    const lastMatch = team.gameResults.sort(
      (a, b) => b.gamePosition - a.gamePosition
    )[0];

    return lastMatch !== undefined && lastMatch.result.teamPlacement === 1;
  };

  if (tournament && stageComplete && tournament.matchPointFormatFinish) {
    if (tournament.matchPointFormatFinish && selectedGameView === 0) {
      sortedGroupTeams.sort((a, b) => {
        return (wonLastMatch(b) ? 1 : 0) - (wonLastMatch(a) ? 1 : 0);
      });
    }
  }

  const finalStage =
    tournament !== null &&
    leaderboardGroup.stageNum === tournament.totalStages - 1;
  return tournament ? (
    <div className="flex flex-col gap-y-3">
      {sortedGroupTeams.map((team, index) => (
        <div key={`leaderboard-team-${team.id}`}>
          <LeaderboardTeamCard
            team={team}
            wonMatch={
              stageComplete && selectedGameView === 0
                ? tournament.matchPointFormatFinish && wonLastMatch(team)
                : wonMatch(team, selectedGameView)
            }
            matchPointEligible={
              tournament.format === 'Match Point' &&
              finalStage &&
              !stageComplete &&
              (selectedGameView === 0
                ? team.matchPointEligible
                : team.gameResults.sort(
                    (a, b) => a.gamePosition - b.gamePosition
                  )[selectedGameView - 2]?.gameScore >=
                  tournament.matchPointFormatThreshold)
            }
            teamIndex={index}
            group={leaderboardGroup}
            groupStarted={resultsAvailable}
            stageComplete={stageComplete}
            finalScore={finalScore}
          />
          {resultsAvailable ? (
            <>
              {(stageComplete &&
                selectedGameView === 0 &&
                index === qualifiedNumber - 1) ||
              (!stageComplete &&
                selectedGameView === 0 &&
                index === qualifyingSlots - 1) ? (
                <div className="relative my-4 h-[2px] w-full dashed-steelGray">
                  <p
                    className={`
                      absolute left-1/2 top-1/2 mt-[1px] flex min-w-[125px]
                      -translate-x-1/2 -translate-y-1/2 px-3 pr-4 text-steelGray
                      font-compact items-center justify-center gap-x-2 bg-black
                    `}
                  >
                    <FaCaretUp />
                    <span>{stageComplete ? 'Qualified' : 'Qualifying'}</span>
                  </p>
                </div>
              ) : (
                ''
              )}
              {!stageComplete &&
              finalStage &&
              tournament.format === 'Match Point' &&
              selectedGameView === 0 &&
              totalMatchPointEligibleTeams > 0 &&
              index === totalMatchPointEligibleTeams - 1 ? (
                <div
                  className={`
                    relative my-6 mb-4 h-[2px] w-full dashed-steelGray
                  `}
                >
                  <p
                    className={`
                      absolute left-1/2 top-1/2 mt-[1px] flex min-w-[125px]
                      -translate-x-1/2 -translate-y-1/2 gap-x-2 bg-black px-3
                      pr-4 text-steelGray font-thin font-compact items-center
                      justify-center
                    `}
                  >
                    <FaCaretUp />
                    <span className="translate-y-[1px]">
                      Match Point Eligible -{' '}
                      <em className="not-italic">
                        {tournament.matchPointFormatThreshold} pts
                      </em>
                    </span>
                  </p>
                </div>
              ) : (
                ''
              )}
              {!stageComplete &&
              selectedGameView === 0 &&
              dangerQualifyingSlots > 0 &&
              index === qualifyingSlots + dangerQualifyingSlots - 1 ? (
                <div className="relative my-4 h-[2px] w-full dashed-steelGray">
                  <p
                    className={`
                      absolute left-1/2 top-1/2 mt-[1px] flex min-w-[125px]
                      -translate-x-1/2 -translate-y-1/2 px-3 pr-4 text-steelGray
                      font-compact items-center justify-center gap-x-2 bg-black
                    `}
                  >
                    <FaCaretUp />
                    <span>Danger Zone</span>
                  </p>
                </div>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </div>
      ))}
    </div>
  ) : (
    ''
  );
};

export default LeaderboardTeamCards;
