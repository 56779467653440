// Importing necessary dependencies and assets
import { Link } from 'react-router-dom';
import CompetitiveIncectImg from '../../assets/images/webp/homepage/competitive-insect-img.webp';
import {
  CompetitiveLine,
  CompetitiveLineForMobile,
} from '../common/icons/home/Competitive';
import { CompetitiveIconsList } from '../common/content/HomePageContent';

// Defining the UnleashCompetitive functional component
const UnleashCompetitive = () => {
  return (
    <>
      {/* Container for the competitive banner */}
      <div
        className={`
          mx-auto mt-10 overflow-x-hidden px-4 pb-10 custom_container

          lg:px-12

          sm:mt-0 sm:px-8 sm:py-16

          xl:py-24
        `}
      >
        {/* Background container with competitive splash image */}
        <div
          className={`
            relative overflow-hidden rounded-xl
            bg-[url('/src/assets/images/svg/homepage/competitive-bg-splash-img.svg')]
            bg-cover bg-no-repeat object-cover

            sm:rounded-2xl
          `}
        >
          {/* Left zigzag line for decoration */}
          <span
            className={`
              absolute -left-10 top-2.5 hidden

              lg:top-10 lg:block

              xl:left-0
            `}
          >
            <CompetitiveLine />
          </span>
          {/* Main content wrapper */}
          <div
            className={`
              relative flex flex-wrap items-center justify-center

              lg:justify-between
            `}
          >
            {/* Left side content */}
            <div
              className={`
                relative order-2 w-full px-6 pb-6 pt-20

                lg:order-1 lg:w-6/12 lg:pb-14 lg:pt-20

                max-[420px]:px-4

                sm:px-12

                xl:w-7/12 xl:pl-24
              `}
            >
              {/* Full zigzag line for small screens */}
              <span
                className={`
                  absolute left-0 top-[0.225rem] mt-6 block w-full

                  lg:-left-40 lg:top-[2.063rem] lg:hidden

                  max-[420px]:mt-2

                  md:-left-32

                  sm:-left-24
                `}
              >
                <CompetitiveLineForMobile />
              </span>
              {/* List of competitive features */}
              <div
                className={`
                  mt-6 flex flex-wrap items-center gap-2

                  lg:mt-0

                  max-[420px]:mt-3
                `}
              >
                {CompetitiveIconsList.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className={`
                        flex items-center bg-lightGray rounded-[22px] px-3
                        py-[0.313rem]

                        min-[420px]:mt-3
                      `}
                    >
                      {data.icon}
                      <p
                        className={`
                          pl-2 pt-1 text-xs font-bold uppercase text-white
                          font-wide

                          md:text-sm
                        `}
                      >
                        {data.title}
                      </p>
                    </div>
                  );
                })}
              </div>
              {/* Heading */}
              <h3
                className={`
                  text-green uppercase font-wide max-w-[33.625rem] pt-6 text-2xl
                  font-bold

                  lg:text-[1.75rem]

                  max-[420px]:pt-3

                  md:pt-5 md:!leading-9

                  xl:text-[2.25rem]
                `}
              >
                UNLEASH YOUR COMPETITIVE INSTINCT
              </h3>
              {/* Description */}
              <p
                className={`
                  text-darkGray font-compact max-w-[33.625rem] pt-6 text-base
                  font-normal leading-[150%] tracking-[0.01rem]

                  max-[420px]:pt-3
                `}
              >
                You're not just a player here. You're part of a global community
                of gamers, constantly pushing boundaries and shattering limits.
                From scouting and team management to intense tournaments and
                trophies. This is where passion meets competition. This is
                esports redefined.
              </p>
              {/* Button */}
              <div
                className={`
                  inline-block pt-6

                  max-[420px]:pt-3
                `}
              >
                <Link
                  to="/games"
                  className={`
                    bg-green flex h-12 w-[10.313rem] items-center justify-center
                    whitespace-nowrap rounded-xl px-6 pt-4 text-sm font-medium
                    text-black font-compact pb-3 uppercase a-auto

                    hover:bg-gorse

                    md:text-base
                  `}
                >
                  compete now
                </Link>
              </div>
            </div>
            {/* Right side content with an image */}
            <div
              className={`
                order-1 w-full
                bg-[url('/src/assets/images/svg/homepage/competitive-bg-splash-img.svg')]
                bg-cover bg-right bg-no-repeat

                lg:order-2 lg:w-6/12

                max-lg:relative
              `}
            >
              <div
                className={`
                  z-10 translate-x-[15%]

                  lg:absolute lg:bottom-0 lg:right-0 lg:translate-x-0

                  min-[1340px]:right-28
                `}
              >
                {/* Competitive insect image */}
                <img
                  className={`
                    mx-auto w-full object-scale-down pe-4

                    lg:h-auto lg:w-[40.375rem]

                    md:pe-0

                    sm:h-[18.75rem]

                    xl:w-[49.75rem] xl:pe-[4rem]
                  `}
                  src={CompetitiveIncectImg}
                  alt="Competitive-Incect-Img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Exporting the UnleashCompetitive component
export default UnleashCompetitive;
