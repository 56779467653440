import { useState, useEffect, useRef, ReactNode } from 'react';
// icons
import { DownSelectArrow } from '@components/common/icons/Common';
import { SkeletonDark } from '../common/skeleton-loader/Skeletons';
import { FaCheck } from 'react-icons/fa';

interface DropDownOption {
  value: number;
  label: string;
}

interface IDropDownMultiSelect {
  title: string;
  className?: string;
  icon?: ReactNode;
  loaded?: boolean;
  selectedOptions: number[];
  options: DropDownOption[];
  setSelectedOptions: (options: number[]) => void;
}

const DropDownMultiSelect: React.FC<IDropDownMultiSelect> = ({
  title,
  selectedOptions,
  options,
  icon,
  setSelectedOptions,
  loaded,
  className,
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);

  const handleCloseDropDownOnOutsideClick = (e: MouseEvent) => {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(e.target as Node)
    ) {
      setDropDownOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) =>
      handleCloseDropDownOnOutsideClick(e);

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleOnChange = (option: number) => {
    const localOptions = [...selectedOptions];
    if (localOptions.includes(option)) {
      localOptions.splice(localOptions.indexOf(option), 1);
    } else {
      localOptions.push(option);
    }
    setSelectedOptions(localOptions);
  };

  return loaded !== false ? (
    <div
      className={`
        ${className}

        flex h-fit gap-2 whitespace-nowrap rounded-xl bg-lightBlack

        lg:gap-4

        md:gap-3
      `}
    >
      <div
        className={`
          relative inline-block w-full

          lg:min-w-[14.375rem]

          md:min-w-[11.25rem]

          sm:min-w-[12.5rem]
        `}
        ref={dropDownRef}
      >
        <div
          className={`
            relative flex cursor-pointer items-center justify-between gap-1 px-4
            pb-[0.688rem] pt-[0.813rem] duration-300

            sm:gap-3
          `}
          onClick={() => setDropDownOpen(!dropDownOpen)}
        >
          {icon ? (
            <div
              className={'absolute left-3 top-1/2 h-fit w-fit -translate-y-1/2'}
            >
              {icon}
            </div>
          ) : (
            ''
          )}
          <div className="flex items-center align-middle">
            <p
              className={`
                font-compact align-middle text-xs font-normal !leading-4
                tracking-[0.009rem] text-white

                sm:ms-2 sm:text-sm

                ${icon ? 'pl-6' : ''}
              `}
            >
              {title}
            </p>
          </div>
          <div className="flex items-center gap-x-2">
            <p
              className={`
                flex aspect-square h-auto w-[15px] flex-shrink-0 items-center
                justify-center rounded text-xs font-compact font-semibold
                transition-colors

                ${
                  selectedOptions.length > 0
                    ? 'bg-green text-black'
                    : 'bg-lightGray text-steelGray'
                }
              `}
            >
              <span className="translate-y-[1px]">
                {selectedOptions.length}
              </span>
            </p>
            <span
              className={`
                ${dropDownOpen ? 'open rotate-[180deg]' : ''}
              `}
            >
              <DownSelectArrow />
            </span>
          </div>
        </div>
        {dropDownOpen && (
          <div
            className={`
              absolute left-0 top-11 z-50 bg-lightBlack w-full rounded-md
              bg-cover bg-no-repeat
            `}
          >
            <div
              className={`
                flex w-full flex-col overflow-hidden rounded-md border
                border-white border-opacity-[0.1]
              `}
            >
              {options.map((option, index) => {
                const selected = selectedOptions.includes(option.value);
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setDropDownOpen(false);
                      handleOnChange(option.value);
                    }}
                    className={`
                      mb-0 flex w-full cursor-pointer items-center
                      justify-between border-b border-b-white border-opacity-5
                      p-3 text-sm font-normal tracking-[0.009rem] text-[#B1B1B1]
                      font-compact delay-[20ms]

                      hover:bg-lightGray hover:text-white
                    `}
                  >
                    <p>{option.label}</p>

                    <div
                      className={`
                        aspect-square h-auto w-[15px] rounded border
                        border-ebonyClay

                        ${selected ? 'bg-green' : 'bg-transparent'}

                        flex items-center justify-center transition-colors
                      `}
                    >
                      <FaCheck
                        className={`
                          ${selected ? '' : 'hidden'}

                          text-xs text-black
                        `}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <SkeletonDark
      className={`
        w-full rounded-xl

        lg:min-w-[14.375rem]

        md:min-w-[11.25rem]

        sm:min-w-[12.5rem]
      `}
    >
      <input
        type="text"
        className={`
          pointer-events-none p-2 font-thin opacity-0 outline-none font-compact
        `}
      />
    </SkeletonDark>
  );
};

export default DropDownMultiSelect;
