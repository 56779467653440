import { Link } from 'react-router-dom';
import { GamesSelectList } from '../common/content/HomePageContent';
import comingSoon from '../../assets/images/png/homepage/coming-soon-img.webp';
import {
  GameSelectAboveLine,
  GameSelectBottomLine,
} from '../common/icons/home/HomeIcons';
import { Game, gameToLink } from '@src/types/Games/Games';

const GameSelect = () => {
  return (
    <>
      {/* Main container for the GameSelect component */}
      <div
        className={`
          relative overflow-hidden px-4 pb-20 pt-16 custom_container

          lg:px-12

          sm:px-8 sm:pt-24

          xl:pb-28 xl:pt-48
        `}
      >
        <div
          className={`
            mx-3 mt-4 flex flex-col items-center justify-center text-center

            md:mx-36 md:mt-0
          `}
        >
          {/* Heading and subheading */}

          <h2 className="text-2xl font-bold uppercase text-white font-wide">
            game select
          </h2>
          <p
            className={`
              text-darkGray font-compact pt-2 text-base font-normal

              lg:pt-3.5
            `}
          >
            Tactical shooters, battle royales, fighters and more
          </p>
        </div>
        <div
          className={`
            mx-auto flex flex-wrap justify-center pt-4

            custom_2xl:gap-6

            lg:pt-10

            md:pt-8
          `}
        >
          <span
            className={`
              absolute -top-7

              md:-top-4

              xl:top-16
            `}
          >
            <GameSelectAboveLine />
          </span>
          <span
            className={`
              absolute -bottom-8 hidden

              sm:block
            `}
          >
            <GameSelectBottomLine />
          </span>
          {/* MAPPING THROUGH GAME DATA TO RENDER */}
          {GamesSelectList.map((obj, index) => (
            <GameSelectCards
              key={index}
              icon={obj.icon}
              isActive={obj.isActive}
              thumbnail={obj.thumbnail}
              title={obj.title}
              game={obj.game}
            />
          ))}
        </div>
      </div>
    </>
  );
};
export default GameSelect;

interface GameCardProps {
  title: string;
  thumbnail: string;
  icon: JSX.Element;
  isActive: boolean;
  game: Game;
}

const GameSelectCards = ({
  title,
  thumbnail,
  icon,
  isActive,
  game,
}: GameCardProps) => {
  return (
    <Link
      to={isActive ? `/games/${gameToLink(game)}` : ''}
      className={`
        w-full px-2

        pt-4

        custom_2xl:w-[19.875rem]

        custom_2xl:px-0

        custom_sm:w-6/12

        lg:w-3/12

        max-[420px]:px-0

        xl:px-3

        h-fit${isActive ? 'cursor-pointer' : 'cursor-not-allowed'}
      `}
    >
      <div
        className={`
          relative rounded-2xl bg-lightBlack group flex flex-col gap-y-2 p-2

          lg:h-[20.50rem]

          md:h-[21.2rem]

          sm:h-[19.5rem] sm:gap-y-4 sm:p-4

          xl:h-[25.5rem]
        `}
      >
        {/* CONDITIONAL RENDERING OF 'COMING SOON' label */}
        {!isActive && (
          <img
            className={'absolute left-0 top-0 z-10'}
            src={comingSoon}
            alt="coming-soon"
          />
        )}
        {/* DISPLAYING THUMBNAIL OF THE GAME */}
        <div
          className={`
            relative h-[14rem] overflow-hidden rounded-lg

            custom_2xl:w-[17.875rem]

            xl:h-[19rem]
          `}
        >
          <img
            className={`
              h-full w-full object-cover duration-200

              group-hover:scale-110

              ${isActive ? '' : 'saturate-0'}
            `}
            src={thumbnail}
            alt="thumbnail-img"
          />
        </div>
        {/* GAME ICON AND DETAILS */}
        <div className="flex items-center">
          <span className="rounded-xl bg-black">{icon}</span>
          <span
            className={`
              pl-1

              sm:mt-0 sm:pl-3
            `}
          >
            <h4
              className={`
                whitespace-nowrap pt-1 text-[0.938rem] font-bold uppercase
                text-white font-wide

                md:whitespace-normal
              `}
            >
              {title}
            </h4>
          </span>
        </div>
      </div>
    </Link>
  );
};
