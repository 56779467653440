import { useEffect, useState } from 'react';
// libaries
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  A11y,
  EffectFade,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper/modules';
// icons
import { usePlayersContext } from '@src/components/players/PlayersProvider';
import heroImage1 from '@src/assets/images/png/games/hero-apex-extended.webp';

const Hero = () => {
  const { featuredPlayers } = usePlayersContext();
  const [swiperController, setSwiperController] = useState<SwiperCore>();

  useEffect(() => {
    setTimeout(() => {
      if (swiperController && featuredPlayers.length > 0) {
        // we have to do this to 'activate' swiper to reconfigure itself for the loaded featured tournaments since they arent there when it mounts
        swiperController.slideTo(0);
        swiperController.params.loop = true;
        swiperController.loopCreate();
        // @ts-expect-error loopFix IS defined but inconsistent types provided with swiper
        swiperController.loopFix();
        swiperController.update();
      }
    }, 100);
  }, [featuredPlayers, swiperController]);

  return (
    <div className={'relative mx-auto'}>
      {/* <button className="z-10 absolute left-0 lg:left-4 translate-x-[50%] top-[43%] translate-y-[-50%] bg-transparent border-0 rotate-[180deg] left_arrow hidden md:block">
                <span className="duration-300 hover:opacity-70 w-8 h-8 rounded-full bg-lightGray flex justify-center items-center">
                    <SwiperArrow />
                </span>
            </button>
            <button className="z-10 absolute right-8 lg:right-12 translate-x-[50%] top-[43%] translate-y-[-50%] bg-transparent border-0 right_arrow hidden md:block">
                <span className="duration-300 hover:opacity-70 w-8 h-8 rounded-full bg-lightGray flex justify-center items-center">
                    <SwiperArrow />
                </span>
            </button> */}
      <Swiper
        className="relative compete_swiper pb-11"
        onSwiper={setSwiperController}
        modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
        navigation={{ nextEl: '.right_arrow', prevEl: '.left_arrow' }}
        speed={1000}
        loop={false}
        autoplay={false}
        pagination={{ clickable: true }}
        spaceBetween={24}
        slidesPerView={1}
      >
        <SwiperSlide>
          <img
            className="h-full w-full object-cover"
            src={heroImage1}
            alt="Example 1"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="h-full w-full object-cover"
            src={heroImage1}
            alt="Example 2"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Hero;
