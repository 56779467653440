import { useState, useEffect } from 'react';
// components
import Modal from '@ui/Modal';
import DropDown from '@ui/DropDown';
// icons
import { ImSpinner8 } from 'react-icons/im';
import { ApexCode } from '@src/firestore/apexCodes';

interface TeamResult {
  teamName: string;
  teamPlacement: number;
}

interface MatchData {
  match_start: number;
  team_results: TeamResult[];
}

interface MatchDataModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matchData: any;
  loadingMatchData: boolean;
  startDate: Date;
  handleGameComplete: (matchIndex: number) => void;
  handleGameEdit: (matchIndex: number) => void;
  currentCode: ApexCode;
  markingComplete: boolean;
  editingMatchData: boolean;
  isEditMode: boolean;
}

const formatMatchDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const now = new Date();
  const diffInMinutes = Math.floor(
    (now.getTime() - date.getTime()) / (1000 * 60)
  );
  // const timeString = date.toLocaleTimeString([], {
  //   hour: '2-digit',
  //   minute: '2-digit',
  // });
  // return `${diffInMinutes} minutes ago - ${timeString}`;
  return `${diffInMinutes} min ago`;
};

const MatchDataModal = ({
  open,
  setOpen,
  matchData,
  loadingMatchData,
  startDate,
  handleGameComplete,
  handleGameEdit,
  currentCode,
  markingComplete,
  isEditMode,
  editingMatchData,
}: MatchDataModalProps) => {
  const [selectedMatchIndex, setSelectedMatchIndex] = useState<number>(
    currentCode?.lastMatchIndex >= 0 ? currentCode.lastMatchIndex : 0
  );

  useEffect(() => {
    if (currentCode?.lastMatchIndex >= 0) {
      setSelectedMatchIndex(currentCode.lastMatchIndex);
    }
  }, [currentCode]);

  const matches = matchData?.data?.orderedMatchesAggregated || [];

  const getDropdownOptions = () => {
    const validMatches = matches.filter(
      (match: MatchData) => new Date(match.match_start * 1000) >= startDate
    );

    if (validMatches.length === 0) {
      return [
        {
          value: -1,
          label: 'No match data available',
        },
      ];
    }

    // Create dropdown options only for valid matches, but keep original indices
    return validMatches.map((match: MatchData) => {
      const matchIndex = matches.findIndex((m: MatchData) => m === match);
      const winner = match.team_results.find(
        (team) => team.teamPlacement === 1
      );

      return {
        value: matchIndex, // Preserves original match index
        label: `${formatMatchDate(match.match_start)} - Winner: ${winner?.teamName || 'Unknown'}`,
      };
    });
  };

  const getTopThreePlacement = () => {
    // Check if selectedMatchIndex is -1 (no valid matches) or if the match doesn't exist
    if (selectedMatchIndex === -1 || !matches[selectedMatchIndex]) return null;

    // Check if the selected match is before startDate
    const selectedMatch = matches[selectedMatchIndex];
    if (new Date(selectedMatch.match_start * 1000) < startDate) return null;

    const sortedTeams = [...matches[selectedMatchIndex].team_results]
      .sort((a, b) => a.teamPlacement - b.teamPlacement)
      .slice(0, 3);

    return (
      <div className="mt-4 flex flex-col gap-4">
        {sortedTeams.map((team, index) => (
          <div
            key={`team-${index}`}
            className="flex items-center gap-2 text-lg"
          >
            <span className="font-bold">{index + 1}# </span>
            <span className="text-white">{team.teamName}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Modal
      title={isEditMode ? 'Edit Game Data' : 'Complete Game'}
      open={open}
      setOpen={setOpen}
      buttonDisabled={
        markingComplete ||
        editingMatchData ||
        selectedMatchIndex === -1 ||
        !matches[selectedMatchIndex] ||
        new Date(matches[selectedMatchIndex]?.match_start * 1000) < startDate
      }
      buttonText={
        <div className="flex items-center gap-x-2">
          <span>{isEditMode ? 'Save Changes' : 'Mark Complete'}</span>
          {markingComplete ? <ImSpinner8 className="animate-spin" /> : ''}
        </div>
      }
      buttonOnClick={() => {
        isEditMode
          ? handleGameEdit(selectedMatchIndex)
          : handleGameComplete(selectedMatchIndex);
      }}
    >
      <div className="flex flex-col gap-y-4">
        {loadingMatchData ? (
          <div className="flex items-center justify-center">
            <ImSpinner8 className="animate-spin text-2xl" />
          </div>
        ) : matchData ? (
          <div className="text-white">
            {matches.length > 0 ? (
              <>
                <DropDown
                  currentOption={
                    getDropdownOptions().find(
                      (option: { value: number }) =>
                        option.value === selectedMatchIndex
                    ) || getDropdownOptions()[0]
                  }
                  options={getDropdownOptions()}
                  handleOnChange={(value) => setSelectedMatchIndex(value)}
                  label="Select Match"
                />
                {getTopThreePlacement()}
              </>
            ) : (
              <p className="text-center">No matches found</p>
            )}
          </div>
        ) : (
          <p className="text-center">No match data available</p>
        )}
      </div>
    </Modal>
  );
};

export default MatchDataModal;
