import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// firebase
import { firestore } from '@src/firebase';
import { doc, updateDoc } from 'firebase/firestore';
// context
import { useTeamContext } from '@components/team/TeamShowProvider';
import { useTeamInfoContext } from '@components/team/TeamInfoProvider';
import { useAuthContext } from '@provider/AuthContextProvider';
// types
import { TeamEditModalStep } from '@components/team/types';
import { DBUser } from '@src/firestore/users';
import { DBTeam, teamConverter } from '@src/firestore/teams';
// libraries
import { toast } from 'react-toastify';
// components
import CheckBox from '@ui/CheckBox';
import NoticeText from '@ui/NoticeText';
// assets
import imagePlaceholder from '@assets/images/placeholder/placeholder.webp';
// icons
import { MagnifyingGlassIcon, TeamIcon } from '@components/common/icons/Common';

// eslint-disable-next-line react-refresh/only-export-components
export const getPlayerTeamTitle = (playerUid: string, team: DBTeam) => {
  if (team) {
    if (playerUid === team.captain) {
      return 'Owner';
    } else if (playerUid === team.manager) {
      return 'Manager';
    } else if (team.players.includes(playerUid)) {
      return 'Player';
    }
  }
  return null;
};

interface IRemovePlayer {
  player: DBUser;
  removePlayer: (uid: string, name: string) => void;
}

const RemovePlayer: React.FC<IRemovePlayer> = ({ removePlayer, player }) => {
  const [confirm, setConfirm] = useState<boolean>(false);

  return (
    <div className="flex items-center gap-x-3">
      {confirm ? (
        <div className="flex flex-col sm:flex-row items-center gap-2">
          <div className="flex items-center gap-x-2">
            <button
              type="button"
              onClick={() => removePlayer(player.uid, player.displayName)}
              className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center  text-red/70 bg-red/20 hover:opacity-75 transition-opacity"
            >
              Confirm
            </button>
            <button
              type="button"
              onClick={() => setConfirm(false)}
              className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-white bg-ebonyClay hover:opacity-75 transition-opacity"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <button
          type="button"
          onClick={() => setConfirm(true)}
          className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-red/70 bg-red/20 hover:opacity-75 transition-opacity"
        >
          Remove Player
        </button>
      )}
    </div>
  );
};

const Members = () => {
  const { user } = useAuthContext();
  const { team, editor } = useTeamContext();
  const { setCurrentEditModalStep, cancelPlayerInvite, removePlayer } =
    useTeamInfoContext();

  const toggleLookingForPlayers = async () => {
    try {
      if (!team) throw new Error('No team found');

      const teamRef = doc(firestore, 'teams', team.id).withConverter(
        teamConverter
      );
      const updatePromise = updateDoc(teamRef, {
        lookingForPlayers: !team.lookingForPlayers,
      });

      toast.promise(updatePromise, {
        pending: 'Updating Looking For Players',
        success: 'Looking For Players updated',
        error: 'Error updating Looking for Players',
      });
      await updatePromise;
    } catch (err) {
      console.error(err);
      toast.error('Error updating Looking For Players');
    }
  };

  const [teamPlayers, setTeamPlayers] = useState<DBUser[]>([]);
  const [teamInvitedPlayers, setTeamInvitedPlayers] = useState<DBUser[]>([]);

  useEffect(() => {
    if (team) {
      const loadPlayersFromUids = async () => {
        const localTeamPlayers = (await team?.getPlayerObjs!()) as DBUser[];
        setTeamPlayers(localTeamPlayers);
      };

      const loadInvitedPlayersFromUids = async () => {
        const localTeamPlayers =
          (await team?.getPendingPlayerObjs!()) as DBUser[];
        setTeamInvitedPlayers(localTeamPlayers);
      };

      loadPlayersFromUids();
      loadInvitedPlayersFromUids();
    }
  }, [team]);

  return team ? (
    <>
      <div className="mt-3">
        <div className="flex flex-col sm:flex-row sm:items-center gap-2 my-3">
          <button
            type="button"
            onClick={toggleLookingForPlayers}
            className={`relative w-full sm:w-2/5 sm:flex-grow px-6 pt-4 pb-3 rounded-xl flex items-center justify-center gap-x-2
                              ${team.lookingForPlayers ? 'bg-lightGray hover:bg-ebonyClay' : 'bg-lightBlack hover:bg-lightGray'}
                              uppercase font-semibold !leading-4 font-compact  transition-colors`}
          >
            <span>Looking for Players?</span>
            <MagnifyingGlassIcon className="w-[12px] h-auto aspect-square stroke-green -translate-y-[1px]" />

            <CheckBox
              selected={team.lookingForPlayers}
              setSelected={(selected: boolean) => selected}
              asDiv={true}
              className="absolute top-1/2 right-5 -translate-y-1/2"
            />
          </button>
          <button
            type="button"
            onClick={() => setCurrentEditModalStep(TeamEditModalStep.invite)}
            className="w-full sm:w-2/5 sm:flex-grow bg-green hover:bg-gorse uppercase font-semibold text-black !leading-4 px-6
                            pt-4 pb-3 rounded-xl font-compact flex items-center justify-center gap-x-2 transition-colors"
          >
            <span>Invite players</span>
            <TeamIcon className="w-[12px] h-auto aspect-square fill-black -translate-y-[1px] scale-125" />
          </button>
        </div>

        {teamPlayers.map((player: DBUser, index) => (
          <div key={index}>
            <div className="bg-lightBlack p-4 rounded-xl mt-3 sm:mt-2">
              <div className={`flex justify-between items-center`}>
                <Link
                  to={`/profile/${player.uid}`}
                  className="flex items-center gap-x-4 hover:opacity-75 transition-opacity"
                >
                  <img
                    className="w-[3rem] max-w-none h-auto aspect-square rounded-full object-cover"
                    src={
                      player.displayImage
                        ? player.displayImage
                        : imagePlaceholder
                    }
                    alt="image"
                  />
                  <div className="flex flex-col items-start w-full gap-y-1">
                    <div className="flex items-center gap-x-2">
                      <h2 className="font-wide text-left font-bold text-white leading-4">
                        {player.displayName}
                      </h2>
                      <p
                        className={`font-compact font-semibold bg-ebonyClay px-2 py-[0.1rem] pb-[0.07rem] text-xs mb-[2px] rounded-full
                                      ${getPlayerTeamTitle(player.uid, team) === 'Owner' ? 'text-green' : 'text-slateGray'}`}
                      >
                        {getPlayerTeamTitle(player.uid, team)}
                      </p>
                    </div>
                    <h3 className="font-compact text-sm text-steelGray">
                      @{player.username}
                    </h3>
                  </div>
                </Link>
                <div className="flex items-center gap-x-4">
                  {user && editor && player.uid !== user.uid ? (
                    <RemovePlayer player={player} removePlayer={removePlayer} />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
        <h2 className="font-wide font-bold text-white !leading-5 uppercase my-6">
          Pending Invites
        </h2>
        {teamInvitedPlayers.length > 0 ? (
          <>
            {teamInvitedPlayers.map((player: DBUser, index) => (
              <div key={index}>
                <div className="bg-lightBlack p-4 rounded-xl mt-3 sm:mt-2">
                  <div className={`flex justify-between items-center`}>
                    <Link
                      to={`/profile/${player.uid}`}
                      className="flex items-center gap-x-4 hover:opacity-75 transition-opacity"
                    >
                      <img
                        className="w-[3rem] max-w-none h-auto aspect-square rounded-full object-cover"
                        src={
                          player.displayImage
                            ? player.displayImage
                            : imagePlaceholder
                        }
                        alt="image"
                      />
                      <div className="flex flex-col items-start w-full gap-y-1">
                        <div className="flex items-center gap-x-2">
                          <h2 className="font-wide text-left font-bold text-white leading-4">
                            {player.displayName}
                          </h2>
                        </div>
                        <h3 className="font-compact text-sm text-steelGray">
                          @{player.username}
                        </h3>
                      </div>
                    </Link>
                    <div className="flex items-center gap-x-4">
                      {user && editor ? (
                        <button
                          type="button"
                          onClick={() =>
                            cancelPlayerInvite(player.uid, player.displayName)
                          }
                          className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-white bg-ebonyClay hover:opacity-75 transition-opacity"
                        >
                          Cancel Invite
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <NoticeText>No pending invites</NoticeText>
        )}
      </div>
    </>
  ) : (
    ''
  );
};

export default Members;
