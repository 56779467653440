import { useEffect, useState } from 'react';
// utils
import { registerInterest } from '../../../utils/NewsEmail';
// packages
import { toast } from 'react-toastify';
// assets
import comingSoonImage from '../../../assets/images/webp/coming-soon/coming_soon_image.webp';
import comingSoonSmImage from '../../../assets/images/webp/coming-soon/coming_soon_sm_image.webp';
// icons
import { ImSpinner8 } from 'react-icons/im';
import { BlackArrow, InputCross } from '../icons/Common';

interface IComingSoon {
  title: string;
  className?: string;
}

const ComingSoon: React.FC<IComingSoon> = ({ title, className }) => {
  const [email, setEmail] = useState('');
  const [activeField, setActiveField] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const checkForOutsideInputClick: EventListener = (e: Event) => {
    if (e.target) {
      const target = e.target as HTMLElement;
      if (
        !target.classList.contains('emailInput') &&
        !target.closest('emailInput')
      ) {
        setActiveField('');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', checkForOutsideInputClick);

    return () =>
      document.removeEventListener('click', checkForOutsideInputClick);
  }, []);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const submitPromise = registerInterest(email, [title.toLowerCase()]);
      toast.promise(submitPromise, {
        pending: 'Registering interest',
        success: 'You will recieve an email when we release this feature!',
        error: 'Error registering interest',
      });
      await submitPromise;
      setEmail('');
      setSubmitting(false);
    } catch (err) {
      toast.error('Error registering interest');
    }
  };

  return (
    <>
      <div
        className={`
          ${className ? className : 'min-h-screen'}

          relative flex items-center justify-center overflow-x-hidden
        `}
      >
        <div
          className={`
            absolute top-52 w-full px-4

            lg:px-12

            sm:px-8
          `}
        >
          <div className="relative">
            <img
              className={`
                absolute start-1/2 top-1/2 hidden w-full max-w-[61.875rem]
                -translate-x-1/2 -translate-y-1/2

                md:block
              `}
              src={comingSoonImage}
              alt="comingSoonImage"
            />
            <img
              className={`
                absolute start-1/2 top-1/2 block h-[43.75rem] max-w-[56.25rem]
                -translate-x-1/2 -translate-y-1/2

                md:hidden
              `}
              src={comingSoonSmImage}
              alt="comingSoonSmImage"
            />
            <div
              className={`
                relative z-10 pt-8

                sm:pt-0
              `}
            >
              <p
                className={`
                  font-compact my-4 text-center text-base font-normal uppercase
                  !leading-4 !tracking-[0.2rem] text-green
                `}
              >
                {title}
              </p>
              <h2
                className={`
                  text-white font-wide text-center !text-5xl font-bold uppercase
                  !leading-[2.688rem]
                `}
              >
                coming soon!
              </h2>
              <form action="">
                <div
                  className={`
                    mt-8 flex flex-col items-center justify-center gap-3

                    md:flex-row

                    sm:mt-10
                  `}
                >
                  <div
                    id="stayUpdateInput"
                    className={`
                      relative w-full

                      lg:max-w-[30.188rem]

                      sm:max-w-[25rem]
                    `}
                  >
                    <input
                      className={`
                        px-4 emailInput w-full rounded-xl pb-[0.625rem]
                        pt-[0.875rem] text-sm text-white outline-none
                        font-compact bg-lightBlack

                        md:w-full

                        placeholder:text-steelGray

                        sm:w-[25rem]

                        ${
                          activeField === 'email'
                            ? `
                              border-green border-2 pr-10

                              placeholder:text-white
                            `
                            : 'border-2 border-transparent'
                        }
                      `}
                      onClick={() => setActiveField('email')}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="email"
                      placeholder="Your e-mail"
                      required
                      autoComplete="off"
                    />
                    {activeField === 'email' && (
                      <span
                        onClick={() => {
                          setActiveField('');
                          setEmail(''); // Clear the input value
                        }}
                        className={`
                          absolute end-4 top-1/2 -translate-y-1/2 cursor-pointer
                        `}
                      >
                        <InputCross />
                      </span>
                    )}
                  </div>
                  <button
                    type="button"
                    disabled={submitting}
                    onClick={handleSubmit}
                    className={`
                      font-compact flex w-full items-center justify-center gap-2
                      px-6 py-4 text-base font-medium uppercase !leading-4
                      common_green_btn transition-all duration-300 ease-out

                      md:w-auto

                      sm:w-[25rem]

                      ${submitting ? 'opacity-50' : ''}
                    `}
                  >
                    <span className="-mb-[0.188rem] text">Notify Me</span>
                    <span>
                      {submitting ? (
                        <ImSpinner8 className="animate-spin" />
                      ) : (
                        <BlackArrow />
                      )}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
