import { ReactElement, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// context
import TournamentProvider from './TournamentProvider';
import LeaderboardProvider from './LeaderboardProvider';
import TournamentAdminProvider from './AdminSettings/TournamentAdminProvider';
// types
import { Tournament } from '@src/firestore/tournaments';
import { TournamentSubViews } from '@components/tournament/types';
// components
import Hero from './Hero/Hero';
import SubViewBar from './SubViewBar';
import { SkeletonDark } from '../common/skeleton-loader/Skeletons';
// subview components
// ---- Overview
import Status from './Overview/Status';
import Summary from './Overview/Summary';
import Rules from './Overview/Rules';
// ----Entrants
import Entrants from './Entrants/Entrants';
// ---- PrizePool
import PrizePool from './PrizePool/PrizePool';
// ---- Leaderboards/Results
import Leaderboards from './Leaderboards/Leaderboards';
// ---- Maps
import Maps from './Maps/Maps';
// ---- Stats
import Stats from './Stats/Stats';
// ---- Admin Settings
import AdminSettings from './AdminSettings/AdminSettings';
// icons
import { LuUnlink } from 'react-icons/lu';
import ALGSModeMapProvider from './ALGSModeMapProvider';

const TournamentView = (): ReactElement => {
  const location = useLocation();

  const [currentSubView, setCurrentSubView] = useState<TournamentSubViews>(
    TournamentSubViews.overview
  );

  const [, setTournament] = useState<Tournament | null>(null);
  const [tournamentLoaded, setTournamentLoaded] = useState<boolean>(false);
  const [tournamentFound, setTournamentFound] = useState<boolean>(false);

  useEffect(() => {
    const pathName = location.pathname;
    const resultsPattern = /\/results$/;
    if (resultsPattern.test(pathName)) {
      setCurrentSubView(TournamentSubViews.leaderboards);
    }
  }, [location.pathname]);

  return (
    <div
      className={`
        custom_container pb-10

        md:pb-14
      `}
    >
      <TournamentProvider
        announceTournament={setTournament}
        setTournamentLoaded={setTournamentLoaded}
        setTournamentFound={setTournamentFound}
      >
        <TournamentAdminProvider>
          <LeaderboardProvider>
            <ALGSModeMapProvider>
              {tournamentLoaded ? (
                <>
                  {tournamentFound ? (
                    <>
                      <Hero setCurrentSubView={setCurrentSubView} />
                      <SubViewBar
                        currentSubView={currentSubView}
                        setCurrentSubView={setCurrentSubView}
                      />
                      {/* Subviews */}
                      <div className="relative h-fit w-full">
                        <div
                          className={`
                            ${
                              currentSubView === TournamentSubViews.overview
                                ? ''
                                : 'hidden'
                            }
                          `}
                        >
                          <Status />
                          <Summary />
                          <Rules />
                        </div>

                        <div
                          className={`
                            ${
                              currentSubView === TournamentSubViews.prizePool
                                ? ''
                                : 'hidden'
                            }
                          `}
                        >
                          <PrizePool />
                        </div>

                        <div
                          className={`
                            ${
                              currentSubView === TournamentSubViews.entrants
                                ? ''
                                : 'hidden'
                            }
                          `}
                        >
                          <Entrants />
                        </div>

                        <div
                          className={`
                            ${
                              currentSubView === TournamentSubViews.leaderboards
                                ? ''
                                : 'hidden'
                            }
                          `}
                        >
                          <Leaderboards />
                        </div>

                        <div
                          className={`
                            ${
                              currentSubView === TournamentSubViews.maps
                                ? ''
                                : 'hidden'
                            }
                          `}
                        >
                          <Maps />
                        </div>

                        <div
                          className={`
                            ${
                              currentSubView === TournamentSubViews.stats
                                ? ''
                                : 'hidden'
                            }
                          `}
                        >
                          <Stats />
                        </div>

                        <div
                          className={`
                            ${
                              currentSubView ===
                              TournamentSubViews.adminSettings
                                ? ''
                                : 'hidden'
                            }
                          `}
                        >
                          <AdminSettings />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="relative min-h-[80vh] w-full">
                      <h3
                        className={`
                          absolute left-1/2 top-1/2 flex -translate-x-1/2
                          -translate-y-1/2 flex-col items-center gap-x-4 text-xl
                          font-semibold text-green

                          sm:flex-row sm:text-4xl
                        `}
                      >
                        <p>Tournament not found</p>
                        <LuUnlink />
                      </h3>
                    </div>
                  )}
                </>
              ) : (
                <div
                  className={`
                    custom_container mt-[80px] pb-10

                    md:pb-14
                  `}
                >
                  <div className="flex w-full items-center justify-between">
                    <SkeletonDark className="h-[40px] w-[75px] rounded-lg" />
                    <SkeletonDark className="h-[20px] w-[100px] rounded-lg" />
                  </div>
                  <div className="mt-5 flex justify-between">
                    <SkeletonDark className="h-[300px] w-[33%] rounded-lg" />
                    <SkeletonDark className="h-[300px] w-[63%] rounded-lg" />
                  </div>
                  <div className="mt-[50px] flex gap-x-5">
                    <SkeletonDark className="h-[25px] w-[85px] rounded-lg" />
                    <SkeletonDark className="h-[25px] w-[85px] rounded-lg" />
                    <SkeletonDark className="h-[25px] w-[85px] rounded-lg" />
                    <SkeletonDark className="h-[25px] w-[85px] rounded-lg" />
                    <SkeletonDark className="h-[25px] w-[85px] rounded-lg" />
                  </div>
                  <div className="mt-7">
                    <SkeletonDark className="h-[25px] w-[150px] rounded-lg" />
                    <div className="mt-3 flex w-full justify-between">
                      <SkeletonDark className="h-[100px] w-1/5 rounded-lg" />
                      <SkeletonDark className="h-[100px] w-1/5 rounded-lg" />
                      <SkeletonDark className="h-[100px] w-1/5 rounded-lg" />
                      <SkeletonDark className="h-[100px] w-1/5 rounded-lg" />
                    </div>
                  </div>
                  <div className="mt-[50px]">
                    <SkeletonDark className="h-[25px] w-[150px] rounded-lg" />
                    <div className="mt-3 flex w-full justify-between">
                      <SkeletonDark className="h-[100px] w-1/5 rounded-lg" />
                      <SkeletonDark className="h-[100px] w-1/5 rounded-lg" />
                      <SkeletonDark className="h-[100px] w-1/5 rounded-lg" />
                      <SkeletonDark className="h-[100px] w-1/5 rounded-lg" />
                    </div>
                  </div>
                  <div>
                    <SkeletonDark className="mt-2 h-[20px] w-1/6 rounded-lg" />
                    <SkeletonDark className="mt-2 h-[20px] w-2/6 rounded-lg" />
                    <SkeletonDark className="mt-2 h-[20px] w-3/6 rounded-lg" />
                    <SkeletonDark className="mt-2 h-[20px] w-4/6 rounded-lg" />
                    <SkeletonDark className="mt-2 h-[20px] w-1/6 rounded-lg" />
                    <SkeletonDark className="mt-2 h-[20px] w-2/6 rounded-lg" />
                    <SkeletonDark className="mt-2 h-[20px] w-3/6 rounded-lg" />
                    <SkeletonDark className="mt-2 h-[20px] w-3/6 rounded-lg" />
                  </div>
                </div>
              )}
            </ALGSModeMapProvider>
          </LeaderboardProvider>
        </TournamentAdminProvider>
      </TournamentProvider>
    </div>
  );
};

export default TournamentView;
