import { useState, useEffect, useRef, ChangeEvent } from 'react';
// context
import { useTournamentContext } from '@components/tournament/TournamentProvider';
import { useTournamentAdminContext } from '../../../TournamentAdminProvider';
// types
import { Region, tournamentRegionToString } from '@src/firestore/tournaments';
// components
import Input from '@ui/Input';
import ButtonPrimary from '@ui/ButtonPrimary';
// assets
import placeholderImage from '@assets/images/placeholder/placeholder.webp';
// icons
import { ImSpinner8 } from 'react-icons/im';
import DropDown, { DropDownOption } from '@ui/DropDown';
import { toast } from 'react-toastify';
import { FaXmark } from 'react-icons/fa6';
import NumericalInput from '@ui/NumericalInput';
import { FaLock } from 'react-icons/fa';
import PopoverText from '@ui/PopoverText';

const tournamentRegionOptions: DropDownOption[] = [
  {
    value: Region.EMEA,
    label: tournamentRegionToString(Region.EMEA),
  },
  {
    value: Region.NA,
    label: tournamentRegionToString(Region.NA),
  },
  {
    value: Region.LATAM,
    label: tournamentRegionToString(Region.LATAM),
  },
  {
    value: Region.APAC,
    label: tournamentRegionToString(Region.APAC),
  },
  {
    value: Region.GLOBAL,
    label: tournamentRegionToString(Region.GLOBAL),
  },
];

const tournamentGameOptions: DropDownOption[] = [
  {
    value: 0,
    label: 'Apex Legends',
  },
  {
    value: 1,
    label: 'CS2',
  },
];

const tournamentBrandOptions: DropDownOption[] = [
  {
    value: 0,
    label: 'none',
  },
  {
    value: 1,
    label: 'Surfshark',
  },
];

const tournamentGameModeOptionsByGame: { [key: string]: DropDownOption[] } = {
  'Apex Legends': [
    {
      value: 0,
      label: 'Battle Royale: Trios',
    },
  ],
  CS2: [
    {
      value: 0,
      label: 'Defuse',
    },
    {
      value: 1,
      label: 'Hostage',
    },
  ],
};

const tournamentFormatOptionsByGame: { [key: string]: DropDownOption[] } = {
  'Apex Legends': [
    {
      value: 0,
      label: 'Series',
    },
    {
      value: 1,
      label: 'Match Point',
    },
  ],
  CS2: [
    {
      value: 0,
      label: 'Single Match',
    },
    {
      value: 1,
      label: 'BO3',
    },
    {
      value: 2,
      label: 'BO5',
    },
  ],
};

interface ITournamentEditPage {
  checkInStarted: boolean;
}

const EventDetails: React.FC<ITournamentEditPage> = ({ checkInStarted }) => {
  const cardImageInputRef = useRef<HTMLInputElement | null>(null);
  const bannerInputRef = useRef<HTMLInputElement | null>(null);

  const { tournament } = useTournamentContext();
  const { tournamentEditInfo, setTournamentEditInfo, saveTournamentEditInfo } =
    useTournamentAdminContext();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [stepValid, setStepValid] = useState<boolean>(false);

  const handleCardImageOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (tournamentEditInfo) {
      const files = e.target.files;
      if (files) {
        const cardImage = files[0];

        if (
          !['image/jpeg', 'image/png', 'image/webp'].includes(cardImage.type)
        ) {
          toast.error(`You can only upload jpeg, png or webp image formats`);
        } else {
          const cardUrl = URL.createObjectURL(cardImage);
          setTournamentEditInfo({
            ...tournamentEditInfo,
            cardImageFile: cardImage,
            cardImagePreviewUrl: cardUrl,
          });
        }
      } else {
        setTournamentEditInfo({
          ...tournamentEditInfo,
          cardImageFile: null,
          cardImagePreviewUrl: '',
        });
      }
    }
  };

  const handleBannerOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (tournamentEditInfo) {
      const files = e.target.files;
      if (files) {
        const bannerImage = files[0];

        if (
          !['image/jpeg', 'image/png', 'image/webp'].includes(bannerImage.type)
        ) {
          toast.error(`You can only upload jpeg, png or webp image formats`);
        } else {
          const bannerUrl = URL.createObjectURL(bannerImage);
          setTournamentEditInfo({
            ...tournamentEditInfo,
            bannerImageFile: bannerImage,
            bannerImagePreviewUrl: bannerUrl,
          });
        }
      } else {
        setTournamentEditInfo({
          ...tournamentEditInfo,
          bannerImageFile: null,
          bannerImagePreviewUrl: '',
        });
      }
    }
  };

  const removeCardImageAttachement = () => {
    if (tournamentEditInfo) {
      setTournamentEditInfo({
        ...tournamentEditInfo,
        cardImageFile: null,
        cardImagePreviewUrl: '',
      });
    }
  };

  const removeBannerAttachement = () => {
    if (tournamentEditInfo) {
      setTournamentEditInfo({
        ...tournamentEditInfo,
        bannerImageFile: null,
        bannerImagePreviewUrl: '',
      });
    }
  };

  const handleSaveChanges = async () => {
    setSubmitting(true);
    await saveTournamentEditInfo();
    setSubmitting(false);
  };

  useEffect(() => {
    if (tournament && tournamentEditInfo) {
      if (
        tournament.name !== tournamentEditInfo.name ||
        tournament.game !== tournamentEditInfo.game ||
        tournament.region !== tournamentEditInfo.region ||
        tournament.cardImage !== tournamentEditInfo.cardImagePreviewUrl ||
        tournament.bannerImage !== tournamentEditInfo.bannerImagePreviewUrl ||
        tournament.entryFee !== tournamentEditInfo.entryFee ||
        tournament.brand !== tournamentEditInfo.brand
      ) {
        setChangesMade(true);
      } else {
        setChangesMade(false);
      }
    } else {
      setChangesMade(false);
    }
  }, [tournament, tournamentEditInfo]);

  useEffect(() => {
    if (
      tournamentEditInfo &&
      tournamentEditInfo.cardImagePreviewUrl &&
      tournamentEditInfo.bannerImagePreviewUrl
    ) {
      setStepValid(true);
    } else {
      setStepValid(false);
    }
  }, [tournamentEditInfo]);

  return tournament && tournamentEditInfo ? (
    <div className="text-white font-compact flex flex-col gap-y-4">
      <div className="flex flex-col gap-y-2">
        <p>Event Name</p>
        <Input
          value={tournamentEditInfo.name}
          onChange={(newValue) =>
            setTournamentEditInfo({
              ...tournamentEditInfo,
              name: newValue,
            })
          }
        />
      </div>

      <div className="flex flex-col gap-y-2">
        <p>Game</p>
        <DropDown
          currentOption={
            tournamentGameOptions.find(
              (option) => option.value === tournamentEditInfo.game
            ) ?? tournamentGameOptions[0]
          }
          options={tournamentGameOptions}
          handleOnChange={(newValue) => {
            const newGameValue = tournamentGameOptions.find(
              (option) => option.value === newValue
            )!.value;
            const newGameModeValue =
              tournamentGameModeOptionsByGame[newGameValue][0].label;
            setTournamentEditInfo({
              ...tournamentEditInfo,
              game: newGameValue,
              gameMode: newGameModeValue,
              format:
                tournamentFormatOptionsByGame[tournamentEditInfo.game][0].label,
            });
          }}
          locked={true}
        />
      </div>

      <div className="w-full flex flex-col sm:flex-row gap-x-2 gap-y-2 sm:items-center">
        <div className="w-full sm:w-1/2 flex flex-col gap-y-2">
          <p>Region</p>
          <DropDown
            currentOption={
              tournamentRegionOptions.find(
                (option) => option.value === tournamentEditInfo.region
              )!
            }
            options={tournamentRegionOptions}
            handleOnChange={(newValue) => {
              setTournamentEditInfo({
                ...tournamentEditInfo,
                region: newValue,
              });
            }}
          />
        </div>

        <div className="w-full sm:w-1/2 flex flex-col gap-y-2">
          <p>Brand</p>
          <DropDown
            currentOption={
              tournamentBrandOptions.find(
                (option) => option.label === tournamentEditInfo.brand
              ) ?? tournamentBrandOptions[0]
            }
            options={tournamentBrandOptions}
            optionClassName={
              tournamentEditInfo.brand === '' ? '!text-steelGray' : ''
            }
            handleOnChange={(newValue) => {
              setTournamentEditInfo({
                ...tournamentEditInfo,
                brand:
                  newValue === 0
                    ? ''
                    : tournamentBrandOptions.find(
                        (option) => option.value === newValue
                      )!.label,
              });
            }}
          />
        </div>
      </div>

      <div className="flex flex-col gap-y-2 text-start items-start">
        <p>
          Entry Fee{' '}
          <em className="not-italic text-steelGray">(Versus Tokens)</em>
        </p>
        <div className="flex items-center gap-x-3">
          <NumericalInput
            value={tournamentEditInfo.entryFee}
            interval={1}
            minValue={0}
            onChange={(newValue) => {
              setTournamentEditInfo({
                ...tournamentEditInfo,
                entryFee: newValue,
              });
            }}
          />
          {tournamentEditInfo.entryFee === 0 ? (
            <p className="text-green text-sm font-compact translate-y-[2px]">
              (Free)
            </p>
          ) : (
            ''
          )}
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:items-center h-fit md:h-[175px] gap-4">
        <div className="w-full h-fit md:h-full md:w-auto max-w-[35%] md:aspect-[1.89] flex flex-col gap-y-2">
          <p>Event Card</p>
          <div className="relative w-full h-[30%] flex-grow-[1]">
            {tournamentEditInfo.cardImagePreviewUrl ||
            tournamentEditInfo.cardImage ? (
              <button
                type="button"
                aria-label="remove banner attachement"
                onClick={removeCardImageAttachement}
                className="absolute flex items-center justify-center z-[2] top-2 right-2 w-[25px] h-auto aspect-square rounded-full bg-lightGray text-red/70
                                hover:opacity-75 transition-opacity "
              >
                <FaXmark />
              </button>
            ) : (
              ''
            )}
            <button
              type="button"
              aria-label="change banner attachement"
              disabled={tournamentEditInfo.cardImagePreviewUrl !== ''}
              onClick={() => {
                if (cardImageInputRef.current) {
                  cardImageInputRef.current.click();
                }
              }}
              className="w-full h-full rounded-lg overflow-hidden relative
                            border border-lightGray"
            >
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .webp"
                ref={cardImageInputRef}
                onChange={handleCardImageOnChange}
                className="hidden"
              />
              <img
                src={
                  tournamentEditInfo.cardImagePreviewUrl
                    ? tournamentEditInfo.cardImagePreviewUrl
                    : placeholderImage
                }
                className="w-full h-full object-cover"
              />
            </button>
          </div>
        </div>

        <div className="w-full h-fit md:h-full md:w-auto md:aspect-[2.85] max-w-[60%] flex flex-col gap-y-2 md:flex-shrink-1">
          <p>Event Banner</p>
          <div className="relative w-full h-[30%] flex-grow-[1]">
            {tournamentEditInfo.bannerImagePreviewUrl ||
            tournamentEditInfo.bannerImage ? (
              <button
                type="button"
                aria-label="remove banner attachement"
                onClick={removeBannerAttachement}
                className="absolute flex items-center justify-center z-[2] top-2 right-2 w-[25px] h-auto aspect-square rounded-full bg-lightGray text-red/70
                                hover:opacity-75 transition-opacity "
              >
                <FaXmark />
              </button>
            ) : (
              ''
            )}
            <button
              type="button"
              aria-label="change banner attachement"
              disabled={tournamentEditInfo.bannerImagePreviewUrl !== ''}
              onClick={() => {
                if (bannerInputRef.current) {
                  bannerInputRef.current.click();
                }
              }}
              className="w-full h-full rounded-lg overflow-hidden relative
                            border border-lightGray"
            >
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .webp"
                ref={bannerInputRef}
                onChange={handleBannerOnChange}
                className="hidden"
              />
              <img
                src={
                  tournamentEditInfo.bannerImagePreviewUrl
                    ? tournamentEditInfo.bannerImagePreviewUrl
                    : placeholderImage
                }
                className="w-full h-full object-cover"
              />
            </button>
          </div>
        </div>
      </div>

      <div className="relative group w-full h-fit mt-4">
        {checkInStarted ? (
          <PopoverText
            visible={true}
            right={true}
            className="opacity-0 group-hover:opacity-100 transition-opacity"
          >
            Unable to affect changes after check-in has started
          </PopoverText>
        ) : (
          ''
        )}
        <ButtonPrimary
          disabled={checkInStarted || submitting || !changesMade || !stepValid}
          onClick={handleSaveChanges}
        >
          <span>Save Changes</span>
          {checkInStarted ? <FaLock /> : submitting ? <ImSpinner8 /> : ''}
        </ButtonPrimary>
      </div>
    </div>
  ) : (
    ''
  );
};

export default EventDetails;
