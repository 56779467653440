// toast and custom toast close button (style var overrides in index.css)
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import toast css for custom style overrides
import '../../css/toast.css';
// icons
import { IoCloseSharp } from 'react-icons/io5';
import { FaInfo, FaCheck, FaExclamation } from 'react-icons/fa';
import { ImSpinner8 } from 'react-icons/im';

interface ICustomToastCloseButton {
  type: string;
  closeToast: (e: React.MouseEvent<HTMLElement>) => void;
}

const customToastCloseButton: React.FC<ICustomToastCloseButton> = ({
  type,
  closeToast,
}) => {
  return (
    <button
      type="button"
      aria-label="remove banner attachment"
      onClick={closeToast}
      className={`
        relative z-50 h-fit p-1

        ${type === 'error' ? 'text-red/70' : 'text-steelGray'}

        ursor-pointer self-center rounded-full

        hover:opacity-75
      `}
    >
      <IoCloseSharp className="text-3xl" />
    </button>
  );
};

const SuccessIcon = () => {
  return (
    <div className="bg-ebonyClay rounded-full p-2">
      <FaCheck className="text-xl text-white" />
    </div>
  );
};

const PendingIcon = () => {
  return (
    <div className="bg-ebonyClay rounded-full p-2">
      <ImSpinner8 className="animate-spin text-xl text-white" />
    </div>
  );
};

const InfoIcon = () => {
  return (
    <div className="bg-ebonyClay rounded-full p-2">
      <FaInfo className="text-xl text-white" />
    </div>
  );
};

const ErrorIcon = () => {
  return (
    <div className="bg-ebonyClay rounded-full p-2">
      <FaExclamation className="text-red/70 text-xl" />
    </div>
  );
};

interface ICustomToastContainer {
  announcementVisible: boolean;
}

const CustomToastContainer: React.FC<ICustomToastContainer> = ({
  announcementVisible,
}) => {
  interface IProcessIcon {
    type: string;
  }

  const processIcon = ({ type }: IProcessIcon) => {
    switch (type) {
      case 'success':
        return <SuccessIcon />;
      case 'info':
        return <InfoIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'default':
        return <PendingIcon />;
      default:
        return '';
    }
  };

  return (
    <ToastContainer
      icon={processIcon}
      className={`
        ${announcementVisible ? 'mt-[80px]' : 'mt-[50px]'}
      `}
      autoClose={5000}
      toastClassName="custom-toast"
      closeButton={customToastCloseButton}
    />
  );
};

export default CustomToastContainer;
