import { useEffect, useRef, useState } from 'react';
// firebase
import { firestore } from '../../../../firebase';
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { functions } from '../../../../firebase';
import { httpsCallable } from 'firebase/functions';
// context
import { useTournamentContext } from '../../TournamentProvider';
// types
import {
  TournamentGame,
  TournamentGroup,
  TournamentStatus,
} from '../../../../firestore/tournaments';
import { ApexCode, apexCodeConverter } from '../../../../firestore/apexCodes';
// libaries
import { toast } from 'react-toastify';
// icons
import { FaCheck, FaPen } from 'react-icons/fa';
import { IoCloseSharp } from 'react-icons/io5';
import { ImSpinner8 } from 'react-icons/im';
import { FiAlertTriangle } from 'react-icons/fi';
import Modal from '@ui/Modal';
import { formatDateCountdownLong } from '@utils/Date';
import { MdOutlineContentCopy } from 'react-icons/md';
import MatchDataModal from './MatchDataModal';

interface IGameCodes {
  game: TournamentGame;
  group: TournamentGroup;
  gamesInGroup: TournamentGame[];
}

const sendGroupGamePlayerCodes = httpsCallable(
  functions,
  'sendGroupGamePlayerCodes'
);
const auditApexCode = httpsCallable(functions, 'auditApexCode');
const returnOrderedMatchesAggregated = httpsCallable(
  functions,
  'returnOrderedMatchesAggregated'
);
const processResults = httpsCallable(functions, 'processResults');
const processEditResults = httpsCallable(functions, 'processEditResults');

const GameCodes: React.FC<IGameCodes> = ({ game, group, gamesInGroup }) => {
  const { tournament } = useTournamentContext();
  const stageLocked =
    tournament && tournament.lockedStages.includes(group.stage);
  const activeGameInGroup = game.gamePosition === group.activeGame;
  const actionsDisabled =
    (tournament && tournament.status < TournamentStatus.ongoing) ||
    !activeGameInGroup ||
    !stageLocked;

  const failedAttempt = game.failedAttempt ?? null;

  const [failedAttemptStep1Open, setFailedAttemptStep1Open] =
    useState<boolean>(false);
  const [failedAttemptStep2Open, setFailedAttemptStep2Open] =
    useState<boolean>(false);

  const [gettingNewCode, setGettingNewCode] = useState<boolean>(false);
  const [keepingSameCode, setKeepingSameCode] = useState<boolean>(false);
  const [markingFailedAttempt, setMarkingFailedAttempt] =
    useState<boolean>(false);

  const [currentTime, setCurrentTime] = useState<number>(0);

  const [lastAdminCodeCopyTime, setLastAdminCodeCopyTime] = useState<number>(0);

  const getCurrentTime = () => {
    setCurrentTime(new Date().getTime());
  };

  useEffect(() => {
    const interval = setInterval(getCurrentTime, 1000);

    return () => clearInterval(interval);
  }, []);

  const [newCodeOpen, setNewCodeOpen] = useState<boolean>(false);

  const [markingComplete, setMarkingComplete] = useState<boolean>(false);
  const [editingMatchData, setEditingMatchData] = useState<boolean>(false);
  const gameCompleted = game.completed;

  const adminCodeInputRef = useRef(null);
  const playerCodeInputRef = useRef(null);
  const statsTokenInputRef = useRef(null);

  const [adminCodeEdit, setAdminCodeEdit] = useState<boolean>(false);
  const [playerCodeEdit, setPlayerCodeEdit] = useState<boolean>(false);
  const [statsTokenEdit, setStatsTokenEdit] = useState<boolean>(false);

  const [adminCodeValid, setAdminCodeValid] = useState<boolean>(true);
  const [playerCodeValid, setPlayerCodeValid] = useState<boolean>(true);
  const [statsTokenValid, setStatsTokenValid] = useState<boolean>(true);

  const [matchDataModalOpen, setMatchDataModalOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [matchData, setMatchData] = useState<any>(null);
  const [loadingMatchData, setLoadingMatchData] = useState<boolean>(false);

  const [currentCode, setCurrentCode] = useState<ApexCode>({} as ApexCode);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  useEffect(() => {
    const gameIndex = gamesInGroup.indexOf(game);
    if (
      gameIndex > 0 &&
      activeGameInGroup &&
      gamesInGroup[gameIndex - 1].playerCode === game.playerCode &&
      !game.playerCodesDistributed
    ) {
      handleDistributePlayerCodes({ notice: false });
    }
  }, []);

  const validateAdminCode = (adminCode: string) => {
    if (adminCode) {
      setAdminCodeValid(/^a[a-z0-9]{7}$/.test(adminCode));
    } else {
      setAdminCodeValid(true);
    }
  };

  const validatePlayerCode = (playerCode: string) => {
    if (playerCode) {
      setPlayerCodeValid(/^p[a-z0-9]{7}$/.test(playerCode));
    } else {
      setPlayerCodeValid(true);
    }
  };

  const validateStatsToken = (statsToken: string) => {
    if (statsToken) {
      setStatsTokenValid(/^[a-z0-9]{7,8}-[a-z0-9]{22}$/.test(statsToken));
    } else {
      setStatsTokenValid(true);
    }
  };

  const handleAdminCodeEdit = () => {
    setAdminCodeEdit(!adminCodeEdit);
    if (!adminCodeEdit) {
      setTimeout(() => {
        if (adminCodeInputRef.current) {
          (adminCodeInputRef.current as HTMLInputElement).focus();
          (adminCodeInputRef.current as HTMLInputElement).value =
            game.adminCode;
        }
      }, 50);
    }
  };

  const handleSaveAdminCode = async () => {
    if (tournament && adminCodeInputRef.current) {
      const newValue = (adminCodeInputRef.current as HTMLInputElement).value;
      const groupGameRef = doc(
        firestore,
        'tournaments',
        tournament.id,
        'groups',
        group.id,
        'games',
        game.id
      );
      const groupGameUpdatePromise = updateDoc(groupGameRef, {
        adminCode: newValue,
      });
      toast.promise(groupGameUpdatePromise, {
        pending: 'Updating admin code',
        success: 'Admin code updated',
        error: 'Error updating admin code',
      });
      await groupGameUpdatePromise;
      setAdminCodeEdit(false);
    }
  };

  const handleFailedAttempt = async () => {
    setMarkingFailedAttempt(true);

    const codeRef = doc(firestore, 'apexCodes', game.codeId).withConverter(
      apexCodeConverter
    );
    const code = (await getDoc(codeRef)).data();
    if (!code) throw new Error('code for game not found');
    if (!tournament) throw new Error('tournament not found');

    const gameRef = doc(
      firestore,
      'tournaments',
      tournament.id,
      'groups',
      group.id,
      'games',
      game.id
    );
    const gamePromise = updateDoc(gameRef, {
      failedAttempt: Timestamp.now(),
    });

    const codePromise = updateDoc(codeRef, {
      lastMatchIndex: code.lastMatchIndex + 1,
    });

    const combinedPromise = Promise.all([gamePromise, codePromise]);

    toast.promise(combinedPromise, {
      pending: 'Marking game attempt failed',
      success: 'Game Attempt marked as failed',
      error: 'Error marking game attempt failed',
    });

    await combinedPromise;
    setFailedAttemptStep1Open(false);
    setFailedAttemptStep2Open(true);
    setMarkingFailedAttempt(false);
  };

  const handleUseSameCode = async () => {
    setKeepingSameCode(true);
    try {
      if (!tournament) throw new Error('tournament not found');

      const gameRef = doc(
        firestore,
        'tournaments',
        tournament.id,
        'groups',
        group.id,
        'games',
        game.id
      );
      const gamePromise = updateDoc(gameRef, {
        failedAttempt: null,
        playerCodesDistributed: true,
      });

      const combinedPromise = Promise.all([gamePromise]);

      toast.promise(combinedPromise, {
        pending: 'Keeping same code',
        success: 'Kept same code',
        error: 'Error keeping same code',
      });
      await combinedPromise;
      setFailedAttemptStep2Open(false);
    } catch (err) {
      toast.error('Error keeping same code');
      console.error(err);
    }
    setKeepingSameCode(false);
  };

  const handleUseNewCode = async () => {
    setGettingNewCode(true);
    try {
      if (!tournament) throw new Error('tournament not found');

      const prevCodeRef = doc(firestore, 'apexCodes', game.codeId);

      const currentTime = Timestamp.fromDate(new Date());
      const timePlus2Hours = Timestamp.fromDate(
        new Date(new Date().getTime() + 7_200_000)
      );

      const newCodesCollection = collection(
        firestore,
        'apexCodes'
      ).withConverter(apexCodeConverter);
      const newCodeSnapshot = await getDocs(
        query(
          newCodesCollection,
          where('inUse', '==', false),
          where('used', '==', false),
          where('highPerformance', '==', false),
          where('activation', '<=', currentTime),
          where('expiration', '>=', timePlus2Hours),
          limit(1)
        )
      );

      let gamesUpdatePromise: Promise<void | void[]> = Promise.resolve();
      let newCodeUpdatePromise: Promise<void> = Promise.resolve();

      const newCode =
        newCodeSnapshot.docs.length > 0
          ? (newCodeSnapshot.docs[0].data() as ApexCode)
          : null;

      if (newCode) {
        const newCodeRef = doc(firestore, 'apexCodes', newCode.id);
        newCodeUpdatePromise = updateDoc(newCodeRef, {
          inUse: true,
        });
      } else {
        toast.error('No more apex codes left to use!');
      }

      const gameInGroupCollection = collection(
        firestore,
        'tournaments',
        tournament.id,
        'groups',
        group.id,
        'games'
      );
      const gamesInGroup = (await getDocs(gameInGroupCollection)).docs.map(
        (doc) => doc.data()
      ) as TournamentGame[];
      const upcomingGames = gamesInGroup.filter((game) => !game.completed);

      const updatePromises = upcomingGames.map((game) => {
        const gameRef = doc(
          firestore,
          'tournaments',
          tournament.id,
          'groups',
          group.id,
          'games',
          game.id
        );
        return updateDoc(gameRef, {
          codeId: newCode ? newCode.id : '',
          adminCode: newCode ? newCode.adminToken : '',
          playerCode: newCode ? newCode.playerToken : '',
          statsToken: newCode ? newCode.statsToken : '',
          playerCodesDistributed: false,
          failedAttempt: null,
        });
      });

      gamesUpdatePromise = Promise.all(updatePromises);

      const previousCodePromise = await updateDoc(prevCodeRef, {
        inUse: false,
      });

      const combinedPromise = Promise.all([
        gamesUpdatePromise,
        previousCodePromise,
        newCodeUpdatePromise,
      ]);

      toast.promise(combinedPromise, {
        pending: 'Getting new code',
        success: `${
          newCode
            ? `New code assigned to this game${upcomingGames.length > 1 ? ` and the next ${upcomingGames.length - 1}` : ''}`
            : 'No new codes available'
        }`,
        error: 'Error getting new code',
      });

      await combinedPromise;

      setFailedAttemptStep2Open(false);
    } catch (err) {
      console.error(err);
    }
    setGettingNewCode(false);
  };

  const handleCopyAdminCode = () => {
    toast.info('Admin code copied to clipboard');
    navigator.clipboard.writeText(game.adminCode);
    setLastAdminCodeCopyTime(new Date().getTime());
  };

  const handleDistributePlayerCodes = async (options: { notice: boolean }) => {
    const { notice } = options;

    if (!tournament) return;

    let tournamentPromise = Promise.resolve();

    if (!tournament.stagesInPlay.includes(group.stage)) {
      const tournamentRef = doc(firestore, 'tournaments', tournament.id);
      tournamentPromise = updateDoc(tournamentRef, {
        stagesInPlay: arrayUnion(group.stage),
      });
    }

    const codesPromise = sendGroupGamePlayerCodes({
      tournamentId: tournament.id,
      groupName: group.groupName,
      groupId: group.id,
      gameId: game.id,
      gameName: game.gameName,
      playerCode: game.playerCode,
    });

    const groupGameRef = doc(
      firestore,
      'tournaments',
      tournament.id,
      'groups',
      group.id,
      'games',
      game.id
    );
    const groupPromise = updateDoc(groupGameRef, {
      playerCodesDistributed: true,
    });

    const combinedPromise = Promise.all([
      codesPromise,
      groupPromise,
      tournamentPromise,
    ]);

    if (notice) {
      toast.promise(combinedPromise, {
        pending: 'Distributing player codes',
        success: 'Player codes distributed',
        error: 'Error distributing player codes',
      });
    }

    await combinedPromise;
  };

  const handleMatchDataReturn = async (editMode: boolean = false) => {
    if (tournament) {
      try {
        setIsEditMode(editMode);
        setLoadingMatchData(true);

        interface TokenAuditResp {
          data: {
            status: number;
            gameDataReady: boolean;
          };
        }

        const tokenAudit = (await auditApexCode({
          codeId: game.codeId,
        })) as TokenAuditResp;

        const auditStatus = tokenAudit.data.status;
        const gameDataReady = tokenAudit.data.gameDataReady;

        if (auditStatus !== 200) {
          toast.error(
            `Stats token appears to be invalid: status ${auditStatus}`
          );
          setMarkingComplete(false);
          return;
        } else if (!gameDataReady) {
          toast.error('Game server has not yet finished processing results');
          setMarkingComplete(false);
          return;
        }

        const codeRef = doc(firestore, 'apexCodes', game.codeId).withConverter(
          apexCodeConverter
        );
        const code = (await getDoc(codeRef)).data();
        if (!code) throw new Error('Code not found');
        setCurrentCode(code);

        const matchData = await returnOrderedMatchesAggregated({
          codeId: game.codeId,
          tournamentId: tournament.id,
          groupId: group.id,
          gameId: game.id,
        });
        setMatchData(matchData);
        setMatchDataModalOpen(true);
      } catch (error) {
        console.error('Error fetching match data:', error);
        toast.error('Failed to fetch match data');
      } finally {
        setLoadingMatchData(false);
      }
    }
  };

  const handleGameComplete = async (matchIndex: number) => {
    if (tournament && matchData) {
      setMarkingComplete(true);

      try {
        const processResultsPromise = processResults({
          codeId: game.codeId,
          tournamentId: tournament.id,
          groupId: group.id,
          gameId: game.id,
          matchData: matchData,
          currentMatchIndex: matchIndex,
          activeGame: game.gamePosition,
        });

        toast.promise(processResultsPromise, {
          pending: 'Processing match results...',
          success: 'Match results processed successfully',
          error: 'Error processing match results',
        });

        await processResultsPromise;
        setMatchDataModalOpen(false);
      } catch (err) {
        console.error('Error processing results:', err);
        toast.error('Failed to process match results');
      }

      setMarkingComplete(false);
    }
  };

  const handleGameEdit = async (matchIndex: number) => {
    if (tournament && matchData) {
      setEditingMatchData(true);

      try {
        const processResultsPromise = processEditResults({
          codeId: game.codeId,
          tournamentId: tournament.id,
          groupId: group.id,
          gameId: game.id,
          matchData: matchData,
          selectedMatchIndex: matchIndex,
          activeGame: game.gamePosition,
        });

        toast.promise(processResultsPromise, {
          pending: 'Processing match results...',
          success: 'Match results processed successfully',
          error: 'Error processing match results',
        });

        await processResultsPromise;
        setMatchDataModalOpen(false);
      } catch (err) {
        console.error('Error processing results:', err);
        toast.error('Failed to process match results');
      }

      setEditingMatchData(false);
    }
  };

  const handleSavePlayerCode = async () => {
    if (tournament && playerCodeInputRef.current) {
      const newValue = (playerCodeInputRef.current as HTMLInputElement).value;
      const groupGameRef = doc(
        firestore,
        'tournaments',
        tournament.id,
        'groups',
        group.id,
        'games',
        game.id
      );
      const groupGameUpdatePromise = updateDoc(groupGameRef, {
        playerCode: newValue,
      });
      toast.promise(groupGameUpdatePromise, {
        pending: 'Updating player code',
        success: 'Player code updated',
        error: 'Error updating player code',
      });
      await groupGameUpdatePromise;
      setPlayerCodeEdit(false);
    }
  };

  const handleSaveStatsToken = async () => {
    if (tournament && statsTokenInputRef.current) {
      const newValue = (statsTokenInputRef.current as HTMLInputElement).value;
      const groupGameRef = doc(
        firestore,
        'tournaments',
        tournament.id,
        'groups',
        group.id,
        'games',
        game.id
      );
      const groupGameUpdatePromise = updateDoc(groupGameRef, {
        statsToken: newValue,
      });
      toast.promise(groupGameUpdatePromise, {
        pending: 'Updating stats token',
        success: 'Stats token updated',
        error: 'Error updating stats token',
      });
      await groupGameUpdatePromise;
      setStatsTokenEdit(false);
    }
  };

  const handlePlayerCodeEdit = () => {
    setPlayerCodeEdit(!playerCodeEdit);
    if (!adminCodeEdit) {
      setTimeout(() => {
        if (playerCodeInputRef.current) {
          (playerCodeInputRef.current as HTMLInputElement).focus();
          (playerCodeInputRef.current as HTMLInputElement).value =
            game.playerCode;
        }
      }, 50);
    }
  };

  const handleStatsTokenEdit = () => {
    setStatsTokenEdit(!statsTokenEdit);
    if (!statsTokenEdit) {
      setTimeout(() => {
        if (statsTokenInputRef.current) {
          (statsTokenInputRef.current as HTMLInputElement).focus();
          (statsTokenInputRef.current as HTMLInputElement).value =
            game.statsToken;
        }
      }, 50);
    }
  };

  const renderMatchDataModal = () =>
    tournament && (
      <MatchDataModal
        open={matchDataModalOpen}
        setOpen={setMatchDataModalOpen}
        matchData={matchData}
        loadingMatchData={loadingMatchData}
        startDate={tournament.statusDates.ongoing}
        handleGameComplete={handleGameComplete}
        handleGameEdit={handleGameEdit}
        currentCode={currentCode}
        markingComplete={markingComplete}
        isEditMode={isEditMode}
        editingMatchData={editingMatchData}
      />
    );

  return (
    <>
      <div
        className={'mt-6 flex w-full flex-col gap-y-2 font-compact font-medium'}
      >
        {!stageLocked ? (
          <p className="text-red/70 flex items-center gap-x-2">
            <FiAlertTriangle className="mb-1 text-xl" />
            <span>
              Groups in this stage must be locked before games can commence
            </span>
          </p>
        ) : (
          ''
        )}
        <div className="flex w-full flex-col gap-y-2">
          <div className="flex h-[45px] items-center gap-x-2 text-white">
            <div
              className={`
                relative flex h-full w-1/2 bg-lightGray flex-col justify-start
                rounded-xl border-2 py-2 pl-4

                ${
                  adminCodeEdit
                    ? `
                      ${adminCodeValid ? 'border-green' : 'border-red'}
                    `
                    : 'border-green/0'
                }
              `}
            >
              <p
                className={`
                  ${
                    adminCodeEdit
                      ? `
                        absolute left-3 top-0 -translate-y-1/2 bg-lightGray p-1
                        px-2
                      `
                      : 'bg-lightGray/0'
                  }

                  rounded text-sm transition-all
                `}
              >
                Admin Code
              </p>
              {adminCodeEdit ? (
                <input
                  type="text"
                  onChange={(e) => validateAdminCode(e.target.value)}
                  ref={adminCodeInputRef}
                  className={`
                    h-full w-full border-none bg-transparent p-2 pl-1 text-white
                    font-compact opacity-0 outline-none

                    ${adminCodeEdit ? 'opacity-100' : ''}
                  `}
                />
              ) : (
                <p className="text-steelGray text-sm">{game.adminCode}</p>
              )}
            </div>
            <div className="h-full w-1/4">
              {adminCodeEdit ? (
                <div className="flex h-full w-full items-center">
                  <button
                    type="button"
                    disabled={!adminCodeValid}
                    onClick={handleSaveAdminCode}
                    className={`
                      h-full w-3/4 bg-green rounded-l-xl font-bold uppercase
                      text-black transition-all

                      disabled:opacity-50 disabled:hover:bg-green

                      hover:bg-gorse
                    `}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => setAdminCodeEdit(false)}
                    className={`
                      flex h-full w-1/4 items-center justify-center bg-red/50
                      rounded-r-xl font-bold uppercase text-white

                      hover:bg-red/70
                    `}
                  >
                    <IoCloseSharp className="text-3xl" />
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  disabled={gameCompleted}
                  onClick={handleAdminCodeEdit}
                  className={`
                    flex h-full w-full items-center justify-center gap-x-3
                    bg-lightGray rounded-xl text-sm uppercase transition-opacity

                    disabled:opacity-50

                    hover:opacity-75

                    sm:text-base
                  `}
                >
                  <span>Edit Code</span>
                  <FaPen
                    className={`
                      mb-1 hidden text-sm text-green

                      sm:block
                    `}
                  />
                </button>
              )}
            </div>
            <button
              type="button"
              disabled={gameCompleted || actionsDisabled}
              onClick={handleCopyAdminCode}
              className={`
                h-full w-1/4 bg-lightGray flex items-center justify-center
                gap-x-2 rounded-xl text-sm uppercase transition-opacity

                disabled:opacity-50

                hover:opacity-75

                sm:text-base
              `}
            >
              <span>
                {lastAdminCodeCopyTime === 0 ||
                currentTime - lastAdminCodeCopyTime > 3_000
                  ? 'Copy'
                  : 'Copied'}
              </span>
              {lastAdminCodeCopyTime === 0 ||
              currentTime - lastAdminCodeCopyTime > 3_000 ? (
                <MdOutlineContentCopy />
              ) : (
                <FaCheck className="mb-1 text-sm" />
              )}
            </button>
          </div>

          <div
            className={`
              flex h-[45px] items-center gap-x-2 text-white transition-all
            `}
          >
            <div
              className={`
                relative flex h-full w-1/2 bg-lightGray flex-col justify-start
                rounded-xl border-2 py-2 pl-4

                ${
                  playerCodeEdit
                    ? `
                      ${playerCodeValid ? 'border-green' : 'border-red'}
                    `
                    : 'border-green/0'
                }
              `}
            >
              <p
                className={`
                  ${
                    playerCodeEdit
                      ? `
                        absolute left-3 top-0 -translate-y-1/2 bg-lightGray p-1
                        px-2
                      `
                      : 'bg-lightGray/0'
                  }

                  rounded text-sm transition-all
                `}
              >
                Player Code
              </p>
              {playerCodeEdit ? (
                <input
                  type="text"
                  onChange={(e) => validatePlayerCode(e.target.value)}
                  ref={playerCodeInputRef}
                  className={`
                    h-full w-full border-none bg-transparent p-2 pl-1 text-white
                    font-compact outline-none
                  `}
                />
              ) : (
                <p className="text-steelGray text-sm">{game.playerCode}</p>
              )}
            </div>
            <div className="h-full w-1/4">
              {playerCodeEdit ? (
                <div className="flex h-full w-full items-center">
                  <button
                    type="button"
                    disabled={!playerCodeValid}
                    onClick={handleSavePlayerCode}
                    className={`
                      h-full w-3/4 bg-green rounded-l-xl font-bold uppercase
                      text-black

                      disabled:opacity-50 disabled:hover:bg-green

                      hover:bg-gorse
                    `}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => setPlayerCodeEdit(false)}
                    className={`
                      flex h-full w-1/4 items-center justify-center bg-red/50
                      rounded-r-xl font-bold uppercase text-white

                      hover:bg-red/70
                    `}
                  >
                    <IoCloseSharp className="text-3xl" />
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  disabled={gameCompleted}
                  onClick={handlePlayerCodeEdit}
                  className={`
                    flex h-full w-full items-center justify-center gap-x-3
                    bg-lightGray rounded-xl text-sm uppercase transition-opacity

                    disabled:opacity-50

                    hover:opacity-75

                    sm:text-base
                  `}
                >
                  <span>Edit Code</span>
                  <FaPen
                    className={`
                      mb-1 hidden text-sm text-green

                      sm:block
                    `}
                  />
                </button>
              )}
            </div>
            <button
              type="button"
              disabled={
                !playerCodeValid ||
                game.playerCode === '' ||
                gameCompleted ||
                actionsDisabled ||
                game.playerCodesDistributed
              }
              onClick={() => handleDistributePlayerCodes({ notice: true })}
              className={`
                flex h-full w-1/4 items-center justify-center gap-x-2
                bg-lightGray rounded-xl text-sm uppercase transition-opacity

                disabled:opacity-50

                hover:opacity-75

                sm:text-base
              `}
            >
              <span>
                {game.playerCodesDistributed ? 'Distributed' : 'Distribute'}
              </span>
              {game.playerCodesDistributed ? (
                <FaCheck className="mb-1 text-sm" />
              ) : (
                ''
              )}
            </button>
          </div>

          <div className="flex h-[45px] items-center gap-x-2 text-white">
            <div
              className={`
                relative flex h-full w-1/2 bg-lightGray flex-col justify-start
                rounded-xl border-2 py-2 pl-4

                ${
                  statsTokenEdit
                    ? `
                      ${statsTokenValid ? 'border-green' : 'border-red'}
                    `
                    : 'border-green/0'
                }
              `}
            >
              <p
                className={`
                  ${
                    statsTokenEdit
                      ? `
                        absolute left-3 top-0 -translate-y-1/2 bg-lightGray p-1
                        px-2
                      `
                      : 'bg-lightGray/0'
                  }

                  rounded text-sm transition-all
                `}
              >
                Stats Token
              </p>
              {statsTokenEdit ? (
                <input
                  type="text"
                  onChange={(e) => validateStatsToken(e.target.value)}
                  ref={statsTokenInputRef}
                  className={`
                    h-full w-full border-none bg-transparent p-2 pl-1 text-white
                    font-compact outline-none
                  `}
                />
              ) : (
                <p className="text-steelGray text-sm">{game.statsToken}</p>
              )}
            </div>
            <div className="h-full w-1/4">
              {statsTokenEdit ? (
                <div className="flex h-full w-full items-center">
                  <button
                    type="button"
                    disabled={!statsTokenValid}
                    onClick={handleSaveStatsToken}
                    className={`
                      h-full w-3/4 bg-green rounded-l-xl font-bold uppercase
                      text-black

                      disabled:opacity-50 disabled:hover:bg-green

                      hover:bg-gorse
                    `}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => setStatsTokenEdit(false)}
                    className={`
                      flex h-full w-1/4 items-center justify-center bg-red/50
                      rounded-r-xl font-bold uppercase text-white

                      hover:bg-red/70
                    `}
                  >
                    <IoCloseSharp className="text-3xl" />
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  disabled={gameCompleted}
                  onClick={handleStatsTokenEdit}
                  className={`
                    flex h-full w-full items-center justify-center gap-x-3
                    bg-lightGray rounded-xl text-sm uppercase transition-opacity

                    disabled:opacity-50

                    hover:opacity-75

                    sm:text-base
                  `}
                >
                  <span>Edit Code</span>
                  <FaPen
                    className={`
                      mb-1 hidden text-sm text-green

                      sm:block
                    `}
                  />
                </button>
              )}
            </div>
            <button
              type="button"
              disabled={gameCompleted || actionsDisabled}
              className={`
                h-full w-1/4 bg-lightGray rounded-xl text-sm uppercase
                transition-opacity

                disabled:opacity-50

                hover:opacity-75

                sm:text-base
              `}
            >
              Refresh
            </button>
          </div>
        </div>
        <div className="flex w-full flex-col gap-y-2">
          <button
            type="button"
            disabled={
              !game.playerCodesDistributed || gameCompleted || actionsDisabled
            }
            onClick={() => {
              if (failedAttempt) {
                setFailedAttemptStep2Open(true);
              } else {
                setFailedAttemptStep1Open(true);
              }
            }}
            className={`
              flex w-full items-center justify-center gap-x-2 rounded-xl p-3
              px-3 bg-red/60 font-semibold uppercase text-white transition-all

              disabled:opacity-50 disabled:hover:bg-red/60

              hover:bg-red/70
            `}
          >
            <span>
              {failedAttempt
                ? 'Game Attempt Failed - Choose Option'
                : 'Game Attempt Failed'}
            </span>
          </button>
          <button
            type="button"
            disabled={
              !game.playerCodesDistributed ||
              failedAttempt !== null ||
              gameCompleted ||
              actionsDisabled ||
              markingComplete ||
              loadingMatchData
            }
            onClick={() => handleMatchDataReturn(false)}
            className={`
              flex w-full items-center justify-center gap-x-2 rounded-xl p-3
              px-3 bg-green font-semibold uppercase text-black transition-all

              disabled:opacity-50 disabled:hover:bg-green

              hover:bg-gorse
            `}
          >
            <span>
              {gameCompleted
                ? `${game.gameName} Completed`
                : `Mark ${game.gameName} complete`}
            </span>
            {loadingMatchData ? (
              <ImSpinner8 className="mb-1 animate-spin text-xl" />
            ) : (
              ''
            )}
          </button>

          {gameCompleted && (
            <button
              type="button"
              disabled={!gameCompleted || markingComplete || loadingMatchData}
              onClick={() => handleMatchDataReturn(true)}
              className={`
                flex w-full items-center justify-center gap-x-2 rounded-xl p-3
                px-3 bg-lightGray font-semibold uppercase text-white
                transition-all

                disabled:opacity-50 disabled:hover:bg-lightGray

                hover:bg-ebonyClay
              `}
            >
              <span>Edit Game Data for {game.gameName}</span>
              {loadingMatchData ? (
                <ImSpinner8 className="mb-1 animate-spin text-xl" />
              ) : (
                ''
              )}
            </button>
          )}
        </div>
      </div>
      <Modal
        title="Game Attempt Failed"
        open={failedAttemptStep1Open}
        setOpen={setFailedAttemptStep1Open}
        buttonDisabled={markingFailedAttempt}
        buttonNegative={true}
        buttonText={
          <div className="flex items-center gap-x-2">
            <span>Mark Game Attempt Failed</span>
            {markingFailedAttempt ? (
              <ImSpinner8 className="animate-spin" />
            ) : (
              ''
            )}
          </div>
        }
        buttonOnClick={() => {
          handleFailedAttempt();
        }}
      >
        <p className="text-red/70 font-compact my-2 font-medium">
          Are you sure you want to mark this game as failed?
        </p>
      </Modal>
      <Modal
        title="Failed Game Attempt"
        open={failedAttemptStep2Open}
        setOpen={setFailedAttemptStep2Open}
        buttonDisabled={true}
        buttonNegative={true}
      >
        <p className="text-steelGray font-compact my-2 font-medium">
          You marked your last game attempt as failed, how do you want to
          proceed?
        </p>
        <div className="flex flex-col gap-y-4">
          <button
            type="button"
            disabled={keepingSameCode}
            onClick={handleUseSameCode}
            className={`
              flex w-full items-center justify-center gap-x-2 rounded-xl p-3
              px-3 bg-lightGray font-semibold uppercase text-white
              transition-all

              disabled:opacity-50 disabled:hover:bg-lightGray

              hover:bg-ebonyClay
            `}
          >
            <span>Keep same code</span>
            {keepingSameCode ? (
              <ImSpinner8 className="animate-spin text-xl" />
            ) : (
              ''
            )}
          </button>
          <div>
            {failedAttempt !== null &&
            failedAttempt.seconds * 1000 >= currentTime - 600_000 ? (
              <p className="mb-1">
                You have{' '}
                <em className="not-italic text-white">
                  {formatDateCountdownLong(
                    new Date(failedAttempt.seconds * 1000 + 600_000)
                  )}
                </em>{' '}
                to do this
              </p>
            ) : (
              ''
            )}
            <button
              type="button"
              disabled={
                failedAttempt === null ||
                failedAttempt.seconds * 1000 < currentTime - 600_000 ||
                gettingNewCode
              }
              onClick={handleUseNewCode}
              className={`
                flex w-full items-center justify-center gap-x-2 rounded-xl p-3
                px-3 bg-red/60 font-semibold uppercase text-white transition-all

                disabled:opacity-50 disabled:hover:bg-red/60

                hover:bg-red/70
              `}
            >
              <span>Use new code</span>
              {gettingNewCode ? (
                <ImSpinner8 className="animate-spin text-xl" />
              ) : (
                ''
              )}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        title="Use new code"
        open={newCodeOpen}
        setOpen={setNewCodeOpen}
        buttonNegative={true}
        buttonText="Use new code"
        buttonOnClick={() => {
          setNewCodeOpen(false);
          handleUseNewCode();
        }}
      >
        <p className="text-steelGray font-compact font-medium">
          Are you sure you want to use a new code?
        </p>
      </Modal>

      {renderMatchDataModal()}
    </>
  );
};
export default GameCodes;
