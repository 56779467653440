import { useState } from 'react';
import SearchSortFilterBarGames from '../global/SearchSortFilterBarGames';

import Hero from './components/Hero';
import FindTeam from '../common/FindTeam';
import GameSelect from './components/GameSelect';

const GamesSelectPage = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [category, setCategory] = useState<number>(1);
  const [sortBy, setSortBy] = useState<number>(2);

  const handleSearch = () => {
    console.log('Searching for:', searchQuery);
    console.log('Category:', category);
    console.log('Sort By:', sortBy);
  };

  return (
    <div className={'custom_container px-16'}>
      <Hero />
      <SearchSortFilterBarGames
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        category={category}
        setCategory={setCategory}
        sortBy={sortBy}
        setSortBy={setSortBy}
        onSearch={handleSearch}
      />
      <GameSelect />
      <FindTeam />
    </div>
  );
};
export default GamesSelectPage;
