// types
import { DropDownOption } from '@ui/DropDown';
import {
  apexGameModesAsDropDownOptions,
  apexFormatsAsDropDownOptions,
} from './ApexLegends';

export enum Game {
  apex,
  valorant,
  fortnite,
  rocketLeague,
}

// yes this is a mess but due to how tournaments handles some things these workaround have to be in place until tournaments are refactored.
export const gameToString = (game: Game) => {
  switch (game) {
    case Game.apex:
      return 'Apex Legends';
    case Game.valorant:
      return 'Valorant';
    case Game.fortnite:
      return 'Fortnite';
    case Game.rocketLeague:
      return 'Rocket League';
    default:
      return '';
  }
};

export const gameToLink = (game: Game) => {
  switch (game) {
    case Game.apex:
      return 'apex';
    case Game.valorant:
      return 'valorant';
    case Game.fortnite:
      return 'fortnite';
    case Game.rocketLeague:
      return 'rocket-league';
  }
};
export const linkToGame = (link: string): Game => {
  switch (link) {
    case 'apex':
      return Game.apex;
    case 'valorant':
      return Game.valorant;
    case 'fortnite':
      return Game.fortnite;
    case 'rocket-league':
      return Game.rocketLeague;
    default:
      throw new Error(`Invalid game link: ${link}`);
  }
};

export const gameStringToID = (game: string) => {
  // Not the best way but works for now
  switch (game) {
    case 'Apex Legends':
      return 0;
    case 'Valorant':
      return 1;
    case 'Fortnite':
      return 2;
    case 'Rocket League':
      return 3;
  }
};

const gameEnumValues = Object.values(Game).filter(
  (value) => typeof value === 'number'
);

export const gameTitles = gameEnumValues.map((gameEnumValue) =>
  gameToString(gameEnumValue)
);

export const gamesAsDropDownOptions: DropDownOption[] = gameEnumValues.map(
  (gameEnum) => ({
    value: gameEnum,
    label: gameToString(gameEnum),
  })
);

export const gameModeOptionsByGame = (game: Game): DropDownOption[] => {
  switch (game) {
    case Game.apex:
      return apexGameModesAsDropDownOptions;
    default:
      return [];
  }
};

export const gameFormatOptionsByGame = (game: Game): DropDownOption[] => {
  switch (game) {
    case Game.apex:
      return apexFormatsAsDropDownOptions;
    default:
      return [];
  }
};
