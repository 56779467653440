import {
  GoBackIcon,
  NextRouteIcon,
} from '@src/components/common/icons/TournamentIcons';
import { Link, useParams } from 'react-router-dom';
import SubNav from '@components/ui/SubNav';
import { useState } from 'react';
import Tournaments from '@src/components/tournaments/Tournaments';
import Teams from '@src/components/teams/Teams';
import Players from '@src/components/players/Players';
import { linkToGame, gameToString } from '@src/types/Games/Games';
import heroImage1 from '@src/assets/images/png/games/hero-apex-extended.webp';
import Leaderboards from '@src/components/leaderboards/Leaderboards';
import ComingSoon from '@src/components/common/coming-soon/ComingSoon';
// // Dummy components for the tabs (replace with actual implementations)
// const Overview = () => <div>Overview Content</div>;
// const Creators = () => <div>Creators Content</div>;

const IndividualGamesPage = () => {
  const { game_id = '' } = useParams<{ game_id: string }>();
  const [activeTab, setActiveTab] = useState<string>('Tournaments');

  // Tabs array now includes both the title and the component
  const tabs = [
    { title: 'Overview', component: <ComingSoon title={'Overview'} /> },
    { title: 'Tournaments', component: <Tournaments /> },
    { title: 'Spaces', component: <ComingSoon title={'Spaces'} /> },
    { title: 'Teams', component: <Teams /> },
    { title: 'Players', component: <Players /> },
    { title: 'Leaderboards', component: <Leaderboards /> },
    {
      title: 'Premium Stats',
      component: <ComingSoon title={'Premium Stats'} />,
    },
  ];

  // Find the active tab's component
  const activeTabComponent = tabs.find(
    (tab) => tab.title === activeTab
  )?.component;

  return (
    <div className={'custom_container overflow-hidden px-16'}>
      <div className={'relative mx-auto'}>
        <div className={'flex items-center justify-between pb-8'}>
          <Link
            to="/games"
            className={`
              font-compact group flex items-center gap-2 rounded-xl border py-3
              pe-4 ps-3 text-base font-medium !leading-4 !text-white
              border-lightGray

              hover:opacity-80
            `}
          >
            <span
              className={`
                duration-300

                group-hover:translate-x-[-0.313rem]
              `}
            >
              <GoBackIcon />
            </span>
            <span className="-mb-0.5"> Back</span>
          </Link>
          <div className="flex items-center gap-4">
            <Link
              to="/games"
              className={`
                font-compact -mb-0.5 text-sm font-normal !leading-4
                text-ebonyClay duration-200

                hover:text-slateGray
              `}
            >
              Games
            </Link>
            <span>
              <NextRouteIcon />
            </span>
            <span
              className={`
                font-compact -mb-0.5 text-sm font-normal !leading-4
                text-slateGray
              `}
            >
              {gameToString(linkToGame(game_id))}
            </span>
          </div>
        </div>
      </div>
      <div className="">
        <img
          className="h-full w-full object-cover"
          src={heroImage1}
          alt="hero-image"
        />
      </div>
      <SubNav activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
      <div className="">{activeTabComponent}</div>{' '}
      {/* Render active tab's component */}
    </div>
  );
};

export default IndividualGamesPage;
