import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
// context
import { useHeaderContext } from '@provider/HeaderProvider';
// icons
import {
  CogIcon,
  MegaphoneIcon,
  VersusIcon,
  UserIcon,
  BellIcon,
  ChevronRight,
} from '@icons/Common';
import {
  TwitchIcon,
  YouTubeIcon,
  TwitterIcon,
  DiscordIcon,
} from '@icons/Socials';
import { TrophyIcon } from '@icons/TournamentIcons';
import { useNotificationContext } from '@provider/NotificationProvider';
import { useAuthContext } from '@provider/AuthContextProvider';
import { useProfileInfoContext } from '@components/completeProfile/ProfileInfoProvider';

interface ISocialLink {
  link: string;
  icon: ReactNode;
  handleClick: () => void;
  className?: string;
}

const SocialLink: React.FC<ISocialLink> = ({
  link,
  icon,
  className,
  handleClick,
}) => {
  return (
    <Link
      to={link}
      onClick={handleClick}
      target="_blank"
      className={`
        profile_dropdown_navigation_social_links

        ${className}
      `}
    >
      {icon}
    </Link>
  );
};

interface ISocialIcons {
  handleClick: () => void;
  className?: string;
}

export const SocialIcons: React.FC<ISocialIcons> = ({
  handleClick,
  className,
}) => {
  const { setNavbarState, navbarState } = useHeaderContext();

  const handleLinkClick = () => {
    setNavbarState({
      ...navbarState,
      profileMenuVisible: false,
      notificationMenuVisible: false,
    });
    handleClick();
  };

  return (
    <div
      className={`
        flex flex-col gap-y-2

        ${className}
      `}
    >
      <div className="mt-[20px] flex items-center justify-center gap-4">
        <SocialLink
          link={'https://www.twitch.tv/versus_gg_'}
          icon={
            <TwitchIcon
              className={`
                aspect-square h-auto w-[16px] fill-steelGray transition-colors

                hover:fill-green
              `}
            />
          }
          handleClick={handleClick}
        />
        <SocialLink
          link={'https://youtube.com/@versus_gg'}
          icon={
            <YouTubeIcon
              className={`
                aspect-square h-auto w-[16px] fill-steelGray transition-colors

                hover:fill-green
              `}
            />
          }
          handleClick={handleClick}
        />
        <SocialLink
          link={'https://twitter.com/gg_versus'}
          icon={
            <TwitterIcon
              className={`
                aspect-square h-auto w-[16px] fill-steelGray transition-colors

                hover:fill-green
              `}
            />
          }
          handleClick={handleClick}
        />
        <SocialLink
          link={'https://discord.com/invite/versus-gg'}
          icon={
            <DiscordIcon
              className={`
                aspect-square h-auto w-[16px] fill-steelGray transition-colors

                hover:fill-green
              `}
            />
          }
          handleClick={handleClick}
        />
      </div>
      <div
        className={`
          mt-2 flex w-fit items-center gap-x-4 self-center text-steelGray
          font-compact text-sm font-medium

          md:hidden
        `}
      >
        <Link to="/terms" onClick={handleLinkClick}>
          Terms of use
        </Link>
        <div className="h-[15px] w-[1px] bg-lightGray"></div>
        <Link to="/privacy-policy" onClick={handleLinkClick}>
          Privacy Policy
        </Link>
      </div>
    </div>
  );
};

interface IMenuLink {
  title: string;
  link: string;
  icon?: ReactNode;
  className?: string;
  handleClick: () => void;
  noArrow?: boolean;
}

const MenuLink: React.FC<IMenuLink> = ({
  title,
  link,
  icon,
  className,
  handleClick,
  noArrow,
}) => {
  return (
    <Link
      to={link}
      className={`
        my-2 flex items-center justify-between py-2 transition-opacity

        hover:opacity-75

        ${className}
      `}
      onClick={handleClick}
    >
      <div className="flex items-center gap-2">
        {icon}
        <span
          className={`
            font-compact text-steelGray text-base font-normal leading-4
          `}
        >
          {title}
        </span>
      </div>
      {!noArrow ? (
        <ChevronRight className="aspect-square h-auto w-[12px] fill-steelGray" />
      ) : (
        ''
      )}
    </Link>
  );
};

interface IMenuLinks {
  handleClick: () => void;
}

const MenuLinks: React.FC<IMenuLinks> = ({ handleClick }) => {
  const { userObj } = useAuthContext();
  const { notificationsCount, activeTournamentsCount } =
    useNotificationContext();
  const { setNavbarState, navbarState } = useHeaderContext();

  const { openEditProfile } = useProfileInfoContext();

  return (
    <div
      className={`
        ${userObj ? 'mt-6' : ''}

        custom_lg:mb-6 custom_lg:pb-0
      `}
    >
      {userObj ? (
        <>
          <button
            type="button"
            className={`
              my-2 flex w-full items-center justify-between py-2
              transition-opacity

              hover:opacity-75

              md:hidden
            `}
            onClick={() => {
              setNavbarState({
                ...navbarState,
                notificationMenuVisible: true,
              });
            }}
          >
            <div className="flex items-center gap-2">
              <BellIcon
                className={`
                  aspect-square h-auto w-[15px]

                  ${notificationsCount > 0 ? 'fill-green' : 'fill-steelGray'}
                `}
              />
              <span
                className={`
                  font-compact text-base font-normal leading-4

                  ${notificationsCount > 0 ? 'text-green' : 'text-steelGray'}
                `}
              >
                Notifications
              </span>
            </div>
            <div className="flex items-center gap-x-2">
              <p
                className={`
                  ${notificationsCount > 0 ? 'flex' : 'hidden'}

                  aspect-square h-[16px] w-[16px] items-center rounded
                  font-compact justify-center font-semibold text-black bg-green
                `}
              >
                <span className="mt-1">{notificationsCount}</span>
              </p>
              <ChevronRight
                className={`
                  aspect-square h-auto w-[12px]

                  ${notificationsCount > 0 ? 'fill-green' : 'fill-steelGray'}
                `}
              />
            </div>
          </button>
          <button
            type="button"
            className={`
              my-2 flex w-full items-center justify-between py-2
              transition-opacity

              hover:opacity-75
            `}
            onClick={() => {
              setNavbarState({
                ...navbarState,
                activeTournamentsVisible: true,
              });
            }}
          >
            <div className="flex items-center gap-2">
              <TrophyIcon
                className={`
                  aspect-square h-auto w-[15px]

                  ${activeTournamentsCount > 0 ? 'fill-green' : 'fill-steelGray'}
                `}
              />
              <span
                className={`
                  font-compact text-base font-normal leading-4

                  ${activeTournamentsCount > 0 ? 'text-green' : 'text-steelGray'}
                `}
              >
                My Tournaments
              </span>
            </div>
            <div className="flex items-center gap-x-2">
              <p
                className={`
                  ${activeTournamentsCount > 0 ? 'flex' : 'hidden'}

                  aspect-square h-[16px] w-[16px] items-center rounded
                  font-compact justify-center font-semibold text-black bg-green
                `}
              >
                <span className="mt-1">{activeTournamentsCount}</span>
              </p>
              <ChevronRight
                className={`
                  aspect-square h-auto w-[12px]

                  ${activeTournamentsCount > 0 ? 'fill-green' : 'fill-steelGray'}
                `}
              />
            </div>
          </button>
        </>
      ) : (
        ''
      )}
      <div className="md:hidden">
        <MenuLink
          title="Home"
          link="/"
          icon={
            <VersusIcon
              className={'aspect-square h-auto w-[15px] fill-steelGray'}
            />
          }
          handleClick={handleClick}
        />
        <MenuLink
          title="Games"
          link="/games"
          icon={
            <TrophyIcon
              className={'aspect-square h-auto w-[15px] fill-steelGray'}
            />
          }
          handleClick={handleClick}
        />
        {/* <MenuLink
          title="Teams"
          link="/teams"
          icon={
            <TeamIcon
              className={'aspect-square h-auto w-[15px] fill-steelGray'}
            />
          }
          handleClick={handleClick}
        />
        <MenuLink
          title="Players"
          link="/players"
          icon={
            <UserIcon
              className={'aspect-square h-auto w-[15px] fill-steelGray'}
            />
          }
          handleClick={handleClick}
        /> */}

        <div className="border-t border-lightGray my-4 block"></div>
      </div>

      <MenuLink
        title="Edit Profile"
        link="/my-profile"
        className={`
          hidden

          md:flex
        `}
        icon={
          <UserIcon
            className={'aspect-square h-auto w-[15px] fill-steelGray'}
          />
        }
        noArrow={true}
        handleClick={() => {
          openEditProfile();
          handleClick();
        }}
      />
      <MenuLink
        title="Settings"
        link="/settings"
        icon={
          <CogIcon className={'aspect-square h-auto w-[15px] fill-steelGray'} />
        }
        noArrow={true}
        handleClick={handleClick}
      />
      {/* <MenuLink title="Account" link="/settings/account" className='hidden md:flex'
                icon={<LockIcon className="w-[15px] h-auto aspect-square stroke-steelGray"/>}
                handleClick={handleClick}/>
      <MenuLink title="Notification Settings" link="/settings/notifications" className='hidden md:flex'
          icon={<BellIcon className="w-[15px] h-auto aspect-square fill-steelGray"/>}
          handleClick={handleClick}/> */}
      {/* <div className='opacity-50 pointer-events-none'>
        <MenuLink title="Linked Games" link="/settings" className='hidden md:flex'
                  icon={<SwordsIcon className="w-[15px] h-auto aspect-square fill-steelGray"/>}
                  handleClick={handleClick}/>
      </div> */}
      <MenuLink
        title="Contact"
        link="/contact"
        className="md:hidden"
        icon={
          <MegaphoneIcon
            className={'aspect-square h-auto w-[15px] fill-steelGray'}
          />
        }
        handleClick={handleClick}
      />
    </div>
  );
};

export default MenuLinks;
