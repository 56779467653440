// components
import Hero from './Hero';
import GameSelect from './GameSelect';
import GrindStarts from './GrindStarts';
import UnleashCompetitive from './UnleashCompetitive';
import StayUpdatedBanner from './StayUpdatedBanner';

const HomePage = () => {
  return (
    <div className="md:-mt-8">
      <Hero />
      <GameSelect />
      <GrindStarts />
      <UnleashCompetitive />
      <StayUpdatedBanner />
    </div>
  );
};

export default HomePage;
