import React, { useState } from 'react';
import LeaderboardsHeader from './components/LeaderboardsHeader';
import PlayerLeaderboards, {
  playerSortingDropdownOptions,
} from './components/PlayerLeaderboards';
import { RegionOption } from '../global/utils';
import {
  LeaderboardSortingOption,
  TeamsLeaderboardSortingOption,
} from './types';
import TeamLeaderBoards, {
  teamSortingDropdownOptions,
} from './components/TeamLeaderBoards';

interface LeaderboardsProps {
  // Add your props here
}

const regionDropdownOptions = [
  { value: RegionOption.ALL, label: 'All Regions' },
  { value: RegionOption.EMEA, label: 'EMEA' },
  { value: RegionOption.NA, label: 'NA' },
  { value: RegionOption.APAC, label: 'APAC' },
  { value: RegionOption.LATAM, label: 'LATAM' },
];

const Leaderboards: React.FC<LeaderboardsProps> = () => {
  const [selectedRegionOption, setSelectedRegionOption] =
    useState<RegionOption>(RegionOption.ALL);
  const [selectedPlayerSortingOption, setSelectedPlayerSortingOption] =
    useState<LeaderboardSortingOption>(
      LeaderboardSortingOption.totalWinningsDesc
    );
  const [selectedTeamSortingOption, setSelectedTeamSortingOption] =
    useState<TeamsLeaderboardSortingOption>(
      TeamsLeaderboardSortingOption.totalWinningsDesc
    );
  const [activeTab, setActiveTab] = useState<string>('Teams');

  const tabs = [
    {
      title: 'Teams',
      component: (
        <TeamLeaderBoards
          selectedRegion={selectedRegionOption}
          selectedSorting={selectedTeamSortingOption}
        />
      ),
    },
    {
      title: 'Players',
      component: (
        <PlayerLeaderboards
          selectedRegion={selectedRegionOption}
          selectedSorting={selectedPlayerSortingOption}
        />
      ),
    },
  ];

  const activeTabComponent = tabs.find(
    (tab) => tab.title === activeTab
  )?.component;

  return (
    <div className="custom_container">
      <LeaderboardsHeader
        selectedRegionOption={selectedRegionOption}
        setSelectedRegionOption={setSelectedRegionOption}
        regionDropdownOptions={regionDropdownOptions}
        sortingDropdownOptions={
          activeTab === 'Teams'
            ? teamSortingDropdownOptions
            : playerSortingDropdownOptions
        }
        selectedSortingOption={
          activeTab === 'Teams'
            ? selectedTeamSortingOption
            : selectedPlayerSortingOption
        }
        setSelectedSortingOption={
          activeTab === 'Teams'
            ? setSelectedTeamSortingOption
            : setSelectedPlayerSortingOption
        }
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
      />
      <div className="pt-8">{activeTabComponent}</div>
    </div>
  );
};

export default Leaderboards;
