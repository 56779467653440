// types
import { MatchPointPlannedMap } from '../../../../firestore/tournaments';
// components
import CheckBox from '@ui/CheckBox';
// assets
import worldsEdge from '@assets/images/apex/maps/WE/cover.webp';
import stormPoint from '@assets/images/apex/maps/SP/cover.webp';
import olympus from '@assets/images/apex/maps/OL/cover.webp';
import brokenMoon from '@assets/images/apex/maps/BM/cover.webp';
import kingsCanyon from '@assets/images/apex/maps/KC/cover.webp';
import eDistrict from '@assets/images/apex/maps/ED/cover.webp';

interface IPlannedGameMaps {
  plannedGame: MatchPointPlannedMap;
  updatePlannedMap: (gameNum: number, map: string) => Promise<void>;
}

const PlannedGameMaps: React.FC<IPlannedGameMaps> = ({
  plannedGame,
  updatePlannedMap,
}) => {
  return (
    <div className="w-full flex flex-col gap-y-2 mt-6 font-compact font-medium">
      <div className="w-full flex flex-wrap gap-x-6 gap-y-8">
        <button
          type="button"
          onClick={() => updatePlannedMap(plannedGame.gameNum, 'WE')}
          className={`group w3/4 sm:w-[250px] md:w-[300px] lg:w-[250px] h-auto aspect-[1/1.2] rounded-xl bg-lightGray p-4`}
        >
          <img
            src={worldsEdge}
            alt="World's Edge Apex Map"
            className={`h-4/5 rounded-lg object-cover ${plannedGame.map === 'WE' ? 'opacity-100' : 'opacity-50'} transition-opacity`}
          />
          <div className="flex items-center h-1/5 w-full justify-between">
            <div className="flex flex-col items-start">
              <p
                className={`font-wide ${plannedGame.map === 'WE' ? 'text-white' : 'text-white/75'} uppercase`}
              >
                World's Edge
              </p>
              <p className="text-steelGray">WE</p>
            </div>
            <CheckBox
              selected={plannedGame.map === 'WE'}
              setSelected={() => false}
              asDiv={true}
            />
          </div>
        </button>

        <button
          type="button"
          onClick={() => updatePlannedMap(plannedGame.gameNum, 'SP')}
          className={`group w3/4 sm:w-[250px] md:w-[300px] lg:w-[250px] h-auto aspect-[1/1.2] rounded-xl bg-lightGray p-4`}
        >
          <img
            src={stormPoint}
            alt="World's Edge Apex Map"
            className={`h-4/5 rounded-lg object-cover ${plannedGame.map === 'SP' ? 'opacity-100' : 'opacity-50'} transition-opacity`}
          />
          <div className="flex items-center h-1/5 w-full justify-between">
            <div className="flex flex-col items-start">
              <p
                className={`font-wide ${plannedGame.map === 'SP' ? 'text-white' : 'text-white/75'} uppercase`}
              >
                Storm Point
              </p>
              <p className="text-steelGray">SP</p>
            </div>
            <CheckBox
              selected={plannedGame.map === 'SP'}
              setSelected={() => false}
              asDiv={true}
            />
          </div>
        </button>

        <button
          type="button"
          onClick={() => updatePlannedMap(plannedGame.gameNum, 'OL')}
          className={`group w3/4 sm:w-[250px] md:w-[300px] lg:w-[250px] h-auto aspect-[1/1.2] rounded-xl bg-lightGray p-4`}
        >
          <img
            src={olympus}
            alt="World's Edge Apex Map"
            className={`h-4/5 rounded-lg object-cover ${plannedGame.map === 'OL' ? 'opacity-100' : 'opacity-50'} transition-opacity`}
          />
          <div className="flex items-center h-1/5 w-full justify-between">
            <div className="flex flex-col items-start">
              <p
                className={`font-wide ${plannedGame.map === 'OL' ? 'text-white' : 'text-white/75'} uppercase`}
              >
                Olympus
              </p>
              <p className="text-steelGray">OL</p>
            </div>
            <CheckBox
              selected={plannedGame.map === 'OL'}
              setSelected={() => false}
              asDiv={true}
            />
          </div>
        </button>

        <button
          type="button"
          onClick={() => updatePlannedMap(plannedGame.gameNum, 'BM')}
          className={`group w3/4 sm:w-[250px] md:w-[300px] lg:w-[250px] h-auto aspect-[1/1.2] rounded-xl bg-lightGray p-4`}
        >
          <img
            src={brokenMoon}
            alt="World's Edge Apex Map"
            className={`h-4/5 rounded-lg object-cover ${plannedGame.map === 'BM' ? 'opacity-100' : 'opacity-50'} transition-opacity`}
          />
          <div className="flex items-center h-1/5 w-full justify-between">
            <div className="flex flex-col items-start">
              <p
                className={`font-wide ${plannedGame.map === 'BM' ? 'text-white' : 'text-white/75'} uppercase`}
              >
                Broken Moon
              </p>
              <p className="text-steelGray">BM</p>
            </div>
            <CheckBox
              selected={plannedGame.map === 'BM'}
              setSelected={() => false}
              asDiv={true}
            />
          </div>
        </button>

        <button
          type="button"
          onClick={() => updatePlannedMap(plannedGame.gameNum, 'KC')}
          className={`group w3/4 sm:w-[250px] md:w-[300px] lg:w-[250px] h-auto aspect-[1/1.2] rounded-xl bg-lightGray p-4`}
        >
          <img
            src={kingsCanyon}
            alt="World's Edge Apex Map"
            className={`h-4/5 rounded-lg object-cover ${plannedGame.map === 'KC' ? 'opacity-100' : 'opacity-50'} transition-opacity`}
          />
          <div className="flex items-center h-1/5 w-full justify-between">
            <div className="flex flex-col items-start">
              <p
                className={`font-wide ${plannedGame.map === 'KC' ? 'text-white' : 'text-white/75'} uppercase`}
              >
                King's Canyon
              </p>
              <p className="text-steelGray">KC</p>
            </div>
            <CheckBox
              selected={plannedGame.map === 'KC'}
              setSelected={() => false}
              asDiv={true}
            />
          </div>
        </button>

        <button
          type="button"
          onClick={() => updatePlannedMap(plannedGame.gameNum, 'ED')}
          className={`group w3/4 sm:w-[250px] md:w-[300px] lg:w-[250px] h-auto aspect-[1/1.2] rounded-xl bg-lightGray p-4`}
        >
          <img
            src={eDistrict}
            alt="World's Edge Apex Map"
            className={`h-4/5 rounded-lg object-cover ${plannedGame.map === 'ED' ? 'opacity-100' : 'opacity-50'} transition-opacity`}
          />
          <div className="flex items-center h-1/5 w-full justify-between">
            <div className="flex flex-col items-start">
              <p
                className={`font-wide ${plannedGame.map === 'ED' ? 'text-white' : 'text-white/75'} uppercase`}
              >
                E-District
              </p>
              <p className="text-steelGray">ED</p>
            </div>
            <CheckBox
              selected={plannedGame.map === 'ED'}
              setSelected={() => false}
              asDiv={true}
            />
          </div>
        </button>
      </div>
    </div>
  );
};

export default PlannedGameMaps;
