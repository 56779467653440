// Importing images and components for the EsportsProduction component
import productionImg from '../../assets/images/webp/services/EsportsProdution.webp';
import productionImgMobile from '../../assets/images/png/services/EsportsProdutionmobile.webp';
import { ProductionIcon } from '../common/icons/Services';
import { ProductionList } from '../common/content/Services';

// Functional component for Esports Production section
const EsportsProduction = () => {
  return (
    // Container for the entire component
    <div className="px-4 sm:px-8 lg:px-12 custom_container">
      {/* Main container for the content */}
      <div className="flex flex-col-reverse lg:flex-row items-center justify-between rounded-2xl bg-lightBlack overflow-hidden mb-8">
        {/* Left side container with text content */}
        <div className="max-[420px]:p-4 max-[420px]:pt-0 p-6 pt-0 -mt-6 lg:mt-0 sm:p-4 sm:pt-0 lg:py-10 lg:pl-6  lg:w-1/2 xl:w-auto xl:px-10">
          {/* Icon for the Esports Production section */}
          <ProductionIcon />
          {/* Title for the Esports Production section */}
          <h3 className="max-[420px]:text-2xl text-4xl font-bold text-white font-wide mt-6 mb-2 xl:mt-8">
            ESPORTS PRODUCTION
          </h3>
          {/* Description for the Esports Production section */}
          <p className="text-sm custom_xsm:text-base text-slateGray tracing-[0.01rem] font-normal leading-[150%] mb-6 xl:mb-10 font-compact xl:pe-32 max-w-[45rem]">
            Complete production services for esports events including design,
            talent sourcing, broadcasting, and fan-engagement.
          </p>
          {/* Container for the list of production services */}
          <div className="overflow-x-auto scrollbar_none">
            {/* Grid layout for the list of production services */}
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 md:gap-3 xl:gap-4 lg:w-[50rem] xl:w-auto">
              {/* Mapping through ProductionList array to display individual services */}
              {ProductionList.map((obj, index) => (
                <div
                  className="max-[420px]:p-4 p-8 sm:p-4 lg:p-6 xl:p-8 rounded-2xl bg-lightGray"
                  key={index}
                >
                  {/* Subtitle for each production service */}
                  <h6 className="flex gap-2 font-wide text-base font-bold text-white uppercase mb-3.5">
                    {/* Numbered circle for each production service */}
                    <span className="bg-electricViolet text-black h-6 w-6 rounded-full flex items-center justify-center pt-1">
                      {index + 1}
                    </span>
                    {/* Title of the production service */}
                    <span className="pt-[0.125rem] uppercase">{obj.title}</span>
                  </h6>
                  {/* Description of the production service */}
                  <p className="text-base text-slateGray leading-[150%] tracking-[0.01rem] font-compact">
                    {obj.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Right side container with images */}
        <div className="w-full lg:max-w-[33rem] lg:h-[33.5rem]">
          {/* Desktop image for the Esports Production section */}
          <img
            className="w-full h-full hidden lg:block esports_production_clippath"
            src={productionImg}
            alt="productionImg"
          />
          {/* Mobile image for the Esports Production section */}
          <img
            className="w-full lg:hidden"
            src={productionImgMobile}
            alt="productionImgMobile"
          />
        </div>
      </div>
    </div>
  );
};

// Exporting the EsportsProduction component as the default export
export default EsportsProduction;
