import { ReactNode, useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// context
import { useTournamentContext } from '@components/tournament/TournamentProvider';
import { useHeaderContext } from '@provider/HeaderProvider';
import { useAuthContext } from '@provider/AuthContextProvider';
import { useProfileInfoContext } from '@components/completeProfile/ProfileInfoProvider';
// packages
import { toast } from 'react-toastify';
// components
import Modal from '@ui/Modal';
import MiniTeamCard from './MiniTeamCard';
// icons
// -- react-icons
import { FaCheck, FaLock } from 'react-icons/fa';
import { FaClock } from 'react-icons/fa';
import { FaPen } from 'react-icons/fa6';
import { FaArrowRightLong } from 'react-icons/fa6';
// -- custom icons
import {
  LeaveIcon,
  Player,
  PlayersIcon,
  TeamIcon,
  VersusIcon,
} from '@icons/Common';
import { DiscordSocialIcon } from '@icons/TournamentIcons';
import PopoverText from '@ui/PopoverText';

const RegistrationViews = () => {
  const navigate = useNavigate();

  const { userTeam } = useAuthContext();
  const { setNavbarState } = useHeaderContext();
  const { setCurrentModalStep } = useProfileInfoContext();

  const {
    tournament,
    tournamentRegionName,
    joinWaitingList,
    registerForTournament,
    leaveWaitingList,
    unregisterFromTournament,
    timeUntilEntrantsCheckIn,
    timeUntilWaitingListCheckin,
    userTournamentState,
  } = useTournamentContext();

  const [joinCooldown, setJoinCooldown] = useState<number>(0);

  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);
  const [waitingModalOpen, setWaitingModalOpen] = useState<boolean>(false);

  const openSignUpModal = useCallback(() => {
    setNavbarState((prevState) => ({
      ...prevState,
      signUpMenuVisible: true,
    }));
  }, [setNavbarState]);

  const openCreateTeam = useCallback(() => {
    navigate('/create-team');
  }, [navigate]);

  const openTeamPage = useCallback(() => {
    navigate('/my-team');
  }, [navigate]);

  // button state
  const [buttonStyle, setButtonStyle] = useState<string>('');
  const [buttonInfo, setButtonInfo] = useState<ReactNode>('');
  const [buttonInner, setButtonInner] = useState<ReactNode | string>('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [secondaryButton, setSecondaryButton] = useState<ReactNode>('');
  const [buttonOnClick, setButtonOnClick] = useState<() => void>(
    () => () => false
  );
  const [buttonPopoverText, setButtonPopoverText] = useState<string>('');

  const setButtonState = useCallback(() => {
    if (!tournament) return;

    setButtonStyle('');
    setButtonInfo('');
    setButtonInner('');
    setButtonDisabled(false);
    setSecondaryButton('');
    setButtonPopoverText('');
    setButtonOnClick(() => () => false);

    if (userTournamentState.registered) {
      setButtonInfo(
        <MiniTeamCard team={userTeam} status={userTournamentState.teamStatus} />
      );
      setButtonInner(
        <>
          <span className="-mb-1">Registered</span>
          <span>
            <FaCheck className="text-black" />
          </span>
        </>
      );
      setSecondaryButton(
        <button
          className={`
            bg-red/60 text-black w-1/8 flex aspect-square h-[40px] w-auto
            items-center justify-center rounded-xl py-[0.8rem] transition-colors

            hover:bg-red/80
          `}
          onClick={unregisterFromTournament}
        >
          <LeaveIcon className="aspect-square h-auto w-[14px] fill-black" />
        </button>
      );
      setButtonDisabled(true);
    } else if (userTournamentState.waiting) {
      setButtonInfo(
        <MiniTeamCard team={userTeam} status={userTournamentState.teamStatus} />
      );
      setButtonInner(
        <>
          <span className="-mb-1">In waiting list</span>
          <span>
            <FaClock className="text-black" />
          </span>
        </>
      );
      setSecondaryButton(
        <button
          className={`
            bg-red/60 text-black w-1/8 flex aspect-square h-[40px] w-auto
            items-center justify-center rounded-xl py-[0.8125rem]
            transition-colors

            hover:bg-red/80
          `}
          onClick={leaveWaitingList}
        >
          <LeaveIcon className="aspect-square h-auto w-[14px] fill-black" />
        </button>
      );
      setButtonDisabled(true);
    } else {
      if (!userTournamentState.signedIn) {
        setButtonPopoverText('You need an account to register!');
        setButtonDisabled(false);
        setButtonOnClick(() => () => {
          openSignUpModal();
        });
        setButtonInner(
          <>
            <span className="-mb-1">Create Account</span>
            <span>
              <VersusIcon className="aspect-square h-auto w-[16px] fill-black" />
            </span>
          </>
        );
      } else if (!userTournamentState.profileComplete) {
        setButtonPopoverText(
          'You must complete your profile before creating/registering a team!'
        );
        setButtonOnClick(() => () => {
          navigate('/my-profile');
          setCurrentModalStep(0);
          document.body.classList.remove('overflow_hidden');
        });
        setButtonInner(
          <>
            <span className="-mb-1">Complete Profile</span>
            <span>
              <Player className="aspect-square h-auto w-[16px] fill-black" />
            </span>
          </>
        );
        setButtonDisabled(false);
      } else if (!userTournamentState.hasTeam) {
        setButtonPopoverText('You need a team to register!');
        setButtonOnClick(() => () => {
          openCreateTeam();
        });
        setButtonInner(
          <>
            <span className="-mb-1">Create Team</span>
            <span>
              <TeamIcon className="aspect-square h-auto w-[16px] fill-black" />
            </span>
          </>
        );
        setButtonDisabled(false);
      } else if (userTournamentState.wrongRegion) {
        setButtonPopoverText(
          `Your team must be from ${tournamentRegionName} to compete in this event!`
        );
        setButtonInner(
          <>
            <span className="-mb-1">Region Locked</span>
            <span>
              <FaLock />
            </span>
          </>
        );
        setButtonStyle('bg-red');
        setButtonDisabled(true);
      } else if (!userTournamentState.teamCaptain) {
        setButtonPopoverText(
          'Permissions required to register! Ask your Team Owner'
        );
        setButtonInner(
          <>
            <span className="-mb-1">Register</span>
            <span>
              <FaLock />
            </span>
          </>
        );
        setButtonDisabled(true);
      } else if (userTournamentState.teamTooSmall) {
        setButtonPopoverText(
          `Your team needs a minimum of ${tournament.teamSize} players!`
        );
        setButtonOnClick(() => () => {
          openTeamPage();
        });
        setButtonInner(
          <>
            <span className="-mb-1">Invite Players</span>
            <span>
              <PlayersIcon className="aspect-square h-auto w-[16px] fill-black" />
            </span>
          </>
        );
        setButtonDisabled(true);
      } else if (userTournamentState.teamTooLarge) {
        setButtonPopoverText(
          `Your team cannot exceed ${tournament.maxTeamSize} players!`
        );
        setButtonOnClick(() => () => {
          openTeamPage();
        });
        setButtonInner(
          <>
            <span className="-mb-1">Edit Team</span>
            <span>
              <FaPen className="text-sm text-black" />
            </span>
          </>
        );
        setButtonDisabled(true);
      } else if (userTournamentState.tournamentFull) {
        setButtonPopoverText(
          'No spaces left! You can still join the waiting list'
        );
        setButtonOnClick(() => () => setWaitingModalOpen(true));
        setButtonInner(
          <>
            <span className="-mb-1">Join waiting list</span>
            <span>
              <FaClock className="text-black" />
            </span>
          </>
        );
      } else {
        setButtonOnClick(() => () => setRegisterModalOpen(true));
        setButtonInner(
          <>
            <span className="-mb-1">Register</span>
            <span>
              <FaArrowRightLong className="text-black" />
            </span>
          </>
        );
      }
    }
  }, [
    leaveWaitingList,
    openCreateTeam,
    openSignUpModal,
    openTeamPage,
    tournament,
    tournamentRegionName,
    unregisterFromTournament,
    userTeam,
    userTournamentState,
    navigate,
    setCurrentModalStep,
  ]);

  useEffect(() => {
    setButtonState();
  }, [tournament, userTournamentState, setButtonState]);

  return (
    <>
      <div
        className={`
          flex h-full w-1/2 flex-grow flex-col items-center justify-end gap-y-8
        `}
      >
        {buttonInfo}
        <div className="flex w-full items-center gap-x-2">
          <Link
            to={
              tournament && tournament.discordSupport
                ? tournament.discordSupport
                : 'https://discord.gg/versus-gg'
            }
            target="_blank"
            className={`
              flex aspect-square h-[40px] w-auto items-center justify-center
              rounded-xl p-2 bg-lightGray

              xl:hidden
            `}
          >
            <DiscordSocialIcon />
          </Link>
          <div className="group relative w-5/6 flex-grow">
            {buttonPopoverText ? (
              <PopoverText
                right={true}
                visible={true}
                className={`
                  opacity-0

                  group-hover:opacity-100
                `}
              >
                {buttonPopoverText}
              </PopoverText>
            ) : (
              ''
            )}
            <button
              className={`
                font-compact flex h-[40px] w-full items-center justify-center
                gap-2 rounded-xl py-[0.8125rem] text-base font-semibold
                uppercase !leading-4 text-black bg-green opacity
                transition-opacity

                disabled:opacity-50 disabled:hover:bg-green

                hover:bg-gorse

                ${buttonStyle}
              `}
              disabled={buttonDisabled}
              onClick={buttonOnClick}
            >
              {buttonInner}
            </button>
          </div>
          {secondaryButton}
        </div>
      </div>
      <Modal
        title="Waiting List Info"
        open={userTournamentState.waitingListOpen && waitingModalOpen}
        setOpen={setWaitingModalOpen}
        buttonText={
          <div className="flex items-center gap-x-2">
            <span>Join Waiting List</span>
            <FaClock />
          </div>
        }
        buttonOnClick={() => {
          if (joinCooldown < new Date().getTime()) {
            joinWaitingList();
            setJoinCooldown(new Date().getTime() + 30_000);
          } else {
            toast.error('You Cannot do that again so quickly');
          }
          setWaitingModalOpen(false);
        }}
      >
        <div
          className={`
            flex flex-col gap-y-4 text-sm text-white/90 font-compact font-thin
          `}
        >
          <div className="mx-auto flex max-w-[90%] flex-col gap-y-4">
            <p>
              Waiting list teams will receive spots that become available before
              registration closes.
            </p>
            <p>
              If still waiting when registration closes, grab spots made
              available by teams that’s don’t check-in!
            </p>
            <p>
              1 hour before the event starts, notifications are sent to waiting
              list teams giving you
              <em className="not-italic text-green uppercase"> 30 MINS </em>
              to claim available spots on a first come, first served basis.
            </p>
          </div>
          <div
            className={`
              flex w-full items-center justify-center gap-x-2 rounded-lg
              border-2 p-2 text-base border-lightGray uppercase
            `}
          >
            <p>Claim Notification:</p>
            <p className="text-green">{timeUntilWaitingListCheckin}</p>
          </div>
        </div>
      </Modal>
      <Modal
        title="Registration Info"
        open={userTournamentState.registrationOpen && registerModalOpen}
        setOpen={setRegisterModalOpen}
        buttonText={
          <div className="flex items-center gap-x-2">
            <span>Register</span>
            <FaArrowRightLong />
          </div>
        }
        buttonOnClick={() => {
          if (joinCooldown < new Date().getTime()) {
            registerForTournament();
            setJoinCooldown(new Date().getTime() + 30_000);
          } else {
            toast.error('You Cannot do that again so quickly');
          }
          setRegisterModalOpen(false);
        }}
      >
        <div
          className={`
            flex flex-col gap-y-4 text-sm text-white/90 font-compact font-thin
          `}
        >
          <div className="mx-auto flex max-w-[90%] flex-col gap-y-4">
            <p>Teams must check-in before the event starts.</p>
            <p>
              Upon opening you will have
              <em className="not-italic text-green uppercase"> 1 Hour </em>
              to check in.
              <br />
              Failing to do so will result in losing your spot.
            </p>
          </div>
          <div
            className={`
              flex w-full items-center justify-center gap-x-2 rounded-lg
              border-2 p-2 text-base border-lightGray uppercase
            `}
          >
            <p>Check-In Opens:</p>
            <p className="text-green">{timeUntilEntrantsCheckIn}</p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RegistrationViews;
