import React, { useRef } from 'react';
import DropDown from '@components/ui/DropDown';
import { TargetIcon } from '@components/common/icons/Common';
import { RegionOption } from '@src/components/global/utils';
import PillButton from '@components/ui/PillButton';

interface LeaderboardsHeaderProps {
  selectedRegionOption: RegionOption;
  setSelectedRegionOption: (option: RegionOption) => void;
  regionDropdownOptions: {
    value: RegionOption;
    label: string;
  }[];
  selectedSortingOption: number;
  setSelectedSortingOption: (option: number) => void;
  sortingDropdownOptions: {
    value: number;
    label: string;
  }[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  tabs: { title: string; component: React.ReactNode }[];
}

const LeaderboardsHeader: React.FC<LeaderboardsHeaderProps> = ({
  selectedRegionOption,
  setSelectedRegionOption,
  regionDropdownOptions,
  sortingDropdownOptions,
  selectedSortingOption,
  setSelectedSortingOption,
  activeTab,
  setActiveTab,
  tabs,
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex flex-col gap-4">
      <div
        className={`
          flex items-center justify-between border-t pt-8 border-lightGray
        `}
      >
        <div className="flex items-center gap-4">
          <h2
            className={`
              font-wide text-xl font-semibold uppercase text-white

              lg:text-3xl
            `}
          >
            Leaderboards
          </h2>
          <div className="flex items-center gap-2">
            {tabs.map((tab) => (
              <PillButton
                key={tab.title}
                active={activeTab === tab.title}
                onClick={() => setActiveTab(tab.title)}
              >
                {tab.title}
              </PillButton>
            ))}
          </div>
        </div>
        <div className="flex items-center gap-4">
          <DropDown
            ref={dropDownRef}
            icon={
              <TargetIcon
                className={'aspect-square h-auto w-[16px] fill-green'}
              />
            }
            currentOption={regionDropdownOptions[selectedRegionOption]}
            options={regionDropdownOptions}
            handleOnChange={setSelectedRegionOption}
            className="!mt-0"
          />
        </div>
      </div>
      <div className="flex items-center gap-2">
        {Object.entries(sortingDropdownOptions).map(([key, option]) => (
          <PillButton
            key={key}
            active={selectedSortingOption === Number(key)}
            onClick={() => setSelectedSortingOption(Number(key))}
          >
            {option.label}
          </PillButton>
        ))}
      </div>
    </div>
  );
};

export default LeaderboardsHeader;
