import {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from 'react';
// firebase
import { firestore } from '@src/firebase';
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  Query,
  query,
  QueryConstraint,
  Unsubscribe,
  where,
} from 'firebase/firestore';
// context
import { useStorageContext } from '@provider/StorageProvider';
// types
import {
  algoliaTournamentConverter,
  Region,
  Tournament,
  tournamentConverter,
} from '@src/firestore/tournaments';
// utils
import { debounce } from '@utils/Debounce';
// libraries
import algoliasearch from 'algoliasearch';

interface TournamentsCount {
  // lets use an aggragation do for this (meta collection ---> 'teams')
  apexLegends: number;
  fortnite: number;
  valorant: number;
  rocketLeague: number;
}

const defaultTournamentsCount = {
  apexLegends: 0,
  fortnite: 0,
  valorant: 0,
  rocketLeague: 0,
};

export enum TournamentsGameOption {
  Apex,
  Valorant,
  Fortnite,
  RocketLeague,
}

export enum TournamentsRegionOption {
  ALL,
  EMEA,
  NA,
  LATAM,
  APAC,
}
export enum TournamentsSortingOption {
  upNext,
  prizeDesc,
  nameAsc,
}

interface ITournamentsContext {
  tournaments: Tournament[]; // all tournaments
  tournamentsCount: TournamentsCount;
  completedTournaments: Tournament[];
  featuredTournaments: Tournament[];
  tournamentsLoaded: boolean;
  completedTournamentsLoaded: boolean;
  featuredTournamentsLoaded: boolean;
  initiallyLoaded: boolean;
  searchQuery: string;
  searchQueued: boolean;
  setSearchQueued: (queued: boolean) => void;
  setSearchQuery: (query: string) => void;
  gameOption: TournamentsGameOption;
  setGameOption: (option: TournamentsGameOption) => void;
  regionOption: TournamentsRegionOption;
  setRegionOption: (option: TournamentsRegionOption) => void;
  sortingOption: TournamentsSortingOption;
  setSortingOption: (option: TournamentsSortingOption) => void;
}

const defaultTournamentsContext = {
  tournaments: [],
  tournamentsCount: defaultTournamentsCount,
  completedTournaments: [],
  featuredTournaments: [],
  tournamentsLoaded: false,
  completedTournamentsLoaded: false,
  featuredTournamentsLoaded: false,
  initiallyLoaded: false,
  searchQuery: '',
  searchQueued: false,
  setSearchQueued: (queued: boolean) => queued,
  setSearchQuery: (query: string) => query,
  gameOption: TournamentsGameOption.Apex,
  setGameOption: (option: TournamentsGameOption) => option,
  regionOption: TournamentsRegionOption.ALL,
  setRegionOption: (option: TournamentsRegionOption) => option,
  sortingOption: TournamentsSortingOption.upNext,
  setSortingOption: (option: TournamentsSortingOption) => option,
};

const TournamentsContext = createContext<ITournamentsContext>(
  defaultTournamentsContext
);

export const useTournamentsContext = () => {
  const context = useContext(TournamentsContext);
  return context;
};

const sortingConstraintConstructors = {
  upNext: () => [orderBy('statusDates.confirmation', 'asc'), orderBy('id')],
  prizeDesc: () => [orderBy('prizePool.totalPrizePool', 'desc'), orderBy('id')],
  nameAsc: () => [orderBy('name', 'asc'), orderBy('id')],
};

const getQueryConstraintsForSortingOption = (
  option: TournamentsSortingOption
): QueryConstraint[] => {
  switch (option) {
    case TournamentsSortingOption.upNext:
      return sortingConstraintConstructors.upNext();
    case TournamentsSortingOption.prizeDesc:
      return sortingConstraintConstructors.prizeDesc();
    case TournamentsSortingOption.nameAsc:
      return sortingConstraintConstructors.nameAsc();
    default:
      return sortingConstraintConstructors.upNext();
  }
};

const getQueryConstraintsForRegionOption = (
  option: TournamentsRegionOption
): QueryConstraint[] => {
  let region: Region | -1 = -1;
  switch (option) {
    case TournamentsRegionOption.ALL:
      region = -1;
      break;
    case TournamentsRegionOption.EMEA:
      region = Region.EMEA;
      break;
    case TournamentsRegionOption.NA:
      region = Region.NA;
      break;
    case TournamentsRegionOption.LATAM:
      region = Region.LATAM;
      break;
    case TournamentsRegionOption.APAC:
      region = Region.APAC;
      break;
    default:
      region = -1;
  }

  return region !== -1 ? [where('region', '==', region)] : [];
};

interface ITournamentsProvider {
  announceInitiallyLoaded: (loaded: boolean) => void;
  children: ReactNode;
}

const TournamentsProvider: React.FC<ITournamentsProvider> = ({
  children,
  announceInitiallyLoaded,
}) => {
  const { storage, updateStorage, storageLoaded } = useStorageContext();
  const [filterOptionsLoaded, setFilterOptionsLoaded] =
    useState<boolean>(false);

  const [initiallyLoaded, setInitiallyLoaded] = useState<boolean>(false);

  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [tournamentsLoaded, setTournamentsLoaded] = useState<boolean>(false);
  const [tournamentsCount, setTournamentsCount] = useState<TournamentsCount>(
    defaultTournamentsCount
  );

  const searchQueryRef = useRef<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchQueued, setSearchQueued] = useState<boolean>(false);

  const [gameOption, setGameOption] = useState<TournamentsGameOption>(
    TournamentsGameOption.Apex
  );
  const [regionOption, setRegionOption] = useState<TournamentsRegionOption>(
    TournamentsRegionOption.ALL
  );
  const [sortingOption, setSortingOption] = useState<TournamentsSortingOption>(
    TournamentsSortingOption.upNext
  );

  const [completedTournaments, setCompletedTournaments] = useState<
    Tournament[]
  >([]);
  const [completedTournamentsLoaded, setCompletedTournamentsLoaded] =
    useState<boolean>(false);

  const [featuredTournaments, setFeaturedTournaments] = useState<Tournament[]>(
    []
  );
  const [featuredTournamentsLoaded, setFeaturedTournamentsLoaded] =
    useState<boolean>(false);

  const getFeaturedTournaments = () => {
    setFeaturedTournamentsLoaded(false);
    const tournamentsCollection = collection(
      firestore,
      'tournaments'
    ).withConverter(tournamentConverter);
    const q = query(
      tournamentsCollection,
      where('hidden', '==', false),
      where('completed', '==', false),
      orderBy('statusDates.confirmation', 'asc'),
      limit(3)
    );

    const unsubscribe = onSnapshot(q, async (snapshots) => {
      const localFeaturedTournaments = snapshots.docs
        .map((doc) => doc.data())
        .sort((a, b) => b.entrantsCount - a.entrantsCount);
      setFeaturedTournaments(localFeaturedTournaments);
      setFeaturedTournamentsLoaded(true);
    });

    return unsubscribe;
  };

  const getTournaments = useCallback(() => {
    let unsubscribe: Unsubscribe | (() => void) = () => false;

    if (filterOptionsLoaded) {
      setTournamentsLoaded(false);

      saveFiltersToLocalStorage();

      const tournamentsCollection = collection(
        firestore,
        'tournaments'
      ).withConverter(tournamentConverter);

      const queryRegionConstraints =
        getQueryConstraintsForRegionOption(regionOption);
      const querySortingConstraints =
        getQueryConstraintsForSortingOption(sortingOption);

      const q: Query<Tournament> = query(
        tournamentsCollection,
        ...querySortingConstraints,
        ...queryRegionConstraints,
        where('hidden', '==', false),
        where('completed', '==', false),
        limit(12)
      );

      unsubscribe = onSnapshot(q, async (snapshots) => {
        const localTournaments = snapshots.docs.map((doc) => doc.data());
        setTournaments(localTournaments);
        setTournamentsCount({
          apexLegends: localTournaments.length,
          fortnite: 0,
          valorant: 0,
          rocketLeague: 0,
        });

        setTournamentsLoaded(true);
        setInitiallyLoaded(true);
        announceInitiallyLoaded(true);
      });
    }

    return unsubscribe;
  }, [
    announceInitiallyLoaded,
    regionOption,
    sortingOption,
    filterOptionsLoaded,
  ]);

  const searchTournaments = async () => {
    const localSearchQuery = searchQueryRef.current;
    if (localSearchQuery !== null && localSearchQuery !== '') {
      let tournaments: Tournament[] = [];
      if (import.meta.env.VITE_ENV === 'production') {
        const client = algoliasearch(
          '1EFPJPOFKM',
          '725ce15dcc1a7cf9b3a0beb00b120456'
        );
        const index = client.initIndex('tournaments');
        const { hits } = await index.search(localSearchQuery);
        tournaments = hits
          .map((hit) => algoliaTournamentConverter(hit))
          .filter((tournament) => !tournament.completed && !tournament.hidden);
      } else if (import.meta.env.VITE_ENV === 'staging') {
        const client = algoliasearch(
          'IONCIJEH1C',
          'f6e30465e863efdc9f34188018765ed8'
        );
        const index = client.initIndex('tournaments');
        const { hits } = await index.search(localSearchQuery);
        tournaments = hits
          .map((hit) => algoliaTournamentConverter(hit))
          .filter((tournament) => !tournament.completed && !tournament.hidden);
      } else {
        const usersCollection = collection(
          firestore,
          'tournaments'
        ).withConverter(tournamentConverter);
        const q = query(
          usersCollection,
          where('name', '==', localSearchQuery),
          where('hidden', '==', false),
          where('completed', '==', false),
          limit(12)
        );
        const querySnapshot = await getDocs(q);
        tournaments = querySnapshot.docs.map((doc) => doc.data());
      }
      setTournaments(tournaments);
    }
    setSearchQueued(false);
  };

  const debouncedSearchTournaments = useMemo(
    () => debounce(searchTournaments, 500),
    []
  );

  useEffect(() => {
    searchQueryRef.current = searchQuery;
    if (searchQuery !== '') {
      setSearchQueued(true);
      debouncedSearchTournaments();
    } else if (initiallyLoaded) {
      getTournaments();
    }
  }, [searchQuery, debouncedSearchTournaments]);

  const getCompletedTournaments = () => {
    setCompletedTournamentsLoaded(false);

    const tournamentsCollection = collection(
      firestore,
      'tournaments'
    ).withConverter(tournamentConverter);
    const q = query(
      tournamentsCollection,
      orderBy('statusDates.confirmation', 'desc'),
      where('completed', '==', true),
      limit(24)
    );

    const unsubscribe = onSnapshot(q, async (snapshots) => {
      const localCompletedTournaments = snapshots.docs.map((doc) => doc.data());
      setCompletedTournaments(localCompletedTournaments);
      setCompletedTournamentsLoaded(true);
    });

    return unsubscribe;
  };

  useEffect(() => {
    const completedTournamentsUnsubscribe = getCompletedTournaments();

    return () => completedTournamentsUnsubscribe();
  }, []);

  useEffect(() => {
    const tournamentsUnsubscribe = getTournaments();

    return () => tournamentsUnsubscribe();
  }, [regionOption, gameOption, sortingOption, getTournaments]);

  const loadFiltersFromLocalStorage = () => {
    if (storage.tournamentsRegionOption !== undefined) {
      setRegionOption(storage.tournamentsRegionOption);
    }

    if (storage.tournamentsSortingOption !== undefined) {
      setSortingOption(storage.tournamentsSortingOption);
    }

    setFilterOptionsLoaded(true);
  };

  const saveFiltersToLocalStorage = () => {
    updateStorage({
      tournamentsRegionOption: regionOption,
      tournamentsSortingOption: sortingOption,
    });
  };

  useEffect(() => {
    if (storageLoaded && !filterOptionsLoaded) {
      loadFiltersFromLocalStorage();
    }
  }, [storageLoaded, filterOptionsLoaded]);

  useEffect(() => {
    const unsubscribe = getFeaturedTournaments();

    return () => unsubscribe();
  }, []);

  const contextvalue = {
    tournaments: tournaments,
    tournamentsCount: tournamentsCount,
    completedTournaments: completedTournaments,
    featuredTournaments: featuredTournaments,
    tournamentsLoaded: tournamentsLoaded,
    completedTournamentsLoaded: completedTournamentsLoaded,
    featuredTournamentsLoaded: featuredTournamentsLoaded,
    initiallyLoaded: initiallyLoaded,
    searchQuery: searchQuery,
    searchQueued: searchQueued,
    setSearchQueued: setSearchQueued,
    setSearchQuery: setSearchQuery,
    gameOption: gameOption,
    setGameOption: setGameOption,
    regionOption: regionOption,
    setRegionOption: setRegionOption,
    sortingOption: sortingOption,
    setSortingOption: setSortingOption,
  };

  return (
    <TournamentsContext.Provider value={contextvalue}>
      {children}
    </TournamentsContext.Provider>
  );
};

export default TournamentsProvider;
