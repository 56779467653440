import { useState } from 'react';
// context
import TournamentsProvider from './TournamentsProvider';
// components
import {
  SkeletonDark,
  SkeletonLight,
} from '../common/skeleton-loader/Skeletons';
import TournamentList from './Lists/TournamentList';
import CompletedList from './Lists/CompletedList';
// adverts
// import FindTeam from "@common/FindTeam";
import SurfsharkAdDesktop from '@components/brands/surfshark/SurfsharkAdDesktop';
import SurfsharkAdMobile from '@components/brands/surfshark/SurfsharkAdMobile';

const Tournaments = () => {
  const [tournamentsLoaded, setTournamentsLoaded] = useState<boolean>(false);

  return (
    <div className={'custom_container'}>
      <TournamentsProvider announceInitiallyLoaded={setTournamentsLoaded}>
        <TournamentList />
        {tournamentsLoaded ? (
          <div className={'mx-auto'}>
            {/* <FindTeam className='!mt-8 !mb-2'/> */}
            <div
              className={`
                !mb-2 !mt-8 hidden

                md:block
              `}
            >
              <SurfsharkAdDesktop />
            </div>
            <div
              className={`
                !mb-2 !mt-8

                md:hidden
              `}
            >
              <SurfsharkAdMobile />
            </div>
          </div>
        ) : (
          <div className={'mx-auto mb-[6rem] mt-12'}>
            <SkeletonDark
              className={`
                flex h-[150px] w-full items-center justify-between rounded-xl
                p-3

                xl:p-6
              `}
            >
              <div className="flex h-full w-[70%] justify-between gap-x-2">
                <SkeletonLight className="h-full w-[40%] rounded-lg" />
                <div className="w-[50%]">
                  <SkeletonLight className="mt-2 h-[30px] w-[80%] rounded-lg" />
                  <SkeletonLight className="mt-2 h-[25px] w-[90%] rounded-lg" />
                  <SkeletonLight className="mt-2 h-[25px] w-[90%] rounded-lg" />
                </div>
              </div>

              <SkeletonLight className="h-[35px] w-[15%] rounded-lg" />
            </SkeletonDark>
          </div>
        )}
        <CompletedList />
      </TournamentsProvider>
    </div>
  );
};
export default Tournaments;
