import { QueryConstraint, where } from 'firebase/firestore';

export enum RegionOption {
  ALL,
  EMEA,
  NA,
  LATAM,
  APAC,
}

export const getQueryConstraintsForRegionOption = (
  option: RegionOption
): QueryConstraint[] => {
  let region: string = '';
  switch (option) {
    case RegionOption.ALL:
      region = '';
      break;
    case RegionOption.EMEA:
      region = 'EMEA';
      break;
    case RegionOption.NA:
      region = 'NA';
      break;
    case RegionOption.LATAM:
      region = 'LATAM';
      break;
    case RegionOption.APAC:
      region = 'APAC';
      break;
    default:
      region = '';
  }

  return region !== '' ? [where('region', '==', region)] : [];
};
