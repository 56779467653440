export enum PlayersGameOption {
  Apex,
  Valorant,
  Fortnite,
  RocketLeague,
}

export enum PlayersFilterOption {
  pc,
  console,
  mouseKeyboard,
  controller,
}

export enum PlayersSortingOption {
  totalWinningsDesc,
  nameDesc,
  tournamentsPlayedDesc,
  trophiesWonDesc,
  legendMainDesc,
}
