import React, { useRef, useState, ReactNode } from 'react';
import { SkeletonDark } from '@components/common/skeleton-loader/Skeletons';
import SearchBar from '@ui/SearchBar';
import DropDown from '@ui/DropDown';
import { SlidersIcon, TargetIcon, TeamIcon, UserIcon } from '@icons/Common';
import DropDownMultiSelect from '@ui/DropDownMultiSelectV2';
import { PrizePoolTrophyIcon } from '@components/common/icons/TournamentIcons';
import { FaXmark } from 'react-icons/fa6';

const getFilterIconForList = (listTitle: string): ReactNode => {
  switch (listTitle.toLowerCase()) {
    case 'tournaments':
      return (
        <PrizePoolTrophyIcon
          className={'aspect-square h-auto w-[16px] fill-green'}
        />
      );
    case 'teams':
      return <TeamIcon className="aspect-square h-auto w-[16px] fill-green" />;
    case 'players':
      return <UserIcon className="aspect-square h-auto w-[12px] fill-green" />;
    default:
      return '';
  }
};

interface DropDownOption {
  value: number;
  label: string;
}

interface ISearchSortFilterBar {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  selectedSortingOption: number;
  setSelectedSortingOption: (option: number) => void;
  sortingOptions: DropDownOption[];
  // Optional filter props
  filterOptions?: DropDownOption[];
  selectedFilterOptions?: number[];
  setSelectedFilterOptions?: (options: number[]) => void;
  // Optional region props
  regionDropdownOptions?: DropDownOption[];
  selectedRegionOption?: number;
  setSelectedRegionOption?: (option: number) => void;
  // Other props
  initiallyLoaded: boolean;
  listTitle: string;
  searchBarPlaceholder?: string;
  className?: string;
  variant?: 'default' | 'tournament' | 'leaderboard';
}

const SearchSortFilterBar: React.FC<ISearchSortFilterBar> = ({
  searchQuery,
  setSearchQuery,
  selectedSortingOption,
  setSelectedSortingOption,
  sortingOptions,
  filterOptions,
  selectedFilterOptions,
  setSelectedFilterOptions,
  regionDropdownOptions,
  selectedRegionOption,
  setSelectedRegionOption,
  searchBarPlaceholder,
  listTitle,
  initiallyLoaded,
  className,
  variant = 'default',
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [filterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);
  const filterIcon = getFilterIconForList(listTitle);

  const toggleFilterMenu = () => {
    const open = !filterMenuOpen;
    setFilterMenuOpen(open);
    if (open) {
      document.body.classList.add('overflow_hidden');
    } else {
      document.body.classList.remove('overflow_hidden');
    }
  };

  return (
    <div
      className={`
        relative

        ${className}

        flex flex-col gap-y-6
      `}
    >
      {/* Mobile filter menu for tournament variant */}
      {variant === 'tournament' && (
        <div
          className={`
            fixed left-0 top-0 z-[20] h-[100dvh] w-[100vw] bg-black/60

            ${filterMenuOpen ? '' : 'pointer-events-none opacity-0'}

            transition-all
          `}
        >
          <div
            className={`
              absolute bg-lightBlack bottom-0 left-0 flex h-[40%] w-full
              flex-col rounded-t-xl
            `}
          >
            <div
              className={`
                mb-2 flex w-full items-center justify-between border-b p-4
                border-lightGray
              `}
            >
              <p
                className={`
                  font-wide text-lg font-semibold uppercase text-white
                `}
              >
                Filters
              </p>
              <button
                type="button"
                aria-label="close filter menu"
                onClick={toggleFilterMenu}
                className={`
                  block !border-0 p-2 !outline-0 text-steelGray
                  transition-opacity

                  hover:opacity-75
                `}
              >
                <FaXmark className="text-3xl" />
              </button>
            </div>

            <div className="h-fit flex-grow overflow-y-scroll px-4 pb-[120px]">
              {/* Region filter section */}
              <div className="my-6 flex flex-col gap-y-2">
                <p
                  className={`
                    font-wide text-lg font-semibold uppercase text-white
                  `}
                >
                  Region
                </p>
                {regionDropdownOptions &&
                  selectedRegionOption !== undefined &&
                  setSelectedRegionOption && (
                    <DropDown
                      icon={
                        <TargetIcon
                          className={'aspect-square h-auto w-[16px] fill-green'}
                        />
                      }
                      loaded={initiallyLoaded}
                      currentOption={
                        regionDropdownOptions[selectedRegionOption]
                      }
                      options={regionDropdownOptions}
                      handleOnChange={setSelectedRegionOption}
                      className="bg-lightGray max-w-[200px]"
                    />
                  )}
              </div>

              {/* Filter section */}
              {filterOptions &&
                selectedFilterOptions &&
                setSelectedFilterOptions && (
                  <div className="my-6 flex flex-col gap-y-2">
                    <p
                      className={`
                        font-wide text-lg font-semibold uppercase text-white
                      `}
                    >
                      Filter By
                    </p>
                    <DropDownMultiSelect
                      icon={filterIcon}
                      title="Filters"
                      loaded={initiallyLoaded}
                      selectedOptions={selectedFilterOptions}
                      options={filterOptions}
                      setSelectedOptions={setSelectedFilterOptions}
                      className="bg-lightGray max-w-[200px]"
                    />
                  </div>
                )}

              {/* Sort by section */}
              <div className="my-6 flex flex-col gap-y-2">
                <p
                  className={`
                    font-wide text-lg font-semibold uppercase text-white
                  `}
                >
                  Sort By
                </p>
                <DropDown
                  icon={
                    <SlidersIcon
                      className={'aspect-square h-auto w-[16px] stroke-green'}
                    />
                  }
                  loaded={initiallyLoaded}
                  currentOption={sortingOptions[selectedSortingOption]}
                  options={sortingOptions}
                  handleOnChange={setSelectedSortingOption}
                  className="bg-lightGray max-w-[200px]"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Header section */}
      <div
        className={`
          flex items-center

          ${
            variant === 'tournament' || variant === 'leaderboard'
              ? 'justify-between'
              : ''
          }

          border-t border-lightBlack pt-8
        `}
      >
        {initiallyLoaded ? (
          <h2
            className={`
              ${`
                font-wide text-xl font-semibold uppercase

                lg:text-3xl
              `}

              text-white
            `}
          >
            {listTitle}
          </h2>
        ) : (
          <SkeletonDark className="h-[25px] w-[120px] rounded-xl" />
        )}

        {/* Desktop dropdowns */}
        <div
          className={`
            ml-auto hidden items-center gap-x-4

            md:flex
          `}
        >
          {filterOptions &&
            selectedFilterOptions &&
            setSelectedFilterOptions && (
              <DropDownMultiSelect
                icon={filterIcon}
                title="Filters"
                loaded={initiallyLoaded}
                selectedOptions={selectedFilterOptions}
                options={filterOptions}
                setSelectedOptions={setSelectedFilterOptions}
                className="!mt-0"
              />
            )}

          {regionDropdownOptions &&
            selectedRegionOption !== undefined &&
            setSelectedRegionOption && (
              <DropDown
                ref={dropDownRef}
                icon={
                  <TargetIcon
                    className={'aspect-square h-auto w-[16px] fill-green'}
                  />
                }
                loaded={initiallyLoaded}
                currentOption={regionDropdownOptions[selectedRegionOption]}
                options={regionDropdownOptions}
                handleOnChange={setSelectedRegionOption}
                className="!mt-0"
              />
            )}

          {variant !== 'leaderboard' && (
            <DropDown
              icon={
                <SlidersIcon
                  className={'aspect-square h-auto w-[16px] stroke-green'}
                />
              }
              loaded={initiallyLoaded}
              currentOption={sortingOptions[selectedSortingOption]}
              options={sortingOptions}
              handleOnChange={setSelectedSortingOption}
              className="!mt-0"
            />
          )}
        </div>
      </div>

      {/* Search bar section - only show if not leaderboard variant */}
      {variant !== 'leaderboard' && (
        <div className="flex w-full flex-col gap-y-4">
          <div className="flex w-full items-center gap-x-4">
            <SearchBar
              loaded={initiallyLoaded}
              placeholder={searchBarPlaceholder || 'Search'}
              value={searchQuery}
              setValue={setSearchQuery}
              className="w-full flex-grow-[4]"
            />
            {variant === 'tournament' && (
              <button
                type="button"
                onClick={toggleFilterMenu}
                className={`
                  flex items-center justify-center rounded-xl p-3
                  transition-opacity bg-lightBlack

                  hover:opacity-75

                  md:hidden
                `}
              >
                <SlidersIcon
                  className={'aspect-square h-auto w-[18px] stroke-green'}
                />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchSortFilterBar;
