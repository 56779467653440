import React, { useEffect, useState } from 'react';
import LeaderboardCard from './LeaderboardCard';
import {
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { where, startAfter, collection, query } from 'firebase/firestore';
import { DBUser } from '@src/firestore/users';
import { userConverter } from '@src/firestore/users';
import { firestore } from '@src/firebase';
import {
  getQueryConstraintsForRegionOption,
  RegionOption,
} from '@src/components/global/utils';
import PaginationBar from '@src/components/ui/PaginationBar';
import { PlayerResultsPerPage } from '@src/components/players/utils';
import { LeaderboardSortingOption } from '../types';
import {
  SkeletonDark,
  SkeletonLight,
} from '@components/common/skeleton-loader/Skeletons';

interface PlayerLeaderboardsProps {
  selectedRegion: RegionOption;
  selectedSorting: LeaderboardSortingOption;
}

const playerSortingDropdownOptions = [
  {
    value: LeaderboardSortingOption.totalWinningsDesc,
    label: 'Winnings',
  },
  {
    value: LeaderboardSortingOption.trophiesWonDesc,
    label: 'Trophies Won',
  },
];

const PlayerLeaderboards: React.FC<PlayerLeaderboardsProps> = ({
  selectedRegion,
  selectedSorting,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [players, setPlayers] = useState<DBUser[]>([]);
  const [pageNum, setPageNum] = useState(1);
  const [lastPageNum, setLastPageNum] = useState<number>(1);
  const [lastVisible, setLastVisible] =
    useState<QueryDocumentSnapshot<DBUser> | null>(null);

  useEffect(() => {
    getPlayers();
    console.log(selectedSorting);
  }, [selectedRegion, selectedSorting, pageNum]);

  const sortingConstraint = (selectedSorting: LeaderboardSortingOption) => {
    switch (selectedSorting) {
      case LeaderboardSortingOption.totalWinningsDesc:
        return [orderBy('totalWinnings', 'desc'), orderBy('uid')];
      case LeaderboardSortingOption.trophiesWonDesc:
        return [orderBy('trophyCount', 'desc'), orderBy('uid')];
      default:
        return [orderBy('totalWinnings', 'desc'), orderBy('uid')];
    }
  };

  const getPlayers = async () => {
    setIsLoading(true);
    const usersCollection = collection(firestore, 'users').withConverter(
      userConverter
    );

    const queryRegionConstraints =
      getQueryConstraintsForRegionOption(selectedRegion);

    let baseQuery = query(
      usersCollection,
      ...queryRegionConstraints,
      ...sortingConstraint(selectedSorting),
      where('profileComplete', '==', true),
      limit(PlayerResultsPerPage)
    );

    if (pageNum > 1 && lastVisible) {
      baseQuery = query(
        usersCollection,
        ...queryRegionConstraints,
        where('profileComplete', '==', true),
        startAfter(lastVisible),
        limit(PlayerResultsPerPage)
      );
    }

    try {
      const countQuery = query(
        usersCollection,
        ...queryRegionConstraints,
        where('profileComplete', '==', true)
      );
      const count = (await getCountFromServer(countQuery)).data().count;
      setLastPageNum(Math.ceil(count / PlayerResultsPerPage));

      const snapshots = await getDocs(baseQuery);
      const lastVisible = snapshots.docs[snapshots.docs.length - 1];
      setLastVisible(lastVisible);

      const playerData = snapshots.docs.map((doc) => doc.data());
      console.log(playerData);
      setPlayers(playerData);
    } catch (error) {
      console.error('Error fetching players:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const changePage = (newPage: number) => {
    setPageNum(newPage);
  };

  return (
    <div>
      {isLoading ? (
        <div>
          {[...Array(PlayerResultsPerPage)].map((_, index) => (
            <div className="mt-4" key={`skeleton-${index}`}>
              {index % 2 === 0 ? (
                <SkeletonDark className="h-[84.5px] w-full rounded-lg" />
              ) : (
                <SkeletonLight className="h-[84.5px] w-full rounded-lg" />
              )}
            </div>
          ))}
        </div>
      ) : (
        <>
          {players.map((player, index) => (
            <div className="mt-4" key={player.id + index}>
              <LeaderboardCard
                currentSorting={selectedSorting}
                entity={player}
                index={(pageNum - 1) * PlayerResultsPerPage + index}
                type="player"
              />
            </div>
          ))}
          <PaginationBar
            className="my-[50px]"
            totalPages={lastPageNum}
            currentPage={pageNum}
            changePage={changePage}
          />
        </>
      )}
    </div>
  );
};

export default PlayerLeaderboards;
export { playerSortingDropdownOptions };
