import { Link } from 'react-router-dom';
// types
import { DBTeam, PlayerData } from '@src/firestore/teams';
// packages
import '/node_modules/flag-icons/css/flag-icons.min.css';
// components
import PopoverText from '@ui/PopoverText';
import { SkeletonLight } from '@components/common/skeleton-loader/Skeletons';
// assetts
import placeholderImage from '@assets/images/placeholder/placeholder.webp';
// icons
import {
  ConsoleIcon,
  ControllerIcon,
  LocationIcon,
  MagnifyingGlassIcon,
  MonitorIcon,
  MouseIcon,
} from '@components/common/icons/Common';
import {
  TrophyBronzeImage,
  TrophyGoldImage,
  TrophySilverImage,
} from '@components/common/icons/TournamentIcons';
import { ApexLegendsIcon } from '@icons/Games';

const platformStringToIcon = (platform: string) => {
  switch (platform) {
    case 'PC':
      return (
        <MonitorIcon className="w-[12px] h-auto aspect-square fill-steelGray group-hover:fill-white/65  transition-colors" />
      );
    default:
      return (
        <ConsoleIcon className="w-[16px] h-auto aspect-square fill-steelGray group-hover:fill-white/65  transition-colors" />
      );
  }
};

const gameInputStringToIcon = (inputName: string) => {
  switch (inputName) {
    case 'Mouse and Keyboard':
      return (
        <MouseIcon className="w-[12px] h-auto aspect-square fill-steelGray group-hover:fill-white/65  transition-colors" />
      );
    case 'Controller':
      return (
        <ControllerIcon className="w-[12px] h-auto aspect-square fill-steelGray group-hover:fill-white/65  transition-colors" />
      );
    default:
      return '';
  }
};

const CardCornerChevron = ({ className }: { className: string }) => (
  <svg
    className={`${className}`}
    viewBox="0 0 301 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M104.375 173.938L221.424 286.485C251.288 315.2 301 294.035 301 252.605V47C301 21.0426 279.957 0 254 0H47.9957C6.62477 0 -14.565 49.5911 14.0315 79.4875L104.375 173.938Z"
      fill="#D4FF27"
      className="fill-inherit"
    />
  </svg>
);

interface IMiniPlayerCard {
  player: PlayerData;
  team: DBTeam;
}

const MiniPlayerCard: React.FC<IMiniPlayerCard> = ({ player, team }) => {
  const captain = player.id === team.captain;
  return (
    <div className="flex items-start gap-x-2">
      <img
        src={
          player.displayImage !== '' ? player.displayImage : placeholderImage
        }
        alt={`${player.displayName} display image`}
        className="w-[36px] h-auto aspect-square rounded-full"
      />
      <div className="flex flex-col gap-y-2 items-center">
        <p className="bg-ebonyClay font-compact font-semibold text-xs text-steelGray px-[5px] py-[1px] rounded-xl">
          {captain ? 'Owner' : 'Player'}
        </p>
        <div className="flex items-center gap-x-2">
          <p className="text-steelGray font-compact font-semibold">
            {platformStringToIcon(player.platform)}
          </p>
          <p className="text-steelGray font-compact font-semibold">
            {gameInputStringToIcon(player.input)}
          </p>
        </div>
      </div>
    </div>
  );
};

interface ITeamCard {
  team: DBTeam;
}

const TeamCard: React.FC<ITeamCard> = ({ team }) => {
  const teamTrophies = team.trophyData;

  const goldTrophies = teamTrophies.filter((trophy) => trophy.position === 1);
  const silverTrophies = teamTrophies.filter((trophy) => trophy.position === 2);
  const bronzeTrophies = teamTrophies.filter((trophy) => trophy.position === 3);

  return (
    <Link
      to={`/team/${team.id}`}
      className="relative overflow-hidden w-[90%] lg:w-full rounded-xl bg-lightBlack hover:bg-lightGray p-2 flex flex-col group flex-shrink-0 flex-grow-0 transition-all"
    >
      <CardCornerChevron className="absolute top-3 right-3 z-[4] w-[15px] h-auto aspect-square fill-green transition-colors" />
      <div className="absolute top-3 right-3 bg-lightBlack group-hover:bg-lightGray z-[3] w-[100px] h-auto aspect-square rotate-45 origin-center translate-x-[70%] -translate-y-[70%]  transition-all"></div>

      <div className="h-auto min-h-[140px] aspect-[4.5] w-full transition-all relative  z-[2]">
        <div className="relative w-full h-full px-6 bg-lightBlack group-hover:bg-lightGray transition-colors">
          <div className="absolute top-0 left-0 group-hover:-top-[2.5px] group-hover:-left-[2.5px] w-full h-full rounded-lg overflow-hidden group-hover:w-[calc(100%+5px)] group-hover:h-[calc(100%+5px)] transition-all">
            <div className="absolute top-0 left-0 z-[1] bg-black/70 group-hover:bg-black/35 w-full h-full transition-colors"></div>
            <img
              src={team.bannerUrl !== '' ? team.bannerUrl : placeholderImage}
              alt={`${team.teamName} banner image`}
              className="absolute z-[0] top-0 left-0 w-full h-full object-cover opacity-50"
            />
          </div>

          <div className="relative flex items-center gap-x-10 h-full z-[2]">
            <div className="relative z-[2] h-fit w-fit transition-transform">
              <img
                src={team.logoUrl}
                alt={`${team.teamName} logo`}
                className="relative z-[1] w-[110px] h-auto aspect-square rounded-2xl group-hover:translate-x-[-5px] bg-lightBlack group-hover:bg-lightGray border-[3px] border-lightBlack object-cover transition-all"
              />
            </div>

            <div className="flex flex-col gap-y-2">
              <div className="flex items-center gap-x-2">
                <h3 className="font-wide font-semibold uppercase text-white text-2xl lg:text-[1.6rem]">
                  {team.teamName}
                </h3>
                {/* <img src={team.logoUrl} alt={`${team.teamName} logo`}
                  className='hidden lg:block mb-1 w-[25px] h-auto aspect-square rounded border border-steelGray group-hover:border-white/65 object-cover transition-colors'/> */}
              </div>
              <div className="flex items-center gap-x-2">
                <p className="text-steelGray group-hover:text-white/65 font-compact font-medium -mb-1 xl:text-lg transition-colors">
                  {team.teamTag.toUpperCase()}
                </p>
                <ApexLegendsIcon className="w-[18px] h-auto aspect-square fill-steelGray group-hover:fill-white/65 transition-colors" />
                <div className="flex items-center gap-x-1">
                  <LocationIcon className="w-[15px] h-auto aspect-square fill-steelGray group-hover:fill-white/65 transition-colors" />
                  <p className="text-steelGray group-hover:text-white/65 font-compact font-medium -mb-1 xl:text-lg transition-colors">
                    {team.region.toUpperCase()}
                  </p>
                </div>
                {team.lookingForPlayers ? (
                  <div className="relative group/lfp">
                    <PopoverText
                      visible={true}
                      className="opacity-0 group-hover/lfp:opacity-100 transition-opacity"
                    >
                      Looking for Players
                    </PopoverText>
                    <MagnifyingGlassIcon className="w-[14px] h-auto aspect-square stroke-green" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-y-4 w-full px-4 py-4">
        <div className="flex flex-col gap-y-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center w-full">
              <div className="flex items-center gap-x-1 -ml-2">
                <div className="relative w-fit h-fit">
                  <div
                    className={`${goldTrophies.length > 0 ? '' : 'hidden'} absolute z-[2] top-0 right-0 w-[15px] h-[15px] aspect-square rounded-full bg-lightBlack  border border-lightGray
                                flex items-center justify-center`}
                  >
                    <p className="text-xs mt-[3px] font-compact text-white">
                      {goldTrophies.length}
                    </p>
                  </div>
                  <TrophyGoldImage
                    className={`w-[54px] h-auto aspect-square object-contain ${goldTrophies.length > 0 ? '' : 'opacity-50'}`}
                  />
                </div>

                <div className="relative w-fit h-fit">
                  <div
                    className={`${silverTrophies.length > 0 ? '' : 'hidden'} absolute z-[2] top-0 right-0 w-[15px] h-[15px] aspect-square rounded-full bg-lightBlack  border border-lightGray
                                flex items-center justify-center`}
                  >
                    <p className="text-xs mt-[3px] font-compact text-white">
                      {silverTrophies.length}
                    </p>
                  </div>
                  <TrophySilverImage
                    className={`w-[54px] h-auto aspect-square object-contain ${silverTrophies.length > 0 ? '' : 'opacity-50'}`}
                  />
                </div>

                <div className="relative w-fit h-fit">
                  <div
                    className={`${bronzeTrophies.length > 0 ? '' : 'hidden'} absolute z-[2] top-0 right-0 w-[15px] h-[15px] aspect-square rounded-full bg-lightBlack  border border-lightGray
                                flex items-center justify-center`}
                  >
                    <p className="text-xs mt-[3px] font-compact text-white">
                      {bronzeTrophies.length}
                    </p>
                  </div>
                  <TrophyBronzeImage
                    className={`w-[54px] h-auto aspect-square object-contain ${bronzeTrophies.length > 0 ? '' : 'opacity-50'}`}
                  />
                </div>
              </div>

              <div className="h-[35px] w-[2px] bg-lightGray group-hover:bg-ebonyClay ml-2 mr-4 transition-colors"></div>

              <div className="mt-1 xl:text-lg">
                <p className="font-wide font-semibold text-white uppercase">
                  Winnings
                </p>
                <p className="font-wide font-semibold text-green uppercase">
                  {team.totalWinnings > 0 ? `$${team.totalWinnings}` : 'N/A'}
                </p>
              </div>
            </div>

            <div className="hidden md:flex items-center gap-x-2">
              <div className="flex flex-col gap-y-1 items-center bg-lightGray group-hover:bg-ebonyClay w-[100px] rounded-lg py-2 px-6 transition-colors">
                <p className="text-white font-compact text-sm whitespace-nowrap">
                  Match Wins
                </p>
                <p className="text-green font-wide font-semibold">
                  {team.apexMatchNum > 0 ? team.apexMatchWins : 'N/A'}
                </p>
              </div>

              <div className="flex flex-col gap-y-1 items-center bg-lightGray group-hover:bg-ebonyClay w-[100px] rounded-lg py-2 px-6 transition-colors">
                <p className="text-white font-compact text-sm whitespace-nowrap">
                  Win Rate
                </p>
                <p className="text-green font-wide font-semibold">
                  {team.apexMatchNum > 0 ? `${team.apexMatchWinrate}%` : 'N/A'}
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-x-4 w-full">
            {team.playerData.length > 0 ? (
              <>
                {team.playerData.slice(0, 5).map((player, index) => (
                  <MiniPlayerCard
                    key={`mini-player-card-${player.id}-${team.id}-${index}`}
                    player={player}
                    team={team}
                  />
                ))}
                {team.playerData.length > 5 ? (
                  <>
                    <div className="h-[35px] w-[2px] bg-lightGray group-hover:bg-ebonyClay mx-2"></div>
                    <div
                      className="w-[40px] h-auto aspect-square rounded-full bg-ebonyClay group-hover:bg-ebonyClay
                                  flex items-center justify-center"
                    >
                      <p className="font-wide font-semibold text-steelGray text-lg md:text-xl -mb-2">
                        +{team.playerData.length - 5}
                      </p>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                {Array.from({ length: 4 }).map((_i, index) => (
                  <div
                    key={`mini-player-card-loader-${index}`}
                    className="flex items-center gap-x-2"
                  >
                    <SkeletonLight className="w-[30px] h-auto aspect-square rounded-full" />
                    <div className="flex flex-col gap-y-2">
                      <SkeletonLight className="w-[40px] h-[15px] rounded" />
                      <div className="flex items-center gap-x-1">
                        <SkeletonLight className="w-[12px] h-auto aspect-square rounded" />
                        <SkeletonLight className="w-[12px] h-auto aspect-square rounded" />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TeamCard;
