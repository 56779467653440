import React from 'react';

interface Tab {
  title: string;
  component: React.ReactNode;
}

interface SubNavProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  tabs: Tab[];
}

const SubNav: React.FC<SubNavProps> = ({ activeTab, setActiveTab, tabs }) => {
  return (
    <div className={'flex items-center justify-start bg-black pt-6'}>
      {tabs.map((tab) => (
        <button
          key={tab.title}
          onClick={() => setActiveTab(tab.title)}
          className={`
            px-4 py-3 text-base font-compact

            ${
              activeTab === tab.title
                ? 'text-green border-b-2 border-green'
                : `
                  text-gray-400

                  hover:text-gray-200
                `
            }
          `}
        >
          {tab.title}
        </button>
      ))}
    </div>
  );
};

export default SubNav;
