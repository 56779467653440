import { useState, useEffect } from 'react';
// context
import {
  usePlayersContext,
  PlayerResultsPerPage,
} from '@components/players/utils';
// types
import {
  PlayersFilterOption,
  PlayersSortingOption,
} from '@components/players/types';
import { DBUser } from '@src/firestore/users';
// utils
// import { debounce } from '@utils/Debounce';
// components
import PlayerCard from './PlayerCard';
import PaginationBar from '@ui/PaginationBar';
import SearchSortFilterBar from '@components/global/SearchSortFilterBar';
import SkeletonPlayerCard from './SkeletonPlayerCard';
// icons
import { ImSpinner8 } from 'react-icons/im';
import { VscDebugDisconnect } from 'react-icons/vsc';
import { RegionOption } from '@src/components/global/utils';

const regionDropdownOptions = [
  { value: RegionOption.ALL, label: 'All Regions' },
  { value: RegionOption.EMEA, label: 'EMEA' },
  { value: RegionOption.NA, label: 'NA' },
  { value: RegionOption.LATAM, label: 'LATAM' },
  { value: RegionOption.APAC, label: 'APAC' },
];

const sortingDropdownOptions = [
  {
    value: PlayersSortingOption.totalWinningsDesc,
    label: 'Winnings: High - Low',
  },
  { value: PlayersSortingOption.nameDesc, label: 'Name: A - Z' },
  {
    value: PlayersSortingOption.tournamentsPlayedDesc,
    label: 'Tournaments Played: High - Low',
  },
  {
    value: PlayersSortingOption.trophiesWonDesc,
    label: 'Trophies Won: High - Low',
  },
  { value: PlayersSortingOption.legendMainDesc, label: 'Legend Main: A - Z' },
];

const filterDropdownOptions = [
  {
    value: PlayersFilterOption.pc,
    label: 'PC',
  },
  {
    value: PlayersFilterOption.console,
    label: 'Console',
  },
  {
    value: PlayersFilterOption.mouseKeyboard,
    label: 'Mouse/Keyboard',
  },
  {
    value: PlayersFilterOption.controller,
    label: 'Controller',
  },
];

const PlayerList = () => {
  const {
    players,
    searchQuery,
    setSearchQuery,
    searchQueued,
    initiallyLoaded,
    loadMorePlayers,
    loadingMore,
    totalPlayerResultsCount,
    // gameOption,
    // setGameOption,
    sortingOption,
    setSortingOption,
    regionOption,
    setRegionOption,
    filterOptions,
    setFilterOptions,
    // playersCountByGame,
  } = usePlayersContext();
  const [playersInPage, setPlayersInPage] = useState<DBUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [pageNum, setPageNum] = useState<number>(1);
  const [lastPageNum, setLastPageNum] = useState<number>(1);

  const changePage = async (newPageNum: number) => {
    setLoading(true);
    if (newPageNum > 0) {
      const loadedPages = players.length / PlayerResultsPerPage;
      if (newPageNum <= loadedPages) {
        setPageNum(newPageNum);
      } else if (newPageNum <= lastPageNum) {
        const amountNeeded = Math.ceil(
          (newPageNum - loadedPages) * PlayerResultsPerPage
        );
        await loadMorePlayers(amountNeeded);
        setPageNum(newPageNum);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const pageStartIndex = Math.max(0, (pageNum - 1) * PlayerResultsPerPage);
    const pageEndIndex = pageNum * PlayerResultsPerPage;
    setPlayersInPage([...players].slice(pageStartIndex, pageEndIndex));
    setLoading(false);
  }, [players, pageNum]);

  useEffect(() => {
    setLastPageNum(Math.ceil(totalPlayerResultsCount / PlayerResultsPerPage));
  }, [totalPlayerResultsCount]);

  //  const gameCheckBoxSubtextValues = {
  //   'Apex Legends': `${playersCountByGame.apexLegends} players`,
  //   Valorant: `${0} players`,
  //   Fortnite: `${0} players`,
  //   'Rocket League': `${0} players`,
  // };

  return (
    <div className="overflow-hidden">
      <SearchSortFilterBar
        listTitle="players"
        regionDropdownOptions={regionDropdownOptions}
        // searchEnabled={true}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedRegionOption={regionOption}
        setSelectedRegionOption={setRegionOption}
        // selectedGameOption={gameOption}
        // setSelectedGameOption={setGameOption}
        // gameCheckBoxSubtextValues={gameCheckBoxSubtextValues}
        selectedSortingOption={sortingOption}
        setSelectedSortingOption={setSortingOption}
        sortingOptions={sortingDropdownOptions}
        filterOptions={filterDropdownOptions}
        selectedFilterOptions={filterOptions}
        setSelectedFilterOptions={setFilterOptions}
        initiallyLoaded={initiallyLoaded}
        searchBarPlaceholder="Search for a player"
      />

      {!loading && initiallyLoaded ? (
        <>
          {searchQueued ? (
            <div
              className={`
                flex min-h-[350px] flex-col items-center justify-center
              `}
            >
              <ImSpinner8
                className={`
                  aspect-square h-auto w-[60px] text-green animate-spin
                `}
              />
            </div>
          ) : (
            <>
              <>
                {playersInPage.length > 0 ? (
                  <div className="w-full">
                    <div
                      className={`
                        mb-6 mt-8 flex w-full flex-wrap-0 gap-4
                        overflow-x-scroll

                        lg:grid lg:grid-cols-2
                      `}
                    >
                      {playersInPage.map((player, index) => (
                        <PlayerCard
                          key={`main-list-${player.displayName}-${player.uid}-${index}`}
                          player={player}
                        />
                      ))}
                      {loadingMore ? (
                        <>
                          {Array.from({ length: 12 }).map((_i, index) => (
                            <SkeletonPlayerCard
                              key={`main-list-loader-${index}`}
                            />
                          ))}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                    <PaginationBar
                      className="my-[50px]"
                      totalPages={lastPageNum}
                      currentPage={pageNum}
                      changePage={changePage}
                    />
                  </div>
                ) : (
                  <div
                    className={`
                      flex min-h-[350px] flex-col items-center justify-center
                      gap-y-2
                    `}
                  >
                    <div
                      className={`
                        flex aspect-square h-auto items-center justify-center
                        bg-lightGray w-[50px] rounded-full
                      `}
                    >
                      <VscDebugDisconnect className="text-2xl text-white" />
                    </div>
                    <p
                      className={`
                        font-wide text-2xl font-semibold uppercase text-white
                      `}
                    >
                      No players found
                    </p>
                    <p className="text-steelGray font-compact">
                      Players will show here when found
                    </p>
                  </div>
                )}
              </>
            </>
          )}
        </>
      ) : (
        <div
          className={`
            my-8 flex w-full flex-wrap-0 gap-4 overflow-x-scroll

            lg:mb-[150px] lg:grid lg:grid-cols-2
          `}
        >
          {Array.from({ length: PlayerResultsPerPage }).map((_i, index) => (
            <SkeletonPlayerCard key={`main-list-loader-${index}`} />
          ))}
        </div>
      )}
    </div>
  );
};

export default PlayerList;
