import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// firebase
import { firestore } from '../../firebase';
import {
  query,
  collection,
  onSnapshot,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
  deleteDoc,
  Unsubscribe,
  Timestamp,
} from 'firebase/firestore';
// types
import {
  Tournament,
  tournamentConverter,
  TournamentGroup,
  TournamentStatus,
  TournamentTeam,
  TournamentTeamStatus,
  tournamentRegionToString,
  TournamentGame,
  TournamentTeamRaw,
} from '../../firestore/tournaments';
import { useAuthContext } from '../../provider/AuthContextProvider';
import { DBTeam, PlayerData, teamConverter } from '../../firestore/teams';
// libraries
import { toast } from 'react-toastify';
import { formatDateCountdown } from '@utils/Date';

export enum TournamentTeamStatusWithEliminated {
  registered,
  waiting,
  confirmed,
  declined,
  ignored,
  eliminated,
}

export interface TournamentStage {
  stageNum: number;
  groups: TournamentGroup[];
}

export interface UserTournamentState {
  tournamentFull: boolean;
  registrationOpen: boolean;
  waitingListOpen: boolean;
  waitingListCheckInActive: boolean;
  teamStatus: TournamentTeamStatusWithEliminated | null;
  signedIn: boolean;
  profileComplete: boolean;
  registered: boolean;
  waiting: boolean;
  declined: boolean;
  ignored: boolean;
  confirmed: boolean;
  eliminated: boolean;
  teamCaptain: boolean;
  hasTeam: boolean;
  teamTooSmall: boolean;
  teamTooLarge: boolean;
  wrongRegion: boolean;
}

const defaultUserTournamentState = {
  tournamentFull: false,
  registrationOpen: false,
  waitingListOpen: false,
  waitingListCheckInActive: false,
  signedIn: false,
  profileComplete: false,
  teamStatus: null,
  registered: false,
  waiting: false,
  declined: false,
  ignored: false,
  confirmed: false,
  eliminated: false,
  teamCaptain: false,
  hasTeam: false,
  teamTooSmall: false,
  teamTooLarge: false,
  wrongRegion: false,
};

interface TournamentContext {
  tournament: Tournament | null;
  setTournament: (tournament: Tournament | null) => void;
  tournamentRegionName: string;
  timeUntilEntrantsCheckIn: string;
  timeUntilWaitingListCheckin: string;
  userTournamentState: UserTournamentState;
  tournamentStages: TournamentStage[];
  tournamentCompleted: boolean;
  tournamentTeams: TournamentTeam[];
  tournamentTeamObjs: DBTeam[];
  setTournamentTeamObjs: (teams: DBTeam[]) => void;
  registeredTeamsLength: number;
  registerForTournament: () => void;
  unregisterFromTournament: () => void;
  joinWaitingList: () => void;
  leaveWaitingList: () => void;
}

const defaultTournamentContext = {
  tournament: null,
  setTournament: () => false,
  tournamentRegionName: '',
  userTournamentState: defaultUserTournamentState,
  timeUntilEntrantsCheckIn: '',
  timeUntilWaitingListCheckin: '',
  tournamentStages: [],
  tournamentCompleted: false,
  tournamentTeams: [],
  tournamentTeamObjs: [],
  setTournamentTeamObjs: () => false,
  registeredTeamsLength: 0,
  registerForTournament: () => false,
  unregisterFromTournament: () => false,
  joinWaitingList: () => false,
  leaveWaitingList: () => false,
};

const TournamentContext = createContext<TournamentContext>(
  defaultTournamentContext
);

export const useTournamentContext = () => {
  const context = useContext(TournamentContext);
  return context;
};

interface ITournamentProvider {
  children: ReactNode;
  setTournamentLoaded: (loaded: boolean) => void;
  setTournamentFound: (found: boolean) => void;
  announceTournament: (tournament: Tournament | null) => void;
}

const TournamentProvider: React.FC<ITournamentProvider> = ({
  children,
  setTournamentLoaded,
  setTournamentFound,
  announceTournament,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { userObj, userTeam } = useAuthContext();

  const [userTournamentState, setUserTournamentState] =
    useState<UserTournamentState>({ ...defaultUserTournamentState });

  const [tournament, setTournament] = useState<Tournament | null>(null);
  const [tournamentRegionName, setTournamentRegionName] = useState<string>('');
  const [tournamentCompleted, setTournamentCompleted] =
    useState<boolean>(false);
  const [tournamentStages, setTournamentStages] = useState<TournamentStage[]>(
    []
  );

  const [tournamentTeams, setTournamentTeams] = useState<TournamentTeam[]>([]);
  const [tournamentTeamObjs, setTournamentTeamObjs] = useState<DBTeam[]>([]);
  const [
    tournamentTeamsListenerInitialised,
    setTournamentTeamsListenerInitialised,
  ] = useState<boolean>(false);

  const [registeredTeamsLength, setRegisteredTeamsLength] = useState<number>(0);

  const [currentTime, setCurrentTime] = useState<number>(0);

  const [timeUntilEntrantsCheckIn, setTimeUntilEntrantsCheckIn] =
    useState<string>('');
  const [timeUntilWaitingListCheckin, setTimeUntilWaitingListCheckin] =
    useState<string>('');

  const processCheckInCountdown = useCallback((): NodeJS.Timeout => {
    return setInterval(() => {
      if (tournament) {
        const latestTimeUntilEntrantsCheckIn = formatDateCountdown(
          new Date(tournament.statusDates.confirmation.getTime())
        );
        const latestTimeUntilWaitingListCheckin = formatDateCountdown(
          new Date(tournament.statusDates.confirmation.getTime() + 3_600_000)
        );
        setTimeUntilEntrantsCheckIn(latestTimeUntilEntrantsCheckIn);
        setTimeUntilWaitingListCheckin(latestTimeUntilWaitingListCheckin);
      }
    }, 15000);
  }, [tournament]);

  useEffect(() => {
    if (tournament && tournament.hidden && (!userObj || !userObj.admin)) {
      toast.error('You shall not pass 🪵');
      navigate('/tournaments');
    }
  }, [navigate, tournament, userObj]);

  useEffect(() => {
    const interval = processCheckInCountdown();
    return () => clearInterval(interval);
  }, [processCheckInCountdown]);

  const updateCurrentTime = () => {
    setCurrentTime(new Date().getTime());
  };

  useEffect(() => {
    setCurrentTime(new Date().getTime());
    const interval = setInterval(updateCurrentTime, 15000);

    return () => clearInterval(interval);
  }, []);

  const calculateUserTournamentState = useCallback(() => {
    const localUserTournamentState: UserTournamentState = {
      ...defaultUserTournamentState,
    };

    localUserTournamentState.tournamentFull =
      tournament !== null && registeredTeamsLength >= tournament.teamCapacity;
    localUserTournamentState.registrationOpen =
      tournament !== null &&
      currentTime <= tournament.statusDates.confirmation.getTime();
    localUserTournamentState.waitingListOpen =
      tournament !== null &&
      currentTime <= tournament.statusDates.confirmation.getTime() + 3_600_000;
    localUserTournamentState.waitingListCheckInActive =
      tournament !== null &&
      tournament.status === TournamentStatus.confirmation &&
      currentTime >= tournament.statusDates.confirmation.getTime() + 3_600_000;

    localUserTournamentState.signedIn = userObj !== null;
    localUserTournamentState.profileComplete =
      userObj !== null && userObj.profileComplete;
    localUserTournamentState.hasTeam = userTeam !== null;

    localUserTournamentState.teamCaptain =
      userObj !== null && userTeam !== null && userObj.uid === userTeam.captain;

    // localUserTournamentState.wrongRegion = tournament !== null && tournament.region !== Region.GLOBAL && userTeam !== null && userTeam.region !== tournamentRegionToString(tournament.region);
    localUserTournamentState.wrongRegion = false;
    localUserTournamentState.teamTooSmall =
      tournament !== null &&
      userTeam !== null &&
      userTeam.players.length < tournament.teamSize;
    localUserTournamentState.teamTooLarge = false;

    if (userTeam) {
      if (
        tournamentTeams.find(
          (team) =>
            team.status === TournamentTeamStatus.registered &&
            team.id === userTeam.id!
        ) !== undefined
      ) {
        localUserTournamentState.registered = true;
        localUserTournamentState.teamStatus =
          TournamentTeamStatusWithEliminated.registered;
      } else if (
        tournamentTeams.find(
          (team) =>
            team.status === TournamentTeamStatus.waiting &&
            team.id === userTeam.id!
        ) !== undefined
      ) {
        localUserTournamentState.waiting = true;
        localUserTournamentState.teamStatus =
          TournamentTeamStatusWithEliminated.waiting;
      } else if (
        tournamentTeams.find(
          (team) =>
            team.status === TournamentTeamStatus.confirmed &&
            team.id === userTeam.id! &&
            team.eliminated
        ) !== undefined
      ) {
        localUserTournamentState.confirmed = true;
        localUserTournamentState.eliminated = true;
        localUserTournamentState.teamStatus =
          TournamentTeamStatusWithEliminated.eliminated;
      } else if (
        tournamentTeams.find(
          (team) =>
            team.status === TournamentTeamStatus.confirmed &&
            team.id === userTeam.id!
        ) !== undefined
      ) {
        localUserTournamentState.confirmed = true;
        localUserTournamentState.teamStatus =
          TournamentTeamStatusWithEliminated.confirmed;
      } else if (
        tournamentTeams.find(
          (team) =>
            team.status === TournamentTeamStatus.declined &&
            team.id === userTeam.id!
        ) !== undefined
      ) {
        localUserTournamentState.declined = true;
        localUserTournamentState.teamStatus =
          TournamentTeamStatusWithEliminated.declined;
      } else if (
        tournamentTeams.find(
          (team) =>
            team.status === TournamentTeamStatus.ignored &&
            team.id === userTeam.id!
        ) !== undefined
      ) {
        localUserTournamentState.ignored = true;
        localUserTournamentState.teamStatus =
          TournamentTeamStatusWithEliminated.ignored;
      }
    }

    setUserTournamentState(localUserTournamentState);
  }, [
    tournament,
    userTeam,
    tournamentTeams,
    userObj,
    currentTime,
    registeredTeamsLength,
  ]);

  useEffect(() => {
    calculateUserTournamentState();
  }, [
    tournament,
    userTeam,
    tournamentTeams,
    userObj,
    calculateUserTournamentState,
  ]);

  const registerForTournament = async () => {
    if (tournament && userTeam) {
      if (
        userTournamentState.registered ||
        userTournamentState.waiting ||
        !userTournamentState.teamCaptain
      )
        return;

      try {
        const serializedUserTeam = teamConverter.toFirestore(userTeam);
        const tournamentTeamRef = doc(
          firestore,
          'tournaments',
          tournament.id,
          'teams',
          userTeam.id!
        );
        const tournamentTeamPromise = setDoc(tournamentTeamRef, {
          eliminated: false,
          matchPointEligible: false,
          id: userTeam.id,
          groups: [],
          joinedAt: Timestamp.now(),
          teamName: userTeam.teamName,
          teamCaptain: userTeam.captain,
          poiPreferences: {},
          poiAllocations: {},
          qualifiedStages: [],
          status: TournamentTeamStatus.registered,
          performanceHistoryId: '',
          participatingPlayers: [],
          fromWaitingList: false,
          participatingPlayerData: [],
          DBTeam: serializedUserTeam,
        } as TournamentTeam);

        const teamRef = doc(firestore, 'teams', userTeam.id).withConverter(
          teamConverter
        );
        const teamPromise = updateDoc(teamRef, {
          activeTournaments: arrayUnion(tournament.id),
        });

        const combinedPromise = Promise.all([
          tournamentTeamPromise,
          teamPromise,
        ]);

        toast.promise(combinedPromise, {
          pending: 'Registering',
          success: 'You have registered for this tournament',
          error: 'Error registering for tournament',
        });

        await combinedPromise;
      } catch (err) {
        console.error(err);
      }
    }
  };

  const joinWaitingList = async () => {
    if (tournament && userTeam) {
      if (
        userTournamentState.registered ||
        userTournamentState.waiting ||
        !userTournamentState.teamCaptain
      )
        return;
      try {
        const serializedUserTeam = teamConverter.toFirestore(userTeam);
        const tournamentTeamRef = doc(
          firestore,
          'tournaments',
          tournament.id,
          'teams',
          userTeam.id!
        );
        const tournamentTeamPromise = setDoc(tournamentTeamRef, {
          eliminated: false,
          matchPointEligible: false,
          id: userTeam.id,
          groups: [],
          qualifiedStages: [],
          joinedAt: Timestamp.now(),
          poiPreferences: {},
          poiAllocations: {},
          teamName: userTeam.teamName,
          teamCaptain: userTeam.captain,
          status: TournamentTeamStatus.waiting,
          performanceHistoryId: '',
          participatingPlayers: [],
          fromWaitingList: true,
          participatingPlayerData: [],
          DBTeam: serializedUserTeam,
        } as TournamentTeam);

        const teamRef = doc(firestore, 'teams', userTeam.id!).withConverter(
          teamConverter
        );
        const teamPromise = updateDoc(teamRef, {
          activeTournaments: arrayUnion(tournament.id),
        });

        const combinedPromise = Promise.all([
          tournamentTeamPromise,
          teamPromise,
        ]);

        toast.promise(combinedPromise, {
          pending: 'Joining waiting list',
          success: 'You have joined the waiting list for this tournament',
          error: 'Error joining waiting list',
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const leaveWaitingList = async () => {
    if (tournament && userObj && userTeam) {
      if (!userTournamentState.teamCaptain) return;

      const tournamentTeamRef = doc(
        firestore,
        'tournaments',
        tournament.id,
        'teams',
        userTeam.id!
      );
      const tournamentTeamPromise = deleteDoc(tournamentTeamRef);

      const teamRef = doc(firestore, 'teams', userTeam.id).withConverter(
        teamConverter
      );
      const teamPromise = updateDoc(teamRef, {
        activeTournaments: arrayRemove(tournament.id),
      });

      const combinedPromise = Promise.all([tournamentTeamPromise, teamPromise]);

      toast.promise(combinedPromise, {
        pending: 'Leaving waiting list',
        success: 'You have left the waiting list for this tournament',
        error: 'Error leaving waiting list',
      });

      await combinedPromise;
    }
  };

  const unregisterFromTournament = async () => {
    if (tournament && userObj && userTeam) {
      if (userTeam.captain !== userObj.uid) return;

      const tournamentTeamRef = doc(
        firestore,
        'tournaments',
        tournament.id,
        'teams',
        userTeam.id!
      );
      const tournamentTeamPromise = deleteDoc(tournamentTeamRef);

      const teamRef = doc(firestore, 'teams', userTeam.id).withConverter(
        teamConverter
      );
      const teamPromise = updateDoc(teamRef, {
        activeTournaments: arrayRemove(tournament.id),
      });

      const combinedPromise = Promise.all([tournamentTeamPromise, teamPromise]);

      toast.promise(combinedPromise, {
        pending: 'Unregistering',
        success: 'You have unregistered from this tournament',
        error: 'Error unregistering from tournament',
      });
    }
  };

  const getTournamentGroups = useCallback(() => {
    let groupsCollectionUnsubscribe: (() => void) | Unsubscribe = () => false;
    const gameCollectionUnsubscribes: Record<
      string,
      (() => void) | Unsubscribe
    > = {};

    if (tournament) {
      const groupsCollection = collection(
        firestore,
        'tournaments',
        tournament.id,
        'groups'
      );

      groupsCollectionUnsubscribe = onSnapshot(
        groupsCollection,
        async (snapshots) => {
          Object.values(gameCollectionUnsubscribes).forEach((unsubscribe) =>
            unsubscribe()
          );

          const localTournamentStages: TournamentStage[] = [];

          const groups = snapshots.docs.map(
            (doc) => doc.data() as TournamentGroup
          );

          for (const group of groups) {
            const stageIndex = localTournamentStages.findIndex(
              (stage) => stage.stageNum === group.stage
            );

            if (stageIndex !== -1) {
              localTournamentStages[stageIndex].groups.push(group);
            } else {
              localTournamentStages.push({
                stageNum: group.stage,
                groups: [group],
              });
            }

            const groupGamesCollection = collection(
              firestore,
              'tournaments',
              tournament.id,
              'groups',
              group.id,
              'games'
            );

            gameCollectionUnsubscribes[group.id] = onSnapshot(
              groupGamesCollection,
              async (snapshots) => {
                const groupGames = snapshots.docs.map((doc) =>
                  doc.data()
                ) as TournamentGame[];

                await new Promise((res) => setTimeout(res, 100));

                setTournamentStages((prevStages) => {
                  let localStages = [...prevStages];

                  localStages = localStages.map((stage) => ({
                    ...stage,
                    groups: stage.groups.map((g) => {
                      if (group.id === g.id) {
                        return {
                          ...g,
                          games: groupGames,
                        };
                      }
                      return g;
                    }),
                  }));

                  return localStages;
                });
              }
            );
          }

          setTournamentStages(
            localTournamentStages.sort((a, b) => a.stageNum - b.stageNum)
          );
        }
      );
    }

    return () => {
      groupsCollectionUnsubscribe();
      Object.values(gameCollectionUnsubscribes).forEach((unsubscribe) =>
        unsubscribe()
      );
    };
  }, [tournament]);

  const processUserTeamRelations = useCallback(() => {
    setRegisteredTeamsLength(
      tournamentTeams.filter(
        (team) =>
          team.status === TournamentTeamStatus.registered ||
          team.status === TournamentTeamStatus.confirmed
      ).length
    );
  }, [tournamentTeams]);

  useEffect(() => {
    processUserTeamRelations();
  }, [userTeam, tournamentTeams, processUserTeamRelations]);

  const getTournament = useCallback(() => {
    let unsubscribe: Unsubscribe | (() => void) = () => false;

    const tournamentId = params.id;
    if (tournamentId) {
      const tournamentRef = doc(
        firestore,
        'tournaments',
        tournamentId
      ).withConverter(tournamentConverter);

      unsubscribe = onSnapshot(tournamentRef, (snapshot) => {
        const tournament = snapshot.data();
        announceTournament(tournament ?? null);
        if (tournament) {
          setTournament(tournament);
          setTournamentCompleted(
            tournament.status === TournamentStatus.results
          );
          setTournamentFound(true);
          setTournamentLoaded(true);
        } else {
          setTournamentFound(false);
          setTournamentLoaded(true);
        }
      });
    }

    return unsubscribe;
  }, [params.id, setTournamentFound, setTournamentLoaded]);

  const getTournamentTeamObjs = useCallback(async () => {
    if (tournament) {
      const teamObjs: DBTeam[] = [];

      for (const tournamentTeam of tournamentTeams) {
        const teamObj = tournamentTeam.DBTeam;
        teamObjs.push(teamObj);
      }

      setTournamentTeamObjs(teamObjs);
    }
  }, [tournament, tournamentTeams]);

  useEffect(() => {
    if (tournament) {
      setTournamentRegionName(tournamentRegionToString(tournament.region));
    }

    const unsubscribe = getTournamentGroups();

    return () => unsubscribe();
  }, [tournament, getTournamentGroups]);

  useEffect(() => {
    getTournamentTeamObjs();
  }, [tournamentTeams, getTournamentTeamObjs]);

  const getTournamentTeams = useCallback(() => {
    if (tournament && !tournamentTeamsListenerInitialised) {
      const tournamentTeamsCollection = collection(
        firestore,
        'tournaments',
        tournament.id,
        'teams'
      );
      const tournamentTeamsQuery = query(tournamentTeamsCollection);
      onSnapshot(tournamentTeamsQuery, (snapshots) => {
        const localTournamentTeams: TournamentTeam[] = [];
        snapshots.forEach((snapshot) => {
          const rawTeam = snapshot.data() as TournamentTeamRaw;
          const convertedPlayerData = rawTeam.participatingPlayerData.map(
            (player) => {
              return {
                ...player,
                dateOfBirth: player.dateOfBirth
                  ? new Date(player.dateOfBirth.seconds * 1000)
                  : new Date(),
              };
            }
          ) as PlayerData[];
          const tournamentTeam = {
            ...rawTeam,
            participatingPlayerData: convertedPlayerData,
          };
          localTournamentTeams.push(tournamentTeam);
        });
        setTournamentTeams(localTournamentTeams);
      });
      setTournamentTeamsListenerInitialised(true);
    }
  }, [tournament, tournamentTeamsListenerInitialised]);

  useEffect(() => {
    getTournamentTeams();
  }, [tournament, tournamentTeamsListenerInitialised, getTournamentTeams]);

  useEffect(() => {
    const unsubscribe = getTournament();

    return () => unsubscribe();
  }, [params, getTournament]);

  const contextValue = {
    tournament: tournament,
    setTournament: setTournament,
    tournamentRegionName: tournamentRegionName,
    userTournamentState: userTournamentState,
    timeUntilEntrantsCheckIn: timeUntilEntrantsCheckIn,
    timeUntilWaitingListCheckin: timeUntilWaitingListCheckin,
    tournamentStages: tournamentStages,
    tournamentCompleted: tournamentCompleted,
    tournamentTeams: tournamentTeams,
    tournamentTeamObjs: tournamentTeamObjs,
    setTournamentTeamObjs: setTournamentTeamObjs,
    registeredTeamsLength: registeredTeamsLength,
    registerForTournament: registerForTournament,
    unregisterFromTournament: unregisterFromTournament,
    joinWaitingList: joinWaitingList,
    leaveWaitingList: leaveWaitingList,
  };

  return (
    <TournamentContext.Provider value={contextValue}>
      {children}
    </TournamentContext.Provider>
  );
};

export default TournamentProvider;
