import {
  MouseEvent,
  useEffect,
  useRef,
  useState,
  ReactNode,
  useCallback,
} from 'react';
import { Link } from 'react-router-dom';
// types
import { DBTeam, PlayerData } from '@src/firestore/teams';
import {
  ApexMapInfos,
  ApexMapMeasurements,
} from '@customTypes/Games/ApexLegends';
// libraries
import { FullGestureState, useDrag, useWheel } from '@use-gesture/react';
import { Transition, TransitionChild } from '@headlessui/react';
// utils
import {
  gameInputStringToIcon,
  platformStringToIcon,
} from '@utils/Players/Icons';
import { getAgeFromDateOfBirth } from '@utils/Date';
import {
  getLegendHeadshot,
  getWeaponImage,
} from '@src/utils/Games/ApexLegends';
// components
import PopoverText from '@ui/PopoverText';
import CheckBox from '@ui/CheckBox';
// assets
import placeholderImage from '@assets/images/placeholder/placeholder.webp';
import respawnBeacon from '@assets/images/apex/icons/respawnBeacon.webp';
import surveyBeacon from '@assets/images/apex/icons/surveyBeacon.webp';
import ringConsole from '@assets/images/apex/icons/ringConsole.webp';
import jumpTower from '@assets/images/apex/icons/jumpTower.webp';
import trident from '@assets/images/apex/icons/trident.webp';
// icons
import { SlidersIcon, TargetIcon } from '@icons/Common';
import {
  TrophyBronzeImage,
  TrophyGoldImage,
  TrophySilverImage,
} from '@icons/TournamentIcons';

interface IApexMapLabel {
  coords: { x: number; y: number };
  rotation?: number;
  mapMeasurements: ApexMapMeasurements;
  children: ReactNode;
  className?: string;
  popoverText?: string;
  priority?: 1 | 2 | 3;
  hidden?: boolean;
  notInteractive?: boolean;
}

const ApexMapLabel: React.FC<IApexMapLabel> = ({
  coords,
  mapMeasurements,
  children,
  className,
  popoverText,
  hidden,
  priority,
  notInteractive,
  rotation,
}) => {
  const getPositionStylesFromCoordinates = useCallback(
    (coords: { x: number; y: number }) => {
      const { x, y } = coords;

      const xPercentageOffset = x * mapMeasurements.x.coordinateToWidth;
      const yPercentageOffset = -(y * mapMeasurements.y.coordinateToWidth);

      return {
        left: `${50 + xPercentageOffset}%`,
        top: `${50 + yPercentageOffset}%`,
      };
    },
    [mapMeasurements]
  );

  return (
    <div
      className={`
        ${className}

        group absolute -translate-x-1/2 -translate-y-1/2

        z-[${priority ? 4 - priority : 1}]

        ${!notInteractive ? 'hover:z-[4]' : ''}

        aspect-square h-auto w-fit rounded-full

        ${hidden ? 'pointer-events-none touch-none opacity-0' : ''}

        transition-all
      `}
      style={{
        ...getPositionStylesFromCoordinates(coords),
        rotate: `${rotation ?? 0}deg`,
      }}
    >
      {children}
      {popoverText ? (
        <PopoverText
          visible={true}
          className={`
            opacity-0

            group-hover:opacity-100
          `}
        >
          {popoverText}
        </PopoverText>
      ) : (
        ''
      )}
    </div>
  );
};

interface IApexMap {
  map: 'WE' | 'SP' | 'ED';
  allocationsReady?: boolean;
  teamMapLabels?: {
    teamId: string;
    teamName: string;
    teamTag: string;
    teamLogo: string;
    participatingPlayerData: PlayerData[];
    DBTeam: DBTeam;
    poiAllocation: number;
  }[];
}

const ApexMap: React.FC<IApexMap> = ({
  map,
  teamMapLabels,
  allocationsReady,
}) => {
  const [poiLabelsVisible, setPoiLabelsVisible] = useState<boolean>(true);
  const [respawnBeaconsVisible, setRespawnBeaconsVisible] =
    useState<boolean>(true);
  const [surveyBeaconsVisible, setSurveyBeaconsVisible] =
    useState<boolean>(false);
  const [ringConsolesVisible, setRingConsolesVisible] =
    useState<boolean>(false);
  const [jumpTowersVisible, setJumpTowersVisible] = useState<boolean>(false);
  const [tridentsVisible, setTridentsVisible] = useState<boolean>(false);

  const imageRef = useRef<HTMLImageElement | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapContainerWidth = mapContainerRef.current?.offsetWidth ?? 0;
  const [mapContainerOffset, setMapContainerOffset] = useState<
    [x: number, y: number]
  >([0, 0]);

  const [zoomLevel, setZoomLevel] = useState<number>(110);

  const [visible, setVisible] = useState<boolean>(false);
  const [dragging, setDragging] = useState<boolean>(false);

  const [showcaseTeam, setShowcaseTeam] = useState<{
    teamId: string;
    teamName: string;
    teamTag: string;
    teamLogo: string;
    participatingPlayerData: PlayerData[];
    DBTeam: DBTeam;
    poiAllocation: number;
  } | null>(null);

  const showcaseTeamGoldTrophies =
    showcaseTeam?.DBTeam.trophyData.filter((trophy) => trophy.position === 1) ??
    [];
  const showcaseTeamSilverTrophies =
    showcaseTeam?.DBTeam.trophyData.filter((trophy) => trophy.position === 2) ??
    [];
  const showcaseTeamBronzeTrophies =
    showcaseTeam?.DBTeam.trophyData.filter((trophy) => trophy.position === 3) ??
    [];

  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  useEffect(() => {
    setVisible(false);

    let transform: string = '';

    switch (map) {
      case 'WE':
        setZoomLevel(105);
        transform = 'translate(-10px, -40px)';
        break;
      case 'SP':
        setZoomLevel(105);
        transform = 'translate(-10px, 10px)';
        break;
      case 'ED':
        setZoomLevel(110);
        transform = 'translate(25px, -25px)';
        break;
    }

    if (mapContainerRef.current) {
      mapContainerRef.current.style.transform = transform;
    }

    setTimeout(() => {
      setVisible(true);
    }, 150);
  }, [map]);

  const mapInfo = ApexMapInfos[map];
  const mapMeasurements = mapInfo.measurements;

  const width = imageRef.current?.offsetWidth ?? 0;
  const height = imageRef.current?.offsetHeight ?? 0;

  const repositionMap = useCallback(
    (options: {
      newOffset?: [x: number, y: number];
      newZoomLevel?: number;
    }) => {
      const { newOffset, newZoomLevel } = options;

      let offset = mapContainerOffset;
      if (newOffset) offset = newOffset;
      setMapContainerOffset(offset);

      let zoom = zoomLevel;
      if (newZoomLevel) zoom = newZoomLevel;

      const [x, y] = offset;
      const zoomMultiplier = zoom / 100;

      const xShift = x !== 0 ? x / zoomMultiplier : 0;
      const yShift = y !== 0 ? y / zoomMultiplier : 0;

      const mapScale = mapContainerWidth / 634;

      const shiftBoundary = mapScale * 500;

      const boundariedXShift =
        xShift > 0
          ? Math.min(xShift, shiftBoundary)
          : Math.max(xShift, -shiftBoundary);
      const boundariedYShift =
        yShift > 0
          ? Math.min(yShift, shiftBoundary)
          : Math.max(yShift, -shiftBoundary);

      if (mapContainerRef.current) {
        const transform = `translate(${boundariedXShift}px, ${boundariedYShift}px)`;
        mapContainerRef.current.style.transform = transform;
      }
    },
    [mapContainerOffset, mapContainerWidth, zoomLevel]
  );

  type DragState = Omit<FullGestureState<'drag'>, 'event'> & {
    event: PointerEvent | globalThis.MouseEvent | TouchEvent | KeyboardEvent;
  };

  type WheelState = Omit<FullGestureState<'wheel'>, 'event'> & {
    event: PointerEvent | MouseEvent | TouchEvent | KeyboardEvent;
  };

  const dragStateHandler = useCallback(
    (state: DragState) => {
      const { offset, dragging } = state;
      repositionMap({ newOffset: offset });
      setDragging(dragging ?? false);
    },
    [repositionMap]
  );

  const wheelStateHandler = useCallback(
    (state: WheelState) => {
      const {
        direction: [, yDirection],
      } = state;
      let newZoomLevel: number;

      if (yDirection === -1) {
        console.log(yDirection, 'positive');
        newZoomLevel = Math.max(zoomLevel + 25, 70);
      } else {
        newZoomLevel = Math.max(zoomLevel - 25, 70);
      }

      setZoomLevel(newZoomLevel);
      repositionMap({ newZoomLevel });
    },
    [repositionMap, zoomLevel]
  );

  const getCoordsForMouseCursor = (e: MouseEvent<HTMLDivElement>) => {
    console.log(e);
    console.clear();
    const xOffset = ((e.nativeEvent?.offsetX as number) / width) * 100;
    const yOffset = ((e.nativeEvent?.offsetY as number) / height) * 100;
    const coords = {
      x: mapMeasurements.x.widthToCoordinate * (xOffset - 50),
      y: mapMeasurements.y.widthToCoordinate * (50 - yOffset),
    };
    console.log('Latest coordinates for mouse position:');
    console.log(coords);
  };

  const dragBind = useDrag(dragStateHandler);
  const wheelBind = useWheel(wheelStateHandler);

  const [mapImages, setMapImages] = useState<Record<string, HTMLImageElement>>(
    {}
  );

  useEffect(() => {
    const loadMapImage = async () => {
      if (mapImages[map]) return; // Image already loaded

      const image = new Image();
      const imageSrc = ApexMapInfos[map].image;

      try {
        await new Promise((resolve, reject) => {
          image.onload = resolve;
          image.onerror = reject;
          image.src = imageSrc;
        });

        setMapImages((prev) => ({
          ...prev,
          [map]: image,
        }));
      } catch (error) {
        console.error(`Failed to load map image for ${map}:`, error);
      }
    };

    loadMapImage();
  }, [map]);

  return (
    <Transition show={visible} appear={true} unmount={false}>
      <div className="relative flex flex-col gap-y-4">
        <button
          type="button"
          aria-label="Open filters menu"
          onClick={() => setFiltersOpen(true)}
          onMouseOver={() => setFiltersOpen(true)}
          className={`
            absolute left-5 top-5 flex aspect-square h-auto w-[30px]
            bg-lightBlack z-[3] items-center justify-center rounded-xl border
            border-steelGray

            hover:opacity-75

            ${filtersOpen ? 'pointer-events-none touch-none opacity-0' : ''}

            transition-all
          `}
        >
          <SlidersIcon className="aspect-square h-auto w-[14px] stroke-green" />
        </button>
        <div
          className={`
            absolute left-5 top-5 z-[3] flex h-fit w-fit justify-start border
            p-4 border-steelGray bg-lightBlack flex-col gap-y-2
            overflow-x-scroll rounded-xl

            ${filtersOpen ? '' : 'pointer-events-none touch-none opacity-0'}

            transition-opacity
          `}
          onMouseLeave={() => setFiltersOpen(false)}
        >
          {/* <button type='button' aria-label='Close filters menu'
                  onClick={() => setFiltersOpen(false)}
                  className='absolute top-1 right-1 w-[20px] h-auto aspect-square'>
            <FaXmark className='w-[14px] h-auto aspect-square fill-steelGray hover:fill-white transition-colors'/>
          </button> */}
          <button
            type="button"
            onClick={() => setPoiLabelsVisible(!poiLabelsVisible)}
            className={`
              flex items-center justify-between gap-x-3 whitespace-nowrap
              rounded-lg px-4 py-2 text-sm text-white font-compact uppercase
              bg-lightGray transition-all

              hover:opacity-75
            `}
          >
            <span className="flex items-center gap-x-2">
              <TargetIcon className="aspect-square h-auto w-[12px] fill-white" />
              <span>POI Labels{teamMapLabels ? '/Teams' : ''}</span>
            </span>
            <CheckBox
              selected={poiLabelsVisible}
              setSelected={() => false}
              asDiv={true}
              small={true}
            />
          </button>

          <button
            type="button"
            onClick={() => setRespawnBeaconsVisible(!respawnBeaconsVisible)}
            className={`
              flex items-center justify-between gap-x-3 whitespace-nowrap
              rounded-lg px-4 py-2 text-sm text-white font-compact uppercase
              bg-lightGray transition-all

              hover:opacity-75
            `}
          >
            <span className="flex items-center gap-x-2">
              <img
                src={respawnBeacon}
                alt="Respawn beacon icon"
                className="aspect-square h-auto w-[16px] fill-white"
              />
              <span>Respawn Beacons</span>
            </span>
            <CheckBox
              selected={respawnBeaconsVisible}
              setSelected={() => false}
              asDiv={true}
              small={true}
            />
          </button>

          <button
            type="button"
            onClick={() => setSurveyBeaconsVisible(!surveyBeaconsVisible)}
            className={`
              flex items-center justify-between gap-x-3 whitespace-nowrap
              rounded-lg px-4 py-2 text-sm text-white font-compact uppercase
              bg-lightGray transition-all

              hover:opacity-75
            `}
          >
            <span className="flex items-center gap-x-2">
              <img
                src={surveyBeacon}
                alt="Survey beacon icon"
                className={'aspect-square h-auto w-[12px] scale-125 fill-white'}
              />
              <span>Survey Beacons</span>
            </span>
            <CheckBox
              selected={surveyBeaconsVisible}
              setSelected={() => false}
              asDiv={true}
              small={true}
            />
          </button>

          <button
            type="button"
            onClick={() => setRingConsolesVisible(!ringConsolesVisible)}
            className={`
              flex items-center justify-between gap-x-3 whitespace-nowrap
              rounded-lg px-4 py-2 text-sm text-white font-compact uppercase
              bg-lightGray transition-all

              hover:opacity-75
            `}
          >
            <span className="flex items-center gap-x-2">
              <img
                src={ringConsole}
                alt="Ring console icon"
                className={'aspect-square h-auto w-[12px] scale-125 fill-white'}
              />
              <span>Ring Consoles</span>
            </span>
            <CheckBox
              selected={ringConsolesVisible}
              setSelected={() => false}
              asDiv={true}
              small={true}
            />
          </button>

          <button
            type="button"
            onClick={() => setJumpTowersVisible(!jumpTowersVisible)}
            className={`
              flex items-center justify-between gap-x-3 whitespace-nowrap
              rounded-lg px-4 py-2 text-sm text-white font-compact uppercase
              bg-lightGray transition-all

              hover:opacity-75
            `}
          >
            <span className="flex items-center gap-x-2">
              <img
                src={jumpTower}
                alt="Jump tower icon"
                className={'aspect-square h-auto w-[12px] scale-125 fill-white'}
              />
              <span>Jump Towers</span>
            </span>
            <CheckBox
              selected={jumpTowersVisible}
              setSelected={() => false}
              asDiv={true}
              small={true}
            />
          </button>

          {mapInfo.tridentCoords.length > 0 ? (
            <button
              type="button"
              onClick={() => setTridentsVisible(!tridentsVisible)}
              className={`
                flex items-center justify-between gap-x-3 whitespace-nowrap
                rounded-lg px-4 py-2 text-sm text-white font-compact uppercase
                bg-lightGray transition-all

                hover:opacity-75
              `}
            >
              <span className="flex items-center gap-x-2">
                <img
                  src={trident}
                  alt="Jump tower icon"
                  className={`
                    aspect-square h-auto w-[12px] scale-125 fill-white
                  `}
                />
                <span>Tridents</span>
              </span>
              <CheckBox
                selected={tridentsVisible}
                setSelected={() => false}
                asDiv={true}
                small={true}
              />
            </button>
          ) : (
            ''
          )}
        </div>

        <div
          className={`
            pointer-events-none absolute right-5 top-5 z-[3] flex h-fit
            w-[250px] origin-top-right scale-[85%] touch-none justify-start
            border p-4 border-steelGray bg-lightBlack flex-col gap-y-2
            overflow-x-scroll rounded-xl

            ${showcaseTeam ? 'transition-opacity delay-[100]' : 'opacity-0'}
          `}
        >
          {showcaseTeam ? (
            <div className="flex flex-col gap-y-3">
              <div className="flex items-center gap-x-4">
                <img
                  src={showcaseTeam.DBTeam.logoUrl}
                  alt={`${showcaseTeam.teamName} logo`}
                  className={`
                    aspect-square h-auto w-[42px] overflow-hidden rounded-lg
                    object-cover
                  `}
                />
                <div className="flex flex-col items-start">
                  <h3
                    className={`
                      text-white font-wide w-fit font-semibold uppercase

                      ${
                        showcaseTeam.teamName.length >= 15
                          ? 'text-sm'
                          : 'text-lg'
                      }
                    `}
                  >
                    {showcaseTeam.teamName}
                  </h3>
                  <p
                    className={`
                      font-compact text-steelGray w-fit font-medium uppercase

                      ${
                        showcaseTeam.teamName.length >= 15
                          ? 'text-xs'
                          : '-mt-1 text-base'
                      }
                    `}
                  >
                    {showcaseTeam.teamTag}
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-x-3">
                <div className="flex items-center gap-x-2">
                  <div
                    className={`
                      relative aspect-square h-auto w-[36px]

                      ${showcaseTeamGoldTrophies.length > 0 ? '' : 'opacity-50'}
                    `}
                  >
                    {showcaseTeamGoldTrophies.length > 0 ? (
                      <p
                        className={`
                          absolute right-[1px] top-0 h-[14px] w-[14px] border
                          border-lightGray bg-lightBlack flex items-center
                          justify-center rounded-full text-white font-compact
                          text-xs
                        `}
                      >
                        <span className="translate-x-[1px] translate-y-[1px]">
                          {showcaseTeamGoldTrophies.length}
                        </span>
                      </p>
                    ) : (
                      ''
                    )}
                    <TrophyGoldImage className="h-full w-full" />
                  </div>

                  <div
                    className={`
                      relative aspect-square h-auto w-[36px]

                      ${
                        showcaseTeamSilverTrophies.length > 0
                          ? ''
                          : 'opacity-50'
                      }
                    `}
                  >
                    {showcaseTeamSilverTrophies.length > 0 ? (
                      <p
                        className={`
                          absolute right-[1px] top-0 h-[14px] w-[14px] border
                          border-lightGray bg-lightBlack flex items-center
                          justify-center rounded-full text-white font-compact
                          text-xs
                        `}
                      >
                        <span className="translate-x-[1px] translate-y-[1px]">
                          {showcaseTeamSilverTrophies.length}
                        </span>
                      </p>
                    ) : (
                      ''
                    )}
                    <TrophySilverImage className="h-full w-full" />
                  </div>

                  <div
                    className={`
                      relative aspect-square h-auto w-[36px]

                      ${
                        showcaseTeamBronzeTrophies.length > 0
                          ? ''
                          : 'opacity-50'
                      }
                    `}
                  >
                    {showcaseTeamBronzeTrophies.length > 0 ? (
                      <p
                        className={`
                          absolute right-[1px] top-0 h-[14px] w-[14px] border
                          border-lightGray bg-lightBlack flex items-center
                          justify-center rounded-full text-white font-compact
                          text-xs
                        `}
                      >
                        <span className="translate-x-[1px] translate-y-[1px]">
                          {showcaseTeamBronzeTrophies.length}
                        </span>
                      </p>
                    ) : (
                      ''
                    )}
                    <TrophyBronzeImage className="h-full w-full" />
                  </div>
                </div>
                <div
                  className={`
                    relative ml-[10px] flex w-fit flex-col justify-start
                  `}
                >
                  <div
                    className={`
                      absolute left-0 top-1/2 h-full w-[1px] -translate-x-[10px]
                      -translate-y-1/2 bg-lightGray
                    `}
                  ></div>
                  <div className="py-1">
                    <p
                      className={`
                        text-white font-wide w-fit text-xs font-semibold
                        uppercase
                      `}
                    >
                      Winnings
                    </p>
                    <p
                      className={`
                        text-green font-wide w-fit text-xs font-semibold
                        uppercase
                      `}
                    >
                      {showcaseTeam.DBTeam.totalWinnings > 0
                        ? `$${showcaseTeam.DBTeam.totalWinnings}`
                        : 'n/a'}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-x-2">
                <div
                  className={`
                    flex w-[50%] flex-shrink flex-col items-center gap-y-1
                    bg-lightGray rounded-xl px-6 py-1 pb-[2px] transition-colors

                    group-hover:bg-ebonyClay
                  `}
                >
                  <p
                    className={`
                      text-white font-compact whitespace-nowrap text-sm
                    `}
                  >
                    Match Wins
                  </p>
                  <p className="text-green font-wide text-xs font-semibold">
                    {showcaseTeam.DBTeam.apexMatchNum > 0
                      ? showcaseTeam.DBTeam.apexMatchWins
                      : 'N/A'}
                  </p>
                </div>

                <div
                  className={`
                    flex w-[50%] flex-shrink flex-col items-center gap-y-1
                    bg-lightGray rounded-xl px-6 py-1 pb-[2px] transition-colors

                    group-hover:bg-ebonyClay
                  `}
                >
                  <p
                    className={`
                      text-white font-compact whitespace-nowrap text-sm
                    `}
                  >
                    Win Rate
                  </p>
                  <p className="text-green font-wide text-xs font-semibold">
                    {showcaseTeam.DBTeam.apexMatchNum > 0
                      ? `${showcaseTeam.DBTeam.apexMatchWinrate}%`
                      : 'N/A'}
                  </p>
                </div>
              </div>

              <div className="mt-1 flex w-full flex-col gap-y-4">
                {showcaseTeam.participatingPlayerData.map((player) => (
                  <div className="flex items-center justify-between gap-x-6">
                    <div className="flex items-center gap-x-3">
                      <img
                        src={
                          player.displayImage !== ''
                            ? player.displayImage
                            : placeholderImage
                        }
                        alt={`${player.displayName} image`}
                        className={`
                          aspect-square h-auto w-[30px] flex-shrink-0
                          overflow-hidden rounded-full border object-cover
                          border-lightGray
                        `}
                      />

                      <div className="flex flex-col">
                        <p
                          className={`
                            text-white font-wide w-fit whitespace-nowrap
                            text-[0.74rem] font-semibold uppercase
                          `}
                        >
                          {player.displayName.length > 9
                            ? `${player.displayName.substring(0, 9)}...`
                            : player.displayName}
                        </p>
                        <div className="flex items-center gap-x-3">
                          <div
                            className={`
                              w-[10px] opacity-75 fi

                              fi-${player.nationality}
                            `}
                          ></div>
                          {player.showAge ? (
                            <p
                              className={`
                                text-steelGray -mb-1 text-xs transition-colors
                                font-compact font-medium

                                group-hover:text-white/65
                              `}
                            >
                              {getAgeFromDateOfBirth(player.dateOfBirth)}
                            </p>
                          ) : (
                            ''
                          )}
                          {platformStringToIcon(
                            player.platform,
                            '!w-[8px] h-auto aspect-square'
                          )}
                          {gameInputStringToIcon(
                            player.input,
                            '!w-[8px] h-auto aspect-square'
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center gap-x-2">
                      <img
                        src={getWeaponImage(player.apexFavouriteWeapon)}
                        alt={`${player.apexFavouriteWeapon} weapon image`}
                        className={`
                          h-auto max-h-[20px] max-w-[50px] flex-shrink-0
                          object-contain
                        `}
                      />
                      <img
                        src={getLegendHeadshot(player.apexMainLegend)}
                        alt={`${player.apexMainLegend} legend image`}
                        className={`
                          h-auto w-[30px] flex-shrink-0 overflow-hidden
                          rounded-lg border object-cover border-lightGray
                        `}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        <div
          className={`
            border border-steelGray overflow-hidden rounded-2xl

            custom_xl:max-w-[625px]

            sm:max-w-[500px]
          `}
        >
          <div
            ref={mapContainerRef}
            className={`
              relative user-select-none origin-center touch-none

              ${dragging ? 'cursor-grabbing' : ''}
            `}
            style={{ scale: `${zoomLevel}%` }}
            {...dragBind()}
            {...wheelBind()}
            onMouseMove={(e) => {
              if (import.meta.env.VITE_ENV !== 'production') {
                getCoordsForMouseCursor(e);
              }
            }}
          >
            <TransitionChild unmount={false}>
              <div
                className={`
                  transition delay-[300] duration-[300]

                  data-[closed]:opacity-0

                  data-[leave]:transition-none
                `}
              >
                {mapInfo.pois.map((poi) => {
                  const allocatedTeam = teamMapLabels
                    ? teamMapLabels.find(
                        (team) => team.poiAllocation === poi.poiNumber
                      )
                    : null;
                  return (
                    <ApexMapLabel
                      key={`${poi.poiName}-poi`}
                      coords={poi.coordinates ?? { x: 0, y: 0 }}
                      mapMeasurements={mapMeasurements}
                      hidden={!poiLabelsVisible}
                      priority={1}
                      className={'hover:translate-y-[-4px] hover:scale-110'}
                    >
                      <div
                        onMouseOver={() =>
                          setShowcaseTeam(allocatedTeam ?? null)
                        }
                        onTouchStart={() =>
                          setShowcaseTeam(allocatedTeam ?? null)
                        }
                        onMouseLeave={() => setShowcaseTeam(null)}
                      >
                        {!allocationsReady || !teamMapLabels ? (
                          <TargetIcon
                            className={`
                              absolute left-1/2 top-1/2 aspect-square h-auto
                              w-[10px] -translate-x-1/2 -translate-y-1/2
                              fill-white stroke-black stroke-[1px]
                              transition-all

                              group-hover:fill-green
                            `}
                          />
                        ) : (
                          ''
                        )}
                        <p
                          className={`
                            ${
                              allocationsReady && teamMapLabels
                                ? 'group-hover:text-green'
                                : `
                                  pointer-events-none
                                  -translate-y-[calc(50%-14px)] touch-none
                                `
                            }

                            absolute left-1/2 top-1/2 -translate-x-1/2
                            font-compact text-xs font-medium uppercase

                            custom_xl:text-sm

                            ${
                              allocationsReady &&
                              teamMapLabels &&
                              !allocatedTeam
                                ? 'text-white/75'
                                : 'text-white'
                            }
                          `}
                        >
                          <span
                            className={`
                              ${
                                poi.congested
                                  ? poi.inverted
                                    ? ''
                                    : 'block translate-y-[20px]'
                                  : 'whitespace-nowrap'
                              }
                              ${poi.inverted ? 'block -translate-y-[100%]' : ''}
                            `}
                            style={{
                              WebkitTextStroke:
                                zoomLevel > 125 ? '1px black' : '2px black',
                              paintOrder: 'stroke fill',
                            }}
                          >
                            {poi.poiName}
                          </span>
                          {allocatedTeam ? (
                            <div
                              className={`
                                absolute left-1/2 top-0 flex -translate-x-1/2
                                items-center gap-x-2

                                ${
                                  zoomLevel > 125
                                    ? poi.inverted
                                      ? '-translate-y-[10px]'
                                      : 'translate-y-[5px]'
                                    : poi.inverted
                                      ? '-translate-y-[15px]'
                                      : 'translate-y-[5px]'
                                }
                              `}
                            >
                              <Link
                                to={`/team/${allocatedTeam.teamId}`}
                                target="_blank"
                                className={`
                                  ${
                                    zoomLevel > 125
                                      ? 'w-[24px] translate-y-[15px] rounded-md'
                                      : 'w-[36px] translate-y-[15px] rounded-lg'
                                  }

                                  relative aspect-square h-auto bg-lightBlack
                                  border border-steelGray
                                `}
                              >
                                <img
                                  src={allocatedTeam.teamLogo}
                                  alt={`${allocatedTeam.teamName} logo`}
                                  className={`
                                    ${
                                      zoomLevel > 125
                                        ? 'rounded-md'
                                        : 'rounded-lg'
                                    }

                                    aspect-square h-auto w-full object-cover
                                  `}
                                />
                              </Link>
                            </div>
                          ) : (
                            ''
                          )}
                        </p>
                      </div>
                    </ApexMapLabel>
                  );
                })}

                {mapInfo.respawnBeaconCoords.map((coords) => (
                  <ApexMapLabel
                    key={`${coords.x}-${coords.y}-respawnBeacon`}
                    coords={coords ?? { x: 0, y: 0 }}
                    mapMeasurements={mapMeasurements}
                    hidden={!respawnBeaconsVisible}
                    priority={2}
                    className={'hover:translate-y-[-4px] hover:scale-125'}
                  >
                    <div
                      className={`
                        absolute left-1/2 top-1/2 aspect-square h-auto w-[4px]
                        -translate-x-1/2 -translate-y-1/2 rounded-full
                        bg-[#adfb56]
                      `}
                    ></div>
                    <div
                      className={`
                        pointer-events-none absolute left-1/2 top-1/2 block
                        h-[32px] w-[32px] -translate-x-1/2 -translate-y-1/2
                        scale-0 opacity-0 transition-all

                        group-hover:pointer-events-auto group-hover:scale-100
                        group-hover:opacity-100
                      `}
                    >
                      <img src={respawnBeacon} />
                    </div>
                  </ApexMapLabel>
                ))}

                {mapInfo.surveyBeaconCoords.map((coords) => (
                  <ApexMapLabel
                    key={`${coords.x}-${coords.y}-surveyBeacon`}
                    coords={coords ?? { x: 0, y: 0 }}
                    mapMeasurements={mapMeasurements}
                    hidden={!surveyBeaconsVisible}
                    priority={2}
                    className={'hover:translate-y-[-4px] hover:scale-125'}
                  >
                    <div
                      className={`
                        absolute left-1/2 top-1/2 block h-auto w-[20px]
                        -translate-x-1/2 -translate-y-1/2 transition-all

                        group-hover:w-[32px]
                      `}
                    >
                      <img src={surveyBeacon} />
                    </div>
                  </ApexMapLabel>
                ))}

                {mapInfo.ringConsoleCoords.map((coords) => (
                  <ApexMapLabel
                    key={`${coords.x}-${coords.y}-ringConsole`}
                    coords={coords ?? { x: 0, y: 0 }}
                    mapMeasurements={mapMeasurements}
                    hidden={!ringConsolesVisible}
                    priority={2}
                    className={'hover:translate-y-[-4px] hover:scale-125'}
                  >
                    <div
                      className={`
                        absolute left-1/2 top-1/2 block h-auto w-[16px]
                        -translate-x-1/2 -translate-y-1/2 transition-all

                        group-hover:w-[32px]
                      `}
                    >
                      <img src={ringConsole} />
                    </div>
                  </ApexMapLabel>
                ))}

                {mapInfo.jumpTowerCoords.map((coords) => (
                  <ApexMapLabel
                    key={`${coords.x}-${coords.y}-jumpTower`}
                    coords={coords ?? { x: 0, y: 0 }}
                    mapMeasurements={mapMeasurements}
                    hidden={!jumpTowersVisible}
                    priority={2}
                    className={'hover:translate-y-[-4px] hover:scale-125'}
                  >
                    <div
                      className={`
                        absolute left-1/2 top-1/2 block h-auto w-[16px]
                        -translate-x-1/2 -translate-y-1/2 transition-all

                        group-hover:w-[32px]
                      `}
                    >
                      <img src={jumpTower} />
                    </div>
                  </ApexMapLabel>
                ))}

                {mapInfo.tridentCoords.length > 0 ? (
                  <>
                    {mapInfo.tridentCoords.map((coords) => (
                      <ApexMapLabel
                        key={`${coords.x}-${coords.y}-trident`}
                        coords={coords ?? { x: 0, y: 0 }}
                        mapMeasurements={mapMeasurements}
                        hidden={!tridentsVisible}
                        priority={2}
                        className={'hover:translate-y-[-4px] hover:scale-125'}
                      >
                        <div
                          className={`
                            absolute left-1/2 top-1/2 block h-auto w-[16px]
                            -translate-x-1/2 -translate-y-1/2 transition-all

                            group-hover:w-[32px]
                          `}
                        >
                          <img src={trident} />
                        </div>
                      </ApexMapLabel>
                    ))}
                  </>
                ) : (
                  ''
                )}
              </div>
            </TransitionChild>

            <TransitionChild unmount={false}>
              <div
                className={`
                  transition duration-[150] ease-in

                  data-[closed]:opacity-0

                  data-[leave]:duration-0
                `}
              >
                {mapImages[map] && (
                  <img
                    ref={imageRef}
                    src={mapImages[map].src}
                    alt={`Apex ${map} map card`}
                    className={`
                      pointer-events-none relative z-[1] block h-full w-full
                      image-smooth touch-none object-contain opacity-[80%]
                    `}
                    style={{
                      objectPosition: `${width * mapMeasurements.originOffset.x}px ${height * mapMeasurements.originOffset.y}px`,
                    }}
                  />
                )}
              </div>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default ApexMap;
