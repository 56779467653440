import { Link } from 'react-router-dom';
import comingSoon from '../../../assets/images/png/homepage/coming-soon-img.webp';
import { Game, gameToLink } from '@src/types/Games/Games';
import { GamesSelectList } from '@src/components/common/content/HomePageContent';

const GameSelect = () => {
  return (
    <>
      <div className={'relative overflow-hidden'}>
        <div
          className={`
            mx-auto grid w-full grid-cols-1 gap-8

            lg:grid-cols-4

            sm:grid-cols-2
          `}
        >
          {/* MAPPING THROUGH GAME DATA TO RENDER */}
          {GamesSelectList.map((obj, index) => (
            <div className="mx-auto w-full">
              <GameSelectCards
                key={index}
                icon={obj.icon}
                isActive={obj.isActive}
                thumbnail={obj.thumbnail}
                title={obj.title}
                game={obj.game}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default GameSelect;

interface GameCardProps {
  title: string;
  thumbnail: string;
  icon: JSX.Element;
  isActive: boolean;
  game: Game;
}

const GameSelectCards = ({
  title,
  thumbnail,
  icon,
  isActive,
  game,
}: GameCardProps) => {
  return (
    <Link to={isActive ? `/games/${gameToLink(game)}` : ''} className="w-full">
      <div
        className={`
          relative rounded-2xl bg-lightBlack group flex flex-col gap-y-4 p-4
        `}
      >
        {/* CONDITIONAL RENDERING OF 'COMING SOON' label */}
        {!isActive && (
          <img
            className="absolute left-0 top-0 z-10"
            src={comingSoon}
            alt="coming-soon"
          />
        )}
        {/* DISPLAYING THUMBNAIL OF THE GAME */}
        <div className={'w-full overflow-hidden rounded-lg'}>
          <img
            className={`
              h-full w-full object-cover duration-200

              group-hover:scale-110

              ${isActive ? '' : 'saturate-0'}
            `}
            src={thumbnail}
            alt="thumbnail-img"
          />
        </div>
        {/* GAME ICON AND DETAILS */}
        <div className="flex items-center">
          <span className="rounded-xl bg-black">{icon}</span>
          <span
            className={`
              pl-3

              sm:mt-0 sm:pl-5
            `}
          >
            <h4
              className={`
                whitespace-nowrap pt-2 text-[1rem] font-bold uppercase
                text-white font-wide

                md:whitespace-normal
              `}
            >
              {title}
            </h4>
          </span>
        </div>
      </div>
    </Link>
  );
};
