// assets
import josephDeAnda from '../../assets/images/misc/testimonials/josephAnda.webp';
import alexGonzalez from '../../assets/images/misc/testimonials/alexGonzalez.webp';
import kesemFine from '../../assets/images/misc/testimonials/kesemFine.webp';
// icons
import { QuotsBg, QuotsIcon } from '../common/icons/Services';

const testimonials = [
  {
    image: josephDeAnda,
    name: 'Joseph De Anda',
    position: 'Partnerships Manager, Electronic Arts',
    testimony:
      "I've worked with Mike (Versus Gaming) for a few years on the Apex Legends Global Series. His team has been extremely responsive and reactive to feedback.",
  },
  {
    image: alexGonzalez,
    name: 'Alex Gonzalez',
    position: 'Head of Luminosity, Enthusiast Gaming',
    testimony:
      "Mike's team ran an Apex Legends tournament for us including open online qualifiers in North America and Europe. They are the perfect team to give a project to, be hands-off, and let them run the show - true professionals.",
  },
  {
    image: kesemFine,
    name: 'Kesem Fine',
    position: 'Business Development, Overwolf',
    testimony:
      'Mike is a expertise driven esports advisor and strategist. Had some bottlenecks in an esports related project and brought Mike in to advise on creative ways forward. Highly recommend!',
  },
];

const Testimonials = () => {
  return (
    <>
      {/* Container for the service cards with responsive padding */}
      <div className="px-4 sm:px-8 lg:px-12 custom_container">
        {/* Grid layout with 1 column on small screens, 2 columns on medium screens, and 3 columns on large screens */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Mapping through the service card data to generate individual cards */}
          {testimonials.map((testimonial, index) => {
            return (
              <div
                key={index}
                className="bg-lightBlack hover:opacity-80 duration-300 p-4 custom_xsm:p-6 xl:p-8 rounded-2xl relative"
              >
                {/* Quotation icon positioned at the bottom right corner */}
                <span className="absolute bottom-0 end-0">
                  <QuotsIcon />
                </span>
                {/* Flex container for image, title, and subtitle */}
                <div className="flex items-center gap-6 text-white">
                  {/* Container for the service card image */}
                  <span className="max-w-[56px]">
                    <img
                      className="w-full h-auto aspect-square rounded-full border border-lightGray"
                      src={testimonial.image}
                      alt="hero_img_slider"
                    />
                  </span>
                  {/* Container for title and subtitle */}
                  <div className="-mb-1">
                    {/* Title of the service card */}
                    <h2 className="font-wide font-bold uppercase text-sm mb-2 !leading-4">
                      {testimonial.name}
                    </h2>
                    {/* Subtitle of the service card */}
                    <p className="font-compact font-normal !tracking-[0.16px] text-base text-slateGray !leading-4">
                      {testimonial.position}
                    </p>
                  </div>
                </div>
                {/* Horizontal line below title and subtitle */}
                <div className="h-[1px] bg-lightGray my-5"></div>
                {/* Icon representing the service */}
                <span>
                  <QuotsBg />
                </span>
                {/* Description of the service */}
                <p className="font-compact font-normal !tracking-[0.16px] text-base text-slateGray !leading-6 xl:!leading-8 mt-5">
                  {testimonial.testimony}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Testimonials;
