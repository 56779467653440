//  HERO SECTION IMAGE DATA
import HeroBottom from '../../../assets/images/png/homepage/hero-bottom-green-aniem.webp';
import HeroBottom2 from '../../../assets/images/png/homepage/hero-bottom-black-aniem.webp';
import HeroBottomMobile from '../../../assets/images/png/homepage/hero-bottom-green-aniem-mobile.webp';
import HeroBottomMobile2 from '../../../assets/images/png/homepage/hero-bottom-black-aniem-mobile.webp';
import cartoonimg1 from '../../../assets/images/png/homepage/hero-cartton-img-1.webp';
import groupImgMobile1 from '../../../assets/images/webp/homepage/hero_group.webp';
import groupImgMobile2 from '../../../assets/images/webp/homepage/hero_group2.webp';
import cartoonimg2 from '../../../assets/images/png/homepage/hero-cartton-img-2.webp';
import cartoonimg3 from '../../../assets/images/png/homepage/hero-cartton-img-3.webp';
import cartoonimg4 from '../../../assets/images/png/homepage/hero-cartton-img-4.webp';
import secondcartoonimg1 from '../../../assets/images/png/homepage/second-slide-cartoon-img-1.webp';
import secondcartoonimg2 from '../../../assets/images/png/homepage/second-slide-cartoon-img-2.webp';
//  GAME SELECT IMAGE DATA
import valorant from '../../../assets/images/webp/homepage/select-game-valorant-image.webp';
import apexLegends from '../../../assets/images/webp/homepage/select-game-apex-image.webp';
import fortnite from '../../../assets/images/webp/homepage/select-game-fortnite-image.webp';
import rocketLeague from '../../../assets/images/webp/homepage/select-game-rocket-league-image.webp';

import {
  HeroIndiosLine,
  HeroIndiosLineBlack,
  HeroLeftGrph,
  HeroLeftGrphBlack,
  HeroRightGrph,
  HeroRightGrphBlack,
} from '../icons/home/Banner';

import {
  ApexLegendsRedIcon,
  ValorantIcon,
  FortniteIcon,
  RocketLeagueIcon,
} from '../../common/icons/SearchFilter';

import { Repeat, Compete, Arrow } from '../icons/home/Competitive';
import { Game } from '@src/types/Games/Games';

// ============ HERO SECTION SLIDER DATA ================
export const BannerContent = [
  {
    image:
      "bg-[url('/src/assets/images/png/homepage/landing-hero-bg-img.webp')]",
    title: 'CHALLENGE INCOMING',
    description: 'PLAY GAMES. WIN PRIZES',
    bannerEffectForBottom: HeroBottom,
    bannerEffectForBottomMobile: HeroBottomMobile,
    bannerCartoonForMobile: groupImgMobile1,
    avatar: cartoonimg1,
    avatarSecond: cartoonimg2,
    avatarThird: cartoonimg3,
    avatarFour: cartoonimg4,
    button: 'compete now',
    bannerLeftLine: <HeroLeftGrph />,
    bannerRightLine: <HeroRightGrph />,
    bannerLine: <HeroIndiosLine />,
    link: '/games',
  },
  {
    image:
      "bg-[url('/src/assets/images/png/homepage/landing-hero-bg-img-2.webp')]",
    title: 'ESPORTS SOLUTIONS',
    description: 'YOUR STRATEGIC ALLY',
    bannerEffectForBottom: HeroBottom2,
    bannerEffectForBottomMobile: HeroBottomMobile2,
    avatar: secondcartoonimg1,
    bannerCartoonForMobile: groupImgMobile2,
    avatarThird: secondcartoonimg2,
    button: 'Services',
    bannerLeftLine: <HeroLeftGrphBlack />,
    bannerRightLine: <HeroRightGrphBlack />,
    bannerLine: <HeroIndiosLineBlack />,
    link: '/services',
  },
];

export const GamesSelectList = [
  {
    thumbnail: apexLegends,
    title: 'apex legends',
    icon: <ApexLegendsRedIcon />,
    isActive: true,
    game: Game.apex,
  },
  {
    thumbnail: valorant,
    title: 'valorant',
    icon: <ValorantIcon />,
    isActive: false,
    game: Game.valorant,
  },
  {
    thumbnail: fortnite,
    title: 'fortnite',
    icon: <FortniteIcon />,
    isActive: false,
    game: Game.fortnite,
  },
  {
    thumbnail: rocketLeague,
    title: 'rocket league',
    icon: <RocketLeagueIcon />,
    isActive: false,
    game: Game.rocketLeague,
  },
];

export const CompetitiveIconsList = [
  {
    title: 'Meet',
    icon: <Arrow />,
  },
  {
    title: 'compete',
    icon: <Compete />,
  },
  {
    title: 'repeat',
    icon: <Repeat />,
  },
];
