import prizeTournamentBlurrBottom from '../../assets/images/png/about/prize-tournament-blurr-bottom.webp';
import prizeTournamentBlurr from '../../assets/images/png/about/prize-tournament-blurr.webp';
import {
  EstablishedIcon,
  PrizesWonIcon,
  TeamsCreatedIcon,
  TournamentsRunIcon,
} from '../common/icons/AboutIcon';
import { BottomGreenIcon } from '../common/icons/Common';

const AboutCards = () => {
  return (
    <div className="flex flex-col md:flex-row items-start gap-4 sm:gap-6 text-white px-4 sm:px-8 lg:px-12 custom_container mx-auto mt-10 xl:mt-14">
      <div className="w-full mx-auto md:w-[38%] rounded-2xl md:h-[31.9rem] lg:h-[32.5rem] xl:h-[42.375rem] peizewon_protrait_card_h">
        <div className="flex flex-col justify-between bg-[url('/src/assets/images/webp/about/find_team_grade_yellow.webp')] bg-cover bg-no-repeat bg-right team_card_parent duration-300 ease-in-out transition-all p-6 custom_xsm:p-8 rounded-2xl h-full relative overflow-hidden">
          <div className="sm:text-end relative z-[1] mb-4 md:mb-0 common_prize">
            <span className="inline-block prizes_wonIcon_shadow">
              <PrizesWonIcon />
            </span>
          </div>
          <div className="relative z-[1]">
            <h2 className="font-wide font-bold text-[2rem] sm:text-3xl lg:text-4xl xl:text-6xl uppercase mb-3 text-lightBlack">
              $150k<span className="text-green plus_icon">+</span>
            </h2>
            <p className="!leading-4 border-s-2 border-lightBlack ps-3 font-wide text-base sm:text-xs lg:text-base font-bold uppercase text-lightBlack pt-1">
              prizes won
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col mx-auto w-full md:w-[62%] gap-4 sm:gap-6">
        <div className="flex flex-col sm:flex-row-reverse bg-[url('/src/assets/images/webp/about/find_team_Grade.webp')]  bg-cover bg-no-repeat bg-right team_card_parent duration-300 p-6 sm:p-8 rounded-2xl items-end justify-between relative overflow-hidden">
          <img
            className="absolute top-0 sm:hidden"
            src={prizeTournamentBlurr}
            alt="prize-tournament-blurrv"
          />
          <img
            className="absolute bottom-0 opacity-40 sm:hidden"
            src={prizeTournamentBlurrBottom}
            alt="prize-tournament-blurrv"
          />
          <span className="common_prize inline-block mb-4 sm:mb-7 relative z-[1] tournaments_run_shadow">
            <TournamentsRunIcon />
          </span>
          <div className="relative z-[1] w-full">
            <h2 className="font-wide font-bold text-[32px] sm:text-3xl lg:text-4xl xl:text-6xl uppercase mb-3">
              1,000<span className="text-green plus_icon">+</span>
            </h2>
            <p className="!leading-4 border-s-2 border-green ps-3 font-wide text-base sm:text-xs lg:text-base font-bold text-green uppercase pt-[0.2rem]">
              tournaments run
            </p>
          </div>
        </div>
        <div className="flex flex-col custom_xsm:flex-row gap-4 sm:gap-6">
          <div className=" custom_xsm:w-1/2 bg-lightBlack p-6 lg:p-7 xl:p-8 rounded-2xl relative">
            <span className="prize_won_triangle_icon -rotate-90 absolute end-4 top-4">
              <BottomGreenIcon />
            </span>
            <span className="Prize_won_card">
              <TeamsCreatedIcon />
            </span>
            <div className="mt-4 custom_xsm:mt-6 sm:mt-10">
              <h2 className="font-wide font-bold text-[2rem] sm:text-3xl lg:text-4xl xl:text-6xl uppercase mb-3">
                3,000<span className="text-green plus_icon">+</span>
              </h2>
              <p className="!leading-4 border-s-2 border-green ps-3 font-wide pt-[0.2rem] text-base sm:text-xs lg:text-base font-bold text-green uppercase">
                teams created
              </p>
            </div>
          </div>
          <div className=" custom_xsm:w-1/2 bg-lightBlack p-6 lg:p-7 xl:p-8 rounded-2xl relative">
            <span className="prize_won_triangle_icon -rotate-90 absolute end-4 top-4">
              <BottomGreenIcon />
            </span>
            <span className="Prize_won_card">
              <EstablishedIcon />
            </span>
            <div className="mt-4 custom_xsm:mt-6 sm:mt-10">
              <h2 className="font-wide font-bold text-[2rem] sm:text-3xl lg:text-4xl xl:text-6xl uppercase mb-3">
                2016
              </h2>
              <p className="!leading-4 border-s-2 pt-[0.2rem] border-green ps-3 font-wide text-base sm:text-xs lg:text-base font-bold text-green uppercase">
                established
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCards;
