import { useContext, useEffect, useState, useMemo, useRef } from 'react';
// firebase
import { firestore } from '../../../firebase';
import { collection, query, where, getDocs, Query } from 'firebase/firestore';
// algolia
import algoliasearch from 'algoliasearch';
// types
import { DBUser, userConverter } from '../../../firestore/users';
// utils
import { debounce } from '../../../utils/Debounce';
// icons
import { Search, InputCross } from '../../common/icons/Common';
import { TeamMateIcon } from '../../common/icons/ProfileIcons';
import { TeamInfoContext } from '../TeamInfoProvider';
// assets
import imagePlaceholder from '../../../assets/images/placeholder/placeholder.webp';
import { ImSpinner8 } from 'react-icons/im';
import { useAuthContext } from '../../../provider/AuthContextProvider';

const SearchTeamMate = () => {
  const { userObj } = useAuthContext();
  const { teamInfo, setTeamInfo } = useContext(TeamInfoContext);

  const [players, setPlayers] = useState<DBUser[]>([]); // playerResults
  // invited players
  const [invitedPlayers, setInvitedPlayers] = useState<DBUser[]>(
    teamInfo.pendingPlayers
  );

  const [searchQuery, setSearchQuery] = useState<string>('');
  const searchQueryRef = useRef(searchQuery);
  const [searching, setSearching] = useState<boolean>(false);
  const [searchBarActive, setSearchBarActive] = useState(false);

  const getPlayers = async () => {
    let q: Query;
    const localPlayers: DBUser[] = [];
    const localSearchQuery = searchQueryRef.current;
    if (localSearchQuery && userObj) {
      if (import.meta.env.VITE_ENV === 'production') {
        const client = algoliasearch(
          '1EFPJPOFKM',
          '725ce15dcc1a7cf9b3a0beb00b120456'
        );
        const index = client.initIndex('users');
        const { hits } = await index.search(localSearchQuery);
        hits.forEach((hit) => {
          if (userObj.uid !== hit.objectID) {
            const uid = hit.objectID;
            const player = hit as unknown as DBUser;
            player.uid = uid;
            localPlayers.push(player);
          }
        });
      } else if (import.meta.env.VITE_ENV === 'staging') {
        const client = algoliasearch(
          'IONCIJEH1C',
          'f6e30465e863efdc9f34188018765ed8'
        );
        const index = client.initIndex('users');
        const { hits } = await index.search(localSearchQuery);
        hits.forEach((hit) => {
          if (userObj.uid !== hit.objectID) {
            const uid = hit.objectID;
            const player = hit as unknown as DBUser;
            player.uid = uid;
            localPlayers.push(player);
          }
        });
      } else {
        q = query(
          collection(firestore, 'users').withConverter(userConverter),
          where('displayName', '==', localSearchQuery)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          if (userObj.uid !== doc.id) {
            const player = doc.data() as DBUser;
            localPlayers.push(player);
          }
        });
      }
    }
    setPlayers(localPlayers);
    setSearching(false);
  };

  useEffect(() => {
    setSearching(true);
    searchQueryRef.current = searchQuery;
    debouncedGetPlayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    setTeamInfo({ ...teamInfo, pendingPlayers: invitedPlayers });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitedPlayers]);

  const debouncedGetPlayers = useMemo(() => debounce(getPlayers, 500), []);

  const togglePlayerInvitation = (uid: string) => {
    if (uid) {
      const localInvitedPlayers = [...invitedPlayers];
      if (localInvitedPlayers.find((player) => player.uid === uid)) {
        // remove the player obj from invited players array
        const playerIndex = localInvitedPlayers.findIndex(
          (player) => player.uid === uid
        );
        localInvitedPlayers.splice(playerIndex, 1);
        setInvitedPlayers(localInvitedPlayers);
      } else {
        // add the player obj to invited players array
        const player = players.find((player) => player.uid === uid) as DBUser;
        setInvitedPlayers([...localInvitedPlayers, player]);
      }
    }
  };

  const checkForOutsideInputClick: EventListener = (e: Event) => {
    if (e.target) {
      const target = e.target as HTMLElement;
      if (
        !target.classList.contains('searchInput') &&
        !target.closest('searchInput')
      ) {
        setSearchBarActive(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', checkForOutsideInputClick);

    return () =>
      document.removeEventListener('mouesdown', checkForOutsideInputClick);
  }, []);

  return (
    <div className="px-6 invite_team_scrollbar h-[50%] flex-grow flex flex-col">
      <div className="relative mb-6 mt-5">
        <label
          htmlFor="playerName"
          className="absolute top-1/2 -translate-y-1/2 left-4"
        >
          <Search />
        </label>
        <input
          type="text"
          id="playerName"
          placeholder="Enter full player name"
          className={`searchInput !bg-black w-full pb-[0.125rem] ps-10 text-sm focus:outline-none placeholder:text-steelGray placeholder:font-normal placeholder:text-sm h-[2.5rem] placeholder:leading-4 rounded-xl ${
            searchBarActive
              ? 'border-green border-2 pr-10 text-white bg-black'
              : 'border-transparent border-2 bg-black'
          }`}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onClick={() => setSearchBarActive(true)}
        />
        {searchBarActive && (
          <span
            onClick={() => {
              setSearchQuery('');
              setSearchBarActive(false);
            }}
            className="absolute top-1/2 -translate-y-1/2 end-4 cursor-pointer validation_pop_up_cross_icon"
          >
            <InputCross />
          </span>
        )}
      </div>
      <div className="relative duration-300 h-[60%] flex-grow">
        <div className="overflow-y-scroll creators_scrollbar relative h-full">
          {!searchQuery ? (
            <div className="pt-6 pb-12 px-8 flex justify-center items-center flex-col">
              <div className="w-[4.5rem] h-[4.5rem] flex justify-center items-center rounded-full bg-lightGray mb-6">
                <TeamMateIcon />
              </div>
              <h3 className="font-wide font-bold text-2xl !leading-6 text-white uppercase text-center">
                search for a teammate
              </h3>
              <h4 className="font-compact text-sm font-normal text-steelGray text-center tracking-[0.009rem] leading-4 pt-2">
                Invite some players to join your team.
              </h4>
            </div>
          ) : (
            <>
              {!searching ? (
                <>
                  {players.length > 0 ? (
                    <>
                      {players.map((player, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between px-3 py-3 bg-lightGray rounded-xl mb-2"
                        >
                          <div className="flex items-center min-[26.25rem]:gap-4 gap-2">
                            <img
                              className="max-w-[2.5rem] h-auto aspect-square object-cover rounded-full"
                              src={
                                player.displayImage
                                  ? player.displayImage
                                  : imagePlaceholder
                              }
                              alt="player"
                            />
                            <div className="flex flex-col gap-y-1">
                              <h2 className="font-wide text-left font-bold text-white leading-4 min-[26.25rem]:pb-1">
                                {player.displayName}
                              </h2>
                              <h3 className="font-compact text-sm text-steelGray">
                                @{player.username}
                              </h3>
                            </div>
                          </div>
                          {invitedPlayers.find(
                            (invPlayer) => invPlayer.uid === player.uid
                          ) ? (
                            <button
                              type="button"
                              onClick={() => togglePlayerInvitation(player.uid)}
                              className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-white bg-ebonyClay hover:opacity-75 transition-opacity"
                            >
                              Cancel Invite
                            </button>
                          ) : (
                            <>
                              {player.team !== '' &&
                              player.team === teamInfo.id ? (
                                <p className="text-sm font-compact font-semibold text-steelGray">
                                  Already in team
                                </p>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() =>
                                    togglePlayerInvitation(player.uid)
                                  }
                                  className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center bg-green hover:bg-gorse transition-colors"
                                >
                                  Invite
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <p className="text-steelGray font-semibold">
                      Player name "<strong>{searchQuery}</strong>" not found.
                    </p>
                  )}
                </>
              ) : (
                <div className="h-full w-full flex items-center justify-center">
                  <ImSpinner8 className="text-5xl text-steelGray animate-spin" />
                </div>
              )}
              <div className="bg_bottom_shadow absolute bottom-0 left-0 w-full h-[2.5rem]"></div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default SearchTeamMate;
