// Importing the BrandLogoSlider component from the common directory
import BrandLogoSlider from '../common/BrandLogoSlider';
// Importing various components related to different services
import ContactForm from '../contact/ContactForm';
import EsportsConsulting from './EsportsConsulting';
import EsportsProduction from './EsportsProduction';
import LeagueOperations from './LeagueOperations';
import Hero from './Hero';
import Testimonials from './Testimonials';
import servicesInfos from '../../assets/images/png/services/services_Infos.webp';

const formHero = (
  <>
    <img
      className="w-[13.375rem] mx-auto"
      src={servicesInfos}
      alt="servicesInfos"
    />
    <h2 className="font-wide font-bold text-2xl !leading-6 mb-3 md:mb-4 mt-6 md:mt-10 text-center uppercase">
      contact us
    </h2>
    <p className="font-compact font-normal !tracking-[0.01rem] text-sm sm:text-base text-steelGray !leading-4 text-center">
      Feel free to get in touch with us. We're here to help you!
    </p>
  </>
);

const ServicesMain = () => {
  return (
    <div className="md:-mt-8">
      {/* Displaying the Hero section */}
      <Hero />

      {/* Displaying the League Operations section */}
      <LeagueOperations />

      {/* Displaying the Esports Production section */}
      <EsportsProduction />

      {/* Displaying the Esports Consulting section */}
      <EsportsConsulting />

      {/* Displaying the Brand Logo Slider */}
      <BrandLogoSlider />

      {/* Displaying the Testimonials section */}
      <Testimonials />

      {/* Displaying the Contact Us section */}
      <ContactForm hero={formHero} purple={true} context={'services'} />
    </div>
  );
};

// Exporting the ServicesMain component as the default export
export default ServicesMain;
