import React, { useState } from 'react';
// import DropDown from "../ui/DropDown";
import SearchBar from '../ui/SearchBar';
// import { SlidersIcon } from "../common/icons/Common";
import { SkeletonDark } from '../common/skeleton-loader/Skeletons';

type SearchAndFilterProps = {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  category: number;
  setCategory: (value: number) => void;
  sortBy: number;
  setSortBy: (value: number) => void;
  onSearch: () => void;
};

const SearchAndFilter: React.FC<SearchAndFilterProps> = ({
  searchQuery,
  setSearchQuery,
  // category,
  // setCategory,
  // sortBy,
  // setSortBy,
}) => {
  // Dropdown options (dummy options for now)
  // const sortingOptions1 = [
  //     { label: "All Categories", value: 0 },
  //     { label: "Action", value: 1 },
  //     { label: "Adventure", value: 2 },
  //     { label: "RPG", value: 3 },
  // ];

  // const sortingOptions2 = [
  //     { label: "Relevance", value: 0 },
  //     { label: "Price: Low to High", value: 1 },
  //     { label: "Price: High to Low", value: 2 },
  //     { label: "A-Z", value: 3 },
  //     { label: "Z-A", value: 4 },
  // ];

  const [initiallyLoaded] = useState<boolean>(true);

  return (
    <div className="custom_container z-20 mb-6">
      <div
        className={`
          mb-4 mt-8 flex items-center justify-between border-t border-lightBlack
          pt-8
        `}
      >
        {initiallyLoaded ? (
          <h2
            className={`
              text-xl font-semibold uppercase text-white font-wide

              lg:text-3xl
            `}
          >
            GAMES
          </h2>
        ) : (
          <SkeletonDark className="h-[25px] w-[120px] rounded-xl"></SkeletonDark>
        )}
        {/* <div className="flex items-center flex-row flex-wrap md:flex-nowrap gap-4 w-full md:w-auto justify-center md:justify-start">
                    <DropDown
                        icon={<SlidersIcon className="w-[16px] h-auto aspect-square stroke-green" />}
                        loaded={true}
                        currentOption={{ label: "Platform", value: category }}
                        options={sortingOptions1}
                        className="bg-lightGray w-full md:max-w-[200px]"
                        handleOnChange={setCategory}
                        black={true}
                    />
                    <DropDown
                        icon={<SlidersIcon className="w-[16px] h-auto aspect-square stroke-green" />}
                        loaded={true}
                        currentOption={{ label: "Sort By", value: sortBy }}
                        options={sortingOptions2}
                        className="bg-lightGray w-full md:max-w-[200px]"
                        handleOnChange={setSortBy}
                        black={true}
                    />
                </div> */}
      </div>

      <div
        className={`
          mt-5 flex flex-col items-center gap-4

          md:flex-row
        `}
      >
        <div className="w-full flex-grow">
          <SearchBar
            value={searchQuery}
            setValue={setSearchQuery}
            placeholder={'Search for Games'}
            loaded={true}
            className="w-full"
          />
        </div>
        {/* <button
          type="button"
          onClick={toggleFilterMenu}
          className={`
            flex items-center justify-center rounded-xl p-3 transition-opacity
            bg-lightBlack

            hover:opacity-75

            md:hidden
          `}
        >
          <SlidersIcon
            className={'aspect-square h-auto w-[18px] stroke-green'}
          />
        </button> */}
      </div>
    </div>
  );
};

export default SearchAndFilter;
