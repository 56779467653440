import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
// types
import {
  PerformanceHistory,
  PerformanceHistoryStatus,
  performanceHistoryStatusToString,
} from '@src/firestore/performanceHistory';
// utils
import { formatDate } from '@utils/Date';
import { positionNumberToString } from '@src/firestore/tournamentPointsSystems';
// assets
import placeHolderImage from '@assets/images/placeholder/placeholder.webp';
// icons
import { FaClock } from 'react-icons/fa';
import {
  TrophyBronzeGradient,
  TrophyGoldGradient,
  TrophyGreenGradient,
  TrophySilverGradient,
} from '@components/common/icons/TournamentIcons';
import { ApexLegendsIcon } from '@components/common/icons/Games';
import { TargetIcon } from '@components/common/icons/Common';

interface IPerformanceHistoryCard {
  performanceHistory: PerformanceHistory;
}

const PerformanceHistoryCard: React.FC<IPerformanceHistoryCard> = ({
  performanceHistory,
}) => {
  let placementGradient: string;
  let placementTextGradient: string;

  switch (performanceHistory.placement) {
    case 1:
      placementGradient = 'bg-gradient-to-b from-lightGold to-gold';
      placementTextGradient =
        'bg-gradient-to-b from-lightGold to-gold text-transparent leading-none bg-clip-text';
      break;
    case 2:
      placementGradient = 'bg-gradient-to-b from-lightSilver to-silver';
      placementTextGradient =
        'bg-gradient-to-b from-lightSilver to-silver text-transparent leading-none bg-clip-text';
      break;
    case 3:
      placementGradient = 'bg-gradient-to-b from-lightBronze to-bronze';
      placementTextGradient =
        'bg-gradient-to-b from-lightBronze to-bronze text-transparent leading-none bg-clip-text';
      break;
    default:
      placementGradient = 'bg-gradient-to-b from-gorse to-green';
      placementTextGradient =
        'bg-gradient-to-b from-gorse to-green text-transparent leading-none bg-clip-text';
      break;
  }

  const PlacementIcon = (): ReactNode => {
    const prizeClassName = `w-[30px] h-auto aspect-square`;
    return performanceHistory.placement && performanceHistory.prized ? (
      <>
        {performanceHistory.placement === 1 ? (
          <TrophyGoldGradient className={prizeClassName} />
        ) : performanceHistory.placement === 2 ? (
          <TrophySilverGradient className={prizeClassName} />
        ) : performanceHistory.placement === 3 ? (
          <TrophyBronzeGradient className={prizeClassName} />
        ) : (
          <TrophyGreenGradient className={prizeClassName} />
        )}
      </>
    ) : (
      <ApexLegendsIcon className="w-[30px] h-auto aspect-square fill-steelGray" />
    );
  };

  return (
    <div
      key={`performance-history-${performanceHistory.id}`}
      className="relative bg-lightBlack p-4 rounded-xl mt-3 sm:mt-2 flex justify-between items-center"
    >
      {performanceHistory.status !== PerformanceHistoryStatus.registered &&
      performanceHistory.status !== PerformanceHistoryStatus.waiting &&
      performanceHistory.status !== PerformanceHistoryStatus.confirmed &&
      performanceHistory.status !== PerformanceHistoryStatus.ongoing ? (
        <div
          className={`absolute left-0  top-1/2 -translate-y-1/2 w-[4px] h-[40px] rounded-r
                        ${performanceHistory.prized ? placementGradient : 'bg-steelGray'}`}
        ></div>
      ) : (
        ''
      )}
      <div className="flex items-center gap-x-8">
        <div className="flex items-center gap-x-4 min-w-[110px]">
          {performanceHistory.status === PerformanceHistoryStatus.registered ||
          performanceHistory.status === PerformanceHistoryStatus.waiting ||
          performanceHistory.status === PerformanceHistoryStatus.confirmed ||
          performanceHistory.status === PerformanceHistoryStatus.ongoing ? (
            <>
              <FaClock className="w-[24px] lg:w-[26px] h-auto aspect-square fill-white" />
              <p className="font-wide text-2xl font-semibold uppercase">TBC</p>
            </>
          ) : performanceHistory.status ===
            PerformanceHistoryStatus.eliminated ? (
            <>
              <TargetIcon className="w-[24px] lg:w-[26px] h-auto aspect-square fill-steelGray" />
              <p
                className={`text-steelGray font-wide text-2xl font-semibold uppercase`}
              >
                N/A
              </p>
            </>
          ) : (
            <>
              <PlacementIcon />
              <p
                className={`${performanceHistory.prized ? placementTextGradient : 'text-steelGray'} font-wide text-2xl font-semibold uppercase`}
              >
                {positionNumberToString(performanceHistory.placement)}
              </p>
            </>
          )}
        </div>
        <Link
          to={`/tournaments/${performanceHistory.tournamentId}${performanceHistory.status === PerformanceHistoryStatus.placement || performanceHistory.status === PerformanceHistoryStatus.eliminated ? '/results' : ''}`}
          className="group flex flex-col hover:opacity-75 transition-opacity"
        >
          <p className="font-compact text-white group-hover:text-green transition-colors uppercase font-semibold lg:text-[1.1rem]">
            {performanceHistory.tournamentName}
          </p>
          <p className="text-steelGray text-sm uppercase font-compact font-semibold -my-1">
            {formatDate(performanceHistory.tournamentStartDate)}
          </p>
        </Link>
      </div>

      <div className="flex items-center gap-x-6">
        {performanceHistory.participatingPlayerData.length > 0 ? (
          <div className="hidden sm:flex items-center w-fit justify-end">
            {performanceHistory.participatingPlayerData.map((player, index) => {
              return (
                <Link
                  key={`performance-history-${performanceHistory.id}-player-image-${player.id}`}
                  to={`/profile/${player.id}`}
                  className="group relative w-[30px] h-auto aspect-square rounded-full bg-lightBlack border-2 border-lightBlack overflow-hidden -ml-[9px]"
                  style={{ zIndex: index + 1 }}
                >
                  <img
                    src={
                      player.displayImage !== ''
                        ? player.displayImage
                        : placeHolderImage
                    }
                    alt={`${player.displayName} image`}
                    className={`group-hover:opacity-75 transition-opacity w-full h-full object-cover}`}
                  />
                </Link>
              );
            })}
          </div>
        ) : (
          ''
        )}
        <div className="bg-lightGray rounded-xl w-[100px] sm:w-[150px] h-[34px] flex items-center justify-center">
          {performanceHistory.status === PerformanceHistoryStatus.placement ? (
            <>
              {performanceHistory.winnings !== null &&
              performanceHistory.winnings > 0 ? (
                <p
                  className={`${performanceHistory.prized ? placementTextGradient : 'text-steelGray'} font-compact text-xl lg:text-2xl font-semibold`}
                >
                  ${performanceHistory.winnings}
                </p>
              ) : (
                <p className="text-steelGray font-compact text-3xl font-semibold">
                  -
                </p>
              )}
            </>
          ) : (
            <p className="text-white font-compact uppercase">
              {performanceHistoryStatusToString(performanceHistory.status)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PerformanceHistoryCard;
