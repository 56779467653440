// assets
// Map Cover Images
import worldsEdgeCover from '@assets/images/apex/maps/WE/cover.webp';
import stormPointCover from '@assets/images/apex/maps/SP/cover.webp';
import olympusCover from '@assets/images/apex/maps/OL/cover.webp';
import brokenMoonCover from '@assets/images/apex/maps/BM/cover.webp';
import kingsCanyonCover from '@assets/images/apex/maps/KC/cover.webp';
import eDistrictCover from '@assets/images/apex/maps/ED/cover.webp';
// Map Images
import worldsEdge from '@assets/images/apex/maps/WE/map.webp';
import stormPoint from '@assets/images/apex/maps/SP/map.webp';
import eDistrict from '@assets/images/apex/maps/ED/map.webp';
// Map Images with Labels
import worldsEdgePois from '@assets/images/apex/maps/WE/mapPois.webp';
import stormPointPois from '@assets/images/apex/maps/SP/mapPois.webp';
import eDistrictPois from '@assets/images/apex/maps/ED/mapPois.webp';
// Player Card Backgrounds
import ashBackground from '@assets/images/apex/playerCardBackgrounds/ashBanner.webp';
import alterBackground from '@assets/images/apex/playerCardBackgrounds/alterBanner.webp';
import ballisticBackground from '@assets/images/apex/playerCardBackgrounds/ballisticBanner.webp';
import bangaloreBackground from '@assets/images/apex/playerCardBackgrounds/bangaloreBanner.webp';
import bloodhoundBackground from '@assets/images/apex/playerCardBackgrounds/bloodhoundBanner.webp';
import catalystBackground from '@assets/images/apex/playerCardBackgrounds/catalystBanner.webp';
import causticBackground from '@assets/images/apex/playerCardBackgrounds/causticBanner.webp';
import conduitBackground from '@assets/images/apex/playerCardBackgrounds/conduitBanner.webp';
import cryptoBackground from '@assets/images/apex/playerCardBackgrounds/cryptoBanner.webp';
import fuseBackground from '@assets/images/apex/playerCardBackgrounds/fuseBanner.webp';
import gibraltarBackground from '@assets/images/apex/playerCardBackgrounds/gibraltarBanner.webp';
import horizonBackground from '@assets/images/apex/playerCardBackgrounds/horizonBanner.webp';
import lifelineBackground from '@assets/images/apex/playerCardBackgrounds/lifelineBanner.webp';
import lobaBackground from '@assets/images/apex/playerCardBackgrounds/lobaBanner.webp';
import madmaggieBackground from '@assets/images/apex/playerCardBackgrounds/madmaggieBanner.webp';
import mirageBackground from '@assets/images/apex/playerCardBackgrounds/mirageBanner.webp';
import newcastleBackground from '@assets/images/apex/playerCardBackgrounds/newcastleBanner.webp';
import octaneBackground from '@assets/images/apex/playerCardBackgrounds/octaneBanner.webp';
import pathfinderBackground from '@assets/images/apex/playerCardBackgrounds/pathfinderBanner.webp';
import rampartBackground from '@assets/images/apex/playerCardBackgrounds/rampartBanner.webp';
import revenantBackground from '@assets/images/apex/playerCardBackgrounds/revenantBanner.webp';
import seerBackground from '@assets/images/apex/playerCardBackgrounds/seerBanner.webp';
import valkyrieBackground from '@assets/images/apex/playerCardBackgrounds/valkyrieBanner.webp';
import wattsonBackground from '@assets/images/apex/playerCardBackgrounds/wattsonBanner.webp';
import wraithBackground from '@assets/images/apex/playerCardBackgrounds/wraithBanner.webp';
import vantageBackground from '@assets/images/apex/playerCardBackgrounds/vantageBanner.webp';
// Legends
import ashHead from '@assets/images/apex/legends/Ash Head.webp';
import alterHead from '@assets/images/apex/legends/Alter Head.webp';
import ballisticHead from '@assets/images/apex/legends/Ballistic Head.webp';
import bangaloreHead from '@assets/images/apex/legends/Bangalore Head.webp';
import bloodhoundHead from '@assets/images/apex/legends/Bloodhound Head.webp';
import catalystHead from '@assets/images/apex/legends/Catalyst Head.webp';
import causticHead from '@assets/images/apex/legends/Caustic Head.webp';
import conduitHead from '@assets/images/apex/legends/Conduit Head.webp';
import cryptoHead from '@assets/images/apex/legends/Crypto Head.webp';
import fuseHead from '@assets/images/apex/legends/Fuse Head.webp';
import gibraltarHead from '@assets/images/apex/legends/Gibraltar Head.webp';
import horizonHead from '@assets/images/apex/legends/Horizon Head.webp';
import lifelineHead from '@assets/images/apex/legends/Lifeline Head.webp';
import lobaHead from '@assets/images/apex/legends/Loba Head.webp';
import maggieHead from '@assets/images/apex/legends/Mad Maggie Head.webp';
import mirageHead from '@assets/images/apex/legends/Mirage Head.webp';
import newcastleHead from '@assets/images/apex/legends/Newcastle Head.webp';
import octaneHead from '@assets/images/apex/legends/Octane Head.webp';
import pathfinderHead from '@assets/images/apex/legends/Pathfinder Head.webp';
import rampartHead from '@assets/images/apex/legends/Rampart Head.webp';
import revenantHead from '@assets/images/apex/legends/Revenant Head.webp';
import seerHead from '@assets/images/apex/legends/Seer Head.webp';
import valkyrieHead from '@assets/images/apex/legends/Valkyrie Head.webp';
import wattsonHead from '@assets/images/apex/legends/Wattson Head.webp';
import wraithHead from '@assets/images/apex/legends/Wraith Head.webp';
import vantageHead from '@assets/images/apex/legends/Vantage Head.webp';
// Weapons
import _3030White from '@assets/images/apex/weapons/3030 white.webp';
import alternatorWhite from '@assets/images/apex/weapons/Alternator white.webp';
import arcStarWhite from '@assets/images/apex/weapons/Arc star white.webp';
import bocekWhite from '@assets/images/apex/weapons/Bocek white.webp';
import carSmgWhite from '@assets/images/apex/weapons/CarSMG white.webp';
import chargeRifleWhite from '@assets/images/apex/weapons/Charge Rifle white.webp';
import devotionWhite from '@assets/images/apex/weapons/Devotion white.webp';
import eva8White from '@assets/images/apex/weapons/Eva8 white.webp';
import flatlineWhite from '@assets/images/apex/weapons/Flatline White.webp';
import fragWhite from '@assets/images/apex/weapons/Frag white.webp';
import g7ScoutWhite from '@assets/images/apex/weapons/G7 Scout white.webp';
import havocWhite from '@assets/images/apex/weapons/Havoc white.webp';
import hemlokWhite from '@assets/images/apex/weapons/Hemlok white.webp';
import kraberWhite from '@assets/images/apex/weapons/Kraber white.webp';
import longbowWhite from '@assets/images/apex/weapons/Longbow white.webp';
import lStarWhite from '@assets/images/apex/weapons/LStar white.webp';
import mastiffWhite from '@assets/images/apex/weapons/Mastiff white.webp';
import meleeWhite from '@assets/images/apex/weapons/melee white.webp';
import mozambiqueWhite from '@assets/images/apex/weapons/Mozambique white.webp';
import nemesisWhite from '@assets/images/apex/weapons/Nemesis white.webp';
import p2020White from '@assets/images/apex/weapons/P2020 white.webp';
import pkWhite from '@assets/images/apex/weapons/PK white.webp';
import prowlerWhite from '@assets/images/apex/weapons/Prowler white.webp';
import r99White from '@assets/images/apex/weapons/R99 white.webp';
import r301White from '@assets/images/apex/weapons/r301 white.webp';
import rampageWhite from '@assets/images/apex/weapons/Rampage white.webp';
import re45White from '@assets/images/apex/weapons/RE-45 white.webp';
import sentinelWhite from '@assets/images/apex/weapons/Sentinel white.webp';
import spitfireWhite from '@assets/images/apex/weapons/Spitfire white.webp';
import thermiteWhite from '@assets/images/apex/weapons/Thermite white.webp';
import tripletakeWhite from '@assets/images/apex/weapons/TripleTake white.webp';
import voltWhite from '@assets/images/apex/weapons/Volt White.webp';
import wingmanWhite from '@assets/images/apex/weapons/Wingman white.webp';

interface WeaponNameToImport {
  [key: string]: string;
}

const weaponNameToImport: WeaponNameToImport = {
  'Arc Star': arcStarWhite,
  Melee: meleeWhite,
  Thermite: thermiteWhite,
  Frag: fragWhite,
  '30-30 Repeater': _3030White,
  'Alternator SMG': alternatorWhite,
  'Bocek Compound Bow': bocekWhite,
  'C.A.R. SMG': carSmgWhite,
  'Charge Rifle': chargeRifleWhite,
  'Devotion LMG': devotionWhite,
  'EVA-8 Auto': eva8White,
  'G7 Scout': g7ScoutWhite,
  'HAVOC Rifle': havocWhite,
  'Hemlok Burst AR': hemlokWhite,
  'Kraber .50-Cal Sniper': kraberWhite,
  'L-STAR EMG': lStarWhite,
  'Longbow DMR': longbowWhite,
  'M600 Spitfire': spitfireWhite,
  'Mastiff Shotgun': mastiffWhite,
  'Mozambique Shotgun': mozambiqueWhite,
  'Nemesis Burst AR': nemesisWhite,
  P2020: p2020White,
  Peacekeeper: pkWhite,
  'Prowler Burst PDW': prowlerWhite,
  'R-301 Carbine': r301White,
  'R-99 SMG': r99White,
  'RE-45 Auto': re45White,
  'Rampage LMG': rampageWhite,
  Sentinel: sentinelWhite,
  'Triple Take': tripletakeWhite,
  'VK-47 Flatline': flatlineWhite,
  'Volt SMG': voltWhite,
  Wingman: wingmanWhite,
};

export const getWeaponImage = (weaponName: string) => {
  return weaponNameToImport[weaponName] !== undefined
    ? weaponNameToImport[weaponName]
    : '';
};

export const apexLegendNames = [
  'Alter',
  'Ash',
  'Bangalore',
  'Ballistic',
  'Bloodhound',
  'Caustic',
  'Catalyst',
  'Conduit',
  'Crypto',
  'Fuse',
  'Gibraltar',
  'Horizon',
  'Lifeline',
  'Loba',
  'Mad Maggie',
  'Mirage',
  'Newcastle',
  'Octane',
  'Pathfinder',
  'Rampart',
  'Revenant',
  'Seer',
  'Valkyrie',
  'Vantage',
  'Wattson',
  'Wraith',
];

export const apexLegendWeaponNames = [
  '30-30 Repeater',
  'Alternator SMG',
  'Bocek Compound Bow',
  'C.A.R. SMG',
  'Charge Rifle',
  'Devotion LMG',
  'EVA-8 Auto',
  'G7 Scout',
  'HAVOC Rifle',
  'Hemlok Burst AR',
  'Kraber .50-Cal Sniper',
  'L-STAR EMG',
  'Longbow DMR',
  'M600 Spitfire',
  'Mastiff Shotgun',
  'Mozambique Shotgun',
  'Nemesis Burst AR',
  'P2020',
  'Peacekeeper',
  'Prowler Burst PDW',
  'R-301 Carbine',
  'R-99 SMG',
  'RE-45 Auto',
  'Rampage LMG',
  'Sentinel',
  'Triple Take',
  'VK-47 Flatline',
  'Volt SMG',
  'Wingman',
];

export function getLegendHeadshot(legend: string) {
  if (!legend) return '';

  const legendHeadshots: Record<string, string> = {
    ash: ashHead,
    alter: alterHead,
    ballistic: ballisticHead,
    bangalore: bangaloreHead,
    bloodhound: bloodhoundHead,
    catalyst: catalystHead,
    caustic: causticHead,
    conduit: conduitHead,
    crypto: cryptoHead,
    fuse: fuseHead,
    gibraltar: gibraltarHead,
    horizon: horizonHead,
    lifeline: lifelineHead,
    loba: lobaHead,
    madmaggie: maggieHead,
    mirage: mirageHead,
    newcastle: newcastleHead,
    octane: octaneHead,
    pathfinder: pathfinderHead,
    rampart: rampartHead,
    revenant: revenantHead,
    seer: seerHead,
    valkyrie: valkyrieHead,
    wattson: wattsonHead,
    wraith: wraithHead,
    vantage: vantageHead,
  };

  const formattedLegend = legend.replace(/\s+/g, '').toLowerCase();
  if (legendHeadshots[formattedLegend]) {
    return legendHeadshots[formattedLegend];
  } else {
    return '';
  }
}

export function getLegendBackground(legend: string) {
  interface LegendBackgrounds {
    [key: string]: string;
  }

  const legendBackgrounds: LegendBackgrounds = {
    ash: ashBackground,
    alter: alterBackground,
    ballistic: ballisticBackground,
    bangalore: bangaloreBackground,
    bloodhound: bloodhoundBackground,
    catalyst: catalystBackground,
    caustic: causticBackground,
    conduit: conduitBackground,
    crypto: cryptoBackground,
    fuse: fuseBackground,
    gibraltar: gibraltarBackground,
    horizon: horizonBackground,
    lifeline: lifelineBackground,
    loba: lobaBackground,
    madmaggie: madmaggieBackground,
    mirage: mirageBackground,
    newcastle: newcastleBackground,
    octane: octaneBackground,
    pathfinder: pathfinderBackground,
    rampart: rampartBackground,
    revenant: revenantBackground,
    seer: seerBackground,
    valkyrie: valkyrieBackground,
    wattson: wattsonBackground,
    wraith: wraithBackground,
    vantage: vantageBackground,
  };

  const formattedLegend = legend.replace(/\s+/g, '').toLowerCase();
  if (legendBackgrounds[formattedLegend]) {
    return legendBackgrounds[formattedLegend];
  } else {
    return 'Image not found';
  }
}

export const getMapCoverImageFromAcronym = (acronym: string): string => {
  switch (acronym) {
    case 'WE':
      return worldsEdgeCover;
    case 'BM':
      return brokenMoonCover;
    case 'KC':
      return kingsCanyonCover;
    case 'SP':
      return stormPointCover;
    case 'OL':
      return olympusCover;
    case 'ED':
      return eDistrictCover;
    default:
      return '';
  }
};

export const getMapImageFromAcronym = (acronym: string): string => {
  switch (acronym) {
    case 'WE':
      return worldsEdge;
    case 'SP':
      return stormPoint;
    case 'ED':
      return eDistrict;
    default:
      return 'https://thumbs.dreamstime.com/z/treasure-map-cartoon-doodle-hand-drawn-vector-illustration-worn-out-108132871.jpg';
  }
};

export const getMapPoiImageFromAcronym = (acronym: string): string => {
  switch (acronym) {
    case 'WE':
      return worldsEdgePois;
    case 'SP':
      return stormPointPois;
    case 'ED':
      return eDistrictPois;
    default:
      return 'https://thumbs.dreamstime.com/z/treasure-map-cartoon-doodle-hand-drawn-vector-illustration-worn-out-108132871.jpg';
  }
};

export const getMapNameFromAcronym = (acronym: string): string => {
  switch (acronym) {
    case 'WE':
      return "World's Edge";
    case 'BM':
      return 'Broken Moon';
    case 'KC':
      return "King's Canyon";
    case 'SP':
      return 'Storm Point';
    case 'OL':
      return 'Olympus';
    case 'ED':
      return 'E-District';
    default:
      return '';
  }
};
