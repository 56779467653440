import React, { useEffect, useState } from 'react';
import {
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { startAfter, collection, query } from 'firebase/firestore';
import { DBTeam } from '@src/firestore/teams';
import { teamConverter } from '@src/firestore/teams';
import { firestore } from '@src/firebase';
import {
  getQueryConstraintsForRegionOption,
  RegionOption,
} from '@src/components/global/utils';
import PaginationBar from '@src/components/ui/PaginationBar';
import { PlayerResultsPerPage } from '@src/components/players/utils';
import {
  SkeletonDark,
  SkeletonLight,
} from '@components/common/skeleton-loader/Skeletons';
import LeaderboardCard from './LeaderboardCard';
import { TeamsLeaderboardSortingOption } from '../types';

interface TeamLeaderboardsProps {
  selectedRegion: RegionOption;
  selectedSorting: TeamsLeaderboardSortingOption;
}

const teamSortingDropdownOptions = [
  {
    value: TeamsLeaderboardSortingOption.totalWinningsDesc,
    label: 'Winnings',
  },
  {
    value: TeamsLeaderboardSortingOption.trophiesWonDesc,
    label: 'Trophies Won',
  },
  {
    value: TeamsLeaderboardSortingOption.apexWinNumDesc,
    label: 'Apex Wins',
  },
  {
    value: TeamsLeaderboardSortingOption.apexWinRateDesc,
    label: 'Apex Win Rate',
  },
];

const TeamLeaderboards: React.FC<TeamLeaderboardsProps> = ({
  selectedRegion,
  selectedSorting,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [teams, setTeams] = useState<DBTeam[]>([]);
  const [pageNum, setPageNum] = useState(1);
  const [lastPageNum, setLastPageNum] = useState<number>(1);
  const [lastVisible, setLastVisible] =
    useState<QueryDocumentSnapshot<DBTeam> | null>(null);

  useEffect(() => {
    getTeams();
  }, [selectedRegion, selectedSorting, pageNum]);

  const sortingConstraint = (
    selectedSorting: TeamsLeaderboardSortingOption
  ) => {
    switch (selectedSorting) {
      case TeamsLeaderboardSortingOption.totalWinningsDesc:
        return [orderBy('totalWinnings', 'desc'), orderBy('id')];
      case TeamsLeaderboardSortingOption.trophiesWonDesc:
        return [orderBy('trophyCount', 'desc'), orderBy('id')];
      case TeamsLeaderboardSortingOption.apexWinNumDesc:
        return [orderBy('apexMatchWins', 'desc'), orderBy('id')];
      case TeamsLeaderboardSortingOption.apexWinRateDesc:
        return [orderBy('apexMatchWinrate', 'desc'), orderBy('id')];
      default:
        return [orderBy('totalWinnings', 'desc'), orderBy('id')];
    }
  };

  const getTeams = async () => {
    setIsLoading(true);
    const teamsCollection = collection(firestore, 'teams').withConverter(
      teamConverter
    );

    const queryRegionConstraints =
      getQueryConstraintsForRegionOption(selectedRegion);

    let baseQuery = query(
      teamsCollection,
      ...queryRegionConstraints,
      ...sortingConstraint(selectedSorting),
      limit(PlayerResultsPerPage)
    );

    if (pageNum > 1 && lastVisible) {
      baseQuery = query(
        teamsCollection,
        ...queryRegionConstraints,
        ...sortingConstraint(selectedSorting),
        startAfter(lastVisible),
        limit(PlayerResultsPerPage)
      );
    }

    try {
      const countQuery = query(teamsCollection, ...queryRegionConstraints);
      const count = (await getCountFromServer(countQuery)).data().count;
      setLastPageNum(Math.ceil(count / PlayerResultsPerPage));

      const snapshots = await getDocs(baseQuery);
      const lastVisible = snapshots.docs[snapshots.docs.length - 1];
      setLastVisible(lastVisible);

      const teamData = snapshots.docs.map((doc) => doc.data());
      setTeams(teamData);
    } catch (error) {
      console.error('Error fetching teams:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const changePage = (newPage: number) => {
    setPageNum(newPage);
  };

  return (
    <div>
      {isLoading ? (
        <div>
          {[...Array(PlayerResultsPerPage)].map((_, index) => (
            <div className="mt-4" key={`skeleton-${index}`}>
              {index % 2 === 0 ? (
                <SkeletonDark className="h-[84.5px] w-full rounded-lg" />
              ) : (
                <SkeletonLight className="h-[84.5px] w-full rounded-lg" />
              )}
            </div>
          ))}
        </div>
      ) : (
        <>
          {teams.map((team, index) => (
            <div className="mt-4" key={team.id + index}>
              <LeaderboardCard
                currentSorting={selectedSorting}
                entity={team}
                index={(pageNum - 1) * PlayerResultsPerPage + index}
                type="team"
              />
            </div>
          ))}
          <PaginationBar
            className="my-[50px]"
            totalPages={lastPageNum}
            currentPage={pageNum}
            changePage={changePage}
          />
        </>
      )}
    </div>
  );
};

export { teamSortingDropdownOptions };
export default TeamLeaderboards;
