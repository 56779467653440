import { Link } from 'react-router-dom';
// types
import { DBUser } from '@src/firestore/users';
// utils
import {
  getWeaponImage,
  getLegendHeadshot,
  getLegendBackground,
} from '@utils/Games/ApexLegends';
import { getAgeFromDateOfBirth } from '@utils/Date';
// packages
import { getName } from 'country-list';
import '/node_modules/flag-icons/css/flag-icons.min.css';
// assetts
import placeholderImage from '@assets/images/placeholder/placeholder.webp';
// components
import PopoverText from '@ui/PopoverText';
// icons
import {
  ConsoleIcon,
  ControllerIcon,
  MonitorIcon,
  MouseIcon,
} from '@components/common/icons/Common';
import {
  TrophyBronzeImage,
  TrophyGoldImage,
  TrophySilverImage,
} from '@components/common/icons/TournamentIcons';

const CardCornerChevron = ({ className }: { className: string }) => (
  <svg
    className={`
      ${className}
    `}
    viewBox="0 0 301 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M104.375 173.938L221.424 286.485C251.288 315.2 301 294.035 301 252.605V47C301 21.0426 279.957 0 254 0H47.9957C6.62477 0 -14.565 49.5911 14.0315 79.4875L104.375 173.938Z"
      fill="#D4FF27"
      className={'fill-inherit'}
    />
  </svg>
);

const platformStringToIcon = (platform: string) => {
  switch (platform) {
    case 'PC':
      return (
        <MonitorIcon
          className={`
            aspect-square h-auto w-[12px] fill-steelGray transition-colors

            group-hover:fill-white/65
          `}
        />
      );
    default:
      return (
        <ConsoleIcon
          className={`
            aspect-square h-auto w-[18px] fill-steelGray mt-[2px]
            transition-colors

            group-hover:fill-white/65
          `}
        />
      );
  }
};

const gameInputStringToIcon = (inputName: string) => {
  switch (inputName) {
    case 'Mouse and Keyboard':
      return (
        <MouseIcon
          className={`
            aspect-square h-auto w-[12px] fill-steelGray transition-colors

            group-hover:fill-white/65
          `}
        />
      );
    case 'Controller':
      return (
        <ControllerIcon
          className={`
            aspect-square h-auto w-[12px] fill-steelGray transition-colors

            group-hover:fill-white/65
          `}
        />
      );
    default:
      return '';
  }
};

interface IPlayerCard {
  player: DBUser;
}

const PlayerCard: React.FC<IPlayerCard> = ({ player }) => {
  const playerTrophies = player.trophyData;

  const apexGameEntry = player.addedGames.find(
    (game) => game.title === 'Apex Legends'
  );
  const goldTrophies = playerTrophies.filter((trophy) => trophy.position === 1);
  const silverTrophies = playerTrophies.filter(
    (trophy) => trophy.position === 2
  );
  const bronzeTrophies = playerTrophies.filter(
    (trophy) => trophy.position === 3
  );

  return (
    <Link
      to={`/profile/${player.uid}`}
      className={`
        group relative flex w-[90%] flex-shrink-0 flex-grow-0 flex-col
        overflow-hidden rounded-xl bg-lightBlack p-2 transition-all

        hover:bg-lightGray

        lg:w-full
      `}
    >
      <CardCornerChevron
        className={`
          absolute right-3 top-3 z-[4] aspect-square h-auto w-[15px] fill-green
          transition-colors
        `}
      />
      <div
        className={`
          absolute right-3 top-3 bg-lightBlack z-[3] aspect-square h-auto
          w-[100px] origin-center -translate-y-[70%] translate-x-[70%] rotate-45
          transition-all

          group-hover:bg-lightGray
        `}
      ></div>

      <div
        className={`
          relative z-[2] aspect-[4.5] h-auto min-h-[140px] w-full transition-all
        `}
      >
        <div
          className={`
            relative h-full w-full px-6 bg-lightBlack transition-colors

            group-hover:bg-lightGray
          `}
        >
          <div
            className={`
              absolute left-0 top-0 h-full w-full overflow-hidden rounded-lg
              transition-all

              group-hover:-left-[2.5px] group-hover:-top-[2.5px]
              group-hover:h-[calc(100%+5px)] group-hover:w-[calc(100%+5px)]
            `}
          >
            <div
              className={`
                absolute left-0 top-0 z-[1] h-full w-full bg-black/70
                transition-colors

                group-hover:bg-black/35
              `}
            ></div>
            <img
              src={
                player.bannerImage !== ''
                  ? player.bannerImage
                  : apexGameEntry
                    ? getLegendBackground(apexGameEntry.mainLegend!)
                    : placeholderImage
              }
              alt={`${player.displayName} banner image`}
              className={`
                absolute left-0 top-0 z-[0] h-full w-full object-cover
                opacity-50
              `}
            />
          </div>

          <div className="relative z-[2] flex h-full items-center gap-x-10">
            <div className="relative z-[2] h-fit w-fit transition-transform">
              {player.teamLogoUrl ? (
                <div
                  className={`
                    absolute -right-3 top-3 z-[2] !aspect-square h-auto
                    !w-[35px] -translate-y-1/3 overflow-hidden rounded-xl
                    border-[3px] border-lightBlack bg-lightGray
                    transition-transform

                    group-hover:translate-x-[-5px]
                    group-hover:translate-y-[calc(-33%-5px)]
                  `}
                >
                  <img
                    src={player.teamLogoUrl}
                    alt={`${player.displayName} team logo`}
                    className="h-full w-full object-cover"
                  />
                </div>
              ) : (
                ''
              )}
              <img
                src={
                  player.displayImage !== ''
                    ? player.displayImage
                    : placeholderImage
                }
                alt={`${player.displayName} display image`}
                className={`
                  relative z-[1] aspect-square h-auto w-[100px] rounded-full
                  transition-all bg-lightBlack border-[3px] border-lightBlack
                  object-cover

                  group-hover:translate-x-[-5px] group-hover:bg-lightGray
                `}
              />
            </div>

            <div className="flex flex-col gap-y-2">
              <h3
                className={`
                  font-wide text-2xl font-semibold uppercase text-white
                `}
              >
                {player.displayName}
              </h3>
              <div className="flex items-center gap-x-4">
                <div className="relative group/nation h-fit w-fit">
                  <div
                    className={`
                      opacity-75 fi

                      fi-${player.nationality}
                    `}
                  ></div>
                  <PopoverText
                    className="group-hover/nation:opacity-100"
                    visible={false}
                  >
                    {getName(player.nationality)}
                  </PopoverText>
                </div>
                {player.showAge ? (
                  <p
                    className={`
                      text-steelGray font-compact -mb-1 font-semibold
                      transition-colors

                      group-hover:text-white/65
                    `}
                  >
                    {getAgeFromDateOfBirth(player.dateOfBirth)}
                  </p>
                ) : (
                  ''
                )}
                {apexGameEntry ? (
                  <>
                    <div
                      className={`
                        relative group/platform text-steelGray font-compact
                        font-semibold
                      `}
                    >
                      <p>{platformStringToIcon(apexGameEntry.platform)}</p>
                      <PopoverText
                        className="group-hover/platform:opacity-100"
                        visible={false}
                      >
                        {apexGameEntry.platform}
                      </PopoverText>
                    </div>
                    <p className="text-steelGray font-compact font-semibold">
                      {gameInputStringToIcon(apexGameEntry.input)}
                    </p>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`
          relative z-[1] flex h-fit w-full items-center justify-between px-2
          pb-1 pt-3 transition-all

          group-hover:px-1 group-hover:pb-[2px] group-hover:pt-[10px]

          lg:min-h-0

          sm:min-h-[90px]
        `}
      >
        <div className="flex items-center">
          <div className="flex items-center gap-x-1">
            <div className="relative h-fit w-fit">
              <div
                className={`
                  ${goldTrophies.length > 0 ? '' : 'hidden'}

                  absolute right-0 top-0 z-[2] flex aspect-square h-[15px]
                  w-[15px] justify-center rounded-full bg-lightBlack border
                  border-lightGray items-center
                `}
              >
                <p className="mt-[3px] text-xs font-compact text-white">
                  {goldTrophies.length}
                </p>
              </div>
              <TrophyGoldImage
                className={`
                  aspect-square h-auto w-[50px] object-contain

                  ${goldTrophies.length > 0 ? '' : 'opacity-50'}
                `}
              />
            </div>

            <div className="relative h-fit w-fit">
              <div
                className={`
                  ${silverTrophies.length > 0 ? '' : 'hidden'}

                  absolute right-0 top-0 z-[2] flex aspect-square h-[15px]
                  w-[15px] justify-center rounded-full bg-lightBlack border
                  border-lightGray items-center
                `}
              >
                <p className="mt-[3px] text-xs font-compact text-white">
                  {silverTrophies.length}
                </p>
              </div>
              <TrophySilverImage
                className={`
                  aspect-square h-auto w-[50px] object-contain

                  ${silverTrophies.length > 0 ? '' : 'opacity-50'}
                `}
              />
            </div>

            <div className="relative h-fit w-fit">
              <div
                className={`
                  ${bronzeTrophies.length > 0 ? '' : 'hidden'}

                  absolute right-0 top-0 z-[2] flex aspect-square h-[15px]
                  w-[15px] justify-center rounded-full bg-lightBlack border
                  border-lightGray items-center
                `}
              >
                <p className="mt-[3px] text-xs font-compact text-white">
                  {bronzeTrophies.length}
                </p>
              </div>
              <TrophyBronzeImage
                className={`
                  aspect-square h-auto w-[50px] object-contain

                  ${bronzeTrophies.length > 0 ? '' : 'opacity-50'}
                `}
              />
            </div>
          </div>
          <div
            className={`
              h-[35px] w-[2px] bg-lightGray ml-2 mr-4

              group-hover:bg-ebonyClay
            `}
          ></div>
          <div className="mt-1">
            <p className="font-wide font-semibold uppercase text-white">
              Winnings
            </p>
            <p className="font-wide font-semibold text-green uppercase">
              {player.totalWinnings > 0
                ? `$${Math.round(player.totalWinnings)}`
                : 'N/A'}
            </p>
          </div>
        </div>
        {apexGameEntry ? (
          <div
            className={`
              hidden items-center gap-x-2

              sm:flex
            `}
          >
            <img
              src={getWeaponImage(apexGameEntry.favouriteWeapon!)}
              className={'h-[40px] w-auto max-w-[98px] object-contain'}
            />
            <img
              src={getLegendHeadshot(apexGameEntry.mainLegend!)}
              className={`
                aspect-square h-auto w-[40px] rounded-lg bg-lightBlack border
                border-lightGray object-cover transition-colors

                group-hover:bg-lightGray group-hover:border-ebonyClay
              `}
            />
            <img
              src={getLegendHeadshot(apexGameEntry.altLegend!)}
              className={`
                aspect-square h-auto w-[40px] rounded-lg bg-lightBlack border
                border-lightGray object-cover transition-colors

                group-hover:bg-lightGray group-hover:border-ebonyClay
              `}
            />
          </div>
        ) : (
          ''
        )}
      </div>
      <div
        className={`
          absolute bottom-0 right-0 flex -translate-x-1/2 translate-y-1/2
          rotate-45 flex-row gap-x-4
        `}
      >
        <div
          className={`
            h-[1000px] w-[1px] bg-lightGray z-[0]

            group-hover:bg-ebonyClay
          `}
        ></div>
        <div
          className={`
            h-[1000px] w-[1px] bg-lightGray z-[0]

            group-hover:bg-ebonyClay
          `}
        ></div>
        <div
          className={`
            h-[1000px] w-[1px] bg-lightGray z-[0]

            group-hover:bg-ebonyClay
          `}
        ></div>
        <div
          className={`
            h-[1000px] w-[1px] bg-lightGray z-[0]

            group-hover:bg-ebonyClay
          `}
        ></div>
        <div
          className={`
            h-[1000px] w-[1px] bg-lightGray z-[0]

            group-hover:bg-ebonyClay
          `}
        ></div>
        <div
          className={`
            h-[1000px] w-[1px] bg-lightGray z-[0]

            group-hover:bg-ebonyClay
          `}
        ></div>
        <div
          className={`
            h-[1000px] w-[1px] bg-lightGray z-[0]

            group-hover:bg-ebonyClay
          `}
        ></div>
        <div
          className={`
            h-[1000px] w-[1px] bg-lightGray z-[0]

            group-hover:bg-ebonyClay
          `}
        ></div>
        <div
          className={`
            z-[0] hidden h-[1000px] w-[1px] bg-lightGray

            group-hover:bg-ebonyClay

            sm:block
          `}
        ></div>
        <div
          className={`
            z-[0] hidden h-[1000px] w-[1px] bg-lightGray

            group-hover:bg-ebonyClay

            sm:block
          `}
        ></div>
        <div
          className={`
            z-[0] hidden h-[1000px] w-[1px] bg-lightGray

            group-hover:bg-ebonyClay

            sm:block
          `}
        ></div>
        <div
          className={`
            z-[0] hidden h-[1000px] w-[1px] bg-lightGray

            group-hover:bg-ebonyClay

            sm:block
          `}
        ></div>
        <div
          className={`
            z-[0] hidden h-[1000px] w-[1px] bg-lightGray

            group-hover:bg-ebonyClay

            sm:block
          `}
        ></div>
        <div
          className={`
            z-[0] hidden h-[1000px] w-[1px] bg-lightGray

            group-hover:bg-ebonyClay

            sm:block
          `}
        ></div>
        <div
          className={`
            z-[0] hidden h-[1000px] w-[1px] bg-lightGray

            group-hover:bg-ebonyClay

            sm:block
          `}
        ></div>
        <div
          className={`
            z-[0] hidden h-[1000px] w-[1px] bg-lightGray

            group-hover:bg-ebonyClay

            sm:block
          `}
        ></div>
      </div>
    </Link>
  );
};

export default PlayerCard;
