// context
import { useTournamentsContext } from '../TournamentsProvider';
// components
import TournamentCard from './TournamentCard';
import SkeletonTournamentCard from './SkeletonTournamentCard';
// icons
import { VscDebugDisconnect } from 'react-icons/vsc';

const CompletedList = () => {
  const { completedTournaments, completedTournamentsLoaded } =
    useTournamentsContext();

  return (
    <div className={'overflow-hidden pt-8'}>
      <h2
        className={`
          mb-8 text-xl font-semibold uppercase text-white font-wide

          lg:text-2xl
        `}
      >
        Completed Tournaments
      </h2>
      {completedTournamentsLoaded ? (
        <>
          {completedTournaments.length > 0 ? (
            <div className="overflow-auto">
              <div
                className={`
                  flex justify-start flex-wrap-0 w-full gap-8 overflow-auto pb-3
                  text-white scrollbar_none

                  lg:grid lg:grid-cols-3

                  md:overflow-hidden

                  xl:grid-cols-4
                `}
              >
                {completedTournaments.map((tournament) => (
                  <TournamentCard
                    key={`main-list-${tournament.id}`}
                    tournament={tournament}
                  />
                ))}
              </div>
            </div>
          ) : (
            // <div className="w-full flex flex-wrap-0 overflow-x-scroll lg:grid lg:grid-cols-2 gap-4 my-8 lg:mb-[150px]">
            // </div>
            <div
              className={`
                flex min-h-[350px] flex-col items-center justify-center gap-y-2
              `}
            >
              <div
                className={`
                  flex aspect-square h-auto items-center justify-center
                  bg-lightGray w-[50px] rounded-full
                `}
              >
                <VscDebugDisconnect className="text-2xl text-white" />
              </div>
              <p
                className={`
                  font-wide text-2xl font-semibold uppercase text-white
                `}
              >
                No tournaments found
              </p>
              <p className="text-steelGray font-compact">
                Try adjusting your search parameters
              </p>
            </div>
          )}
        </>
      ) : (
        <div className="overflow-auto">
          <div
            className={`
              flex w-full overflow-auto pb-3 text-white scrollbar_none

              md:flex-wrap md:overflow-hidden
            `}
          >
            {Array.from({ length: 6 }).map((_i, index) => (
              <SkeletonTournamentCard key={`main-list-${index}`} />
            ))}
          </div>
        </div>
        // <div className="w-full flex flex-wrap-0 overflow-x-scroll lg:grid lg:grid-cols-2 gap-4 my-8 lg:mb-[150px]">
        // </div>
      )}
    </div>
  );
};

export default CompletedList;
