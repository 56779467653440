// import { useState } from "react";
import pcPlayer from '../../assets/images/png/homepage/pcPlayer.webp';
// import grindAnimationImg from "../../assets/images/webp/homepage/grind-start-img.webp";
import {
  GrindStartsTopLine,
  GrindStartLeftLine,
  GrindStartRightLine,
  GrindTopArrow,
  GrindBgEffect,
} from '../common/icons/home/HomeIcons';
// import playBtn from "../../assets/images/png/homepage/videPlayBtn.webp";
// import GamingVideo from "../../assets/video/gaming-grind-star.mp4";
import { Link } from 'react-router-dom';

const GrindStarts = () => {
  // // Define the type for the state
  // const [isVideoPlay, setIsVideoPlay] = useState<boolean>(false);

  // const videoHandler = () => {
  //   // Check if the video is paused and play it
  //   const videoElement = document.querySelector("video");
  //   if (videoElement && videoElement.paused) {
  //     videoElement.play();
  //     videoElement.setAttribute("controls", "true");
  //   }
  //   setIsVideoPlay(true);
  // };
  // // Update body overflow based on video stat
  // if (isVideoPlay === true) {
  //   document.body.style.overflow = "";
  // } else {
  //   document.body.style.overflow = "auto";
  // }

  return (
    <>
      <div
        className={`
          overflow-hidden pb-14 pt-14 custom_container

          lg:pb-5 lg:pt-16

          md:pt-20

          sm:pb-0 sm:pt-16
        `}
      >
        <div
          className={`
            relative px-4

            sm:px-8 sm:py-0

            xl:px-0 xl:py-24
          `}
        >
          {/* ICONS */}
          <span
            className={`
              absolute left-1/2 top-1/2 -z-[1] -translate-x-1/2 -translate-y-1/2
            `}
          >
            <GrindBgEffect />
          </span>
          <span
            className={`
              absolute left-0 top-[55%] hidden -translate-y-1/2

              xl:block
            `}
          >
            <GrindStartLeftLine />
          </span>
          <span
            className={`
              absolute right-0 top-[55%] hidden -translate-y-1/2

              xl:block
            `}
          >
            <GrindStartRightLine />
          </span>
          <GrindTopArrow />
          <div className="relative flex items-center justify-center text-center">
            <div className="max-w-[55.5rem]">
              <h2
                className={`
                  relative z-[1] m-auto max-w-[15.625rem] text-2xl font-bold
                  uppercase leading-[100%] text-white font-wide

                  lg:text-[1.875rem]

                  sm:max-w-[25rem]

                  xl:text-[2.25rem]
                `}
              >
                <span className="text-green">THE GRIND</span> STARTS NOW
              </h2>
              <GrindStartsTopLine />
              {/* VIDEO THUMBNAIL AND PLAY BUTTON */}
              <div
                className={`
                  group relative h-[15.5rem] overflow-hidden rounded-2xl

                  sm:h-auto
                `}
              >
                <img src={pcPlayer} alt="Man playing PC in dark room" />
                {/* {isVideoPlay ? (
                  ""
                ) : (
                  <span
                    onClick={videoHandler}
                    className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 cursor-pointer"
                  >
                    <img
                      className="max-w-[6.25rem] md:max-w-[7.5rem]"
                      src={playBtn}
                      alt="playBtn"
                    />
                  </span>
                )}

                {isVideoPlay ? (
                  ""
                ) : (
                  <img
                    onClick={videoHandler}
                    className="rounded-2xl absolute group-hover:scale-110 duration-300 h-full object-cover"
                    src={grindAnimationImg}
                    alt="grind-Animation-Img"
                  />
                )}

                <video
                  className="w-full h-full object-cover rounded-2xl"
                  muted
                  src={GamingVideo}
                ></video> */}
              </div>
              <div
                className={`
                  inline-block pt-10

                  lg:pt-12
                `}
              >
                <Link
                  to="/games"
                  className={`
                    bg-green flex h-12 w-[10.313rem] items-center justify-center
                    whitespace-nowrap rounded-xl px-6 pt-4 text-sm font-medium
                    text-black font-compact pb-3 uppercase a-auto

                    hover:bg-gorse

                    md:text-base
                  `}
                >
                  compete now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GrindStarts;
