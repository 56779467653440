// Importing images for the Esports Consulting section
import consulting from '../../assets/images/webp/services/esportsconsulting.webp';
import consultingMobile from '../../assets/images/png/services/esportsconsultingmobile.webp';

// Importing icons and content components
import { ConsultingIcon } from '../common/icons/Services';
import { ConsultingList } from '../common/content/Services';

// Functional component for Esports Consulting section
const EsportsConsulting = () => {
  return (
    <div className="px-4 sm:px-8 lg:px-12 custom_container">
      {/* Container for the Esports Consulting section */}
      <div className="flex flex-col lg:flex-row items-center rounded-2xl bg-lightBlack overflow-hidden mb-8">
        {/* Image container */}
        <div className="w-full lg:max-w-[33rem] lg:h-[33.5rem] ">
          {/* Desktop image */}
          <img
            className="w-full h-full hidden lg:block operation_consulting_clippath"
            src={consulting}
            alt="consulting"
          />
          {/* Mobile image */}
          <img
            className="w-full lg:hidden"
            src={consultingMobile}
            alt="consultingMobile"
          />
        </div>

        {/* Content container */}
        <div className="max-[420px]:p-4 max-[420px]:pt-0 p-6 pt-0 -mt-6 lg:mt-0 sm:p-4 sm:pt-0 lg:py-10 lg:pr-6 lg:w-1/2 xl:w-auto xl:px-10">
          {/* Consulting icon */}
          <ConsultingIcon />

          {/* Title */}
          <h3 className="max-[420px]:text-2xl text-4xl font-bold text-white font-wide mt-6 mb-2 xl:mt-8">
            ESPORTS CONSULTING
          </h3>

          {/* Description */}
          <p className="text-sm custom_xsm:text-base text-slateGray tracing-[0.01rem] leading-[150%] mb-6 xl:mb-10 font-compact xl:pe-52 max-w-[45rem]">
            Professional advice and support on Licensing, Strategy, Eco-System
            Design, Digital Marketing, Partnerships & Monetisation.
          </p>

          {/* Consulting list */}
          <div className="overflow-x-auto scrollbar_none">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 md:gap-3 xl:gap-4 lg:w-[50rem] xl:w-auto">
              {ConsultingList.map((obj, index) => (
                <div
                  className="max-[420px]:p-4 p-8 sm:p-4 lg:p-6 xl:p-8 rounded-2xl bg-lightGray my="
                  key={index}
                >
                  {/* Subsection title */}
                  <h6 className="flex gap-2 font-wide text-base font-bold text-white uppercase mb-3.5">
                    <span className="bg-electricViolet text-black h-6 w-6 rounded-full flex items-center justify-center pt-1">
                      {index + 1}
                    </span>
                    <span className="pt-[0.125rem] uppercase">{obj.title}</span>
                  </h6>

                  {/* Subsection description */}
                  <p className="text-base text-slateGray leading-[150%] tracking-[0.01rem] font-compact">
                    {obj.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Exporting the EsportsConsulting component
export default EsportsConsulting;
