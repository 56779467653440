import { useState, useEffect } from 'react';
// context
import { useTournamentContext } from '@components/tournament/TournamentProvider';
import { useAuthContext } from '@provider/AuthContextProvider';
// components
import CheckBox from '@ui/CheckBox';
// assets
import imagePlaceholder from '@assets/images/placeholder/placeholder.webp';

interface ICheckInPlayerSelect {
  checkInSelectedPlayers: string[];
  setCheckInSelectedPlayers: (playerIds: string[]) => void;
  setStepValid: (valid: boolean) => void;
}

const CheckInPlayerSelect: React.FC<ICheckInPlayerSelect> = ({
  checkInSelectedPlayers,
  setCheckInSelectedPlayers,
  setStepValid,
}) => {
  const { tournament } = useTournamentContext();
  const { userTeam } = useAuthContext();

  const [enoughPlayersSelected, setEnoughPlayersSelected] =
    useState<boolean>(true);

  useEffect(() => {
    if (
      checkInSelectedPlayers.length > 0 &&
      tournament &&
      checkInSelectedPlayers.length < tournament.teamSize
    ) {
      setEnoughPlayersSelected(false);
    } else {
      setEnoughPlayersSelected(true);
    }
  }, [tournament, checkInSelectedPlayers]);

  useEffect(() => {
    setStepValid(checkInSelectedPlayers.length > 0 && enoughPlayersSelected);
  }, [checkInSelectedPlayers, enoughPlayersSelected, setStepValid]);

  const toggleSelectedPlayer = (playerId: string) => {
    const localSelectedPlayers = [...checkInSelectedPlayers];

    const existingPlayerIndex = localSelectedPlayers.indexOf(playerId);
    if (existingPlayerIndex !== -1) {
      localSelectedPlayers.splice(existingPlayerIndex, 1);
    } else {
      localSelectedPlayers.push(playerId);
    }

    setCheckInSelectedPlayers(localSelectedPlayers);
  };

  if (!tournament) return '';
  if (!userTeam) return '';

  return (
    <div className="relative flex-grow -mt-2">
      <p
        className={`font-compact text-white my-2 font-medium absolute bottom-1 left-1 bg-black/70 p-3 rounded-lg backdrop-blur z-[2]
                    ${checkInSelectedPlayers.length > 0 ? (enoughPlayersSelected ? '!text-green' : '!text-red') : ''}`}
      >
        {checkInSelectedPlayers.length}/{tournament.maxTeamSize}
      </p>
      <div className="flex flex-col gap-y-2 max-h-[210px] overflow-y-scroll maskedListVert py-[20px] -my-[10px]">
        {userTeam.playerData.map((player) => (
          <div
            key={`${player.id}-player-select-card`}
            onClick={() => toggleSelectedPlayer(player.id)}
            className="flex items-center justify-between px-3 py-3 bg-lightGray rounded-xl mb-2 w-full cursor-pointer
                            hover:opacity-75 transition-opacity"
          >
            <div className="flex items-center min-[26.25rem]:gap-4 gap-2">
              <img
                className="max-w-[2.5rem] h-auto aspect-square object-cover rounded-full"
                src={
                  player.displayImage ? player.displayImage : imagePlaceholder
                }
                alt="player"
              />
              <div className="flex flex-col gap-y-1">
                <h2 className="font-wide text-left font-bold text-white leading-4 min-[26.25rem]:pb-1">
                  {player.displayName}
                </h2>
                <h3 className="font-compact text-sm text-steelGray">
                  @{player.username}
                </h3>
              </div>
            </div>
            <CheckBox
              selected={checkInSelectedPlayers.includes(player.id)}
              setSelected={() => toggleSelectedPlayer(player.id)}
              interactive={false}
              label={
                checkInSelectedPlayers.includes(player.id)
                  ? 'Deselect Player'
                  : 'Select Player'
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckInPlayerSelect;
