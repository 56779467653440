import { useMemo, useState } from 'react';
// context
import TeamsProvider from './TeamsProvider';
// components
import TeamList from './components/TeamList';
import FindTeam from '@common/FindTeam';

const Teams = () => {
  const [teamsLoaded, setTeamsLoaded] = useState<boolean>(false);

  const announceTeamsLoaded = useMemo(() => setTeamsLoaded, []);

  return (
    <div className="custom_container">
      <TeamsProvider announceTeamsLoaded={announceTeamsLoaded}>
        <div className="">
          <TeamList />
          <div className={''}>
            <FindTeam loaded={teamsLoaded} />
          </div>
        </div>
      </TeamsProvider>
    </div>
  );
};

export default Teams;
