import { Link } from 'react-router-dom';
import {
  DiscordIcon,
  TwitterIcon,
  TwitchIcon,
  YouTubeIcon,
} from '@icons/Socials';
import {
  TrophyGoldImageNoCup,
  TrophySilverImageNoCup,
  TrophyBronzeImageNoCup,
  TrophyGoldImage,
  TrophySilverImage,
  TrophyBronzeImage,
} from '@icons/TournamentIcons';
import { ReactNode, useState } from 'react';
import { Prize } from '@src/firestore/tournaments';
import { DBUser } from '@src/firestore/users';
import { DBTeam } from '@src/firestore/teams';
import {
  LeaderboardSortingOption,
  TeamsLeaderboardSortingOption,
} from '../types';
import { toast } from 'react-toastify';

interface LeaderboardCardProps {
  index: number;
  entity: DBUser | DBTeam;
  currentSorting: LeaderboardSortingOption | TeamsLeaderboardSortingOption;
  type: 'player' | 'team';
}

const LeaderboardCard: React.FC<LeaderboardCardProps> = ({
  index,
  entity,
  currentSorting,
  type,
}) => {
  const [prizes] = useState<Prize[]>([
    {
      position: 1,
      prizeAmount: 125,
      prizeDescription: 'placement',
      prizeName: '1st',
      special: false,
    },
    {
      position: 2,
      prizeAmount: 75,
      prizeDescription: 'placement',
      prizeName: '2nd',
      special: false,
    },
    {
      position: 3,
      prizeAmount: 125,
      prizeDescription: 'placement',
      prizeName: '3rd',
      special: false,
    },
  ]);

  const trophies = entity.trophyData;
  const goldTrophies = trophies.filter((trophy) => trophy.position === 1);
  const silverTrophies = trophies.filter((trophy) => trophy.position === 2);
  const bronzeTrophies = trophies.filter((trophy) => trophy.position === 3);

  const isTeam = type === 'team';
  const isPlayer = type === 'player';

  const getEntityProps = () => {
    if (isTeam) {
      const team = entity as DBTeam;
      return {
        name: team.teamName,
        image: team.logoUrl,
        path: `/team/${team.id}`,
        socials: {
          discord: team.discordLink,
          twitter: team.twitterLink,
          twitch: team.twitchLink,
          youtube: team.websiteLink,
        },
        dissolved: team.dissolved,
        totalWinnings: team.totalWinnings,
        trophyCount: team.trophyCount,
        apexMatchWins: team.apexMatchWins,
        apexMatchWinrate: team.apexMatchWinrate,
        apexMatchNum: team.apexMatchNum,
        tag: team.teamTag,
      };
    } else {
      const player = entity as DBUser;
      return {
        name: player.displayName,
        username: player.username,
        image: player.displayImage,
        path: `/profile/${player.id}`,
        socials: {
          discord: player.discord,
          twitter: player.twitter,
          twitch: player.twitch,
          youtube: player.youtube,
        },
        totalWinnings: player.totalWinnings,
        trophyCount: player.trophyCount,
      };
    }
  };

  const entityProps = getEntityProps();

  const getPrizeTextColorForPosition = (position: number) => {
    const prizeForPosition = prizes.find(
      (prize) => prize.position === position
    );
    if (prizeForPosition) {
      switch (position) {
        case 1:
          return '!text-yellow';
        case 2:
          return '!text-slateGray';
        case 3:
          return '!text-lightRed';
        default:
          return '!text-green';
      }
    }
    return null;
  };

  const getPrizeIconForPosition = (position: number) => {
    const prizeForPosition = prizes.find(
      (prize) => prize.position === position
    );
    let prizeIcon: ReactNode = '';

    if (prizeForPosition) {
      switch (position) {
        case 1:
          prizeIcon = (
            <TrophyGoldImageNoCup
              className={'aspect-square h-auto w-[50px] object-contain'}
            />
          );
          break;
        case 2:
          prizeIcon = (
            <TrophySilverImageNoCup
              className={'aspect-square h-auto w-[50px] object-contain'}
            />
          );
          break;
        case 3:
          prizeIcon = (
            <TrophyBronzeImageNoCup
              className={'aspect-square h-auto w-[50px] object-contain'}
            />
          );
          break;
      }
      return (
        <div className="relative h-fit w-fit">
          <p
            className={`
              ${getPrizeTextColorForPosition(position)}

              font-compact absolute left-1/2 top-1/2 z-10 -translate-x-1/2
              -translate-y-1/2 text-xl leading-none
            `}
          >
            {position}
          </p>
          {prizeIcon}
        </div>
      );
    }
    return null;
  };

  const prizeIcon = getPrizeIconForPosition(index + 1);
  const prizeColor = getPrizeTextColorForPosition(index + 1);

  const renderSocialIcons = () => (
    <span className="flex h-fit items-center gap-x-2">
      {Object.entries(entityProps.socials).map(([platform, link]) => {
        if (!link) return null;

        const IconComponent = {
          discord: DiscordIcon,
          twitter: TwitterIcon,
          twitch: TwitchIcon,
          youtube: YouTubeIcon,
        }[platform];

        return (
          <Link key={platform} target="_blank" to={link}>
            {IconComponent && (
              <IconComponent
                className={`
                  aspect-square h-auto w-[18px] transition-colors fill-steelGray

                  hover:fill-green
                `}
              />
            )}
          </Link>
        );
      })}
    </span>
  );

  const renderSortingMetric = () => {
    switch (currentSorting) {
      case LeaderboardSortingOption.totalWinningsDesc:
        if (entityProps.totalWinnings != null) {
          return (
            <div
              className={`
                relative flex flex-col rounded-2xl py-3 text-center text-white
                bg-lightGray min-w-[120px] items-center
              `}
            >
              {index === 0 && (
                <p
                  className={`
                    text-sm font-compact !text-steelGray absolute top-[-44px]
                  `}
                >
                  Winnings
                </p>
              )}
              <p
                className={`
                  ${prizeColor ? prizeColor : ''}

                  text-2xl font-medium uppercase font-wide
                `}
              >
                ${Math.round(entityProps.totalWinnings)}
              </p>
            </div>
          );
        }
        return null;
      case LeaderboardSortingOption.trophiesWonDesc:
        if (entityProps.trophyCount != null) {
          return (
            <div
              className={`
                rounded-2xl px-6 py-2 text-white bg-lightGray relative flex
                flex-col items-center
              `}
            >
              {index === 0 && (
                <p
                  className={`
                    text-sm font-compact !text-steelGray absolute top-[-36px]
                  `}
                >
                  Trophies
                </p>
              )}
              <div className="flex items-center gap-x-1">
                <div className="relative h-fit w-fit">
                  <div
                    className={`
                      ${goldTrophies.length > 0 ? '' : 'hidden'}

                      absolute right-0 top-0 z-[2] flex aspect-square h-[15px]
                      w-[15px] justify-center rounded-full bg-lightBlack border
                      border-lightGray items-center
                    `}
                  >
                    <p className="mt-[3px] text-xs font-compact text-white">
                      {goldTrophies.length}
                    </p>
                  </div>
                  <TrophyGoldImage
                    className={`
                      aspect-square h-auto w-[50px] object-contain

                      ${goldTrophies.length > 0 ? '' : 'opacity-50'}
                    `}
                  />
                </div>

                <div className="relative h-fit w-fit">
                  <div
                    className={`
                      ${silverTrophies.length > 0 ? '' : 'hidden'}

                      absolute right-0 top-0 z-[2] flex aspect-square h-[15px]
                      w-[15px] justify-center rounded-full bg-lightBlack border
                      border-lightGray items-center
                    `}
                  >
                    <p className="mt-[3px] text-xs font-compact text-white">
                      {silverTrophies.length}
                    </p>
                  </div>
                  <TrophySilverImage
                    className={`
                      aspect-square h-auto w-[50px] object-contain

                      ${silverTrophies.length > 0 ? '' : 'opacity-50'}
                    `}
                  />
                </div>

                <div className="relative h-fit w-fit">
                  <div
                    className={`
                      ${bronzeTrophies.length > 0 ? '' : 'hidden'}

                      absolute right-0 top-0 z-[2] flex aspect-square h-[15px]
                      w-[15px] justify-center rounded-full bg-lightBlack border
                      border-lightGray items-center
                    `}
                  >
                    <p className="mt-[3px] text-xs font-compact text-white">
                      {bronzeTrophies.length}
                    </p>
                  </div>
                  <TrophyBronzeImage
                    className={`
                      aspect-square h-auto w-[50px] object-contain

                      ${bronzeTrophies.length > 0 ? '' : 'opacity-50'}
                    `}
                  />
                </div>
              </div>
            </div>
          );
        }
        return null;
      case TeamsLeaderboardSortingOption.apexWinNumDesc:
        if (entityProps.apexMatchWins != null) {
          return (
            <div className="flex items-center gap-x-4">
              <div
                className={`
                  relative flex flex-col items-center rounded-2xl px-8 py-3
                  text-center text-white bg-lightGray min-w-[120px]
                `}
              >
                {index === 0 && (
                  <p
                    className={`
                      text-sm font-compact !text-steelGray absolute top-[-44px]
                    `}
                  >
                    Matches Played
                  </p>
                )}
                <p
                  className={`
                    ${prizeColor ? prizeColor : ''}

                    text-2xl font-medium uppercase font-wide
                  `}
                >
                  {Math.round(entityProps.apexMatchNum)}
                </p>
              </div>
              <div
                className={`
                  relative flex flex-col items-center rounded-2xl px-8 py-3
                  text-center text-white bg-lightGray min-w-[120px]
                `}
              >
                {index === 0 && (
                  <p
                    className={`
                      text-sm font-compact !text-steelGray absolute top-[-44px]
                    `}
                  >
                    Matches Won
                  </p>
                )}
                <p
                  className={`
                    ${prizeColor ? prizeColor : ''}

                    text-2xl font-medium uppercase font-wide
                  `}
                >
                  {Math.round(entityProps.apexMatchWins)}
                </p>
              </div>
            </div>
          );
        }
        return null;
      case TeamsLeaderboardSortingOption.apexWinRateDesc:
        if (entityProps.apexMatchWins != null) {
          return (
            <div className="flex items-center gap-x-4">
              <div
                className={`
                  relative flex flex-col items-center rounded-2xl px-8 py-3
                  text-center text-white bg-lightGray min-w-[120px]
                `}
              >
                {index === 0 && (
                  <p
                    className={`
                      text-sm font-compact !text-steelGray absolute top-[-44px]
                    `}
                  >
                    Matches Won
                  </p>
                )}
                <p
                  className={`
                    ${prizeColor ? prizeColor : ''}

                    text-2xl font-medium uppercase font-wide
                  `}
                >
                  {Math.round(entityProps.apexMatchNum)}
                </p>
              </div>
              <div
                className={`
                  relative flex flex-col items-center rounded-2xl px-8 py-3
                  text-center text-white bg-lightGray min-w-[120px]
                `}
              >
                {index === 0 && (
                  <p
                    className={`
                      text-sm font-compact !text-steelGray absolute top-[-44px]
                    `}
                  >
                    Matches Won %
                  </p>
                )}
                <p
                  className={`
                    ${prizeColor ? prizeColor : ''}

                    text-2xl font-medium uppercase font-wide
                  `}
                >
                  {Math.round(entityProps.apexMatchWinrate)}%
                </p>
              </div>
            </div>
          );
        }
        return null;
      default:
        return null;
    }
  };

  return (
    <div
      className={
        'w-full bg-lightBlack flex min-h-[88px] justify-between rounded-lg'
      }
    >
      <div className={'flex items-center gap-x-2'}>
        <div
          className={`
            flex aspect-square h-full items-center justify-center border-r
            text-2xl font-semibold uppercase border-lightGray
          `}
        >
          {prizeIcon ? (
            <>{prizeIcon}</>
          ) : (
            <p className={'z-[10] text-white opacity-30'}>{index + 1}</p>
          )}
        </div>
        <div className={'flex items-center justify-start gap-x-4 pl-4'}>
          <div className="flex items-center gap-x-4">
            <Link
              to={entityProps.path}
              onClick={(e) => {
                if (isTeam && entityProps.dissolved) {
                  e.preventDefault();
                  e.stopPropagation();
                  toast.error('Team has been dissolved');
                }
              }}
              className={`
                flex items-center gap-x-4 transition-opacity

                hover:opacity-75
              `}
            >
              <div
                className={`
                  flex h-16 w-16 items-center justify-center border
                  border-lightGray p-0.5

                  ${isPlayer ? 'rounded-full' : 'rounded-lg'}
                `}
              >
                <img
                  className={`
                    aspect-square h-full w-full object-cover object-center

                    ${isPlayer ? 'rounded-full' : 'rounded-lg'}
                  `}
                  src={entityProps.image}
                  alt={`${type}-image`}
                />
              </div>
              <div className={'w-fit'}>
                <div className={'flex items-center gap-x-4'}>
                  <h2
                    className={`
                      font-wide flex items-center gap-4 whitespace-nowrap
                      text-lg font-medium uppercase !leading-6 text-white
                      transition-colors

                      ${prizeColor ? prizeColor : 'text-white'}
                    `}
                  >
                    {entityProps.name}
                  </h2>
                </div>
                <div>
                  <p className={'font-compact text-sm text-steelGray'}>
                    {isPlayer ? `@${entityProps.username}` : entityProps.tag}
                  </p>
                </div>
              </div>
            </Link>
            <span className={'h-[20px] w-[1px] bg-lightGray'}></span>
            {renderSocialIcons()}
          </div>
        </div>
      </div>

      <div
        className={`
          ml-auto mr-8 flex flex-wrap items-center justify-end gap-x-4
        `}
      >
        {renderSortingMetric()}
      </div>
    </div>
  );
};

export default LeaderboardCard;
