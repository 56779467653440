import { useState, useEffect, useRef, ReactNode, forwardRef } from 'react';
// components
import { SkeletonDark } from '../common/skeleton-loader/Skeletons';
// icons
import { DownSelectArrow } from '@components/common/icons/Common';
import { FaLock } from 'react-icons/fa';

export interface DropDownOption {
  value: number;
  label: string;
}

interface IDropDown {
  currentOption: DropDownOption;
  options: DropDownOption[];
  label?: string;
  className?: string;
  wrapperClassName?: string;
  optionClassName?: string;
  labelClassName?: string;
  icon?: ReactNode;
  loaded?: boolean;
  locked?: boolean;
  black?: boolean;
  containerToScroll?: HTMLDivElement | null;
  handleOnChange: (option: number) => void;
  narrow?: boolean;
}

const DropDown = forwardRef<HTMLDivElement, IDropDown>(
  (
    {
      currentOption,
      options,
      icon,
      label,
      handleOnChange,
      loaded,
      className,
      wrapperClassName,
      optionClassName,
      black,
      containerToScroll,
      locked,
      labelClassName,
      narrow,
    },
    ref
  ) => {
    const dropDownRef = useRef<HTMLDivElement>(null);

    const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);

    const handleCloseDropDownOnOutsideClick = (e: MouseEvent) => {
      if (
        dropDownRef.current &&
        !dropDownRef.current.contains(e.target as Node)
      ) {
        setDropDownOpen(false);
      }
    };

    useEffect(() => {
      const handleClickOutside = (e: MouseEvent) =>
        handleCloseDropDownOnOutsideClick(e);

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    useEffect(() => {
      if (dropDownOpen && containerToScroll) {
        setTimeout(() => {
          containerToScroll.scrollTop = containerToScroll.scrollHeight;
        }, 30);
      }
    }, [dropDownOpen, containerToScroll]);

    return loaded !== false ? (
      <div
        className={`
          text-left

          ${wrapperClassName}
          ${locked ? 'opacity-50' : ''}
        `}
      >
        {label ? (
          <p
            className={`
              ${labelClassName}

              text-sm font-compact text-steelGray
            `}
          >
            {label}
          </p>
        ) : (
          ''
        )}
        <div
          ref={ref}
          className={`
            ${className}

            mt-2 flex h-[35px] w-full gap-2 whitespace-nowrap

            lg:gap-4

            md:gap-3

            ${black ? 'bg-black' : 'bg-lightBlack'}

            rounded-xl

            ${locked ? 'opacity-50' : ''}
          `}
        >
          <div
            ref={dropDownRef}
            className={`
              relative inline-block h-full w-full

              ${
                narrow
                  ? 'sm:min-w-[10rem]'
                  : `
                    lg:min-w-[14.375rem]

                    md:min-w-[11.25rem]

                    sm:min-w-[12.5rem]
                  `
              }
            `}
          >
            <div
              className={`
                relative flex h-full cursor-pointer items-center justify-between
                gap-1 px-4 duration-300

                sm:gap-3

                ${locked ? 'pointer-events-none touch-none' : ''}
              `}
              onClick={() => setDropDownOpen(!dropDownOpen)}
            >
              {icon ? (
                <div
                  className={`
                    absolute left-3 top-1/2 h-fit w-fit -translate-y-1/2
                  `}
                >
                  {icon}
                </div>
              ) : (
                ''
              )}
              <div className="flex items-center align-middle">
                <p
                  className={`
                    ${optionClassName}

                    font-compact align-middle text-xs font-normal !leading-4
                    tracking-[0.009rem] text-white

                    sm:ms-2 sm:text-sm

                    ${icon ? 'pl-6' : ''}
                  `}
                >
                  {currentOption.label}
                </p>
              </div>
              {locked ? (
                <FaLock className="w-[12px]" />
              ) : (
                <span
                  className={`
                    ${dropDownOpen ? 'open rotate-[180deg]' : ''}
                  `}
                >
                  <DownSelectArrow />
                </span>
              )}
            </div>
            {dropDownOpen && (
              <div
                className={`
                  absolute left-0 top-11 z-50 bg-lightBlack w-full rounded-md
                  bg-cover bg-no-repeat
                `}
              >
                <div
                  className={`
                    flex flex-col overflow-hidden rounded-md border border-white
                    border-opacity-[0.1]
                  `}
                >
                  {options.map((option, index) => (
                    <div
                      key={`${option.label}-${index}`}
                      onClick={() => {
                        setDropDownOpen(false);
                        handleOnChange(option.value);
                      }}
                      className={`
                        mb-0 cursor-pointer border-b border-b-white
                        border-opacity-5 p-3 text-sm font-normal
                        tracking-[0.009rem] text-[#B1B1B1] font-compact
                        delay-[20ms]

                        hover:bg-lightGray hover:text-white
                      `}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    ) : (
      <SkeletonDark
        className={`
          w-full rounded-xl

          lg:min-w-[14.375rem]

          md:min-w-[11.25rem]

          sm:min-w-[12.5rem]
        `}
      >
        <input
          type="text"
          className={`
            pointer-events-none p-2 font-thin opacity-0 outline-none
            font-compact
          `}
        />
      </SkeletonDark>
    );
  }
);

export default DropDown;
