import { Link, useNavigate } from 'react-router-dom';
// context
import { useProfileInfoContext } from '@components/completeProfile/ProfileInfoProvider';
// types
import { DBUser } from '@src/firestore/users';
// utils
import { getLegendBackground } from '@utils/Games/ApexLegends';
import { getAgeFromDateOfBirth } from '@utils/Date';
import {
  gameInputStringToIcon,
  platformStringToIcon,
} from '@utils/Players/Icons';
// packages
import '/node_modules/flag-icons/css/flag-icons.min.css';
// assetts
import placeholderImage from '@assets/images/placeholder/placeholder.webp';

const CardCornerChevron = ({ className }: { className: string }) => (
  <svg
    className={`${className}`}
    viewBox="0 0 301 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M104.375 173.938L221.424 286.485C251.288 315.2 301 294.035 301 252.605V47C301 21.0426 279.957 0 254 0H47.9957C6.62477 0 -14.565 49.5911 14.0315 79.4875L104.375 173.938Z"
      fill="#D4FF27"
      className="fill-inherit"
    />
  </svg>
);

interface IProfileBox {
  handleClick: () => void;
  userObj: DBUser | null;
}

const ProfileBox: React.FC<IProfileBox> = ({ handleClick, userObj }) => {
  const navigate = useNavigate();
  const { setCurrentModalStep } = useProfileInfoContext();
  const apexGameEntry = userObj
    ? userObj.addedGames.find((game) => game.title === 'Apex Legends')
    : null;

  const processDisplayNameClass = (displayName: string) => {
    if (displayName.length <= 8) {
      return 'text-xl';
    } else if (displayName.length <= 10) {
      return 'text-base';
    } else {
      return 'text-base';
    }
  };

  return userObj && userObj.profileComplete ? (
    <Link
      to={'/my-profile'}
      onClick={() => {
        handleClick();
        document.body.classList.remove('overflow_hidden');
      }}
      className="relative w-full overflow-hidden flex flex-col group transition-all"
    >
      <CardCornerChevron className="absolute top-[2px] right-[2px] z-[4] w-[12px] h-auto aspect-square fill-green transition-colors" />
      <div className="absolute top-[2px] right-[2px] bg-lightBlack  z-[3] w-[100px] h-auto aspect-square rotate-45 origin-center translate-x-[70%] -translate-y-[70%]  transition-all"></div>

      <div className="h-auto aspect-[2.67] w-full transition-all relative z-[2] rounded-xl overflow-hidden">
        <div className="relative w-full h-full px-4 bg-lightBlack transition-colors">
          <div className="absolute top-0 left-0 group-hover:-top-[2.5px] group-hover:-left-[2.5px] w-full h-full group-hover:w-[calc(100%+5px)] group-hover:h-[calc(100%+5px)] transition-all">
            <div className="absolute top-0 left-0 z-[1] bg-black/70 group-hover:bg-black/35 w-full h-full transition-colors scale-105"></div>
            <img
              src={
                userObj.bannerImage !== ''
                  ? userObj.bannerImage
                  : apexGameEntry
                    ? getLegendBackground(apexGameEntry.mainLegend!)
                    : placeholderImage
              }
              alt={`${userObj.displayName} banner image`}
              className="absolute z-[0] top-0 left-0 w-full h-full object-cover opacity-50"
            />
          </div>

          <div className="relative flex items-center gap-x-4 h-full z-[2]">
            <div className="relative z-[2] h-fit w-fit transition-transform flex-shrink-0">
              <img
                src={userObj.displayImage}
                alt={`${userObj.displayName} display image`}
                className="relative z-[1] w-[75px] h-auto aspect-square rounded-full bg-lightBlack group-hover:bg-lightGray border-[3px] border-lightBlack object-cover transition-all"
              />
            </div>

            <div className="flex flex-col gap-y-1">
              <h3
                className={`font-wide font-semibold uppercase text-white ${processDisplayNameClass(userObj.displayName)}`}
              >
                {userObj.displayName}
              </h3>
              <div className="flex items-center gap-x-3">
                <div
                  className={`opacity-75 h-[10px] rounded-sm overflow-hidden fi fi-${userObj.nationality}`}
                ></div>
                <p className="text-sm text-steelGray group-hover:text-white/65 font-compact font-medium transition-colors -mb-[3px]">
                  {getAgeFromDateOfBirth(userObj.dateOfBirth)}
                </p>
                {apexGameEntry ? (
                  <>
                    <p>{platformStringToIcon(apexGameEntry.platform)}</p>
                    <p>{gameInputStringToIcon(apexGameEntry.input)}</p>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  ) : (
    <div className="mt-3">
      <div
        className="bg-lightGray p-2  rounded-xl flex flex-col items-center gap-y-1
                         font-compact font-semibold text-steelGray "
      >
        <p className="text-sm font-medium">Profile not complete</p>
        {/* {userObj !== null && !userObj.profileComplete ? (
          <p className='text-xs font-thin text-red/70 font-compact text-center'>You must complete your profile</p>
        ) : ''} */}
        <button
          type="button"
          onClick={() => {
            navigate('/my-profile');
            setCurrentModalStep(0);
            handleClick();
            document.body.classList.remove('overflow_hidden');
          }}
          className={`flex justify-center items-center font-compact text-base font-semibold
                p-2 py-3 pb-2 !leading-5 rounded-xl uppercase text-center common_green_btn gap-2 w-full`}
        >
          Complete Profile
        </button>
      </div>
    </div>
  );
};

export default ProfileBox;
